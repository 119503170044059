import React, { useEffect, useState, useRef } from "react";
import {
  Grid2 as Grid,
  Chip,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  TableContainer,
} from "@mui/material";
import Panel from "../../../components/Panel";
import { TableSkeletonComponent } from "../../../components/form/TableComponent";
import IncomeIcon from "../../../assets/IconComponents/IncomeIcon";
import TransferIcon from "../../../assets/IconComponents/TransferIcon";
import moment from "moment";
import { GetAccountTransactionsByAccountId } from "../../../api/IBAReport";

const IconMap: any = {
  income: <IncomeIcon />,
  Health: <IncomeIcon />,
  Services: <TransferIcon />,
  Food: <IncomeIcon />,
};

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid #D5E1F9 !important`,
  fontSize: "14px",
  color: "#616773",
  fontWeight: "600",
}));

const TransactionHistoryComponent = () => {
  const theme = useTheme();
  const [transactionsList, setTransactionsList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const tableRef = useRef<any>(null);

  const fetchTransactions = async (pageNum: number) => {
    if (!hasMore || isLoading) return;

    setIsLoading(true);
    try {
      const data = await GetAccountTransactionsByAccountId(
        8765432,
        pageNum,
        100
      );
      if (data?.data.length > 0) {
        setTransactionsList((prev) => [...prev, ...data.data]);
        setPage(pageNum);
      } else {
        setHasMore(false);
      }
    } catch (err) {
      console.error("Error fetching transactions", err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTransactions(1);
  }, []);

  const handleScroll = () => {
    const table = tableRef.current;
    if (
      table &&
      table.scrollHeight - table.scrollTop <= table.clientHeight + 1
    ) {
      fetchTransactions(page + 1);
    }
  };

  return (
    <Grid size={12} rowSpacing={2} rowGap={5}>
      <Panel
        sx={{
          backgroundColor: theme?.palette?.primary?.main,
          padding: 2,
          color: "white",
          borderRadius: "0px",
          borderBottom: "3px solid #D6C64D",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
          Transaction History
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          Wells Fargo Account: 6200566
        </Typography>
      </Panel>

      <TableContainer
        sx={{ maxHeight: "900px", overflowY: "auto" }}
        onScroll={handleScroll}
        ref={tableRef}
      >
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell>Date</CustomTableCell>
              <CustomTableCell>Category</CustomTableCell>
              <CustomTableCell>Description/Memo</CustomTableCell>
              <CustomTableCell>Deposits ($)</CustomTableCell>
              <CustomTableCell>Withdrawals ($)</CustomTableCell>
              {/* <CustomTableCell sx={{ textAlign: "right" }}>
                Balance ($)
              </CustomTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionsList.length === 0 && !isLoading ? (
              <TableSkeletonComponent rows={2} columns={5} />
            ) : (
              transactionsList.map((transaction: any, index: number) => (
                <TableRow key={`table_row_${index}`}>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {moment(transaction.postedDt).format("MM/DD/YYYY")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      <Chip
                        icon={<IncomeIcon />}
                        label={transaction.category}
                        sx={{
                          backgroundColor: "#F0F8FE",
                          color: "#1D3DB0",
                          width: "180px",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {transaction.memo}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {transaction.trnType === "Credit"
                        ? `$ ${Math.abs(transaction.amt).toLocaleString(
                            "en-US"
                          )}`
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                    >
                      {transaction.trnType === "Debit"
                        ? `$ ${Math.abs(transaction.amt).toLocaleString(
                            "en-US"
                          )}`
                        : "-"}
                    </Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography
                      sx={{
                        textAlign: "right",
                        color: "#333333",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      $ {transaction?.curAmt?.amt || 0}
                    </Typography>
                  </TableCell> */}
                </TableRow>
              ))
            )}
            {isLoading && <TableSkeletonComponent rows={2} columns={5} />}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default TransactionHistoryComponent;
