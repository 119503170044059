import React from 'react'

const ViewIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_86_129131)">
        <circle cx="27" cy="27" r="19" fill="white" />
      </g>
      <g filter="url(#filter1_d_86_129131)">
        <rect x="12.375" y="12.375" width="29.25" height="29.25" rx="14.625" fill="#EFFFFE" />
        <g clipPath="url(#clip0_86_129131)">
          <path d="M27 21.6348C23.5609 21.6348 20.4422 23.5163 18.1408 26.5725C17.9531 26.8229 17.9531 27.1727 18.1408 27.423C20.4422 30.4829 23.5609 32.3644 27 32.3644C30.4391 32.3644 33.5578 30.4829 35.8592 27.4267C36.0469 27.1763 36.0469 26.8265 35.8592 26.5762C33.5578 23.5163 30.4391 21.6348 27 21.6348ZM27.2467 30.7774C24.9638 30.9211 23.0786 29.0395 23.2222 26.7529C23.34 24.8677 24.8681 23.3396 26.7533 23.2218C29.0362 23.0782 30.9214 24.9597 30.7778 27.2463C30.6563 29.1279 29.1283 30.6559 27.2467 30.7774ZM27.1326 29.0321C25.9027 29.1095 24.8865 28.0969 24.9675 26.867C25.0301 25.8508 25.8549 25.0297 26.8711 24.9634C28.101 24.8861 29.1172 25.8987 29.0362 27.1285C28.9699 28.1484 28.1451 28.9695 27.1326 29.0321Z" fill="#01B6A8" />
        </g>
      </g>
      <defs>
        <filter id="filter0_d_86_129131" x="0.6875" y="0.6875" width="54.875" height="54.875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="0.5625" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_86_129131" />
          <feOffset dx="1.125" dy="1.125" />
          <feGaussianBlur stdDeviation="3.9375" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.24975 0 0 0 0 0.405 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_86_129131" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_86_129131" result="shape" />
        </filter>
        <filter id="filter1_d_86_129131" x="5.0625" y="5.0625" width="46.125" height="46.125" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="0.5625" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_86_129131" />
          <feOffset dx="1.125" dy="1.125" />
          <feGaussianBlur stdDeviation="3.9375" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.24975 0 0 0 0 0.405 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_86_129131" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_86_129131" result="shape" />
        </filter>
        <clipPath id="clip0_86_129131">
          <rect width="18" height="18" fill="white" transform="translate(18 18)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default ViewIcon