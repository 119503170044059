import {
  styled,
  TableCell,
  Grid2 as Grid,
  Table,
  Typography,
  TableRow,
  TableHead,
  TableBody,
  useTheme,
  useMediaQuery,
  TableContainer,
} from "@mui/material";
import Panel from "../../../components/Panel";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid #D5E1F9 !important`,
  fontSize: "14px",
  color: "#616773",
  fontWeight: "600",
}));

const RecurringDebits = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid size={12} rowSpacing={2} rowGap={5} sx={{ marginY: 5 }}>
      <Panel
        sx={{
          backgroundColor: theme?.palette?.primary?.main,
          padding: 2,
          color: "white",
          borderRadius: "0px",
          borderBottom: "3px solid #D6C64D",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
          Recurring Debits (4)
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
          $2,505.23 to $2,600.50
        </Typography>
      </Panel>
      <Grid container size={12} spacing={1}>
        {!isSmallScreen && (
          <>
            <Grid size={{ sm: 6 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#616773",
                          fontWeight: "600",
                        }}
                      >
                        Payee
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#616773",
                          fontWeight: "600",
                        }}
                      >
                        Amount
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#616773",
                          fontWeight: "600",
                        }}
                      >
                        Date
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Rocket Mortgage
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $4,756.2
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        6/15/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Rocket Mortgage
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $4,756.2
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        6/15/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid size={{ sm: 6 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#616773",
                          fontWeight: "600",
                        }}
                      >
                        Payee
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#616773",
                          fontWeight: "600",
                        }}
                      >
                        Amount
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#616773",
                          fontWeight: "600",
                        }}
                      >
                        Date
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Rocket Mortgage
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $4,756.2
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        6/15/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Rocket Mortgage
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $4,756.2
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        6/15/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </>
        )}
        {isSmallScreen && (
          <Grid size={{ xs: 12 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#616773",
                          fontWeight: "600",
                        }}
                      >
                        Payee
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#616773",
                          fontWeight: "600",
                        }}
                      >
                        Amount
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#616773",
                          fontWeight: "600",
                        }}
                      >
                        Date
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Rocket Mortgage
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $4,756.2
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        6/15/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                  <TableRow>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#333333",
                        }}
                      >
                        Rocket Mortgage
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        $4,756.2
                      </Typography>
                    </CustomTableCell>
                    <CustomTableCell>
                      <Typography
                        sx={{
                          color: "#797979",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        6/15/2024
                      </Typography>
                    </CustomTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default RecurringDebits;
