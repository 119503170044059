import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid2 as Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "../../assets/IconComponents/AddIcon";
import Panel from "../../components/Panel";
import InputComponent from "../../components/form/input";
import SearchIcon from "../../assets/IconComponents/SearchIcon";
import { useEffect, useState } from "react";
import {
  DataTable,
  PaginationControl,
} from "../../components/form/TableComponent";
import ViewIcon from "../../assets/media/icons/EditIcon.png";
import TruncatedTooltipText from "../../components/form/ToolTip";
import { getFullName } from "../customers";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";
import { GetBilling } from "../../api/billingAPI";
import { useAlertMessage } from "../../utils/useAlertMessage";

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  color: "#ff0000",
  border: "none",
  height: "48px !important",
  "& .MuiButtonGroup-grouped": {
    border: "1px solid #D5E1F9 !important",
  },
  "&:hover": {
    boxShadow: "none !important",
  },
}));

const Billing = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const notification = useAlertMessage();
  const [billingList, setBillingList] = useState<any>([]);
  const [totalRecordsCount, setTotalRecordsCount] = useState<any>(0);
  const [query, setQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    orderBy: "invoiceDate",
    order: "desc",
  });
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getData(query, true);
  }, []);

  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value); 
  };

  const selectBillingStatus = (type: string) => {
    getData({
      ...query,
      pageNumber: 1,
      status: type,
    });
  };

  const createSortHandler = (key: string) => {
    getData({
      ...query,
      pageNumber: 1,
      order: query.order?.toLowerCase() === "asc" ? "desc" : "asc",
      orderBy: key,
    });
  };

  const handlePageChange = (event: any, value: any) => {
    getData({
      ...query,
      pageNumber: value,
    });
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      getData({ ...query, nameQuery: searchValue, pageNumber: 1 });
    }, 500); 
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchValue]);

  const renderStatus = (status: string) => {
    const statusConfig = {
      Paid: {
        iconColor: "#01B6A8",
        backgroundColor: "#EFFFFE",
        textColor: "#01B6A8",
      },
      Failed: {
        iconColor: "#AF6DD1",
        backgroundColor: "#A800FF0F",
        textColor: "#AF6DD1",
      },
    };

    const config = statusConfig[status as keyof typeof statusConfig];
    if (!config) return null;
    return (
      <Chip
        icon={
          <FiberManualRecordIcon
            sx={{ color: `${config.iconColor} !important`, height: "10px" }}
          />
        }
        label={status.charAt(0).toUpperCase() + status.slice(1)}
        sx={{
          backgroundColor: config.backgroundColor,
          color: config.textColor,
          minWidth: "110px",
        }}
      />
    );
  };

  const getData: any = async (query: any, onInitialCall: boolean) => {
    toggleLoading(true);
    if (!onInitialCall) {
      setQuery(query);
    }
    try {
      const result: any = await GetBilling(query);
      setBillingList(result?.data.data || []);
      setTotalRecordsCount(result.data.totalCount);
    } catch (err) {
      notification.errorNotification(
        "Error",
        (err as any).response?.data.message
      );
    } finally {
      toggleLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid container size={12}>
        <Grid size={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography>
            All New Invoices can be generated from this screen
          </Typography>
        </Grid>
      </Grid>
      <Grid size={12} container marginTop={"20px"}>
        <Panel sx={{ width: "100%" }}>
          <Box>
            <Grid size={12} sx={{ padding: "20px 24px 10px" }}>
              <Grid container>
                <Grid size={{ xs: 12, sm: 6 }} container>
                  <Grid size={{ xs: 12, sm: 8, md: 6 }}>
                    <InputComponent
                      onkeyup={handleInputChange}
                      placeholder="Search"
                      icon={<SearchIcon />}
                      backgroundColor="#fff"
                      borderColor="rgba(213, 225, 249, 0.5)"
                    />
                  </Grid>
                </Grid>
                <Grid
                  size={{ xs: 12, sm: 6 }}
                  sx={{
                    textAlign: { xs: "", sm: "right" },
                    marginTop: { xs: "15px", sm: "0px" },
                  }}
                >
                  <StyledButtonGroup>
                    {[
                      { value: undefined, label: "All" },
                      { value: "paid", label: "Paid" },
                      { value: "failed", label: "Failed" },
                    ].map((type: any) => (
                      <Button
                        key={type.label}
                        onClick={() => selectBillingStatus(type.value)}
                        sx={{
                          border: "1px solid #D5E1F9",
                          backgroundColor:
                            query.status === type.value
                              ? "#F1F4FF"
                              : "transparent",
                          color:
                            query.status === type.value ? "#1F3A91" : "#6B7280",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: query.status === type.value ? 600 : 500,
                          }}
                        >
                          {type.label}
                        </Typography>
                      </Button>
                    ))}
                  </StyledButtonGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid size={12} rowSpacing={2} rowGap={5}>
              <DataTable
                columns={[
                  {
                    label: "Invoice ID",
                    id: "id",
                    render: (row) => `#${row.id}`,
                  },
                  {
                    label: "Name",
                    id: "name",
                    render: (row) => {
                      const fullName = row.name;
                      return (
                        <TruncatedTooltipText
                          text={fullName}
                          tooltipPlacement="top"
                        >
                          <Box
                            onClick={() =>
                              navigate(`/admin/billing/${row?.id}/viewbill`, {
                                state: {
                                  fullName,
                                },
                              })
                            }
                            sx={{
                              cursor: "pointer",
                              fontWeight: "600",
                              textDecoration: "underline",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {row.name}
                          </Box>
                        </TruncatedTooltipText>
                      );
                    },
                  },
                  {
                    label: "Products",
                    id: "product",
                    render: (row) => {
                      if (
                        !row.invoiceDetails ||
                        row.invoiceDetails.length === 0
                      )
                        return "-";
                      return row.invoiceDetails
                        .map((detail: any) => detail.product)
                        .join(", ");
                    },
                  },
                  {
                    label: "Invoice Date",
                    id: "invoiceDate",
                    render: (row) =>
                      moment(row.invoiceDate).format("MM/DD/YYYY"),
                  },
                  {
                    label: "Amount",
                    id: "amount",
                    render: (row) => (
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#374151",
                        }}
                      >
                        ${row.amount?.toFixed(2)}
                      </Typography>
                    ),
                  },
                  {
                    label: "Status",
                    id: "status",
                    sortable: false,
                    render: (row) => renderStatus(row.status),
                  },
                  {
                    label: "Action",
                    id: "action",
                    sortable: true,
                    render: (row) => {
                      const fullName = row.name;
                      return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <TruncatedTooltipText text="">
                            <Box
                              component={"img"}
                              src={ViewIcon}
                              onClick={() =>
                                navigate(`/admin/billing/${row?.id}/viewbill`, {
                                  state: {
                                    fullName,
                                  },
                                })
                              }
                              sx={{
                                width: { xs: "50px", md: "3.2rem" },
                                height: { xs: "50px", md: "3.2rem" },
                              }}
                            />
                          </TruncatedTooltipText>
                        </Box>
                      );
                    },
                  },
                ]}
                rows={billingList || []}
                isLoading={isLoading}
                orderBy={query.orderBy}
                order={query.order}
                createSortHandler={createSortHandler}
                NodataTitle="Billing's"
              />
              <PaginationControl
                page={query.pageNumber}
                rowsPerPage={query.pageSize}
                totalItems={totalRecordsCount}
                handlePageChange={handlePageChange}
              />
            </Grid>
          </Box>
        </Panel>
      </Grid>
    </Grid>
  );
};

export default Billing;
