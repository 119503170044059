const DeleteIcon = () => {
  return (
<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="21" cy="21" r="21" fill="#FF220C" fill-opacity="0.1"/>
<path d="M28 13H24.5L23.5 12H18.5L17.5 13H14V15H28M15 28C15 28.5304 15.2107 29.0391 15.5858 29.4142C15.9609 29.7893 16.4696 30 17 30H25C25.5304 30 26.0391 29.7893 26.4142 29.4142C26.7893 29.0391 27 28.5304 27 28V16H15V28Z" fill="#FF220C"/>
</svg>

  )
}

export default DeleteIcon