const DTIIcon = () => {
  return (
    <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 13C0 5.8203 5.8203 0 13 0H61C68.1797 0 74 5.8203 74 13V61C74 68.1797 68.1797 74 61 74H13C5.8203 74 0 68.1797 0 61V13Z" fill="#EFF5FF"/>
<g clip-path="url(#clip0_2739_59624)">
<path d="M15.0156 14.9082L9 20.2793V59.166C9 61.8358 11.1642 64 13.834 64H36.5L41.8711 39.6152L36.5 14.9082H15.0156Z" fill="#FEFEFE"/>
<path d="M57.9844 14.9082H36.5V64H59.166C61.8358 64 64 61.8358 64 59.166V18.1309L57.9844 14.9082Z" fill="#F7FAFF"/>
<path d="M31.666 9L30.0547 12.1152L28.4434 9H25.2207L23.6094 12.1152L21.998 9H18.7754L17.1641 12.1152L15.5527 9H13.9414C12.6573 9 11.4334 9.49382 10.4955 10.3905C9.53109 11.3124 9 12.5354 9 13.834V20.2793H33.9712C34.2214 20.2793 34.468 20.2211 34.6918 20.1091L36.5 19.2051L40.7969 14.1562L36.5 9H31.666Z" fill="#3E75F3"/>
<path d="M59.166 9H36.5V19.2051L38.6484 18.1309H64V13.834C64 11.1685 61.8315 9 59.166 9Z" fill="#2036AD"/>
<path d="M17.1641 15.4453C16.2742 15.4453 15.5527 14.7239 15.5527 13.834V9H18.7754V13.834C18.7754 14.7239 18.0539 15.4453 17.1641 15.4453Z" fill="#2036AD"/>
<path d="M23.6094 15.4453C22.7195 15.4453 21.998 14.7239 21.998 13.834V9H25.2207V13.834C25.2207 14.7239 24.4993 15.4453 23.6094 15.4453Z" fill="#6AA8FF"/>
<path d="M30.0547 15.4453C29.1648 15.4453 28.4434 14.7239 28.4434 13.834V9H31.666V13.834C31.666 14.7239 30.9446 15.4453 30.0547 15.4453Z" fill="#8EBBFF"/>
<path d="M36.5 40.2598C35.6101 40.2598 34.8887 40.9812 34.8887 41.8711V49.3906H36.5L37.3594 44.8252L36.5 40.2598Z" fill="#2036AD"/>
<path d="M38.1113 41.8711C38.1113 40.9812 37.3899 40.2598 36.5 40.2598V49.3906H38.1113V41.8711Z" fill="#0C1C70"/>
<path d="M36.5 47.7793C33.8345 47.7793 31.666 49.9478 31.666 52.6133C31.666 55.2787 33.8345 57.4473 36.5 57.4473L38.2188 52.6133L36.5 47.7793Z" fill="#3E75F3"/>
<path d="M41.334 52.6133C41.334 49.9478 39.1655 47.7793 36.5 47.7793V57.4473C39.1655 57.4473 41.334 55.2787 41.334 52.6133Z" fill="#6AA8FF"/>
<path d="M36.5 25.2207C24.9497 25.2207 15.5527 34.6176 15.5527 46.168C15.5527 47.0579 16.2742 47.7793 17.1641 47.7793H25.7578C26.6477 47.7793 27.3691 47.0579 27.3691 46.168C27.3691 41.1332 31.4652 37.0371 36.5 37.0371L40.582 31.1289L36.5 25.2207Z" fill="#EEEEF0"/>
<path d="M24.2515 44.5551H18.8484C19.1814 40.8756 20.6439 37.5172 22.8853 34.8301L26.7043 38.6489C25.4109 40.3302 24.5396 42.3521 24.2515 44.5551Z" fill="#2036AD"/>
<path d="M34.8886 33.9245C32.6857 34.2127 30.6636 35.083 28.9823 36.3762L25.1636 32.5575C27.8508 30.3163 31.2092 28.8546 34.8886 28.5215V33.9245Z" fill="#3E75F3"/>
<path d="M45.6309 46.168C45.6309 47.0579 46.3523 47.7793 47.2422 47.7793H55.8359C56.7258 47.7793 57.4473 47.0579 57.4473 46.168C57.4473 34.6176 48.0503 25.2207 36.5 25.2207V37.0371C41.5348 37.0371 45.6309 41.1332 45.6309 46.168Z" fill="#DDDDE1"/>
<path d="M54.151 44.5552H48.7479C48.4597 42.3522 47.5885 40.3304 46.2952 38.649L50.1141 34.8301C52.3555 37.5175 53.8181 40.8757 54.151 44.5552Z" fill="#8EBBFF"/>
<path d="M38.1114 28.5215C41.7909 28.8546 45.1492 30.3164 47.8364 32.5575L44.0177 36.3762C42.3364 35.0829 40.3142 34.2127 38.1113 33.9245V28.5215H38.1114Z" fill="#6AA8FF"/>
</g>
<defs>
<clipPath id="clip0_2739_59624">
<rect width="55" height="55" fill="white" transform="translate(9 9)"/>
</clipPath>
</defs>
</svg>

  )
}

export default DTIIcon