const AcuPayIcon = () => {
  return (
    <svg width="63" height="64" viewBox="0 0 63 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3745_87607)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5956 24.6541V23.0968C22.5956 23.0967 22.5956 23.6159 22.5956 24.6541ZM22.5957 30.0665C22.5956 30.0565 22.5954 30.0465 22.5956 30.0364L22.5957 30.0665ZM29.001 23.5968C29.001 20.5736 26.931 18.0321 23.9705 17.4202L22.5956 17.136L22.4728 17.1106C16.3449 15.8441 15.1458 22.0454 11.8888 23.8108C11.8889 23.9091 11.5983 24.0069 11.5984 24.1044C11.599 24.7131 11.2589 25.2984 11.2035 25.8796C11.0085 27.9266 11.1601 29.8051 11.1611 31.4346C11.1654 38.0799 11.8975 41.3326 11.8889 41.3187C15.0119 44.31 18.0476 44.348 22.5957 43.6495L24.0299 34.3275L22.5957 30.0665C22.6119 31.8143 24.0468 33.2391 25.7984 33.2391C27.5599 33.2391 29.0011 31.7979 29.0011 30.0363V23.5968H29.001Z" fill="#4378D0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5961 43.6506C23.0645 43.604 23.1061 42.3689 22.5961 42.4201C18.0883 42.8724 14.9839 43.0532 11.8893 40.0894C11.8978 40.1034 11.1657 36.8506 11.1615 30.2053C11.161 29.3993 11.1236 28.5323 11.1097 27.6143C11.0841 28.9945 11.1608 30.278 11.1615 31.4357C11.1658 38.081 11.898 41.3337 11.8893 41.3198C15.0124 44.311 18.0481 44.349 22.5961 43.6506Z" fill="#083885"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0566 31.7796C15.5151 31.8051 15.0554 31.3867 15.0299 30.8452C15.0044 30.3037 15.4228 29.844 15.9643 29.8185L16.0262 29.8155C17.7101 29.7337 21.611 29.544 21.611 24.654V23.0967C21.611 22.5531 22.0517 22.1123 22.5953 22.1123C23.1389 22.1123 23.5797 22.5531 23.5797 23.0967V24.6541C23.5797 31.4139 18.3683 31.6673 16.1185 31.7767L16.0566 31.7796Z" fill="#083885"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8884 23.7998C11.8926 28.1386 11.897 41.3334 11.8884 41.3193H4.8623C4.52343 41.3193 4.24707 41.043 4.24707 40.7041V24.415C4.24707 24.0762 4.52343 23.7998 4.8623 23.7998H11.8884Z" fill="url(#paint0_linear_3745_87607)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8882 23.7998C11.8924 28.1386 11.8968 41.3334 11.8882 41.3193H10.6577C10.6663 41.3334 10.6619 28.1386 10.6577 23.7998H11.8882Z" fill="#78A6F1"/>
    <path d="M9.21306 27.5132C9.39198 26.8827 9.02592 26.2266 8.39545 26.0477C7.76497 25.8687 7.10883 26.2348 6.92992 26.8653C6.751 27.4957 7.11706 28.1519 7.74753 28.3308C8.378 28.5097 9.03414 28.1437 9.21306 27.5132Z" fill="#FFECFF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M48.6403 43.1634H52.6606C54.0303 43.1635 55.1509 42.0428 55.1509 40.6731C55.1509 39.3035 54.0305 38.1827 52.6606 38.1827H54.146C55.5158 38.1826 56.6364 37.062 56.6364 35.6922C56.6364 34.3223 55.5156 33.2019 54.146 33.2019H55.8935C57.2634 33.2019 58.384 32.0813 58.384 30.7115C58.384 29.3418 57.2633 28.221 55.8935 28.221H54.8444C56.2143 28.221 57.3349 27.1005 57.3349 25.7306C57.3349 24.3607 56.2142 23.2402 54.8445 23.2402H48.6403C47.2705 23.2401 46.1499 24.3607 46.1499 25.7306C46.1499 27.1003 47.2705 28.221 48.6403 28.221H47.5912C46.2213 28.221 45.1006 29.3418 45.1006 30.7115C45.1006 32.0813 46.2213 33.2019 47.5912 33.2019H48.6403C47.2704 33.2019 46.1499 34.3225 46.1498 35.6922C46.1499 37.062 47.2705 38.1827 48.6403 38.1827C47.2704 38.1827 46.1498 39.3034 46.1499 40.6731C46.1499 42.0428 47.2705 43.1634 48.6403 43.1634Z" fill="#4378D0"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M52.9189 43.149C53.996 43.0369 54.8748 42.2307 55.0969 41.1889L49.8983 41.1865L48.3521 41.1892C47.441 41.1892 46.6852 41.5456 46.5444 42.0123C46.9464 42.6376 47.6201 43.0736 48.3952 43.1502L49.8983 43.1476L52.9189 43.149Z" fill="#083885"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.2229 38.1812C55.3775 38.1459 56.3407 37.315 56.5797 36.2212L51.2019 36.2188L49.6556 36.2215C48.6442 36.2215 47.8242 36.6605 47.8242 37.202C47.8242 37.7436 48.6442 38.1826 49.6556 38.1826L51.2019 38.1799L54.2229 38.1812Z" fill="#083885"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.146 33.2012H55.8935C57.0846 33.2012 58.087 32.354 58.3285 31.2324H49.4606C48.5133 31.2324 47.7451 31.6732 47.7451 32.2168C47.7451 32.7604 48.5132 33.2012 49.4606 33.2012H54.146Z" fill="#083885"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M54.8447 28.2207C56.0222 28.2207 57.0156 27.3926 57.2713 26.2899C57.1601 26.2652 57.0429 26.252 56.9216 26.252H48.925C48.2242 26.252 47.6562 26.6927 47.6562 27.2363C47.6562 27.7799 48.2244 28.2207 48.925 28.2207H54.8447Z" fill="#083885"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M52.568 59.1713C52.568 60.4764 51.4994 61.545 50.1943 61.545H24.9689C23.6635 61.545 22.5952 60.4769 22.5952 59.1713V9.10124C22.5952 7.79571 23.6634 6.72754 24.9689 6.72754H50.1944C51.4999 6.72754 52.5681 7.79596 52.5681 9.10124L52.568 59.1713Z" fill="url(#paint1_linear_3745_87607)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M52.5679 59.1713C52.5679 60.4764 51.4994 61.545 50.1942 61.545H48.626C49.9311 61.545 50.9997 60.4765 50.9997 59.1713V9.10124C50.9997 7.79596 49.9315 6.72754 48.626 6.72754H50.1942C51.4997 6.72754 52.5679 7.79596 52.5679 9.10124V59.1713Z" fill="#C4E2FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4982 54.5146H39.6662C40.5238 54.5146 41.2254 55.2163 41.2254 56.0739C41.2254 56.9315 40.5238 57.6331 39.6662 57.6331H35.4982C34.6406 57.6331 33.939 56.9315 33.939 56.0739C33.939 55.2163 34.6406 54.5146 35.4982 54.5146Z" fill="#2070BD"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1401 6.72754H46.0244L44.9839 9.92983C44.9198 10.127 44.7437 10.2549 44.5365 10.2549H30.6279C30.4206 10.2549 30.2445 10.127 30.1805 9.92983L29.1401 6.72754Z" fill="#F0F6FF"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.1396 6.72754H30.8308L31.5802 9.03393C31.6321 9.19377 31.7748 9.2975 31.9429 9.2975H43.2206C43.3887 9.2975 43.5315 9.19377 43.5834 9.03393L44.3329 6.72754H46.024L44.9835 9.92983C44.9194 10.127 44.7435 10.2549 44.5361 10.2549H30.6275C30.4202 10.2549 30.2441 10.127 30.1801 9.92983L29.1396 6.72754Z" fill="#447DDA"/>
    <path d="M41.9119 42.7878C46.1288 40.3982 47.6102 35.0426 45.2206 30.8257C42.831 26.6088 37.4754 25.1274 33.2584 27.517C29.0415 29.9066 27.5602 35.2622 29.9498 39.4791C32.3394 43.6961 37.695 45.1774 41.9119 42.7878Z" fill="url(#paint2_linear_3745_87607)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M37.5819 26.3945C42.429 26.3945 46.3582 30.3238 46.3582 35.1708C46.3582 40.0179 42.429 43.9472 37.5819 43.9472C37.3288 43.9472 37.0783 43.9358 36.8306 43.9148C41.3257 43.5336 44.8556 39.7648 44.8556 35.1708C44.8556 30.5769 41.3258 26.8081 36.8306 26.4269C37.0783 26.4059 37.3287 26.3945 37.5819 26.3945Z" fill="#123369"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.1007 32.1128C41.3172 32.6291 41.0742 33.2233 40.558 33.4397C40.0417 33.6563 39.4475 33.4133 39.231 32.8971C39.0058 32.3609 38.5787 32.0514 38.1096 31.9325C37.8035 31.8549 37.4841 31.8501 37.1878 31.9097C36.9035 31.9669 36.643 32.0817 36.4409 32.2459C36.2035 32.4389 36.0528 32.7164 36.0695 33.0592C36.0842 33.3616 36.2447 33.5708 36.4688 33.7169C36.8104 33.9398 37.2919 34.0654 37.7526 34.1411C38.4635 34.2578 39.2413 34.4537 39.8978 34.85C40.6725 35.3177 41.2568 36.0221 41.3885 37.1065C41.3982 37.1869 41.4045 37.2864 41.4072 37.4032C41.4309 38.4028 40.9763 39.1467 40.293 39.6652C39.804 40.0363 39.2012 40.2757 38.5963 40.3982V40.7246C38.5963 41.2847 38.1422 41.7388 37.5823 41.7388C37.0223 41.7388 36.5682 41.2847 36.5682 40.7246V40.3779C36.0872 40.2671 35.6297 40.0779 35.2275 39.81C34.5372 39.3503 34.0084 38.6717 33.7844 37.7735C33.6509 37.2309 33.9826 36.6829 34.5252 36.5494C35.0677 36.4159 35.6157 36.7476 35.7492 37.2901C35.8399 37.654 36.0584 37.9318 36.3446 38.1224C36.7131 38.3678 37.1886 38.4847 37.6732 38.4731C37.751 38.4713 37.8505 38.463 37.9715 38.4484C38.3839 38.3983 38.7912 38.2708 39.0731 38.057C39.2652 37.9113 39.3931 37.7105 39.387 37.4508C39.3866 37.4368 39.3832 37.4017 39.3762 37.3444C39.3332 36.9904 39.127 36.7512 38.8521 36.5852C38.459 36.3478 37.928 36.2199 37.4278 36.1378C36.738 36.0245 35.9871 35.817 35.3677 35.4126C34.6305 34.9313 34.101 34.2186 34.0495 33.1544C33.9984 32.102 34.4512 31.2583 35.1656 30.6773C35.566 30.3518 36.05 30.1158 36.5682 29.9808V29.6175C36.5682 29.0576 37.0223 28.6035 37.5823 28.6035C38.1424 28.6035 38.5963 29.0576 38.5963 29.6175V29.9668L38.601 29.9679C39.6478 30.2333 40.6005 30.9216 41.1007 32.1128Z" fill="#D8E6FF"/>
    <path d="M55.9069 18.9992C59.6904 15.2156 59.6904 9.08124 55.9069 5.29765C52.1233 1.51406 45.9889 1.51406 42.2053 5.29765C38.4217 9.08123 38.4217 15.2156 42.2053 18.9992C45.9889 22.7828 52.1233 22.7828 55.9069 18.9992Z" fill="url(#paint3_linear_3745_87607)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M49.0622 2.45508C54.4137 2.45508 58.7521 6.79346 58.7521 12.145C58.7521 17.4966 54.4137 21.835 49.0622 21.835C48.8119 21.835 48.5638 21.8255 48.3184 21.8068C53.3223 21.4269 57.2645 17.2463 57.2645 12.145C57.2645 7.04374 53.3223 2.8631 48.3184 2.48313C48.5638 2.46455 48.8119 2.45508 49.0622 2.45508Z" fill="#0C3881"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M47.7896 12.999L52.5013 8.24028C53.0591 7.67697 53.9689 7.67537 54.5306 8.234C55.0922 8.79239 55.0939 9.69974 54.5367 10.2621C52.6261 12.1905 50.7284 14.1315 48.8089 16.051C48.2478 16.6121 47.3375 16.6121 46.7763 16.051L43.5917 12.8665C43.0306 12.3054 43.0306 11.3951 43.5917 10.8339C44.1528 10.2726 45.0631 10.2726 45.6243 10.8339L47.7896 12.999Z" fill="#ECEFF1"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5957 24.6539V30.0362V30.0662C22.6119 31.8141 24.0468 33.239 25.7984 33.239C27.5599 33.239 29.0012 31.7978 29.0012 30.0362V23.5966C29.0012 20.5734 26.9311 18.0319 23.9706 17.42L22.5957 17.1357V23.0965V24.6539Z" fill="#BCD6FF"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_3745_87607" x1="8.0702" y1="23.7998" x2="8.0702" y2="41.3194" gradientUnits="userSpaceOnUse">
    <stop stop-color="#215DBE"/>
    <stop offset="1" stop-color="#053482"/>
    </linearGradient>
    <linearGradient id="paint1_linear_3745_87607" x1="37" y1="4.24486e-07" x2="37.5816" y2="61.545" gradientUnits="userSpaceOnUse">
    <stop stop-color="#D8ECFE"/>
    <stop offset="1" stop-color="#99BDF8"/>
    </linearGradient>
    <linearGradient id="paint2_linear_3745_87607" x1="33.2584" y1="27.517" x2="41.9119" y2="42.7878" gradientUnits="userSpaceOnUse">
    <stop stop-color="#2680C7"/>
    <stop offset="1" stop-color="#1958A6"/>
    </linearGradient>
    <linearGradient id="paint3_linear_3745_87607" x1="41" y1="1.5" x2="50" y2="20.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="#288ACD"/>
    <stop offset="1" stop-color="#1D57A0"/>
    </linearGradient>
    <clipPath id="clip0_3745_87607">
    <rect width="63" height="63" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default AcuPayIcon