import React, { useState, ChangeEvent, ReactNode } from "react";
import {
  InputLabel,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface InputComponentProps {
  label?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onkeyup?: React.KeyboardEventHandler<HTMLInputElement>;
  icon?: ReactNode;
  rows?: number;
  placeholder?: string;
  type?: string;
  password?: boolean;
  error?: string | null;
  backgroundColor?: string;
  borderColor?: string;
  isRequired?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  sx?: object; 
  maxLength?:number;
  minLength?:number;
}

const InputComponent: React.FC<InputComponentProps> = ({
  label,
  value,
  onChange,
  icon,
  rows,
  placeholder = "",
  password = false,
  error,
  type = "text",
  backgroundColor = "#f7f9fc",
  borderColor = "#D5E1F9",
  isRequired = false,
  disabled = false,
  children,
  sx = {}, 
  maxLength,
  minLength,
  onkeyup
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      {label && (
        <Typography
          sx={{ fontSize: "14px", color: "#374151", fontWeight: "600" }}
        >
          {label}{" "}
          {isRequired && (
            <span
              style={{
                color: "#FF220C",
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              *
            </span>
          )}
        </Typography>
      )}
      <TextField
        size="small"
        rows={rows}
        onKeyUp={onkeyup}
        type={password && !showPassword ? "password" : "text"}
        value={value}
        autoComplete="off"
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        InputProps={{
          startAdornment: icon && (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
          endAdornment: password && (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            ...sx, 
            fontSize: "16px",
            color: "#6B7280",
            height: "40px !important",
            backgroundColor,
            "& .Mui-disabled": {
              backgroundColor: "#F9FAFB",
              color: "#6B7280",
              border: "none",
              "& .MuiOutlinedInput-notchedOutline": {
                border: borderColor,
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor:  error
                ? "#E94832"
                : borderColor,
              borderRadius: "8px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor:  error
                ? "#E94832"
                : borderColor,
              borderRadius: "8px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor:  error
                ? "#E94832"
                : borderColor,
              borderRadius: "8px",
            },
          },
        }}
        fullWidth
        inputProps={{
          readOnly: disabled,
          maxLength: maxLength, 
          minLength: minLength, 
        }}
        sx={{
          fontSize: "16px",
          color: "#6B7280",
          background: "#F9FAFB",
          marginTop: "5px",
          fontWeight: "400",
          ...sx, 
        }}
      />
      {error && (
        <Typography
          style={{ color: "#DF6060", fontSize: "14px", fontWeight: "600" }}
        >
          {error}
        </Typography>
      )}
      {children}
    </>
  );
};

export default InputComponent;
