import React from 'react'

const EditIcon = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_86_129121)">
        <circle cx="27" cy="27" r="19" fill="white" />
      </g>
      <g filter="url(#filter1_d_86_129121)">
        <rect x="12.375" y="12.375" width="29.25" height="29.25" rx="14.625" fill="#EFFFFE" />
        <path d="M25 29.0005V26.3855L33.944 17.4415C34.0547 17.3308 34.1707 17.2541 34.292 17.2115C34.4133 17.1688 34.5417 17.1478 34.677 17.1485C34.803 17.1485 34.9257 17.1698 35.045 17.2125C35.1643 17.2551 35.273 17.3251 35.371 17.4225L36.483 18.5005C36.5897 18.6111 36.6703 18.7328 36.725 18.8655C36.7797 18.9981 36.8073 19.1315 36.808 19.2655C36.8087 19.3995 36.7883 19.5268 36.747 19.6475C36.707 19.7675 36.6317 19.8825 36.521 19.9925L27.52 29.0005H25ZM34.466 20.6465L35.813 19.2855L34.702 18.1155L33.315 19.4965L34.466 20.6465ZM20.615 35.0005C20.1543 35.0005 19.77 34.8465 19.462 34.5385C19.154 34.2305 19 33.8458 19 33.3845V20.6165C19 20.1558 19.1543 19.7715 19.463 19.4635C19.7717 19.1555 20.156 19.0011 20.616 19.0005H29.002L22.616 25.3875V31.3855H28.512L35 24.8955V33.3845C35 33.8445 34.846 34.2288 34.538 34.5375C34.23 34.8461 33.8453 35.0005 33.384 35.0005H20.615Z" fill="#01B6A8" />
      </g>
      <defs>
        <filter id="filter0_d_86_129121" x="0.6875" y="0.6875" width="54.875" height="54.875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="0.5625" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_86_129121" />
          <feOffset dx="1.125" dy="1.125" />
          <feGaussianBlur stdDeviation="3.9375" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.24975 0 0 0 0 0.405 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_86_129121" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_86_129121" result="shape" />
        </filter>
        <filter id="filter1_d_86_129121" x="5.0625" y="5.0625" width="46.125" height="46.125" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="0.5625" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_86_129121" />
          <feOffset dx="1.125" dy="1.125" />
          <feGaussianBlur stdDeviation="3.9375" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.24975 0 0 0 0 0.405 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_86_129121" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_86_129121" result="shape" />
        </filter>
      </defs>
    </svg>

  )
}

export default EditIcon