import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { InputAdornment, Typography } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MultipleSelectChipProps {
  options: string[];
  label: string;
  value: string[];
  onChange: (selected: string[]) => void;
  fullWidth?: boolean;
  error?: string | null;
  required?: boolean;
icon?: React.ReactNode;
  placeholder?: string;
  backgroundColor?: string;
}

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const MultipleSelectChip: React.FC<MultipleSelectChipProps> = ({
  options,
  label,
  value,
  onChange,
  fullWidth = false,
  error = null,
  placeholder = "Please select",
  required,
  icon,
}) => {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    onChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl sx={{ width: fullWidth ? "100%" : 300 }} error={!!error}>
      <Typography
        sx={{
          fontSize: "14px",
          color: "#111928",
          fontWeight: "600",
        }}
      >
        {label ? label : ""}&nbsp;
        {required && (
          <Typography component={"span"} color="#FF220C">
            *
          </Typography>
        )}
      </Typography>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        sx={{
          backgroundColor: "transparent",
          border: "1px solid #D1D5DB",
          borderRadius: "8px",
          fontSize: "16px",
          fontWeight: "400",
          color: "#6B7280",
          height:"max-content",
          "&:hover": {
            borderColor: "none !important",
          },
          "&.Mui-focused": {
            borderColor: "none !important",
          },
          "& .MuiSelect-select": {
            padding: "8px", 
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
        input={
          <OutlinedInput 
            placeholder={`Select${label}`}
            id="select-multiple-chip"
            label={label}
            sx={{
              borderRadius: "8px !important",
              fontSize: "16px !important",
              color: "#6B7280 !important",
              fontWeight: "400 !important",
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#D5E1F9 !important",
                  backgroundColor:"#F9FAFB"
                },
                "&:hover fieldset": {
                  borderColor: "#D5E1F9 !important",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#2563EB !important",
                },
                "&.MuiOutlinedInput-root.Mui-focused": {
                  height: "auto !important",
                },
              },
            }}
          />
        }
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((value) => (
            <Chip key={value} label={value}   />
          ))}
        </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
            style={getStyles(option, value, theme)}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Box sx={{ color: "#DF6060", fontSize: "14px", fontWeight: "600" }}>
          {error}
        </Box>
      )}
    </FormControl>
  );
};

export default MultipleSelectChip;
