import React from 'react';

const AcuCheckIcon: React.FC = () => {
    return (
        <svg width="61" height="64" viewBox="0 0 61 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M60.0074 44.3849C59.9654 44.4059 59.9234 44.4269 59.8708 44.4479L57.4333 45.3515C56.9395 45.5301 56.3931 45.2779 56.204 44.7841L53.1045 36.3788C52.9154 35.885 53.1781 35.3386 53.6719 35.16L54.8592 34.7188C54.4704 34.9499 54.2918 35.4332 54.4494 35.864L57.5489 44.2693C57.7275 44.7631 58.2843 45.0153 58.7676 44.8366L60.0074 44.3849Z" fill="#285092"/>
        <path d="M60.0185 44.3851C60.008 44.3956 58.7892 44.8368 58.7787 44.8368C58.2849 45.026 57.7386 44.7633 57.5599 44.2695L55.7843 39.4469L55.1014 37.5978L54.4605 35.8537C54.3029 35.4124 54.4815 34.9396 54.8702 34.7084L56.1205 34.2461C56.6143 34.057 57.1607 34.3197 57.3393 34.8135C57.665 35.7066 58.3269 37.5032 58.6526 38.3963L60.4388 43.2293C60.6069 43.6706 60.4178 44.1539 60.0185 44.3851Z" fill="#4073C8"/>
        <path d="M59.6731 17.2039C58.0025 12.686 52.8332 9.09277 48.2208 10.9735C37.9663 14.8819 39.6999 29.6858 50.3957 31.2513C57.2565 31.2513 62.1842 23.613 59.6731 17.2039ZM50.8369 29.0554C47.0966 29.5282 43.3982 27.3848 42.0323 23.7075C40.2252 18.948 43.3247 13.5581 48.3364 12.7071C52.1923 12.0031 56.1533 14.1675 57.5612 17.9919C59.4524 22.909 55.9957 28.467 50.8369 29.0554Z" fill="#4073C8"/>
        <path d="M50.3947 31.2513C50.5417 31.2723 50.6783 31.2933 50.8254 31.3038C46.1815 31.7661 41.6216 29.0554 39.9405 24.4745C37.1772 17.372 43.2711 9.51305 50.8254 10.3746C38.4275 11.9296 38.2174 29.2761 50.3947 31.2513Z" fill="#285092"/>
        <path d="M53.4205 28.2888C49.1443 30.4112 43.6913 28.3098 42.0418 23.7079C38.6691 13.2643 53.3155 7.81139 57.5602 17.9818C59.0416 22.0059 57.2029 26.4502 53.4205 28.2888Z" fill="#EFF5F6"/>
        <path d="M54.3974 30.2959L55.8893 34.341L53.9246 35.0659L52.4326 31.0209C53.105 30.8527 53.767 30.6111 54.3974 30.2959Z" fill="#E1ECF0"/>
        <path d="M55.7846 39.4576L54.4293 39.9514L53.7568 38.1127L55.1017 37.6084L55.7846 39.4576Z" fill="#8DB0E9"/>
        <path d="M55.1221 37.6455L58.0029 36.5871L58.6807 38.432L55.7999 39.4904L55.1221 37.6455Z" fill="#ADCBFD"/>
        <path d="M48.8605 24.0748C48.6084 24.0958 48.2827 23.9172 48.1671 23.7911C48.062 23.6756 45.9081 21.5427 45.8556 21.4797C45.0151 20.4921 46.4335 19.2628 47.2845 20.1348C47.3265 20.1769 48.871 21.7213 48.871 21.7213L52.3277 18.2646C53.2523 17.3716 54.6077 18.7269 53.7146 19.6515L49.5645 23.8016C49.3753 23.9698 49.1232 24.0748 48.8605 24.0748Z" fill="#88A8FF"/>
        <path d="M39.9577 24.6199C41.3723 28.4564 44.748 31.007 48.531 31.5321V34.3612C48.531 34.9506 48.0594 35.4329 47.4593 35.4329H41.6617C41.4581 34.7685 41.083 33.5682 40.8794 32.8824H22.2969V1.59018C22.2969 1.00077 22.7684 0.507812 23.3685 0.507812H41.3938L48.531 7.645V10.2813C41.8546 11.16 37.5465 18.3186 39.9577 24.6199Z" fill="#E1ECFF"/>
        <path d="M22.2959 3.2832V32.8822H15.3516V4.36557C15.3516 3.77616 15.8231 3.2832 16.4232 3.2832H22.2959Z" fill="#C5DAFF"/>
        <path d="M41.4902 6.56616V0.507812L48.619 7.63654H42.5606C41.9612 7.63654 41.4902 7.15487 41.4902 6.56616Z" fill="#E1ECF0"/>
        <path d="M27.9432 11.5668H26.2392C25.7891 11.5668 25.4141 11.2025 25.4141 10.7524V6.85156C25.4141 6.40147 25.7784 6.03711 26.2392 6.03711H27.9432C27.4931 6.03711 27.118 6.40147 27.118 6.85156V10.7524C27.118 11.1917 27.4931 11.5668 27.9432 11.5668Z" fill="#8DB0E9"/>
        <path d="M30.944 6.85179V10.7526C30.944 11.2027 30.5796 11.5671 30.1295 11.5671H27.9433C27.4932 11.5671 27.1182 11.2027 27.1182 10.7526V6.85179C27.1182 6.4017 27.4825 6.03734 27.9433 6.03734H30.1295C30.5796 6.02662 30.944 6.39098 30.944 6.85179Z" fill="#ADCBFD"/>
        <path d="M27.857 10.3241C27.5355 10.3884 26.8067 9.5311 26.421 9.23103C25.853 8.71664 26.6353 7.84861 27.2033 8.363L27.8034 8.90954L29.1108 7.47353C29.6252 6.90555 30.4932 7.68786 29.9788 8.26655L28.2856 10.1312C28.1677 10.2598 28.007 10.3241 27.857 10.3241Z" fill="white"/>
        <path d="M27.9432 20.7416H26.2392C25.7891 20.7416 25.4141 20.3773 25.4141 19.9272V16.0264C25.4141 15.5763 25.7784 15.2119 26.2392 15.2119H27.9432C27.4931 15.2119 27.118 15.5763 27.118 16.0264V19.9272C27.118 20.3773 27.4931 20.7416 27.9432 20.7416Z" fill="#8DB0E9"/>
        <path d="M30.943 16.0254V19.9262C30.943 20.3763 30.5786 20.7407 30.1285 20.7407H27.9424C27.4923 20.7407 27.1172 20.3763 27.1172 19.9262V16.0254C27.1172 15.5753 27.4816 15.2109 27.9424 15.2109H30.1285C30.5786 15.2109 30.943 15.5753 30.943 16.0254Z" fill="#ADCBFD"/>
        <path d="M27.8578 19.4979C27.5363 19.5622 26.8076 18.7049 26.4218 18.4049C25.8538 17.8905 26.6361 17.0224 27.2148 17.5368L27.8149 18.0834L29.1116 16.6474C29.626 16.0794 30.4941 16.8617 29.9797 17.4404L28.2865 19.305C28.1686 19.4336 28.0185 19.4979 27.8578 19.4979Z" fill="white"/>
        <path d="M27.9432 29.9145H26.2392C25.7891 29.9145 25.4141 29.5501 25.4141 29.1V25.1992C25.4141 24.7491 25.7784 24.3848 26.2392 24.3848H27.9432C27.4931 24.3848 27.118 24.7491 27.118 25.1992V29.1C27.118 29.5501 27.4931 29.9145 27.9432 29.9145Z" fill="#8DB0E9"/>
        <path d="M30.943 25.21V29.1108C30.943 29.5609 30.5786 29.9252 30.1285 29.9252H27.9424C27.4923 29.9252 27.1172 29.5609 27.1172 29.1108V25.21C27.1172 24.7599 27.4816 24.3955 27.9424 24.3955H30.1285C30.5786 24.3955 30.943 24.7599 30.943 25.21Z" fill="#ADCBFD"/>
        <path d="M27.8578 28.6708C27.5363 28.7351 26.8076 27.8778 26.4218 27.5777C25.8538 27.0633 26.6361 26.1953 27.2148 26.7097L27.8149 27.2562L29.1116 25.8202C29.626 25.2522 30.4941 26.0345 29.9797 26.6132L28.2865 28.4779C28.1686 28.6065 28.0185 28.6708 27.8578 28.6708Z" fill="white"/>
        <path d="M36.9133 7.87025H33.484C32.7124 7.87025 32.7124 6.70215 33.484 6.70215H36.9133C37.6741 6.70215 37.6741 7.87025 36.9133 7.87025Z" fill="#88A8FF"/>
        <path d="M40.0424 10.3243H33.4731C32.7122 10.3243 32.7015 9.15625 33.4731 9.15625H40.0424C40.814 9.15625 40.814 10.3243 40.0424 10.3243Z" fill="#88A8FF"/>
        <path d="M35.2746 17.0431H33.4742C32.7026 17.0431 32.7026 15.875 33.4742 15.875H35.2746C36.0355 15.875 36.0355 17.0324 35.2746 17.0431Z" fill="#88A8FF"/>
        <path d="M36.9142 19.4972H33.4849C32.7134 19.4972 32.7134 18.3291 33.4849 18.3291H36.9142C37.6751 18.3291 37.6751 19.4972 36.9142 19.4972Z" fill="#88A8FF"/>
        <path d="M36.5177 27.1388H33.4742C32.7026 27.1388 32.7026 25.9707 33.4742 25.9707H36.5177C37.2893 25.9707 37.2893 27.1388 36.5177 27.1388Z" fill="#88A8FF"/>
        <path d="M39.2932 29.5919H33.4741C32.7132 29.5919 32.7025 28.4238 33.4741 28.4238H39.2932C40.0648 28.4238 40.0648 29.5919 39.2932 29.5919Z" fill="#88A8FF"/>
        <path d="M33.6638 33.166V63.4928H6.76758V44.3195H27.3816L33.6638 33.166Z" fill="#3160AE"/>
        <path d="M33.6636 33.166C32.3121 35.572 28.7774 41.8244 27.3813 44.3195H0.5L6.76736 33.166H33.6636Z" fill="#6592DC"/>
        <path d="M48.4404 44.3205V63.4938H33.6631V33.167L37.1087 44.3205H48.4404Z" fill="#4378D0"/>
        <path d="M51.886 44.3205H37.1087L33.6631 33.167H48.4404L51.886 44.3205Z" fill="#7FACF6"/>
        </svg>        
    );
};

export default AcuCheckIcon;