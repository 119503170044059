import {
  Box,
  Breadcrumbs,
  Grid as MuiGrid,
  IconButton,
  Typography,
  useTheme,
  Card,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid2 as Grid,
  MenuItem,
  MenuList,
  Button,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Link from "@mui/material/Link";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InputComponent from "../../components/form/input";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import { Controller, useForm } from "react-hook-form";
import SelectComponent from "../../components/form/SelectComponent";
import States from "../../assets/json/states.json";
import UserIcon from "../../assets/IconComponents/UserIcon";
import AgeIcon from "../../assets/IconComponents/Setting/AgeIcon";
import ProfessionIcon from "../../assets/IconComponents/Setting/ProfessionIcon";
import Panel from "../../components/Panel";
import RoundDollarIcon from "../../assets/IconComponents/RoundDollarIcon";
import { useAlertMessage } from "../../utils/useAlertMessage";
import TransportIcon from "../../assets/IconComponents/Setting/TransportIcon";
import UtilityIcon from "../../assets/IconComponents/Setting/UtilityIcon";
import professionsData from "../../assets/json/professions.json";
import lifestyleData from "../../assets/json/lifestylePreference.json";
import subscriptionData from "../../assets/json/subscriptions.json";
import transportData from "../../assets/json/transport.json";
import genderData from "../../assets/json/gender.json";
import utilityData from "../../assets/json/utilities.json";
import yearsData from "../../assets/json/numberOfYears.json";
import LocationIcon from "../../assets/IconComponents/Setting/LocationIcon";
import MultipleSelectChip from "../../components/form/MultipleSelectChip";
import SubscriptionIcon from "../../assets/IconComponents/Setting/SubscriptionIcon";
import {
  GenerateSimulation,
  GetAllTenantNames,
  MultiSimulation,
} from "../../api/DataSimulatorAPI";
import CircularSpinner from "../../components/Loader/CircularLoader";

interface SimulationData {
  income?: number;
  minamount?: number;
  maxamount?: number;
  entity?: string;
  fullName?: string;
  age?: number;
  gender?: string;
  profession?: string;
  location?: string;
  familyType?: string;
  lifestylePreference?: string;
}

const initialValues = {
  year: "",
  clientId: "",
  income: "",
  name: "",
  age: undefined,
  gender: "",
  profession: "",
  location: "",
  spendinghabit: "",
  savinghabit: "",
  familyType: null,
  medicalCondition: "",
  housing: "",
  subscription: "",
  transport: "",
  habits: "",
  utilities: "",
  lifestylePreference: "",
}

const GenerateSimulationPage = (props: any) => {
  const [reportCount, setReportCount] = useState(1);
  const theme = useTheme();
  const [cityList, setCityList] = useState<any[]>([]);
  const [familyType, setFamilyType] = useState("");
  const [housing, setHousing] = useState("");
  const [medicalCondition, setMedicalCondition] = useState("");
  const [activeSection, setActiveSection] = useState("reportDetails");
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const notification = useAlertMessage();
  const [isActive, setIsActive] = useState(true);
  const [multiFormState, setMultiFormState] = useState({
    years: "",
    clientId: "",
    reportCount: "",
    minamount: undefined,
    maxamount: undefined,
  });
  const [formState, setFormState] = useState(initialValues);

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
  });

  const requiredFields = [
    "income",
    "name",
    "age",
    "profession",
    "location",
    "lifestylePreference",
    "utilities",
    "subscription",
    "transport",
    "familyType",
    "medicalCondition",
    "housing",
  ];

  const formValues = watch();

  // const isFormComplete = requiredFields.every((field) => !!formValues[field]);
  const isFormComplete = requiredFields.every(
    (field) => formValues[field] !== undefined && formValues[field] !== ""
  );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/admin/datasimulator"}
      sx={{ width: "140px", display: "flex" }}
    >
      <DashboardIcon sx={{ color: "#142A73", marginRight: "10px" }} />
      <Typography sx={{ color: theme.palette.primary.main }}>
        Data Simulator
      </Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      Generate Simulation
    </Typography>,
  ];

  const [clientsList, setClientsList] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    async function fetchClients() {
      try {
        const { data } = await GetAllTenantNames();
        if (data.length > 0) {
          setClientsList(
            data.map((tenant: any) => ({
              value: tenant.tenantId,
              label: tenant.businessName,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch clients:", error);
      }
    }

    fetchClients();
  }, []);

  const handleReportCountChange = (increment: boolean) => {
    setReportCount((prev) => {
      const newCount = increment ? prev + 1 : prev - 1;
      const finalCount = newCount < 1 ? 1 : newCount > 10 ? 10 : newCount;
      setMultiFormState((prevState) => ({
        ...prevState,
        reportCount: String(finalCount),
      }));

      return finalCount;
    });
  };

  const professionList = professionsData.professions.map(
    (profession: string) => ({
      value: profession,
      label: profession,
    })
  );

  const lifestyleList = lifestyleData.lifestyles.map(
    (lifestylePreference: string) => ({
      value: lifestylePreference,
      label: lifestylePreference,
    })
  );

  const subscriptionList = subscriptionData.subscriptions.map(
    (subscription: string) => ({
      value: subscription,
      label: subscription,
    })
  );

  const transportList = transportData.transports.map((transport: string) => ({
    value: transport,
    label: transport,
  }));

  const genderList = genderData.genderOptions.map(
    (gender: { value: string; label: string }) => ({
      value: gender.value,
      label: gender.label,
    })
  );

  const utilityList = utilityData.utilities.map((utility: string) => ({
    value: utility,
    label: utility,
  }));

  const yearsList = yearsData.years.map((year) => ({
    value: year.value,
    label: year.label,
  }));

  const countries = Object.keys(States).map((state) => ({
    value: state,
    label: state,
  }));

  const onStateSelect = (state: string) => {
    setCityList(
      (States as any)[state].map((city: string) => ({
        value: city,
        label: city,
      }))
    );
  };

  const scrollToSection = (sectionId: string) => {
    setActiveSection(sectionId);
    setTimeout(() => {
      const container = document.querySelector(
        "#scroll-container"
      ) as HTMLElement;
      const targetElement = document.getElementById(sectionId) as HTMLElement;
      if (container && targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 0);
  };

  const handleGenerateCancel = () => {
    reset();
    setFormState(initialValues);
    setHousing("");
    setMedicalCondition("");  
    setFamilyType("");
    setIsActive(true);
  };

  const handleChange = (name: string, value: any) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleMultiChange = (name: string, value: any) => {
    setMultiFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFamilySizeChange = (event: any) => {
    setFamilyType(event.target.value);
  };

  const handleMedicalChange = (event: any) => {
    setMedicalCondition(event.target.value);
  };
  const handleHouseChange = (event: any) => {
    setHousing(event.target.value);
  };

  const onSubmit = async (formState: any) => {
    const finalFormState = {
      ...formState,
      familyType,
      medicalCondition,
      housing,
      reportCount,
    };
    setLoading(true);
    setProgress(0);
    let timer;

    try {
      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const { status } = await GenerateSimulation(finalFormState);
      setProgress(100);
      if (status === 200) {
        notification.successNotification(
          "Success",
          "Simulation generated successfully!"
        );
        reset();
        navigate("/admin/datasimulator");
      } else {
        notification.errorNotification(
          "Error",
          "Something went wrong while Generating Simulation"
        );
      }
    } catch (error) {
      console.error("Error generating simulation:", error);
      notification.errorNotification(
        "Error",
        (error as any).response.data.message
      );
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  const onMultiSubmit = async (multiFormState: any) => {
    const finalMultiFormState = {
      ...multiFormState,
    };
    console.log("Final Payload for API:", multiFormState);
    setLoading(true);
    setProgress(0);
    let timer;

    try {
      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const result = await MultiSimulation(finalMultiFormState);
      setProgress(100);
      if (result.statusCode === 200) {
        notification.successNotification(
          "Success",
          "Simulation generated successfully!"
        );
        reset();
        navigate("/admin/datasimulator");
      } else {
        notification.errorNotification(
          "Error",
          result.content || "Failed to generate simulation."
        );
      }
    } catch (error) {
      console.error("Error generating simulation:", error);
      notification.errorNotification(
        "Error",
        "An error occurred while generating the simulation."
      );
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  const sections = [
    {
      id: "reportDetails",
      label: "Report Details",
      content: (
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          border="1px solid #D5E1F9"
          padding="15px"
          borderRadius="8px"
        >
          <Box display="none" flexDirection="column" gap={2}>
            <Typography
              variant="h6"
              fontSize="16px"
              fontWeight="700"
              color="#374151"
            >
              How many reports would you like to generate?{" "}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#6B7280",
                }}
              >
                (A maximum of 10 reports can be generated.)
              </span>
            </Typography>

            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                onClick={() => handleReportCountChange(false)}
                sx={{
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  border: "1px solid #374151",
                }}
              >
                <RemoveIcon />
              </IconButton>

              <Typography
                variant="h5"
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  borderRadius: "8px",
                  border: "1px solid #E9E9EA",
                  padding: "4px 10px",
                }}
              >
                {reportCount}
              </Typography>
              <IconButton
                onClick={() => handleReportCountChange(true)}
                sx={{
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  border: "1px solid #374151",
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>

          <FormInputRow>
            {reportCount <= 5 && (
              <FormInputCol>
                <Controller
                  name="year"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Year is required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    const selectedOption = yearsList?.find(
                      (option) => option.value === field.value
                    );

                    return (
                      <>
                        <SelectComponent
                          required
                          options={yearsList}
                          value={selectedOption}
                          error={error?.message}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                              handleChange("year", newValue.value);
                            }
                          }}
                          label="No. of years statement generated"
                          placeholder="2 Years"
                        />

                        {error?.message && (
                          <span className="error-text">{error?.message}</span>
                        )}
                      </>
                    );
                  }}
                />
              </FormInputCol>
            )}
            {reportCount <= 5 && (
              <FormInputCol>
                <Controller
                  name="clientId"
                  control={control}
                  defaultValue=""
                  render={({ field }) => {
                    const selectedOption =
                      clientsList.find(
                        (option) => option.value === field.value
                      ) || null;

                    return (
                      <SelectComponent
                        required
                        options={clientsList}
                        value={selectedOption}
                        onChange={(newValue) => {
                          field.onChange(newValue?.value);
                          handleChange("clientId", newValue?.value);
                        }}
                        label="Clients"
                        placeholder="Select Client"
                      />
                    );
                  }}
                />
              </FormInputCol>
            )}
            {reportCount <= 5 && (
              <FormInputCol>
                <Controller
                  name="income"
                  control={control}
                  defaultValue={formState.income}
                  rules={{
                    required: "Income is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Income can only contain numbers",
                    },
                    min: {
                      value: 50000,
                      message: "Income must be at least 50,000",
                    },
                    max: {
                      value: 150000,
                      message: "Income cannot exceed 150,000",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputComponent
                      {...field}
                      isRequired
                      icon={<RoundDollarIcon />}
                      onChange={(e) => {
                        field.onChange(e);
                        handleChange("income", e.target.value);
                      }}
                      label="Income"
                      placeholder="Enter amount"
                      error={error?.message}
                    />
                  )}
                />
              </FormInputCol>
            )}
            {reportCount > 5 && (
              <>
                <FormInputCol>
                  <Controller
                    name="years"
                    control={control}
                    defaultValue={multiFormState.years}
                    render={({ field }) => {
                      const selectedOption = yearsList?.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={yearsList}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                              handleMultiChange("years", newValue.value);
                            }
                          }}
                          label="No. of years statement generated"
                          placeholder="2 Years"
                        />
                      );
                    }}
                  />
                </FormInputCol>

                <FormInputCol>
                  <Controller
                    name="clientId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => {
                      const selectedOption =
                        clientsList.find(
                          (option) => option.value === field.value
                        ) || null;
                      return (
                        <SelectComponent
                          options={clientsList}
                          value={selectedOption}
                          onChange={(newValue) => {
                            field.onChange(newValue?.value);
                            handleChange("clientId", newValue?.value);
                          }}
                          label="Clients"
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol>
                  <Controller
                    name="minamount"
                    control={control}
                    defaultValue={multiFormState.minamount || ""}
                    rules={{
                      required: "Minimum amount is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Minimum amount can only contain numbers",
                      },
                      min: {
                        value: 50000,
                        message: "Minimum amount cannot be less than 50K",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        icon={<RoundDollarIcon />}
                        label="Min Income"
                        disabled={false}
                        placeholder="Min amount"
                        error={error?.message}
                        onChange={(e) => {
                          field.onChange(e);
                          handleMultiChange("minamount", e.target.value);
                        }}
                      />
                    )}
                  />
                </FormInputCol>

                <FormInputCol>
                  <Controller
                    name="maxamount"
                    control={control}
                    defaultValue={multiFormState.maxamount}
                    rules={{
                      required: "Maximum amount is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Maximum amount can only contain numbers",
                      },
                      max: {
                        value: 1000000,
                        message: "Maximum amount cannot be greater than 1000K",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        icon={<RoundDollarIcon />}
                        label="Max Income"
                        disabled={false}
                        placeholder="Max amount"
                        error={error?.message}
                        onChange={(e) => {
                          field.onChange(e);
                          handleMultiChange("maxamount", e.target.value);
                        }}
                      />
                    )}
                  />
                </FormInputCol>
              </>
            )}
          </FormInputRow>
        </Box>
      ),
    },
    ...(reportCount <= 5
      ? [
          {
            id: "personalDetails",
            label: "Personal Details",
            content: (
              <Box
                display="flex"
                flexDirection="column"
                gap={3}
                border="1px solid #D5E1F9"
                padding="15px"
                borderRadius="8px"
              >
                <FormInputRow>
                  {reportCount <= 5 && (
                    <FormInputCol sm={12} md={12} lg={12} xl={12}>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Full Name is required",
                          pattern: {
                            value: /^[A-Za-z]+( [A-Za-z]+)?$/,
                            message:
                              "Full Name can only contain alphabets with one space between first and last name",
                          },
                          maxLength: {
                            value: 20,
                            message: "Full Name cannot exceed 20 characters",
                          },
                        }}
                        render={({ field }) => (
                          <InputComponent
                            {...field}
                            isRequired
                            icon={<UserIcon />}
                            label="Full Name"
                            placeholder="Michael Smith"
                            error={(errors.name?.message as string) || null}
                            onChange={(e) => {
                              field.onChange(e);
                              handleChange("name", e.target.value);
                            }}
                          />
                        )}
                      />
                    </FormInputCol>
                  )}
                  {reportCount <= 5 && (
                    <FormInputCol>
                      <Controller
                        name="age"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Age is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Age must be a number",
                          },
                          min: {
                            value: 18,
                            message: "Age must be at least 18",
                          },
                          max: {
                            value: 99,
                            message: "Age cannot exceed 99",
                          },
                        }}
                        render={({ field }) => (
                          <InputComponent
                            isRequired={true}
                            {...field}
                            icon={<AgeIcon />}
                            label="Age"
                            placeholder="30"
                            error={(errors.age?.message as string) || null}
                            onChange={(e) => {
                              field.onChange(e);
                              handleChange("age", e.target.value);
                            }}
                          />
                        )}
                      />
                    </FormInputCol>
                  )}
                  {reportCount <= 5 && (
                    <FormInputCol>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Gender is required",
                        }}
                        render={({ field, fieldState: { error } }) => {
                          const selectedOption = genderList?.find(
                            (option) => option.value === field.value
                          );
                          return (
                            <SelectComponent
                              required
                              options={genderList}
                              value={selectedOption}
                              icon={<UserIcon />}
                              onChange={(newValue: any) => {
                                if (newValue) {
                                  field.onChange(newValue.value);
                                  handleChange("gender", newValue.value);
                                }
                              }}
                              label="Gender"
                              placeholder="Select Gender"
                              error={error?.message}
                            />
                          );
                        }}
                      />
                    </FormInputCol>
                  )}
                  {reportCount <= 5 && (
                    <FormInputCol>
                      <Controller
                        name="profession"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Profession is required",
                        }}
                        render={({ field, fieldState: { error } }) => {
                          const selectedOption = professionList?.find(
                            (option) => option.value === field.value
                          );
                          return (
                            <SelectComponent
                              options={professionList}
                              value={selectedOption}
                              icon={<ProfessionIcon />}
                              onChange={(newValue: any) => {
                                if (newValue) {
                                  field.onChange(newValue.value);
                                  handleChange("profession", newValue.value);
                                }
                              }}
                              label="Profession"
                              placeholder="Select Profession"
                              error={error?.message}
                            />
                          );
                        }}
                      />
                    </FormInputCol>
                  )}
                  <FormInputCol>
                    <Controller
                      name="location"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Location is required",
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const stateOptions = countries;
                        const selectedOption = stateOptions?.find(
                          (option) => option.value === field.value
                        );
                        return (
                          <SelectComponent
                            required
                            options={stateOptions}
                            value={selectedOption}
                            onChange={(newValue: any) => {
                              if (newValue) {
                                field.onChange(newValue.value);
                                handleChange("location", newValue.value);
                              }
                              onStateSelect(newValue.value);
                            }}
                            label="Location"
                            placeholder="Select"
                            fullWidth={true}
                            icon={<LocationIcon />}
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </FormInputCol>
                  <FormInputCol sm={12} md={12}>
                    <Controller
                      name="utilities"
                      control={control}
                      defaultValue={[]}
                      rules={{
                        required: "Utilities are required",
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const selectedOptions = utilityList
                          ?.filter((option) =>
                            field.value?.includes(option.value)
                          )
                          .map((option) => option.value);

                        return (
                          <MultipleSelectChip
                            required
                            options={utilityList?.map((option) => option.value)}
                            value={selectedOptions}
                            onChange={(newValues: string[]) => {
                              const utilitiesString = newValues.join(",");
                              field.onChange(utilitiesString);
                              handleChange("utilities", utilitiesString);
                            }}
                            label="Utilities"
                            fullWidth
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </FormInputCol>
                  <FormInputCol sm={12} md={12}>
                    <Controller
                      name="lifestylePreference"
                      control={control}
                      defaultValue={[]}
                      rules={{
                        required: "Lifestyle Preference is required",
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const selectedOptions = lifestyleList
                          ?.filter((option) =>
                            field.value?.includes(option.value)
                          )
                          .map((option) => option.value);

                        return (
                          <MultipleSelectChip
                            required
                            options={lifestyleList?.map(
                              (option) => option.value
                            )}
                            value={selectedOptions}
                            icon={<TransportIcon />}
                            placeholder="Please select"
                            onChange={(newValues: string[]) => {
                              const lifestylePreferenceString =
                                newValues.join(",");
                              field.onChange(lifestylePreferenceString);
                              handleChange(
                                "lifestylePreference",
                                lifestylePreferenceString
                              );
                            }}
                            label="Lifestyle Preference"
                            fullWidth
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </FormInputCol>
                  <FormInputCol sm={12} md={12}>
                    <Controller
                      name="subscription"
                      control={control}
                      defaultValue={[]}
                      rules={{
                        required: "Subscription is required",
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const selectedOptions = subscriptionList
                          ?.filter((option) =>
                            field.value?.includes(option.value)
                          )
                          .map((option) => option.value);

                        return (
                          <MultipleSelectChip
                            required
                            options={subscriptionList?.map(
                              (option) => option.value
                            )}
                            value={selectedOptions}
                            icon={<SubscriptionIcon />}
                            placeholder="Select Subscription"
                            onChange={(newValues: string[]) => {
                              const subscriptionString = newValues.join(",");
                              field.onChange(subscriptionString);
                              handleChange("subscription", subscriptionString);
                            }}
                            label="Subscription"
                            fullWidth
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </FormInputCol>
                  <FormInputCol sm={12} md={12}>
                    <Controller
                      name="transport"
                      control={control}
                      defaultValue={[]}
                      rules={{
                        required: "Transport is required",
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const selectedOptions = transportList
                          ?.filter((option) =>
                            field.value?.includes(option.value)
                          )
                          .map((option) => option.value);

                        return (
                          <MultipleSelectChip
                            required
                            options={transportList?.map(
                              (option) => option.value
                            )}
                            value={selectedOptions}
                            icon={<TransportIcon />}
                            placeholder="Select Transport"
                            onChange={(newValues: string[]) => {
                              const transportString = newValues.join(",");
                              field.onChange(transportString);
                              handleChange("transport", transportString);
                            }}
                            label="Transport"
                            fullWidth
                            error={error?.message}
                          />
                        );
                      }}
                    />
                  </FormInputCol>
                </FormInputRow>
                <Box display="flex" flexDirection="column" gap={1}>
                  <FormInputRow>
                    <FormInputCol>
                      <FormLabel
                        component="legend"
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#374151",
                        }}
                      >
                        Select Family Size{" "}
                        <Typography component={"span"} color="#FF220C">
                          *
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        value={familyType}
                        onChange={handleFamilySizeChange}
                        row
                      >
                        <FormControlLabel
                          value="Single"
                          control={<Radio />}
                          label="Single"
                        />
                        <FormControlLabel
                          value="Couple"
                          control={<Radio />}
                          label="Couple"
                        />
                        <FormControlLabel
                          value="Family"
                          control={<Radio />}
                          label="Family"
                        />
                      </RadioGroup>
                    </FormInputCol>
                    <FormInputCol>
                      <FormLabel
                        component="legend"
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#374151",
                        }}
                      >
                        Select Medical Condition{" "}
                        <Typography component={"span"} color="#FF220C">
                          *
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        value={medicalCondition}
                        onChange={handleMedicalChange}
                        row
                      >
                        <FormControlLabel
                          value="Healthy"
                          control={<Radio />}
                          label="Healthy"
                        />
                        <FormControlLabel
                          value="Minor"
                          control={<Radio />}
                          label="Minor"
                        />
                        <FormControlLabel
                          value="Chronic"
                          control={<Radio />}
                          label="Chronic"
                        />
                      </RadioGroup>
                    </FormInputCol>
                  </FormInputRow>
                  <FormInputRow>
                    <FormInputCol>
                      <FormLabel
                        component="legend"
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#374151",
                        }}
                      >
                        Owns a House?{" "}
                        <Typography component={"span"} color="#FF220C">
                          *
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        value={housing}
                        onChange={handleHouseChange}
                        row
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormInputCol>
                  </FormInputRow>
                </Box>
              </Box>
            ),
          },
        ]
      : []),
    ...(reportCount <= 5
      ? [
          {
            id: "financialDetails",
            label: "Financial Details",
            content: (
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                border="1px solid #D5E1F9"
                padding="15px"
                borderRadius="8px"
              >
                <FormInputRow>
                  <FormInputCol>
                    <Controller
                      name="spendinghabit"
                      control={control}
                      defaultValue={formState.spendinghabit}
                      rules={{
                        required: "Spending Habit is required",
                        maxLength: {
                          value: 30,
                          message: "Max length is 30 characters",
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9+\-,% ]+$/,
                          message: "Only alphanumeric and + - , % are allowed",
                        },
                      }}
                      render={({ field }) => (
                        <InputComponent
                          {...field}
                          isRequired
                          icon={<TransportIcon />}
                          onChange={(e) => {
                            field.onChange(e);
                            handleChange("spendinghabit", e.target.value);
                          }}
                          label="Spending Habit"
                          placeholder="Enter Spending habit (e.g. Moderate Spender)"
                          error={
                            (errors.spendinghabit?.message as string) || null
                          }
                        />
                      )}
                    />
                  </FormInputCol>

                  <FormInputCol>
                    <Controller
                      name="savinghabit"
                      control={control}
                      defaultValue={formState.savinghabit}
                      rules={{
                        required: "Saving Habit is required",
                        maxLength: {
                          value: 30,
                          message: "Max length is 30 characters",
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9+\-,% ]+$/,
                          message: "Only alphanumeric and + - , % are allowed",
                        },
                      }}
                      render={({ field }) => (
                        <InputComponent
                          {...field}
                          icon={<UtilityIcon />}
                          onChange={(e) => {
                            field.onChange(e);
                            handleChange("savinghabit", e.target.value);
                          }}
                          isRequired
                          label="Saving Habit"
                          placeholder="Enter Saving habit (e.g. 10% of Income, High-Saver)"
                          error={
                            (errors.savinghabit?.message as string) || null
                          }
                        />
                      )}
                    />
                  </FormInputCol>
                </FormInputRow>
              </Box>
            ),
          },
        ]
      : []),
  ];

  return (
    <Box>
      <Box sx={{ marginBottom: 3 }}>
        <Panel
          sx={{
            width: "100%",
            padding: "15px",
            boxShadow: "none",
            borderRadius: "8px",
            marginBottom: "20px",
          }}
        >
          <Breadcrumbs
            separator={
              <>
                <NavigateNextIcon fontSize="small" />
                <NavigateNextIcon
                  fontSize="small"
                  sx={{ marginLeft: "-15px" }}
                />
              </>
            }
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Panel>
      </Box>

      <Card sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ width: "100%", height: "100%", padding: "10px" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <MenuList
              sx={{
                width: { xs: "70%", sm: "30%", md: "25%" },
                padding: "5px",
              }}
            >
              {sections.map((section) => (
                <MenuItem
                  key={section.id}
                  onClick={() => scrollToSection(section.id)}
                  sx={{
                    position: "relative",
                    backgroundColor:
                      activeSection === section.id ? "#F1F4FF" : "transparent",
                    color: activeSection === section.id ? "#1F3A91" : "inherit",
                    borderRadius: 2,
                    mb: 1,
                    fontSize: "16px",
                    fontWeight: activeSection === section.id ? 700 : 400,
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      left: 0,
                      top: 0,
                      bottom: 0,
                      width: "3px",
                      backgroundColor:
                        activeSection === section.id
                          ? "#1F3A91"
                          : "transparent",
                    },
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "inherit",
                    },
                  }}
                >
                  {section.label}
                </MenuItem>
              ))}
            </MenuList>
            <Box
              sx={{
                border: "1px solid #D5E1F9",
                width: "100%",
                padding: "10px",
                borderRadius: "8px",
                background: "#F4F8FE",
              }}
            >
              <Box
                sx={{ width: "100%", borderRadius: "8px", background: "#fff" }}
              >
                <Box
                  ref={containerRef}
                  id="scroll-container"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "80vh",
                    overflowY: "auto",
                    position: "relative",
                    "&::-webkit-scrollbar": {
                      width: "0px",
                      height: "0px",
                    },
                  }}
                >
                  {sections.map((section) => (
                    <Box
                      key={section.id}
                      id={section.id}
                      sx={{
                        padding: { xs: "5px", sm: "20px" },
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontSize="16px"
                        fontWeight="700"
                        padding="10px"
                        color="#374151"
                      >
                        {section.label}
                      </Typography>
                      <Typography variant="body1">{section.content}</Typography>
                    </Box>
                  ))}
                  <Grid
                    size={{ xs: 6, md: 4 }}
                    textAlign={"right"}
                    margin={"15px"}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleGenerateCancel}
                      sx={{
                        color: "#1F3A91",
                        background: "#ffffff",
                        padding: "10px 16px",
                        borderRadius: "10px",
                        border: "1px solid #1F3A91",
                        fontSize: "14px",
                        fontWeight: "700",
                        height: "48px",
                        marginRight: "20px",
                        marginBottom: { xs: "10px", md: "" },
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      onClick={() => {
                        if (reportCount > 5) {
                          handleSubmit(onMultiSubmit)();
                        } else {
                          handleSubmit(onSubmit)();
                        }
                      }}
                      variant="contained"
                      // disabled={!isFormComplete}
                      disabled={!isValid}
                      sx={{
                        color: "#ffffff !important",
                        background: isValid ? "#1F3A91" : "#BCBCBD",
                        padding: "10px 16px",
                        borderRadius: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        height: "48px",
                        marginBottom: { xs: "10px", md: "" },
                      }}
                    >
                      Generate
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
      {loading && <CircularSpinner progress={progress} />}
    </Box>
  );
};

export default GenerateSimulationPage;
