import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  Link,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import Panel from "../../components/Panel";
import InputComponent from "../../components/form/input";
import "react-datepicker/dist/react-datepicker.css";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import UserIcon from "../../assets/IconComponents/UserIcon";
import BusinessIcon from "../../assets/IconComponents/BussinessIcon";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import "./index.css";
import DownAccordianArrow from "../../assets/IconComponents/DownAccordianArrow";
import SelectComponent from "../../components/form/SelectComponent";
import CustomerOverview from "./CustomerOverview";
import contactInformation from "../../assets/media/icons/Contact_Information.png";
import customerInformationIcon from "../../assets/media/icons/Customer_Information.png";
import EmployeInfo from "../../assets/media/icons/Employe_Info.png";
import BankInfo from "../../assets/media/icons/Bank_Info.png";
import AddressIcon from "../../assets/IconComponents/AddressIcon";
import NoDeatilsInformation from "../../assets/IconComponents/NoDetailsIcon";
import StoreIcon from "../../assets/IconComponents/StoreIcon";
import Homework from "../../assets/IconComponents/Homework";
import Location from "../../assets/IconComponents/Location";
import MyLocation from "../../assets/IconComponents/MyLocation";
import PhoneIcon from "../../assets/IconComponents/PhoneIcon";
import EmailIcon from "../../assets/IconComponents/EmailIcon";
import DriverLicense from "../../assets/IconComponents/DriverLicense";
import SsnNumber from "../../assets/IconComponents/SsnNumber";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { CreateCustomer } from "../../api/customerAPI";
import { useAlertMessage } from "../../utils/useAlertMessage";
import { Controller, useForm } from "react-hook-form";
import { FormData, TabPanelProps } from "./types";
import States from "../../assets/json/states.json";
import CountryIcon from "../../assets/IconComponents/Country";
import AccountNumberIcon from "../../assets/IconComponents/AccountNumberIcon";
import DOBPicker from "../../components/form/DOBPicker";
import CircularSpinner from "../../components/Loader/CircularLoader";
import moment from "moment";

const statesList = Object.keys(States).map((state) => ({
  value: state,
  label: state,
}));

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: "20px", pl: 0, pr: 0 }}>{children}</Box>
      )}
    </div>
  );
};

const AddNewCustomer = () => {
  const theme = useTheme();
  const notification = useAlertMessage();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(max-width:1023px)");
  const isMiniDesktopScreen = useMediaQuery(
    "(min-width:1023px) and (max-width:1439px)"
  );
  const [currentTab, toggleTab] = useState(0);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [cityList, setCityList] = useState<any[]>([]);
  const [expandedBusiness, setExpandedBusiness] = useState<string | false>(
    "businessPanel1"
  );
  const [loading, setLoading] = useState(false);

  const handleAccordionChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      if (!isExpanded && expanded === panel) {
        return;
      }
      setExpanded(isExpanded ? panel : false);
    };

  const handleBusinessAccordionChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      if (!isExpanded && expanded === panel) {
        return;
      }
      setExpandedBusiness(isExpanded ? panel : false);
    };

  const onTabClick = (event: React.SyntheticEvent, newValue: number) => {
    toggleTab(newValue);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/acucheck/customers"}
      sx={{ width: "120px", display: "flex" }}
    >
      <PersonAddIcon
        sx={{ marginRight: 1, color: theme.palette.primary.main }}
      />
      <Typography sx={{ color: theme.palette.primary.main }}>
        Customers
      </Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      Add New Customer
    </Typography>,
  ];

  const {
    control: customerControl,
    handleSubmit: handleCustomerSubmit,
    watch: watchCustomer,
    formState: { errors: customerErrors, isValid: isCustomerValid },
    reset: resetCustomerForm,
    trigger: triggerCustomerForm,
    clearErrors: clearCustomerErrors,
    setValue: setCustomerValue,
  } = useForm<FormData>({ mode: "onChange" });

  const {
    control: businessControl,
    handleSubmit: handleBusinessSubmit,
    watch: watchBusiness,
    formState: { errors: businessErrors, isValid: isBusinessValid },
    reset: resetBusinessForm,
    setValue: setBusinessValue,
  } = useForm<FormData>({ mode: "onChange" });

  const handleCustomerCancel = () => {
    resetCustomerForm();
  };

  const handleBusinessCancel = () => {
    resetBusinessForm();
  };

  const onStateSelect = (state: string, type: "customer" | "business") => {
    if (!state) return;

    const updatedCityList =
      (States as any)[state]?.map((city: string) => ({
        value: city,
        label: city,
      })) || [];

    setCityList(updatedCityList);

    const currentCity =
      type === "customer"
        ? watchCustomer("customer.city")
        : watchBusiness("business.city");
    const isCurrentCityValid = updatedCityList.some(
      (city: any) => city.value === currentCity
    );

    if (!isCurrentCityValid) {
      type === "customer"
        ? setCustomerValue("customer.city", "")
        : setBusinessValue("business.city", "");
    }
  };

  const [progress, setProgress] = useState(0);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    setProgress(0);
    let customerObj;
    const formattedDOB = data?.customer?.dob
      ? moment(data.customer.dob).format("YYYY-MM-DD")
      : "";
    if (currentTab === 1) {
      customerObj = {
        command: "CreateCustomer",
        customerType: "Business",
        companyName: data.business.companyName,
        firstName: data.business.firstName,
        lastName: data.business.lastName,
        phoneType: data.business.selectedPhone,
        phoneNumber: data.business.phoneNumber,
        email: data.business.email,
        addressLine1: data.business.address1,
        addressLine2: data.business.address2,
        city: data.business.city,
        state: data.business.state,
        postalCode: data.business.postalCode,
        userName: data.business.email.split("@")[0] + "_" + Date.now(),
        country: data.business.country,
        bankName: data.business.bankName,
        bankAccNo: data.business.bankAccNo,
        routingNo: data.business.routingNo,
        ein: data.business.ein,
      };
    } else {
      customerObj = {
        command: "CreateCustomer",
        customerType: "Customer",
        firstName: data.customer.firstName,
        lastName: data.customer.lastName,
        phoneType: data.customer.selectedPhone,
        phoneNumber: data.customer.phoneNumber,
        email: data.customer.email,
        addressLine1: data.customer.address1,
        addressLine2: data.customer.address2,
        city: data.customer.city,
        state: data.customer.state,
        postalCode: data.customer.postalCode,
        userName: data.customer.email.split("@")[0] + "_" + Date.now(),
        dob: formattedDOB,
        payFrequency: data.customer.payFrequency,
        driversLicense: data.customer.driversLicense,
        ssn: data.customer.ssn,
        country: data.customer.country,
        bankName: data.customer.bankName,
        bankAccNo: data.customer.bankAccNo,
        routingNo: data.customer.routingNo,
        employerName: data.customer.employerName,
      };
    }
    try {
      const timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const { data, status } = await CreateCustomer(customerObj);
      clearInterval(timer);
      setProgress(100);
      if (status === 200) {
        notification.successNotification(
          "Success",
          "Customer added successfully"
        );
        navigate("/acucheck/customers");
      } else {
        notification.errorNotification(
          "Error",
          "Something went wrong while creating a customer"
        );
      }
    } catch (err) {
      console.error("Error creating customer:", err);
      notification.errorNotification(
        "Error",
        (err as any).response.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  const hasCustomerDetails =
    watchCustomer("customer.firstName") ||
    watchCustomer("customer.lastName") ||
    watchCustomer("customer.dob") ||
    watchCustomer("customer.driversLicense") ||
    watchCustomer("customer.ssn") ||
    watchCustomer("customer.address1") ||
    watchCustomer("customer.address2") ||
    watchCustomer("customer.country") ||
    watchCustomer("customer.state") ||
    watchCustomer("customer.city") ||
    watchCustomer("customer.postalCode") ||
    watchCustomer("customer.phoneNumber") ||
    watchCustomer("customer.selectedPhone") ||
    watchCustomer("customer.email");

  const hasBusinessDetails =
    watchBusiness("business.companyName") ||
    watchBusiness("business.ein") ||
    watchBusiness("business.country") ||
    watchBusiness("business.address1") ||
    watchBusiness("business.address2") ||
    watchBusiness("business.state") ||
    watchBusiness("business.city") ||
    watchBusiness("business.postalCode") ||
    watchBusiness("business.phoneNumber") ||
    watchBusiness("business.selectedPhone") ||
    watchBusiness("business.email");
  watchCustomer("business.firstName") || watchCustomer("business.lastName");

  useEffect(() => {
    const bankAccNo = watchCustomer("customer.bankAccNo");
    if (bankAccNo) {
      triggerCustomerForm("customer.bankName"); 
    } else {
      setCustomerValue("customer.bankName", "");
      clearCustomerErrors("customer.bankName"); 
    }
  }, [watchCustomer("customer.bankAccNo")]);
  
  return (
    <>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Panel>
      <Panel>
        <Box sx={{ padding: "20px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={currentTab}
              onChange={onTabClick}
              aria-label="icon position tabs example"
              sx={{
                ".Mui-selected": {
                  color: "#264D8E !important",
                  fontWeight: "700 !important",
                  marginLeft: "5px",
                  background: "#EFF3FF",
                  minHeight: "56px !important",
                  minWidth: "221px !important",
                  height: "56px !important",
                  borderTopLeftRadius: "8px",
                  padding: "16px !important",
                },
                ".MuiTabs-flexContainer": {
                  borderBottom: "1px solid #d5e1f9",
                },
                "MuiBox-root": {
                  borderBottom: "none !important",
                },
                ".css-1gsv261": {
                  borderBottom: "none",
                },
                ".MuiTab-root": {
                  fontSize: "16px",
                  fontWeight: "600",
                  minWidth: "221px !important",
                  minHeight: "56px !important",
                  "*:first-of-type": {
                    marginRight: "8px",
                  },
                },
                ".MuiTabs-indicator": {
                  backgroundColor: "#264D8E",
                },
              }}
            >
              <Tab
                icon={<UserIcon selected={currentTab === 0} />}
                iconPosition="start"
                label="Individual"
                value={0}
              />
              <Tab
                icon={
                  <BusinessIcon
                    selected={currentTab === 1}
                    width="20px"
                    height="20px"
                  />
                }
                iconPosition="start"
                label="Business"
                value={1}
              />
            </Tabs>
          </Box>

          <CustomTabPanel value={currentTab} index={0}>
            <form onSubmit={handleCustomerSubmit(onSubmit)}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    width: isLargeScreen
                      ? "100%"
                      : isMiniDesktopScreen
                      ? "55%"
                      : "65%",
                  }}
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleAccordionChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Customer Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.firstName"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "First name is required",
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "First name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  icon={<UserIconGray />}
                                  label="First Name"
                                  placeholder="Enter first name"
                                  error={
                                    customerErrors.customer?.firstName
                                      ? customerErrors.customer.firstName
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.lastName"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "Last name is required",
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "Last name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  icon={<UserIconGray />}
                                  label="Last Name"
                                  placeholder="Enter last name"
                                  error={
                                    customerErrors.customer?.lastName
                                      ? customerErrors.customer.lastName.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="customer.dob"
                              control={customerControl}
                              defaultValue=""
                              render={({ field }) => (
                                <DOBPicker
                                  {...field}
                                  label="DOB (Date of Birth)"
                                  placeholder="Enter date of birth"
                                  onDOBSelect={(dob) => {
                                    const formattedDate = dob
                                      ? moment(dob).format("MM/DD/YYYY")
                                      : "";
                                    field.onChange(formattedDate);
                                  }}
                                  error={
                                    customerErrors.customer?.dob
                                      ? customerErrors.customer.dob.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.driversLicense"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                pattern: {
                                  value: /^[a-zA-Z0-9]{4,13}$/,
                                  message:
                                    "Driver’s License must be 4-10 alphanumeric characters.",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<DriverLicense />}
                                  label="Driver’s License Number"
                                  placeholder="Enter Driver’s License Number"
                                  error={
                                    customerErrors.customer?.driversLicense
                                      ? customerErrors.customer.driversLicense
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>

                          <FormInputCol md={12}>
                            <Controller
                              name="customer.ssn"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                pattern: {
                                  value: /^\d{4}$/,
                                  message:
                                    "SSN Number must be exactly 4 digits",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<SsnNumber />}
                                  maxLength={4}
                                  label="SSN Number(Last 4 digits)"
                                  placeholder="Enter SSN Number"
                                  error={
                                    customerErrors.customer?.ssn
                                      ? customerErrors.customer.ssn.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="customer.address1"
                              control={customerControl}
                              defaultValue=""
                              rules={{ required: "Address Line 1 is required" }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  icon={<AddressIcon />}
                                  label="Address Line 1 (Street Address)"
                                  placeholder="Enter address"
                                  error={
                                    customerErrors.customer?.address1
                                      ? customerErrors.customer.address1.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="customer.address2"
                              control={customerControl}
                              defaultValue=""
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<StoreIcon />}
                                  label="Address Line 2 (Post office number, Suite Number, etc)"
                                  placeholder="Enter post office number, suite number, etc."
                                  error={
                                    customerErrors.customer?.address2
                                      ? customerErrors.customer.address2.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.country"
                              control={customerControl}
                              defaultValue="USA"
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<CountryIcon />}
                                  label="Country"
                                  isRequired={true}
                                  disabled={true}
                                  error={
                                    customerErrors.customer?.country
                                      ? customerErrors.customer.country.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.state"
                              control={customerControl}
                              defaultValue=""
                              render={({ field }) => {
                                const stateOptions = statesList;
                                const selectedOption = stateOptions.find(
                                  (option) => option.value === field.value
                                );
                                return (
                                  <SelectComponent
                                    options={stateOptions}
                                    value={selectedOption}
                                    onChange={(newValue: any) => {
                                      if (newValue) {
                                        field.onChange(newValue.value);
                                      }
                                      onStateSelect(newValue.value, "customer");
                                    }}
                                    label="State"
                                    placeholder="Select State"
                                    fullWidth={true}
                                    icon={<Homework />}
                                    error={
                                      customerErrors.customer?.state
                                        ? customerErrors.customer?.state.message
                                        : null
                                    }
                                  />
                                );
                              }}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.city"
                              control={customerControl}
                              defaultValue=""
                              render={({ field }) => {
                                const cityOptions = cityList;
                                const selectedOption = cityOptions.find(
                                  (option) => option.value === field.value
                                );
                                return (
                                  <SelectComponent
                                    options={cityOptions}
                                    value={selectedOption}
                                    onChange={(newValue: any) => {
                                      if (newValue) {
                                        field.onChange(newValue.value);
                                      }
                                    }}
                                    // icon={<location />}
                                    label="City"
                                    placeholder="Select City"
                                    error={
                                      customerErrors.customer?.city
                                        ? customerErrors.customer?.city.message
                                        : null
                                    }
                                  />
                                );
                              }}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.postalCode"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "Zip Code is required",
                                pattern: {
                                  value: /^[0-9]{5}$/,
                                  message:
                                    "Zip Code must be exactly 5 digits",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  maxLength={5}
                                  minLength={5}
                                  icon={<MyLocation />}
                                  label="Zip Code"
                                  placeholder="Enter zip code"
                                  error={
                                    customerErrors.customer?.postalCode
                                      ? customerErrors.customer?.postalCode
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleAccordionChange("panel2")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Contact Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="customer.phoneNumber"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "Phone Number is required",
                                pattern: {
                                  value: /^[0-9]{10}$/,
                                  message: "Phone Number must be exactly 10 digits",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  maxLength={10}
                                  minLength={10}
                                  label="Phone Number"
                                  icon={<PhoneIcon />}
                                  placeholder="Enter phone number"
                                  error={
                                    customerErrors.customer?.phoneNumber
                                      ? customerErrors.customer?.phoneNumber
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Divider sx={{ border: "1px dashed #D5E1F9" }} />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="customer.email"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                  message: "Enter a valid email address",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  label="Email Address"
                                  icon={<EmailIcon />}
                                  placeholder="Enter Email"
                                  error={
                                    customerErrors.customer?.email
                                      ? customerErrors.customer?.email.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleAccordionChange("panel3")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel3-content"
                      id="panel3-header"
                    >
                      Employee Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.employerName"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "Name of Employer can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<UserIconGray />}
                                  label="Name of Employer"
                                  placeholder="Enter name of employer"
                                  error={
                                    customerErrors.customer?.employerName
                                      ? customerErrors.customer.employerName
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.payFrequency"
                              control={customerControl}
                              defaultValue=""
                              render={({ field }) => {
                                const payFrequencyOptions = [
                                  { value: "Weekly", label: "Weekly" },
                                  { value: "Bi-Weekly", label: "Bi-Weekly" },
                                  { value: "Monthly", label: "Monthly" },
                                  { value: "Bi-Monthly", label: "Bi-Monthly" },
                                ];
                                const selectedOption = payFrequencyOptions.find(
                                  (option) => option.value === field.value
                                );
                                return (
                                  <SelectComponent
                                    options={payFrequencyOptions}
                                    value={selectedOption}
                                    onChange={(newValue) => {
                                      if (newValue) {
                                        field.onChange(newValue.value);
                                      }
                                    }}
                                    label="Pay Frequency"
                                    placeholder="Select Pay Frequency"
                                    fullWidth={true}
                                    error={
                                      customerErrors.customer?.payFrequency
                                        ? customerErrors.customer?.payFrequency
                                            .message
                                        : null
                                    }
                                  />
                                );
                              }}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleAccordionChange("panel4")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel4-content"
                      id="panel4-header"
                    >
                      Bank Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="customer.bankName"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                required: {
                                  value: !!watchCustomer("customer.bankAccNo"),
                                  message:
                                    "Bank Name is required when Account Number is entered",
                                },
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "Bank Name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  label="Bank Name"
                                  icon={<UserIconGray />}
                                  placeholder="Enter bank name"
                                  error={
                                    customerErrors.customer?.bankName
                                      ? customerErrors.customer?.bankName
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="customer.bankAccNo"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message:
                                    "Account Number can only contain numbers",
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    "Account Number must be at least 6 digits long",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  minLength={6}
                                  maxLength={17}
                                  icon={<AccountNumberIcon />}
                                  label="Account Number"
                                  placeholder="Enter account number"
                                  error={
                                    customerErrors.customer?.bankAccNo
                                      ? customerErrors.customer.bankAccNo
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={6}>
                            <Controller
                              name="customer.routingNo"
                              control={customerControl}
                              defaultValue=""
                              rules={{
                                pattern: {
                                  value: /^[0-9]{9}$/,
                                  message:
                                    "Routing Number must contain exactly 9 numbers",
                                },
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <InputComponent
                                  {...field}
                                  maxLength={9}
                                  icon={<SsnNumber />}
                                  label="Routing Number"
                                  placeholder="Enter rounting number"
                                  error={error?.message}
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      sx={{
                        background: "#fff",
                        color: "#000000",
                        border: "1px solid black",
                        marginRight: "10px",
                      }}
                      onClick={handleCustomerCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        background: isCustomerValid ? "#1F3A91" : "#BCBCBD",
                        color: "#ffffff !important",
                      }}
                      onClick={handleCustomerSubmit(onSubmit)}
                      disabled={!isCustomerValid}
                    >
                      Add Customer
                    </Button>
                  </Box>
                </Box>
                {!isLargeScreen && (
                  <Box
                    sx={{
                      width: "1%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        border: "1px dashed #D5E1F9",
                        height: "100%",
                      }}
                    />
                  </Box>
                )}
                {!isLargeScreen && (
                  <Box sx={{ width: isMiniDesktopScreen ? "42%" : "32%" }}>
                    <Box
                      className="accordianbox"
                      sx={{ background: "#F7FAFF !important" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          color: "#374151",
                          fontSize: "16px",
                          marginBottom: "10px",
                        }}
                      >
                        {"Customer OverView"}
                      </Typography>
                      <Box
                        sx={{
                          background: "#fff",
                          padding: "20px",
                          borderRadius: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {!hasCustomerDetails && (
                            <Box
                              sx={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <NoDeatilsInformation />
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#303132",
                                  fontWeight: "600",
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                No details added yet!
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#6B7280",
                                  fontWeight: "400",
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                Get started by adding details to onboard a new
                                client.
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        {hasCustomerDetails && (
                          <>
                            {expanded === "panel1" && (
                              <>
                                <CustomerOverview
                                  section="Customer Information"
                                  sectionDetails="Individual"
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "First Name",
                                      value: customerErrors.customer?.firstName
                                        ? "-"
                                        : watchCustomer("customer.firstName") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.firstName &&
                                        !!watchCustomer("customer.firstName") &&
                                        watchCustomer("customer.firstName") !==
                                          "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: customerErrors.customer?.lastName
                                        ? "-"
                                        : watchCustomer("customer.lastName") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.lastName &&
                                        !!watchCustomer("customer.lastName") &&
                                        watchCustomer("customer.lastName") !==
                                          "-",
                                    },
                                    {
                                      label: "DOB",
                                      value: customerErrors.customer?.dob
                                        ? "-"
                                        : watchCustomer("customer.dob")
                                        ? moment(
                                            watchCustomer("customer.dob")
                                          ).format("MM/DD/YYYY")
                                        : "-",
                                      done:
                                        !customerErrors.customer?.dob &&
                                        !!watchCustomer("customer.dob") &&
                                        watchCustomer("customer.dob") !== "-",
                                    },
                                    {
                                      label: "Driver’s License Number",
                                      value: customerErrors.customer
                                        ?.driversLicense
                                        ? "-"
                                        : watchCustomer(
                                            "customer.driversLicense"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer
                                          ?.driversLicense &&
                                        !!watchCustomer(
                                          "customer.driversLicense"
                                        ) &&
                                        watchCustomer(
                                          "customer.driversLicense"
                                        ) !== "-",
                                    },
                                    {
                                      label: "SSN Number(Last 4 digits)",
                                      value: customerErrors.customer?.ssn
                                        ? "-"
                                        : watchCustomer("customer.ssn") || "-",
                                      done:
                                        !customerErrors.customer?.ssn &&
                                        !!watchCustomer("customer.ssn") &&
                                        watchCustomer("customer.ssn") !== "-",
                                    },
                                    {
                                      label: "Address Line 1",
                                      value: customerErrors.customer?.address1
                                        ? "-"
                                        : watchCustomer("customer.address1") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.address1 &&
                                        !!watchCustomer("customer.address1") &&
                                        watchCustomer("customer.address1") !==
                                          "-",
                                    },
                                    {
                                      label: "Address Line 2",
                                      value: customerErrors.customer?.address2
                                        ? "-"
                                        : watchCustomer("customer.address2") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.address2 &&
                                        !!watchCustomer("customer.address2") &&
                                        watchCustomer("customer.address2") !==
                                          "-",
                                    },
                                    {
                                      label: "Country",
                                      value: customerErrors.customer?.country
                                        ? "-"
                                        : watchCustomer("customer.country") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.country &&
                                        !!watchCustomer("customer.country") &&
                                        watchCustomer("customer.country") !==
                                          "-",
                                    },
                                    {
                                      label: "State",
                                      value: customerErrors.customer?.state
                                        ? "-"
                                        : watchCustomer("customer.state") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.state &&
                                        !!watchCustomer("customer.state") &&
                                        watchCustomer("customer.state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: customerErrors.customer?.city
                                        ? "-"
                                        : watchCustomer("customer.city") || "-",
                                      done:
                                        !customerErrors.customer?.city &&
                                        !!watchCustomer("customer.city") &&
                                        watchCustomer("customer.city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: customerErrors.customer?.postalCode
                                        ? "-"
                                        : watchCustomer(
                                            "customer.postalCode"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer?.postalCode &&
                                        !!watchCustomer(
                                          "customer.postalCode"
                                        ) &&
                                        watchCustomer("customer.postalCode") !==
                                          "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={"-"}
                                  section="Contact Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={EmployeInfo}
                                  sectionDetails={"-"}
                                  section="Employee Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={BankInfo}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel2" && (
                              <>
                                <CustomerOverview
                                  section="Customer Information"
                                  sectionDetails="Individual"
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "First Name",
                                      value: customerErrors.customer?.firstName
                                        ? "-"
                                        : watchCustomer("customer.firstName") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.firstName &&
                                        !!watchCustomer("customer.firstName") &&
                                        watchCustomer("customer.firstName") !==
                                          "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: customerErrors.customer?.lastName
                                        ? "-"
                                        : watchCustomer("customer.lastName") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.lastName &&
                                        !!watchCustomer("customer.lastName") &&
                                        watchCustomer("customer.lastName") !==
                                          "-",
                                    },
                                    {
                                      label: "DOB",
                                      value: customerErrors.customer?.dob
                                        ? "-"
                                        : watchCustomer("customer.dob")
                                        ? moment(
                                            watchCustomer("customer.dob")
                                          ).format("MM/DD/YYYY")
                                        : "-",
                                      done:
                                        !customerErrors.customer?.dob &&
                                        !!watchCustomer("customer.dob") &&
                                        watchCustomer("customer.dob") !== "-",
                                    },
                                    {
                                      label: "Driver’s License Number",
                                      value: customerErrors.customer
                                        ?.driversLicense
                                        ? "-"
                                        : watchCustomer(
                                            "customer.driversLicense"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer
                                          ?.driversLicense &&
                                        !!watchCustomer(
                                          "customer.driversLicense"
                                        ) &&
                                        watchCustomer(
                                          "customer.driversLicense"
                                        ) !== "-",
                                    },
                                    {
                                      label: "SSN Number(Last 4 digits)",
                                      value: customerErrors.customer?.ssn
                                        ? "-"
                                        : watchCustomer("customer.ssn") || "-",
                                      done:
                                        !customerErrors.customer?.ssn &&
                                        !!watchCustomer("customer.ssn") &&
                                        watchCustomer("customer.ssn") !== "-",
                                    },
                                    {
                                      label: "Address Line 1",
                                      value: customerErrors.customer?.address1
                                        ? "-"
                                        : watchCustomer("customer.address1") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.address1 &&
                                        !!watchCustomer("customer.address1") &&
                                        watchCustomer("customer.address1") !==
                                          "-",
                                    },
                                    {
                                      label: "Address Line 2",
                                      value: customerErrors.customer?.address2
                                        ? "-"
                                        : watchCustomer("customer.address2") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.address2 &&
                                        !!watchCustomer("customer.address2") &&
                                        watchCustomer("customer.address2") !==
                                          "-",
                                    },
                                    {
                                      label: "Country",
                                      value: customerErrors.customer?.country
                                        ? "-"
                                        : watchCustomer("customer.country") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.country &&
                                        !!watchCustomer("customer.country") &&
                                        watchCustomer("customer.country") !==
                                          "-",
                                    },
                                    {
                                      label: "State",
                                      value: customerErrors.customer?.state
                                        ? "-"
                                        : watchCustomer("customer.state") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.state &&
                                        !!watchCustomer("customer.state") &&
                                        watchCustomer("customer.state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: customerErrors.customer?.city
                                        ? "-"
                                        : watchCustomer("customer.city") || "-",
                                      done:
                                        !customerErrors.customer?.city &&
                                        !!watchCustomer("customer.city") &&
                                        watchCustomer("customer.city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: customerErrors.customer?.postalCode
                                        ? "-"
                                        : watchCustomer(
                                            "customer.postalCode"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer?.postalCode &&
                                        !!watchCustomer(
                                          "customer.postalCode"
                                        ) &&
                                        watchCustomer("customer.postalCode") !==
                                          "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  sectionDetails={
                                    watchCustomer("customer.selectedPhone") ||
                                    "-"
                                  }
                                  src={contactInformation}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "Phone Number",
                                      value: customerErrors.customer
                                        ?.phoneNumber
                                        ? "-"
                                        : watchCustomer(
                                            "customer.phoneNumber"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer?.phoneNumber &&
                                        !!watchCustomer(
                                          "customer.phoneNumber"
                                        ) &&
                                        watchCustomer(
                                          "customer.phoneNumber"
                                        ) !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: customerErrors.customer?.email
                                        ? "-"
                                        : watchCustomer("customer.email") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.email &&
                                        !!watchCustomer("customer.email") &&
                                        watchCustomer("customer.email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={EmployeInfo}
                                  sectionDetails={"-"}
                                  section="Employee Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={BankInfo}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel3" && (
                              <>
                                <CustomerOverview
                                  section="Customer Information"
                                  sectionDetails="Individual"
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "First Name",
                                      value: customerErrors.customer?.firstName
                                        ? "-"
                                        : watchCustomer("customer.firstName") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.firstName &&
                                        !!watchCustomer("customer.firstName") &&
                                        watchCustomer("customer.firstName") !==
                                          "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: customerErrors.customer?.lastName
                                        ? "-"
                                        : watchCustomer("customer.lastName") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.lastName &&
                                        !!watchCustomer("customer.lastName") &&
                                        watchCustomer("customer.lastName") !==
                                          "-",
                                    },
                                    {
                                      label: "DOB",
                                      value: customerErrors.customer?.dob
                                        ? "-"
                                        : watchCustomer("customer.dob")
                                        ? moment(
                                            watchCustomer("customer.dob")
                                          ).format("MM/DD/YYYY")
                                        : "-",
                                      done:
                                        !customerErrors.customer?.dob &&
                                        !!watchCustomer("customer.dob") &&
                                        watchCustomer("customer.dob") !== "-",
                                    },
                                    {
                                      label: "Driver’s License Number",
                                      value: customerErrors.customer
                                        ?.driversLicense
                                        ? "-"
                                        : watchCustomer(
                                            "customer.driversLicense"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer
                                          ?.driversLicense &&
                                        !!watchCustomer(
                                          "customer.driversLicense"
                                        ) &&
                                        watchCustomer(
                                          "customer.driversLicense"
                                        ) !== "-",
                                    },
                                    {
                                      label: "SSN Number(Last 4 digits)",
                                      value: customerErrors.customer?.ssn
                                        ? "-"
                                        : watchCustomer("customer.ssn") || "-",
                                      done:
                                        !customerErrors.customer?.ssn &&
                                        !!watchCustomer("customer.ssn") &&
                                        watchCustomer("customer.ssn") !== "-",
                                    },
                                    {
                                      label: "Address Line 1",
                                      value: customerErrors.customer?.address1
                                        ? "-"
                                        : watchCustomer("customer.address1") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.address1 &&
                                        !!watchCustomer("customer.address1") &&
                                        watchCustomer("customer.address1") !==
                                          "-",
                                    },
                                    {
                                      label: "Address Line 2",
                                      value: customerErrors.customer?.address2
                                        ? "-"
                                        : watchCustomer("customer.address2") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.address2 &&
                                        !!watchCustomer("customer.address2") &&
                                        watchCustomer("customer.address2") !==
                                          "-",
                                    },
                                    {
                                      label: "Country",
                                      value: customerErrors.customer?.country
                                        ? "-"
                                        : watchCustomer("customer.country") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.country &&
                                        !!watchCustomer("customer.country") &&
                                        watchCustomer("customer.country") !==
                                          "-",
                                    },
                                    {
                                      label: "State",
                                      value: customerErrors.customer?.state
                                        ? "-"
                                        : watchCustomer("customer.state") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.state &&
                                        !!watchCustomer("customer.state") &&
                                        watchCustomer("customer.state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: customerErrors.customer?.city
                                        ? "-"
                                        : watchCustomer("customer.city") || "-",
                                      done:
                                        !customerErrors.customer?.city &&
                                        !!watchCustomer("customer.city") &&
                                        watchCustomer("customer.city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: customerErrors.customer?.postalCode
                                        ? "-"
                                        : watchCustomer(
                                            "customer.postalCode"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer?.postalCode &&
                                        !!watchCustomer(
                                          "customer.postalCode"
                                        ) &&
                                        watchCustomer("customer.postalCode") !==
                                          "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  sectionDetails={
                                    watchCustomer("customer.selectedPhone") ||
                                    "-"
                                  }
                                  src={contactInformation}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "Phone Number",
                                      value: customerErrors.customer
                                        ?.phoneNumber
                                        ? "-"
                                        : watchCustomer(
                                            "customer.phoneNumber"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer?.phoneNumber &&
                                        !!watchCustomer(
                                          "customer.phoneNumber"
                                        ) &&
                                        watchCustomer(
                                          "customer.phoneNumber"
                                        ) !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: customerErrors.customer?.email
                                        ? "-"
                                        : watchCustomer("customer.email") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.email &&
                                        !!watchCustomer("customer.email") &&
                                        watchCustomer("customer.email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={EmployeInfo}
                                  sectionDetails={
                                    watchCustomer("customer.employerName") ||
                                    "-"
                                  }
                                  section="Employee Information"
                                  details={[
                                    {
                                      label: "Name of Employer",
                                      value: customerErrors.customer
                                        ?.employerName
                                        ? "-"
                                        : watchCustomer(
                                            "customer.employerName"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer
                                          ?.employerName &&
                                        !!watchCustomer(
                                          "customer.employerName"
                                        ) &&
                                        watchCustomer(
                                          "customer.employerName"
                                        ) !== "-",
                                    },
                                    {
                                      label: "Pay Frequency",
                                      value: customerErrors.customer
                                        ?.payFrequency
                                        ? "-"
                                        : watchCustomer(
                                            "customer.payFrequency"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer
                                          ?.payFrequency &&
                                        !!watchCustomer(
                                          "customer.payFrequency"
                                        ) &&
                                        watchCustomer(
                                          "customer.payFrequency"
                                        ) !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={BankInfo}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel4" && (
                              <>
                                <CustomerOverview
                                  section="Customer Information"
                                  sectionDetails="Individual"
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "First Name",
                                      value: customerErrors.customer?.firstName
                                        ? "-"
                                        : watchCustomer("customer.firstName") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.firstName &&
                                        !!watchCustomer("customer.firstName") &&
                                        watchCustomer("customer.firstName") !==
                                          "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: customerErrors.customer?.lastName
                                        ? "-"
                                        : watchCustomer("customer.lastName") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.lastName &&
                                        !!watchCustomer("customer.lastName") &&
                                        watchCustomer("customer.lastName") !==
                                          "-",
                                    },
                                    {
                                      label: "DOB",
                                      value: customerErrors.customer?.dob
                                        ? "-"
                                        : watchCustomer("customer.dob") &&
                                          moment(
                                            watchCustomer("customer.dob"),
                                            ["MM/DD/YYYY"],
                                            true
                                          ).isValid()
                                        ? moment(
                                            watchCustomer("customer.dob")
                                          ).format("MM/DD/YYYY")
                                        : "-",
                                      done:
                                        !customerErrors.customer?.dob &&
                                        !!watchCustomer("customer.dob") &&
                                        moment(
                                          watchCustomer("customer.dob"),
                                          ["MM/DD/YYYY"],
                                          true
                                        ).isValid(),
                                    },
                                    {
                                      label: "Driver’s License Number",
                                      value: customerErrors.customer
                                        ?.driversLicense
                                        ? "-"
                                        : watchCustomer(
                                            "customer.driversLicense"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer
                                          ?.driversLicense &&
                                        !!watchCustomer(
                                          "customer.driversLicense"
                                        ) &&
                                        watchCustomer(
                                          "customer.driversLicense"
                                        ) !== "-",
                                    },
                                    {
                                      label: "SSN Number(Last 4 digits)",
                                      value: customerErrors.customer?.ssn
                                        ? "-"
                                        : watchCustomer("customer.ssn") || "-",
                                      done:
                                        !customerErrors.customer?.ssn &&
                                        !!watchCustomer("customer.ssn") &&
                                        watchCustomer("customer.ssn") !== "-",
                                    },
                                    {
                                      label: "Address Line 1",
                                      value: customerErrors.customer?.address1
                                        ? "-"
                                        : watchCustomer("customer.address1") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.address1 &&
                                        !!watchCustomer("customer.address1") &&
                                        watchCustomer("customer.address1") !==
                                          "-",
                                    },
                                    {
                                      label: "Address Line 2",
                                      value: customerErrors.customer?.address2
                                        ? "-"
                                        : watchCustomer("customer.address2") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.address2 &&
                                        !!watchCustomer("customer.address2") &&
                                        watchCustomer("customer.address2") !==
                                          "-",
                                    },
                                    {
                                      label: "Country",
                                      value: customerErrors.customer?.country
                                        ? "-"
                                        : watchCustomer("customer.country") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.country &&
                                        !!watchCustomer("customer.country") &&
                                        watchCustomer("customer.country") !==
                                          "-",
                                    },
                                    {
                                      label: "State",
                                      value: customerErrors.customer?.state
                                        ? "-"
                                        : watchCustomer("customer.state") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.state &&
                                        !!watchCustomer("customer.state") &&
                                        watchCustomer("customer.state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: customerErrors.customer?.city
                                        ? "-"
                                        : watchCustomer("customer.city") || "-",
                                      done:
                                        !customerErrors.customer?.city &&
                                        !!watchCustomer("customer.city") &&
                                        watchCustomer("customer.city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: customerErrors.customer?.postalCode
                                        ? "-"
                                        : watchCustomer(
                                            "customer.postalCode"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer?.postalCode &&
                                        !!watchCustomer(
                                          "customer.postalCode"
                                        ) &&
                                        watchCustomer("customer.postalCode") !==
                                          "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  sectionDetails={
                                    watchCustomer("customer.selectedPhone") ||
                                    "-"
                                  }
                                  src={contactInformation}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "Phone Number",
                                      value: customerErrors.customer
                                        ?.phoneNumber
                                        ? "-"
                                        : watchCustomer(
                                            "customer.phoneNumber"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer?.phoneNumber &&
                                        !!watchCustomer(
                                          "customer.phoneNumber"
                                        ) &&
                                        watchCustomer(
                                          "customer.phoneNumber"
                                        ) !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: customerErrors.customer?.email
                                        ? "-"
                                        : watchCustomer("customer.email") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.email &&
                                        !!watchCustomer("customer.email") &&
                                        watchCustomer("customer.email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={EmployeInfo}
                                  sectionDetails={
                                    watchCustomer("customer.employerName") ||
                                    "-"
                                  }
                                  section="Employee Information"
                                  details={[
                                    {
                                      label: "Name of Employer",
                                      value: customerErrors.customer
                                        ?.employerName
                                        ? "-"
                                        : watchCustomer(
                                            "customer.employerName"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer
                                          ?.employerName &&
                                        !!watchCustomer(
                                          "customer.employerName"
                                        ) &&
                                        watchCustomer(
                                          "customer.employerName"
                                        ) !== "-",
                                    },
                                    {
                                      label: "Pay Frequency",
                                      value: customerErrors.customer
                                        ?.payFrequency
                                        ? "-"
                                        : watchCustomer(
                                            "customer.payFrequency"
                                          ) || "-",
                                      done:
                                        !customerErrors.customer
                                          ?.payFrequency &&
                                        !!watchCustomer(
                                          "customer.payFrequency"
                                        ) &&
                                        watchCustomer(
                                          "customer.payFrequency"
                                        ) !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={BankInfo}
                                  sectionDetails={watchCustomer(
                                    "customer.bankName"
                                  )}
                                  section="Bank Information"
                                  details={[
                                    {
                                      label: "Bank Name",
                                      value: customerErrors.customer?.bankName
                                        ? "-"
                                        : watchCustomer("customer.bankName") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.bankName &&
                                        !!watchCustomer("customer.bankName") &&
                                        watchCustomer("customer.bankName") !==
                                          "-",
                                    },
                                    {
                                      label: "Account Number",
                                      value: customerErrors.customer?.bankAccNo
                                        ? "-"
                                        : watchCustomer("customer.bankAccNo") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.bankAccNo &&
                                        !!watchCustomer("customer.bankAccNo") &&
                                        watchCustomer("customer.bankAccNo") !==
                                          "-",
                                    },
                                    {
                                      label: "Routing Number",
                                      value: customerErrors.customer?.routingNo
                                        ? "-"
                                        : watchCustomer("customer.routingNo") ||
                                          "-",
                                      done:
                                        !customerErrors.customer?.routingNo &&
                                        !!watchCustomer("customer.routingNo") &&
                                        watchCustomer("customer.routingNo") !==
                                          "-",
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </form>
          </CustomTabPanel>
          <CustomTabPanel value={currentTab} index={1}>
            <form onSubmit={handleBusinessSubmit(onSubmit)}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    width: isLargeScreen
                      ? "100%"
                      : isMiniDesktopScreen
                      ? "55%"
                      : "65%",
                  }}
                >
                  <Accordion
                    expanded={expandedBusiness === "businessPanel1"}
                    onChange={handleBusinessAccordionChange("businessPanel1")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      Customer Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="business.companyName"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Buiness name is required",
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "Buiness name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  icon={<Homework />}
                                  label="Business Name"
                                  placeholder="Enter business name"
                                  error={
                                    businessErrors.business?.companyName
                                      ? businessErrors.business.companyName
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="business.ein"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                pattern: {
                                  value: /^[0-9]{9}$/,
                                  message: "EIN must contain exactly 9 numbers",
                                },
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <InputComponent
                                  {...field}
                                  maxLength={9}
                                  icon={<SsnNumber />}
                                  label="EIN"
                                  placeholder="Enter EIN"
                                  error={error?.message}
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="business.address1"
                              control={businessControl}
                              defaultValue=""
                              rules={{ required: "Address Line 1 is required" }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  icon={<AddressIcon />}
                                  label="Address Line 1 (Street Address)"
                                  placeholder="Enter address"
                                  error={
                                    businessErrors.business?.address1
                                      ? businessErrors.business.address1.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={12}>
                            <Controller
                              name="business.address2"
                              control={businessControl}
                              defaultValue=""
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  icon={<StoreIcon />}
                                  label="Address Line 2 (Post office number, Suite Number, etc)"
                                  placeholder="Enter post office number, suite number, etc."
                                  error={
                                    businessErrors.business?.address2
                                      ? businessErrors.business.address2.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="business.country"
                              control={businessControl}
                              defaultValue="USA"
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  isRequired={true}
                                  icon={<CountryIcon />}
                                  label="Country"
                                  disabled={true}
                                  error={
                                    customerErrors.business?.country
                                      ? customerErrors.business?.country.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>

                          <FormInputCol md={12}>
                            <Controller
                              name="business.state"
                              control={businessControl}
                              defaultValue=""
                              render={({ field }) => {
                                const stateOptions = statesList;
                                const selectedOption = stateOptions.find(
                                  (option) => option.value === field.value
                                );
                                return (
                                  <SelectComponent
                                    options={stateOptions}
                                    value={selectedOption}
                                    onChange={(newValue: any) => {
                                      if (newValue) {
                                        field.onChange(newValue.value);
                                      }
                                      onStateSelect(newValue.value, "business");
                                    }}
                                    label="State"
                                    placeholder="Select State"
                                    fullWidth={true}
                                    icon={<Homework />}
                                    error={
                                      businessErrors.business?.state
                                        ? businessErrors.business.state.message
                                        : null
                                    }
                                  />
                                );
                              }}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="business.city"
                              control={businessControl}
                              defaultValue=""
                              render={({ field }) => {
                                const cityOptions = cityList;
                                const selectedOption = cityOptions.find(
                                  (option) => option.value === field.value
                                );
                                return (
                                  <SelectComponent
                                    options={cityOptions}
                                    value={selectedOption}
                                    onChange={(newValue: any) => {
                                      if (newValue) {
                                        field.onChange(newValue.value);
                                      }
                                    }}
                                    icon={<Location />}
                                    label="City"
                                    placeholder="Select City"
                                    error={
                                      businessErrors.business?.city
                                        ? businessErrors.business.city.message
                                        : null
                                    }
                                  />
                                );
                              }}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="business.postalCode"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Zip Code is required",
                                pattern: {
                                  value: /^[0-9]{5}$/,
                                  message:
                                    "Zip Code must be exactly 5 digits",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  minLength={5}
                                  maxLength={5}
                                  icon={<MyLocation />}
                                  label="Zip Code"
                                  placeholder="Enter zip code"
                                  error={
                                    businessErrors.business?.postalCode
                                      ? businessErrors.business?.postalCode
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expandedBusiness === "businessPanel2"}
                    onChange={handleBusinessAccordionChange("businessPanel2")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Contact Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol md={12}>
                            <Controller
                              name="business.firstName"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "First name is required",
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "First name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  icon={<UserIconGray />}
                                  label="First Name"
                                  placeholder="Enter first name"
                                  error={
                                    businessErrors.business?.firstName
                                      ? businessErrors.business.firstName
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="business.lastName"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Last name is required",
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message:
                                    "Last name can only contain alphabets",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  icon={<UserIconGray />}
                                  label="Last Name"
                                  placeholder="Enter last name"
                                  error={
                                    businessErrors.business?.lastName
                                      ? businessErrors.business.lastName.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="business.phoneNumber"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Phone Number is required",
                                pattern: {
                                  value: /^[0-9]{10}$/,
                                  message: "Phone Number must be exactly 10 digits",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  label="Phone Number"
                                  maxLength={10}
                                  minLength={10}
                                  icon={<PhoneIcon />}
                                  placeholder="Enter phone number"
                                  error={
                                    businessErrors.business?.phoneNumber
                                      ? businessErrors.business?.phoneNumber
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Divider sx={{ border: "1px dashed #D5E1F9" }} />
                          </FormInputCol>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="business.email"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                required: "Email is required",
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                  message: "Enter a valid email address",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  isRequired={true}
                                  {...field}
                                  label="Email Address"
                                  icon={<EmailIcon />}
                                  placeholder="Enter Email"
                                  error={
                                    businessErrors.business?.email
                                      ? businessErrors.business?.email.message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    expanded={expandedBusiness === "businessPanel3"}
                    onChange={handleBusinessAccordionChange("businessPanel3")}
                  >
                    <AccordionSummary
                      expandIcon={<DownAccordianArrow />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      Bank Information
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="accordianbox">
                        <FormInputRow>
                          <FormInputCol lg={12} md={12} xl={12}>
                            <Controller
                              name="business.bankName"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                pattern: {
                                  value: /^[A-Za-z ]+$/i,
                                  message: "Only alphabets are allowed",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  label="Bank Name"
                                  icon={<UserIconGray />}
                                  placeholder="Enter bank name"
                                  error={
                                    businessErrors.business?.bankName
                                      ? businessErrors.business?.bankName
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12}>
                            <Controller
                              name="business.bankAccNo"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message:
                                    "Account Number can only contain numbers",
                                },
                                minLength: {
                                  value: 6,
                                  message:
                                    "Account Number must be at least 6 digits long",
                                },
                              }}
                              render={({ field }) => (
                                <InputComponent
                                  {...field}
                                  minLength={6}
                                  maxLength={17}
                                  icon={<AccountNumberIcon />}
                                  label="Account Number"
                                  placeholder="Enter account number"
                                  error={
                                    businessErrors.business?.bankAccNo
                                      ? businessErrors.business?.bankAccNo
                                          .message
                                      : null
                                  }
                                />
                              )}
                            />
                          </FormInputCol>
                          <FormInputCol md={12} xl={6}>
                            <Controller
                              name="business.routingNo"
                              control={businessControl}
                              defaultValue=""
                              rules={{
                                pattern: {
                                  value: /^[0-9]{9}$/,
                                  message:
                                    "Routing Number must contain exactly 9 numbers",
                                },
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <InputComponent
                                  {...field}
                                  maxLength={9}
                                  icon={<SsnNumber />}
                                  label="Routing Number"
                                  placeholder="Enter rounting number"
                                  error={error?.message}
                                />
                              )}
                            />
                          </FormInputCol>
                        </FormInputRow>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  {/* {isLargeScreen && ( */}
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      sx={{
                        background: "#fff",
                        color: "#000000",
                        border: "1px solid black",
                        marginRight: "10px",
                      }}
                      onClick={handleBusinessCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        background: isBusinessValid ? "#1F3A91" : "#BCBCBD",
                        color: "#ffffff !important",
                      }}
                      onClick={handleBusinessSubmit(onSubmit)}
                      disabled={!isBusinessValid}
                    >
                      Add Customer
                    </Button>
                  </Box>
                  {/* )} */}
                </Box>
                {!isLargeScreen && (
                  <Box
                    sx={{
                      width: "1%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "1px",
                        border: "1px dashed #D5E1F9",
                        height: "100%",
                      }}
                    />
                  </Box>
                )}
                {!isLargeScreen && (
                  <Box sx={{ width: isMiniDesktopScreen ? "42%" : "32%" }}>
                    <Box
                      className="accordianbox"
                      sx={{ background: "#F7FAFF !important" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          color: "#374151",
                          fontSize: "16px",
                          marginBottom: "10px",
                        }}
                      >
                        {"Customer OverView"}
                      </Typography>
                      <Box
                        sx={{
                          background: "#fff",
                          padding: "20px",
                          borderRadius: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {!hasBusinessDetails && (
                            <Box
                              sx={{
                                width: "80%",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <NoDeatilsInformation />
                              </Box>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "#303132",
                                  fontWeight: "600",
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                No details added yet!
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#6B7280",
                                  fontWeight: "400",
                                  textAlign: "center",
                                  marginTop: "5px",
                                }}
                              >
                                Get started by adding details to onboard a new
                                client.
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        {hasBusinessDetails && (
                          <>
                            {expandedBusiness === "businessPanel1" && (
                              <>
                                <CustomerOverview
                                  section="Customer Information"
                                  sectionDetails="Business"
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Business Name",
                                      value: businessErrors.business
                                        ?.companyName
                                        ? "-"
                                        : watchBusiness(
                                            "business.companyName"
                                          ) || "-",
                                      done:
                                        !businessErrors.business?.companyName &&
                                        !!watchBusiness(
                                          "business.companyName"
                                        ) &&
                                        watchBusiness(
                                          "business.companyName"
                                        ) !== "-",
                                    },
                                    {
                                      label: "EIN Number",
                                      value: businessErrors.business?.ein
                                        ? "-"
                                        : watchBusiness("business.ein") || "-",
                                      done:
                                        !businessErrors.business?.ein &&
                                        !!watchBusiness("business.ein") &&
                                        watchBusiness("business.ein") !== "-",
                                    },
                                    {
                                      label: "Address Line 1",
                                      value: businessErrors.business?.address1
                                        ? "-"
                                        : watchBusiness("business.address1") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.address1 &&
                                        !!watchBusiness("business.address1") &&
                                        watchBusiness("business.address1") !==
                                          "-",
                                    },
                                    {
                                      label: "Address Line 2",
                                      value: businessErrors.business?.address2
                                        ? "-"
                                        : watchBusiness("business.address2") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.address2 &&
                                        !!watchBusiness("business.address2") &&
                                        watchBusiness("business.address2") !==
                                          "-",
                                    },
                                    {
                                      label: "Country",
                                      value: businessErrors.business?.country
                                        ? "-"
                                        : watchBusiness("business.country") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.country &&
                                        !!watchBusiness("business.country") &&
                                        watchBusiness("business.country") !==
                                          "-",
                                    },
                                    {
                                      label: "State",
                                      value: businessErrors.business?.state
                                        ? "-"
                                        : watchBusiness("business.state") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.state &&
                                        !!watchBusiness("business.state") &&
                                        watchBusiness("business.state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: businessErrors.business?.city
                                        ? "-"
                                        : watchBusiness("business.city") || "-",
                                      done:
                                        !businessErrors.business?.city &&
                                        !!watchBusiness("business.city") &&
                                        watchBusiness("business.city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: businessErrors.business?.postalCode
                                        ? "-"
                                        : watchBusiness(
                                            "business.postalCode"
                                          ) || "-",
                                      done:
                                        !businessErrors.business?.postalCode &&
                                        !!watchBusiness(
                                          "business.postalCode"
                                        ) &&
                                        watchBusiness("business.postalCode") !==
                                          "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={"-"}
                                  section="Contact Information"
                                  details={[]}
                                />

                                <CustomerOverview
                                  src={BankInfo}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expandedBusiness === "businessPanel2" && (
                              <>
                                <CustomerOverview
                                  section="Customer Information"
                                  sectionDetails="Business"
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Business Name",
                                      value: businessErrors.business
                                        ?.companyName
                                        ? "-"
                                        : watchBusiness(
                                            "business.companyName"
                                          ) || "-",
                                      done:
                                        !businessErrors.business?.companyName &&
                                        !!watchBusiness(
                                          "business.companyName"
                                        ) &&
                                        watchBusiness(
                                          "business.companyName"
                                        ) !== "-",
                                    },
                                    {
                                      label: "EIN Number",
                                      value: businessErrors.business?.ein
                                        ? "-"
                                        : watchBusiness("business.ein") || "-",
                                      done:
                                        !businessErrors.business?.ein &&
                                        !!watchBusiness("business.ein") &&
                                        watchBusiness("business.ein") !== "-",
                                    },
                                    {
                                      label: "Address Line 1",
                                      value: businessErrors.business?.address1
                                        ? "-"
                                        : watchBusiness("business.address1") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.address1 &&
                                        !!watchBusiness("business.address1") &&
                                        watchBusiness("business.address1") !==
                                          "-",
                                    },
                                    {
                                      label: "Address Line 2",
                                      value: businessErrors.business?.address2
                                        ? "-"
                                        : watchBusiness("business.address2") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.address2 &&
                                        !!watchBusiness("business.address2") &&
                                        watchBusiness("business.address2") !==
                                          "-",
                                    },
                                    {
                                      label: "Country",
                                      value: businessErrors.business?.country
                                        ? "-"
                                        : watchBusiness("business.country") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.country &&
                                        !!watchBusiness("business.country") &&
                                        watchBusiness("business.country") !==
                                          "-",
                                    },
                                    {
                                      label: "State",
                                      value: businessErrors.business?.state
                                        ? "-"
                                        : watchBusiness("business.state") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.state &&
                                        !!watchBusiness("business.state") &&
                                        watchBusiness("business.state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: businessErrors.business?.city
                                        ? "-"
                                        : watchBusiness("business.city") || "-",
                                      done:
                                        !businessErrors.business?.city &&
                                        !!watchBusiness("business.city") &&
                                        watchBusiness("business.city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: businessErrors.business?.postalCode
                                        ? "-"
                                        : watchBusiness(
                                            "business.postalCode"
                                          ) || "-",
                                      done:
                                        !businessErrors.business?.postalCode &&
                                        !!watchBusiness(
                                          "business.postalCode"
                                        ) &&
                                        watchBusiness("business.postalCode") !==
                                          "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={
                                    watchBusiness("business.selectedPhone") ||
                                    "-"
                                  }
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "First Name",
                                      value: businessErrors.business?.firstName
                                        ? "-"
                                        : watchBusiness("business.firstName") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.firstName &&
                                        !!watchBusiness("business.firstName") &&
                                        watchBusiness("business.firstName") !==
                                          "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: businessErrors.business?.lastName
                                        ? "-"
                                        : watchBusiness("business.lastName") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.lastName &&
                                        !!watchBusiness("business.lastName") &&
                                        watchBusiness("business.lastName") !==
                                          "-",
                                    },
                                    {
                                      label: "Phone Number",
                                      value: businessErrors.business
                                        ?.phoneNumber
                                        ? "-"
                                        : watchBusiness(
                                            "business.phoneNumber"
                                          ) || "-",
                                      done:
                                        !businessErrors.business?.phoneNumber &&
                                        !!watchBusiness(
                                          "business.phoneNumber"
                                        ) &&
                                        watchBusiness(
                                          "business.phoneNumber"
                                        ) !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: businessErrors.business?.email
                                        ? "-"
                                        : watchBusiness("business.email") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.email &&
                                        !!watchBusiness("business.email") &&
                                        watchBusiness("business.email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={BankInfo}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expandedBusiness === "businessPanel3" && (
                              <>
                                <CustomerOverview
                                  section="Customer Information"
                                  sectionDetails="Business"
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Business Name",
                                      value: businessErrors.business
                                        ?.companyName
                                        ? "-"
                                        : watchBusiness(
                                            "business.companyName"
                                          ) || "-",
                                      done:
                                        !businessErrors.business?.companyName &&
                                        !!watchBusiness(
                                          "business.companyName"
                                        ) &&
                                        watchBusiness(
                                          "business.companyName"
                                        ) !== "-",
                                    },
                                    {
                                      label: "EIN Number",
                                      value: businessErrors.business?.ein
                                        ? "-"
                                        : watchBusiness("business.ein") || "-",
                                      done:
                                        !businessErrors.business?.ein &&
                                        !!watchBusiness("business.ein") &&
                                        watchBusiness("business.ein") !== "-",
                                    },
                                    {
                                      label: "Address Line 1",
                                      value: businessErrors.business?.address1
                                        ? "-"
                                        : watchBusiness("business.address1") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.address1 &&
                                        !!watchBusiness("business.address1") &&
                                        watchBusiness("business.address1") !==
                                          "-",
                                    },
                                    {
                                      label: "Address Line 2",
                                      value: businessErrors.business?.address2
                                        ? "-"
                                        : watchBusiness("business.address2") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.address2 &&
                                        !!watchBusiness("business.address2") &&
                                        watchBusiness("business.address2") !==
                                          "-",
                                    },
                                    {
                                      label: "Country",
                                      value: businessErrors.business?.country
                                        ? "-"
                                        : watchBusiness("business.country") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.country &&
                                        !!watchBusiness("business.country") &&
                                        watchBusiness("business.country") !==
                                          "-",
                                    },
                                    {
                                      label: "State",
                                      value: businessErrors.business?.state
                                        ? "-"
                                        : watchBusiness("business.state") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.state &&
                                        !!watchBusiness("business.state") &&
                                        watchBusiness("business.state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: businessErrors.business?.city
                                        ? "-"
                                        : watchBusiness("business.city") || "-",
                                      done:
                                        !businessErrors.business?.city &&
                                        !!watchBusiness("business.city") &&
                                        watchBusiness("business.city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: businessErrors.business?.postalCode
                                        ? "-"
                                        : watchBusiness(
                                            "business.postalCode"
                                          ) || "-",
                                      done:
                                        !businessErrors.business?.postalCode &&
                                        !!watchBusiness(
                                          "business.postalCode"
                                        ) &&
                                        watchBusiness("business.postalCode") !==
                                          "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={
                                    watchBusiness("business.selectedPhone") ||
                                    "-"
                                  }
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "First Name",
                                      value: businessErrors.business?.firstName
                                        ? "-"
                                        : watchBusiness("business.firstName") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.firstName &&
                                        !!watchBusiness("business.firstName") &&
                                        watchBusiness("business.firstName") !==
                                          "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: businessErrors.business?.lastName
                                        ? "-"
                                        : watchBusiness("business.lastName") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.lastName &&
                                        !!watchBusiness("business.lastName") &&
                                        watchBusiness("business.lastName") !==
                                          "-",
                                    },
                                    {
                                      label: "Phone Number",
                                      value: businessErrors.business
                                        ?.phoneNumber
                                        ? "-"
                                        : watchBusiness(
                                            "business.phoneNumber"
                                          ) || "-",
                                      done:
                                        !businessErrors.business?.phoneNumber &&
                                        !!watchBusiness(
                                          "business.phoneNumber"
                                        ) &&
                                        watchBusiness(
                                          "business.phoneNumber"
                                        ) !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: businessErrors.business?.email
                                        ? "-"
                                        : watchBusiness("business.email") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.email &&
                                        !!watchBusiness("business.email") &&
                                        watchBusiness("business.email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={BankInfo}
                                  sectionDetails={
                                    watchBusiness("business.bankName") || "-"
                                  }
                                  section="Bank Information"
                                  details={[
                                    {
                                      label: "Bank Name",
                                      value: businessErrors.business?.bankName
                                        ? "-"
                                        : watchBusiness("business.bankName") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.bankName &&
                                        !!watchBusiness("business.bankName") &&
                                        watchBusiness("business.bankName") !==
                                          "-",
                                    },
                                    {
                                      label: "Account Number",
                                      value: businessErrors.business?.bankAccNo
                                        ? "-"
                                        : watchBusiness("business.bankAccNo") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.bankAccNo &&
                                        !!watchBusiness("business.bankAccNo") &&
                                        watchBusiness("business.bankAccNo") !==
                                          "-",
                                    },
                                    {
                                      label: "Routing Number",
                                      value: businessErrors.business?.routingNo
                                        ? "-"
                                        : watchBusiness("business.routingNo") ||
                                          "-",
                                      done:
                                        !businessErrors.business?.routingNo &&
                                        !!watchBusiness("business.routingNo") &&
                                        watchBusiness("business.routingNo") !==
                                          "-",
                                    },
                                  ]}
                                />
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </form>
          </CustomTabPanel>
        </Box>
        {loading && <CircularSpinner progress={progress} />}
      </Panel>
    </>
  );
};

export default AddNewCustomer;
