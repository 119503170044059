const ProfessionIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2982 11.8612C13.4532 12.5962 11.3457 12.9862 9.1857 12.9862C7.02569 12.9862 4.91819 12.5962 3.08069 11.8612C2.80319 11.7487 2.53319 11.6362 2.27069 11.5087C2.14319 11.4487 2.01569 11.3887 1.88819 11.3137L2.05319 15.9937H16.3257L16.4832 11.3137C16.3557 11.3887 16.2282 11.4487 16.1007 11.5087C15.8382 11.6362 15.5682 11.7487 15.2982 11.8612Z"
        fill="#6B7280"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.25054 4.1286L7.68255 3.1311H10.6923L11.1243 4.1286H7.25054ZM12.3505 4.1286L11.4325 2.0061H6.94229L6.02429 4.1286H1.87829V9.59383L2.06354 9.70258C3.86504 10.7563 6.13979 11.3698 8.5278 11.4666V12.4393H9.84705V11.4666C12.2366 11.3691 14.5113 10.7556 16.3105 9.70258L16.4965 9.59383V4.1286H12.3505Z"
        fill="#6B7280"
      />
    </svg>
  );
};

export default ProfessionIcon;
