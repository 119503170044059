import { SyntheticEvent, useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  FormControlLabel,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
  Checkbox,
} from "@mui/material";
import Panel from "../../components/Panel";
import InputComponent from "../../components/form/input";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import "../customers/index.css";
import DownAccordianArrow from "../../assets/IconComponents/DownAccordianArrow";
import PhoneIcon from "../../assets/IconComponents/PhoneIcon";
import EmailIcon from "../../assets/IconComponents/EmailIcon";
import customerInformationIcon from "../../assets/media/icons/Customer_Information.png";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useAlertMessage } from "../../utils/useAlertMessage";
import { Controller, useForm } from "react-hook-form";
import CustomerOverview from "../customers/CustomerOverview";
import NoDeatilsInformation from "../../assets/IconComponents/NoDetailsIcon";
import ToggleSwitch from "../../components/form/ToggleSwitch";
import PasswordIcon from "../../assets/IconComponents/PasswordIcon";
import VectorIcon from "../../assets/IconComponents/VectorIcon";
import VectorIconUp from "../../assets/IconComponents/VectoreIconUp";
import { CreateUser } from "../../api/UsersApi";
import CircularSpinner from "../../components/Loader/CircularLoader";

const AddNewUser: React.FC = () => {
  const theme = useTheme();
  const notification = useAlertMessage();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(max-width:1023px)");
  const isMiniDesktopScreen = useMediaQuery(
    "(min-width:1023px) and (max-width:1439px)"
  );
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});

  const handleToggleVisibility = (field: string) => {
    setVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleAccordionChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      if (!isExpanded && expanded === panel) {
        return;
      }
      setExpanded(isExpanded ? panel : false);
    };

  const [isActive, setIsActive] = useState(true);

  const [collapsedSections, setCollapsedSections] = useState({
    primary: false,
    secondary: true,
  });
  const collapsedSection = (section: keyof typeof collapsedSections) => {
    setCollapsedSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      phone: "",
      email: "",
      secondaryPhone: "",
      secondaryEmail: "",
      lastName: "",
      jobTitle: "",
      confirmPassword: "",
      status: "active",
      password: "",
      addContact: false,
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    setProgress(0);
    let timer;
    const userPayload = {
      userName: "",
      firstName: data.firstName,
      lastName: data.lastName,
      jobTitle: data.jobTitle,
      status: data.status,
      email: data.email,
      phone: data.phone,
      secondaryPhone: data.secondaryPhone || "",
      secondaryEmail: data.secondaryEmail || "",
      password: data.password || "",
    };
    try {
      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const { data, status } = await CreateUser(userPayload);
      setProgress(100);
      if (status === 200) {
        notification.successNotification(
          "Success",
          "User Created successfully!"
        );
        reset();
        navigate("/acucheck/users");
      } else {
        notification.errorNotification("Error", "User creation failed.");
      }
    } catch (error) {
      console.error("Error creating user:", error);
      notification.errorNotification(
        "Error",
        (error as any).response.data.message
      );
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  const handleUserCancel = () => {
    reset();
    setIsActive(true);
    setExpanded("panel1");
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/acucheck/users"}
      sx={{ width: "fit-content", display: "flex" }}
    >
      <PersonAddIcon
        sx={{ marginRight: 1, color: theme.palette.primary.main }}
      />
      <Typography sx={{ color: theme.palette.primary.main }}>Users</Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      Add New User
    </Typography>,
  ];

  const hasUserDetails =
    watch("firstName") ||
    watch("lastName") ||
    watch("jobTitle") ||
    watch("phone") ||
    watch("email") ||
    watch("secondaryEmail") ||
    watch("password") ||
    watch("confirmPassword") ||
    watch("secondaryPhone") ||
    watch("status");

  return (
    <>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Panel>
      <Panel>
        <Box sx={{ padding: "20px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  width: isLargeScreen
                    ? "100%"
                    : isMiniDesktopScreen
                    ? "55%"
                    : "65%",
                }}
              >
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<DownAccordianArrow />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    User Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="accordianbox">
                      <FormInputRow>
                        <FormInputCol md={12}>
                          <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message:
                                  "First name can only contain alphabets",
                              },
                            }}
                            render={({ field }) => (
                              <InputComponent
                                {...field}
                                icon={<UserIconGray />}
                                label="First Name"
                                placeholder="Enter first name"
                                error={
                                  (errors.firstName?.message as string) || null
                                }
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12}>
                          <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Last name is required",
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message: "Last name can only contain alphabets",
                              },
                            }}
                            render={({ field }) => (
                              <InputComponent
                                {...field}
                                icon={<UserIconGray />}
                                label="Last Name"
                                placeholder="Enter last name"
                                error={
                                  (errors.lastName?.message as string) || null
                                }
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12}>
                          <Controller
                            name="jobTitle"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Job title is required",
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message: "Job Title can only contain alphabets",
                              },
                            }}
                            render={({ field }) => (
                              <InputComponent
                                {...field}
                                icon={<UserIconGray />}
                                label="Job Title"
                                placeholder="Enter Job Ttile"
                                error={
                                  (errors.jobTitle?.message as string) || null
                                }
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12}>
                          <Controller
                            name="status"
                            control={control}
                            defaultValue="active"
                            render={({ field }) => {
                              const handleToggleChange = (checked: boolean) => {
                                const newValue = checked
                                  ? "active"
                                  : "inactive";
                                setIsActive(checked);
                                field.onChange(newValue);
                              };

                              return (
                                <ToggleSwitch
                                  checked={isActive}
                                  onChange={handleToggleChange}
                                  labelActive="Active"
                                  labelInactive="Inactive"
                                  label="Account Access"
                                />
                              );
                            }}
                          />
                        </FormInputCol>
                      </FormInputRow>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<DownAccordianArrow />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Contact Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="accordianbox">
                      <FormInputRow>
                        <FormInputCol lg={12} md={12} xl={12}>
                          <Box
                            display="flex"
                            alignItems="center"
                            mb={2}
                            onClick={() => collapsedSection("primary")}
                            style={{ cursor: "pointer" }}
                          >
                            <Typography
                              sx={{ fontWeight: 600, fontSize: 14, mr: 1 }}
                            >
                              Primary Contact Information
                            </Typography>
                            {collapsedSections.primary ? (
                              <VectorIconUp />
                            ) : (
                              <VectorIcon />
                            )}
                          </Box>
                        </FormInputCol>

                        {!collapsedSections.primary && (
                          <>
                            <FormInputCol md={6}>
                              <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Enter a valid email address",
                                  },
                                  validate: (value) => {
                                    return (
                                      value !== watch("secondaryEmail") ||
                                      "Primary and secondary emails cannot be the same"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <InputComponent
                                    {...field}
                                    label="Email Address"
                                    icon={<EmailIcon />}
                                    placeholder="Enter email address"
                                    error={
                                      (errors.email?.message as string) || null
                                    }
                                  />
                                )}
                              />
                            </FormInputCol>
                            <FormInputCol md={6}>
                              <Controller
                                name="phone"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Mobile Number is required",
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Mobile Number must be exactly 10 digits",
                                  },
                                  validate: (value) => {
                                    return (
                                      value !== watch("secondaryPhone") ||
                                      "Primary and secondary phone numbers cannot be the same"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <InputComponent
                                    {...field}
                                    maxLength={10}
                                    minLength={10}
                                    label="Mobile Number"
                                    icon={<PhoneIcon />}
                                    placeholder="Enter mobile number"
                                    error={
                                      (errors.phone?.message as string) || null
                                    }
                                  />
                                )}
                              />
                            </FormInputCol>
                          </>
                        )}

                        <FormInputCol lg={12} md={12} xl={12}>
                          <Divider sx={{ border: "1px dashed #D5E1F9" }} />
                        </FormInputCol>
                        <FormInputCol lg={12} md={12} xl={12}>
                          <Controller
                            name="addContact"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;
                                      field.onChange(isChecked);

                                      if (isChecked) {
                                        setCollapsedSections((prev) => ({
                                          ...prev,
                                          secondary: false,
                                          primary: true,
                                        }));
                                      } else {
                                        const secondaryPhone =
                                          getValues("secondaryPhone");
                                        const secondaryEmail =
                                          getValues("secondaryEmail");

                                        if (secondaryPhone || secondaryEmail) {
                                          setValue("secondaryPhone", "");
                                          setValue("secondaryEmail", "");
                                        }

                                        setCollapsedSections((prev) => ({
                                          ...prev,
                                          secondary: true,
                                          primary: false,
                                        }));
                                      }
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body2"
                                    sx={{ fontSize: 14 }}
                                  >
                                    Do you want to add contact? (additional
                                    contacts are marked as secondary contact in
                                    the database)
                                  </Typography>
                                }
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol lg={12} md={12} xl={12}>
                          <Box
                            display="flex"
                            alignItems="center"
                            mb={2}
                            onClick={() => {
                              if (getValues("addContact"))
                                collapsedSection("secondary");
                            }}
                            style={{
                              cursor: getValues("addContact")
                                ? "pointer"
                                : "not-allowed",
                              opacity: getValues("addContact") ? 1 : 0.5,
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: 600, fontSize: 14, mr: 1 }}
                            >
                              Secondary Contact Information
                            </Typography>
                            {collapsedSections.secondary ? (
                              <VectorIconUp />
                            ) : (
                              <VectorIcon />
                            )}
                          </Box>
                        </FormInputCol>

                        {!collapsedSections.secondary && (
                          <>
                            <FormInputCol md={6}>
                              <Controller
                                name="secondaryEmail"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Enter a valid email address",
                                  },
                                  validate: (value) => {
                                    return (
                                      value !== watch("email") ||
                                      "Primary and secondary emails cannot be the same"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <InputComponent
                                    {...field}
                                    label="Email Address"
                                    icon={<EmailIcon />}
                                    placeholder="Enter email address"
                                    error={
                                      (errors.secondaryEmail
                                        ?.message as string) || null
                                    }
                                  />
                                )}
                              />
                            </FormInputCol>
                            <FormInputCol md={6}>
                              <Controller
                                name="secondaryPhone"
                                control={control}
                                defaultValue=""
                                rules={{
                                  required: "Mobile Number is required",
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Mobile Number must be exactly 10 digits",
                                  },
                                  validate: (value) => {
                                    return (
                                      value !== watch("phone") ||
                                      "Primary and secondary phone numbers cannot be the same"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <InputComponent
                                    {...field}
                                    label="Mobile Number"
                                    maxLength={10}
                                    minLength={10}
                                    icon={<PhoneIcon />}
                                    placeholder="Enter mobile number"
                                    error={
                                      (errors.secondaryPhone
                                        ?.message as string) || null
                                    }
                                  />
                                )}
                              />
                            </FormInputCol>
                          </>
                        )}
                      </FormInputRow>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleAccordionChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<DownAccordianArrow />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Password Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="accordianbox">
                      <FormInputRow>
                        <FormInputCol>
                          <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "New Password is required",
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                                message:
                                  "Password must include at least one uppercase letter, one lowercase letter, one special character, and one number",
                              },
                            }}
                            render={({ field }) => (
                              <InputComponent
                                {...field}
                                icon={<PasswordIcon />}
                                label="New Password"
                                type="password"
                                placeholder="Enter new password"
                                password
                                error={
                                  (errors.password?.message as string) || null
                                }
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol>
                          <Controller
                            name="confirmPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Confirm Password is required",
                              validate: (value) =>
                                value === watch("password") ||
                                "Passwords do not match",
                            }}
                            render={({ field }) => (
                              <InputComponent
                                {...field}
                                icon={<PasswordIcon />}
                                label="Confirm Password"
                                type="password"
                                placeholder="Re-enter new password"
                                password
                                error={errors.confirmPassword?.message || null}
                              />
                            )}
                          />
                        </FormInputCol>
                      </FormInputRow>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    sx={{
                      background: "#fff",
                      color: "#000000",
                      border: "1px solid black",
                      marginRight: "10px",
                    }}
                    onClick={handleUserCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      background: isValid ? "#1F3A91" : "#BCBCBD",
                      color: "#ffffff !important",
                    }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isValid}
                  >
                    Add User
                  </Button>
                </Box>
              </Box>
              {!isLargeScreen && (
                <Box
                  sx={{
                    width: "1%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "1px",
                      border: "1px dashed #D5E1F9",
                      height: "100%",
                    }}
                  />
                </Box>
              )}
              {!isLargeScreen && (
                <Box sx={{ width: isMiniDesktopScreen ? "42%" : "32%" }}>
                  <Box
                    className="accordianbox"
                    sx={{ background: "#F7FAFF !important" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: "#374151",
                        fontSize: "16px",
                        marginBottom: "10px",
                      }}
                    >
                      {"User OverView"}
                    </Typography>
                    <Box
                      sx={{
                        background: "#fff",
                        padding: "20px",
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        {!hasUserDetails && (
                          <Box
                            sx={{
                              width: "80%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <NoDeatilsInformation />
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#303132",
                                fontWeight: "600",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              No details added yet!
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "#6B7280",
                                fontWeight: "400",
                                textAlign: "center",
                                marginTop: "5px",
                              }}
                            >
                              Get started by adding details to onboard a new
                              client.
                            </Typography>
                          </Box>
                        )}
                        {hasUserDetails && (
                          <>
                            {expanded === "panel1" && (
                              <>
                                <CustomerOverview
                                  section="User Information"
                                  sectionDetails={
                                    `${watch("firstName")} ${watch(
                                      "lastName"
                                    )}` || "-"
                                  }
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "First Name",
                                      value: errors.firstName
                                        ? "-"
                                        : watch("firstName") || "-",
                                      done:
                                        !errors.firstName &&
                                        !!watch("firstName") &&
                                        watch("firstName") !== "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: errors.lastName
                                        ? "-"
                                        : watch("lastName") || "-",
                                      done:
                                        !errors.lastName &&
                                        !!watch("lastName") &&
                                        watch("lastName") !== "-",
                                    },
                                    {
                                      label: "Job Title",
                                      value: errors.jobTitle
                                        ? "-"
                                        : watch("jobTitle") || "-",
                                      done:
                                        !errors.jobTitle &&
                                        !!watch("jobTitle") &&
                                        watch("jobTitle") !== "-",
                                    },
                                    {
                                      label: "Account Status",
                                      value: errors.status
                                        ? "-"
                                        : watch("status")
                                        ? watch("status") === "active"
                                          ? "Active"
                                          : "Inactive"
                                        : "-",
                                      done:
                                        !errors.status &&
                                        !!watch("status") &&
                                        watch("status") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={customerInformationIcon}
                                  sectionDetails={"-"}
                                  section="Contact Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={customerInformationIcon}
                                  sectionDetails={"-"}
                                  section="Password Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel2" && (
                              <>
                                <CustomerOverview
                                  section="User Information"
                                  sectionDetails={
                                    `${watch("firstName")} ${watch(
                                      "lastName"
                                    )}` || "-"
                                  }
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "First Name",
                                      value: errors.firstName
                                        ? "-"
                                        : watch("firstName") || "-",
                                      done:
                                        !errors.firstName &&
                                        !!watch("firstName") &&
                                        watch("firstName") !== "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: errors.lastName
                                        ? "-"
                                        : watch("lastName") || "-",
                                      done:
                                        !errors.lastName &&
                                        !!watch("lastName") &&
                                        watch("lastName") !== "-",
                                    },
                                    {
                                      label: "Job Title",
                                      value: errors.jobTitle
                                        ? "-"
                                        : watch("jobTitle") || "-",
                                      done:
                                        !errors.jobTitle &&
                                        !!watch("jobTitle") &&
                                        watch("jobTitle") !== "-",
                                    },
                                    {
                                      label: "Account Status",
                                      value: errors.status
                                        ? "-"
                                        : watch("status")
                                        ? watch("status") === "active"
                                          ? "Active"
                                          : "Inactive"
                                        : "-",
                                      done:
                                        !errors.status &&
                                        !!watch("status") &&
                                        watch("status") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={customerInformationIcon}
                                  sectionDetails={watch("phone")}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "Primary Phone Number",
                                      value: errors.phone
                                        ? "-"
                                        : watch("phone") || "-",
                                      done:
                                        !errors.phone &&
                                        !!watch("phone") &&
                                        watch("phone") !== "-",
                                    },
                                    {
                                      label: "Primary Email",
                                      value: errors.email
                                        ? "-"
                                        : watch("email") || "-",
                                      done:
                                        !errors.email &&
                                        !!watch("email") &&
                                        watch("email") !== "-",
                                    },
                                    ...(watch("secondaryPhone")
                                      ? [
                                          {
                                            label: "Secondary Phone Number",
                                            value: errors.secondaryPhone
                                              ? "-"
                                              : watch("secondaryPhone") || "-",
                                            done:
                                              !errors.secondaryPhone &&
                                              !!watch("secondaryPhone") &&
                                              watch("secondaryPhone") !== "-",
                                          },
                                        ]
                                      : []),
                                    ...(watch("secondaryEmail")
                                      ? [
                                          {
                                            label: "Secondary Email",
                                            value: errors.secondaryEmail
                                              ? "-"
                                              : watch("secondaryEmail") || "-",
                                            done:
                                              !errors.secondaryEmail &&
                                              !!watch("secondaryEmail") &&
                                              watch("secondaryEmail") !== "-",
                                          },
                                        ]
                                      : []),
                                  ]}
                                />
                                <CustomerOverview
                                  src={customerInformationIcon}
                                  sectionDetails={"-"}
                                  section="Password Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel3" && (
                              <>
                                <CustomerOverview
                                  section="User Information"
                                  sectionDetails={
                                    `${watch("firstName")} ${watch(
                                      "lastName"
                                    )}` || "-"
                                  }
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "First Name",
                                      value: errors.firstName
                                        ? "-"
                                        : watch("firstName") || "-",
                                      done:
                                        !errors.firstName &&
                                        !!watch("firstName") &&
                                        watch("firstName") !== "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: errors.lastName
                                        ? "-"
                                        : watch("lastName") || "-",
                                      done:
                                        !errors.lastName &&
                                        !!watch("lastName") &&
                                        watch("lastName") !== "-",
                                    },
                                    {
                                      label: "Job Title",
                                      value: errors.jobTitle
                                        ? "-"
                                        : watch("jobTitle") || "-",
                                      done:
                                        !errors.jobTitle &&
                                        !!watch("jobTitle") &&
                                        watch("jobTitle") !== "-",
                                    },
                                    {
                                      label: "Account Status",
                                      value: errors.status
                                        ? "-"
                                        : watch("status")
                                        ? watch("status") === "active"
                                          ? "Active"
                                          : "Inactive"
                                        : "-",
                                      done:
                                        !errors.status &&
                                        !!watch("status") &&
                                        watch("status") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={customerInformationIcon}
                                  sectionDetails={watch("phone")}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "Primary Phone Number",
                                      value: errors.phone
                                        ? "-"
                                        : watch("phone") || "-",
                                      done:
                                        !errors.phone &&
                                        !!watch("phone") &&
                                        watch("phone") !== "-",
                                    },
                                    {
                                      label: "Primary Email",
                                      value: errors.email
                                        ? "-"
                                        : watch("email") || "-",
                                      done:
                                        !errors.email &&
                                        !!watch("email") &&
                                        watch("email") !== "-",
                                    },
                                    ...(watch("secondaryPhone")
                                      ? [
                                          {
                                            label: "Secondary Phone Number",
                                            value: errors.secondaryPhone
                                              ? "-"
                                              : watch("secondaryPhone") || "-",
                                            done:
                                              !errors.secondaryPhone &&
                                              !!watch("secondaryPhone") &&
                                              watch("secondaryPhone") !== "-",
                                          },
                                        ]
                                      : []),
                                    ...(watch("secondaryEmail")
                                      ? [
                                          {
                                            label: "Secondary Email",
                                            value: errors.secondaryEmail
                                              ? "-"
                                              : watch("secondaryEmail") || "-",
                                            done:
                                              !errors.secondaryEmail &&
                                              !!watch("secondaryEmail") &&
                                              watch("secondaryEmail") !== "-",
                                          },
                                        ]
                                      : []),
                                  ]}
                                />
                                <CustomerOverview
                                  src={customerInformationIcon}
                                  section="Password Information"
                                  details={[
                                    {
                                      label: "Password",
                                      value: errors.password
                                        ? "-"
                                        : watch("password")
                                        ? visibility["Password"]
                                          ? watch("password")
                                          : "*".repeat(watch("password").length)
                                        : "-",
                                      done:
                                        !errors.password &&
                                        !!watch("password") &&
                                        watch("password") !== "-",
                                    },
                                    {
                                      label: "Confirm Password",
                                      value: errors.confirmPassword
                                        ? "-"
                                        : watch("confirmPassword")
                                        ? visibility["Confirm Password"]
                                          ? watch("confirmPassword")
                                          : "*".repeat(
                                              watch("confirmPassword").length
                                            )
                                        : "-",
                                      done:
                                        !errors.confirmPassword &&
                                        !!watch("confirmPassword") &&
                                        watch("confirmPassword") !== "-",
                                    },
                                  ]}
                                  visibility={visibility}
                                  onToggleVisibility={handleToggleVisibility}
                                />
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </form>
        </Box>
        {loading && <CircularSpinner progress={progress} />}
      </Panel>
    </>
  );
};

export default AddNewUser;
