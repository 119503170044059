import AxiosInstance from "./http";

export async function GetBilling(query: any): Promise<any> {
    try {
        if (query.orderBy) {
            query.order = query?.order?.toUpperCase();
            query.orderBy = query.orderBy;
        }
        const response = await AxiosInstance.get('/Invoice/all', {
            params: query
        });
        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

export async function GetBillingByBillingId(billingId: string): Promise<any> {
    try {
        const response = await AxiosInstance.get(`/Invoice/${billingId}`);
        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

export async function ReSendInvoiceNotification(billingId: string): Promise<any> {
    try {
        const response = await AxiosInstance.get(`/Invoice/${billingId}/send-invoice`);
        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};
