const SelectIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="4" fill="#142A73"/>
    <path d="M8.12997 12.2605L5.69747 9.82803L4.86914 10.6505L8.12997 13.9114L15.13 6.91137L14.3075 6.08887L8.12997 12.2605Z" fill="#F6F6F6"/>
    </svg>
    
  )
}

export default SelectIcon