interface IconProps {
    selected?: boolean;
  }
  
  const LocationIcon = ({ selected }: IconProps) => {
    return (
        <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.49954 4.17913C5.26706 4.17913 4.26749 5.15879 4.26749 6.36869C4.26749 7.5777 5.26706 8.55825 6.50044 8.55825C7.73292 8.55825 8.73249 7.5777 8.73249 6.36869C8.73249 5.15967 7.73473 4.17913 6.50225 4.17913H6.49954ZM6.50044 2.9461C8.428 2.9461 9.98942 4.47782 9.98942 6.36867C9.98942 8.25864 8.42707 9.79123 6.50044 9.79123C4.57289 9.79123 3.01054 8.25862 3.01054 6.36867C3.01054 4.47869 4.57198 2.9461 6.49766 2.9461H6.50044ZM6.49773 8.87679e-05C2.90899 8.87679e-05 0 2.85461 0 6.37595C0 7.94945 0.580893 9.38955 1.5433 10.5017L5.9939 15.7511C6.10899 15.9013 6.29206 16 6.49868 16C6.69442 16 6.86934 15.912 6.98443 15.7742L11.4984 10.4527C12.4408 9.34504 13 7.93068 13 6.37586C13 2.85362 10.0901 0 6.49949 0L6.49773 8.87679e-05Z" fill="#374151"/>
        </svg>        
        
    );
  };
  
  export default LocationIcon;
  