import React from "react";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

interface OptionType {
  label: string;
  value: string | number;
}

interface SelectComponentProps {
  options: OptionType[];
  label?: string;
  placeholder?: string;
  value?: OptionType | null; 
  fullWidth?: boolean;
  icon?: React.ReactNode;
  onChange: (newValue: OptionType | null) => void;
  error?: string | null;
  required?:boolean;
  backgroundColor?: string;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  options,
  label,
  value,
  onChange,
  fullWidth,
  icon,
  placeholder = "Please select", 
  error,
  required,
  backgroundColor = "#F9FAFB",
}) => {

  const uniqueOptions = [
    { label: "Please select", value: "" },
    ...Array.from(new Set(options.map(o => o.label)))  
      .map(label => options.find(o => o.label === label))
  ];

  return (
    <>
      <Typography
        sx={{ fontSize: "14px", color: "#111928", fontWeight: "600" }}
      >
        {label ? label : ""}&nbsp;{required && <Typography component={"span"} color="#FF220C">*</Typography>}
      </Typography>
      <Autocomplete
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            fontSize: "16px  !important",
            color: "#6B7280 !important",
            fontWeight: "400 !important",
            padding: "7px !important",
            height: "40px !important",
            "& .MuiInputBase-input": {
              height: "40px !important",
            },
          },
        }}
        options={uniqueOptions}
        value={value || { label: "Please select", value: "" }}
        onChange={(event, newValue) => {
          onChange(newValue?.value ? newValue : null);
        }}
        getOptionLabel={(option) => option?.label || ""}
        // isOptionEqualToValue={(option, value) => option.value === value?.value}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            sx={{
              background: backgroundColor,
              borderRadius: "8px",
              fontSize: "16px !important",
              color: "#6B7280 !important",
              marginTop: "5px",
              fontWeight: "400",

              "& .MuiInputBase-root": {
                boxShadow: "none",
                border: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: error ? "#E94832" : "#D5E1F9",
                borderRadius: "8px",
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D5E1F9",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#D5E1F9",
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                boxShadow: "none",
                border: "none",
              },
              "& .MuiInputAdornment-root": {
                marginRight: "0",
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: icon ? (
                <InputAdornment sx={{ marginLeft: "10px" }} position="start">
                  {icon}
                </InputAdornment>
              ) : null,
            }}
          />
        )}
        disableClearable
        autoHighlight
        fullWidth={fullWidth}
      />
      {error && (
        <Typography
          style={{ color: "#DF6060", fontSize: "14px", fontWeight: "600" }}
        >
          {error}
        </Typography>
      )}
    </>
  );
};

export default SelectComponent;
