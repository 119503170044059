
import * as Yup from 'yup'

const LoginFormValidator: Yup.AnyObject = Yup.object({
    username: Yup.string().required('Email/Username is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required')
});



const PasswordUpdateValidator = Yup.object().shape({
    newPassword: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
        "Password must include at least one uppercase letter, one lowercase letter, one special character, and one number"
      )
      .required("New password is required"),
      
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), undefined], "Passwords do not match")
      .required("Confirm password is required"),
  });

export default {
    LoginFormValidator,
    PasswordUpdateValidator
};