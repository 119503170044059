const InactiveIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd_2914_60230)">
        <circle cx="11" cy="10" r="8" fill="#FF220C"/>
        <circle cx="11" cy="10" r="7.58333" stroke="#FF220C" stroke-width="0.833333"/>
        </g>
        <defs>
        <filter id="filter0_dd_2914_60230" x="0.5" y="0.333333" width="21" height="21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="0.833333"/>
        <feGaussianBlur stdDeviation="1.25"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2914_60230"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="0.833333"/>
        <feGaussianBlur stdDeviation="0.833333"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_2914_60230" result="effect2_dropShadow_2914_60230"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2914_60230" result="shape"/>
        </filter>
        </defs>
        </svg>
        
    );
  };
  
  export default InactiveIcon;