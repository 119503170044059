const UnlockIcon = () => {
  return (
    <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_436_56441)">
    <rect x="10" y="9.6875" width="36.625" height="36.625" rx="18.3125" fill="#EFFFFE"/>
    <path d="M24.375 24.9977C24.3737 24.0384 24.7226 23.1117 25.3562 22.3915C25.9899 21.6712 26.8646 21.2071 27.8162 21.0862C28.7679 20.9653 29.7309 21.196 30.5245 21.7349C31.3181 22.2738 31.8876 23.0838 32.1262 24.0129C32.1668 24.1536 32.2606 24.273 32.3877 24.3456C32.5148 24.4182 32.6653 24.4384 32.8071 24.4019C32.9489 24.3654 33.0708 24.275 33.147 24.15C33.2233 24.025 33.2477 23.8752 33.2153 23.7324C32.908 22.5385 32.1756 21.4977 31.1556 20.8054C30.1355 20.113 28.8979 19.8167 27.6749 19.972C26.4518 20.1273 25.3275 20.7235 24.5128 21.6488C23.6982 22.5741 23.2491 23.7649 23.25 24.9977V26.5389C22.4137 26.6012 21.87 26.7587 21.4717 27.1569C20.8125 27.8154 20.8125 28.8767 20.8125 30.9977C20.8125 33.1187 20.8125 34.1799 21.4717 34.8384C22.1302 35.4977 23.1915 35.4977 25.3125 35.4977H31.3125C33.4335 35.4977 34.4948 35.4977 35.1532 34.8384C35.8125 34.1799 35.8125 33.1187 35.8125 30.9977C35.8125 28.8767 35.8125 27.8154 35.1532 27.1569C34.4948 26.4977 33.4335 26.4977 31.3125 26.4977H25.3125C24.974 26.4972 24.6615 26.4982 24.375 26.5007V24.9977Z" fill="#01AFA1"/>
    </g>
    <defs>
    <filter id="filter0_d_436_56441" x="0.84375" y="0.53125" width="57.7548" height="57.7548" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="0.704327" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_436_56441"/>
    <feOffset dx="1.40865" dy="1.40865"/>
    <feGaussianBlur stdDeviation="4.93029"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.24975 0 0 0 0 0.405 0 0 0 0.2 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_436_56441"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_436_56441" result="shape"/>
    </filter>
    </defs>
    </svg>
    
  );
};

export default UnlockIcon;
