import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Grid2 as Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Panel from "../../components/Panel";
import { useEffect, useRef, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AvatarIcon from "../../assets/IconComponents/AvatarIcon";
import { DataTable } from "../../components/form/TableComponent";
import TruncatedTooltipText from "../../components/form/ToolTip";
import moment from "moment";
import {
  GetBillingByBillingId,
  ReSendInvoiceNotification,
} from "../../api/billingAPI";
import { useAlertMessage } from "../../utils/useAlertMessage";
import CircularSpinner from "../../components/Loader/CircularLoader";
import FileDownloadIcon from "../../assets/IconComponents/FileDownloadIcon";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const ViewBilling = () => {
  const theme = useTheme();
  const { billingId } = useParams();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [billingInfo, setBillingInfo] = useState<any>({});
  const [downloadState, toggleDownloadLoader] = useState(false);

  const [query, setQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    orderBy: "invoiceDate",
    order: "desc",
  });

  const createSortHandler = (key: string) => {
    setQuery({
      ...query,
      pageNumber: 1,
      order: query.order?.toLowerCase() === "asc" ? "desc" : "asc",
      orderBy: key,
    });
  };

  const handlePageChange = (event: any, value: any) => {
    setQuery({
      ...query,
      pageNumber: value,
    });
  };

  const renderStatus = (status: string) => {
    const statusConfig = {
      Paid: {
        iconColor: "#01B6A8",
        backgroundColor: "#EFFFFE",
        textColor: "#01B6A8",
      },
      Failed: {
        iconColor: "#AF6DD1",
        backgroundColor: "#A800FF0F",
        textColor: "#AF6DD1",
      },
    };

    const config = statusConfig[status as keyof typeof statusConfig];
    if (!config) return null;
    return (
      <Chip
        icon={
          <FiberManualRecordIcon
            sx={{ color: `${config.iconColor} !important`, height: "10px" }}
          />
        }
        label={status.charAt(0).toUpperCase() + status.slice(1)}
        sx={{
          backgroundColor: config.backgroundColor,
          color: config.textColor,
          minWidth: "110px",
        }}
      />
    );
  };

  const renderStatusText = (status: string) => {
    const statusText = status.charAt(0).toUpperCase() + status.slice(1);
    return statusText;
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/admin/billing"}
      sx={{ width: "auto", display: "flex" }}
    >
      <ReceiptLongIcon sx={{ color: "#142A73", marginRight: "10px" }} />
      <Typography sx={{ color: theme.palette.primary.main }}>
        Billing
      </Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      {billingInfo?.businessName}
    </Typography>,
  ];

  const navigate = useNavigate();
  const notification = useAlertMessage();

  const getBillingInfo = async () => {
    setLoading(true);
    setProgress(90);
    let timer;
    try {
      if (billingId) {
        const response = await GetBillingByBillingId(billingId);
        console.log(response.data);
        setBillingInfo(response.data);
      }
      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
    } catch (err) {
      navigate("/admin/billing");
      notification.errorNotification(
        "Error",
        (err as any).response?.data.message
      );
      console.error("");
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBillingInfo();
  }, []);

  const reportRef = useRef<HTMLDivElement>(null);

  const downloadPDF = () => {
    const input = reportRef.current;
    toggleDownloadLoader(true);
  
    if (input) {
      setTimeout(() => {
        html2canvas(input, { useCORS: true }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("p", "mm", "a4");
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
  
          const imgProps = pdf.getImageProperties(imgData);
          const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;
          let heightLeft = imgHeight;
          let position = 0;
  
          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
          heightLeft -= pdfHeight;
  
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
            heightLeft -= pdfHeight;
          }
  
          pdf.save("Billing.pdf");
          toggleDownloadLoader(false);
        });
      }, 500);
    }
  };
  
  

  const reSendInvoice = async () => {
    setLoading(true);
    setProgress(90);
    let timer;
    try {
      if (billingId) {
        const { data, status } = await ReSendInvoiceNotification(billingId);
        if (status === 200) {
          notification.successNotification("Success", data.value.message);
        } else {
          notification.errorNotification("Error", data.value.message);
        }
      }
      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
    } catch (err) {
      notification.errorNotification("Error", "Error in sending invoice");
      console.error("");
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  return (
    <>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Panel>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: { xs: "10px", sm: "" },
          }}
        >
          All New Invoices can be generated from this screen
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "row", sm: "row" },
            }}
          >
            <Button
              size="large"
              sx={{
                borderRadius: "10px",
                fontSize: "14px",
                fontWeight: "700",
                border: "1px solid #374151",
                color: "#374151",
                height: "48px",
                width: { md: "180px", lg: "150px" },
              }}
              onClick={reSendInvoice}
            >
              Re-Send Invoice
            </Button>
            <Button
              size="large"
              sx={{
                height: "48px",
                background: "#1F3A91",
                borderRadius: "10px",
                fontSize: "14px",
                fontWeight: "700",
                color: "#FFFFFF",
                width: { sm: "150px", md: "180px", lg: "150px" },
              }}
              onClick={() => {
                downloadPDF();
              }}
              startIcon={
                downloadState ? (
                  <CircularProgress
                    size={20}
                    sx={{
                      height: "20px",
                      width: "20px",
                      color: "#FFFFFF !important",
                    }}
                  />
                ) : (
                  <FileDownloadIcon fill="#FFFFFF" />
                )
              }
            >
              Download
            </Button>
          </Box>
        </Box>
      </Box>
      <Box ref={reportRef} sx={{ width: "100%" }}>
        <Grid container size={12}>
          <Panel
            sx={{
              border: "1px solid #D5E1F9",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                padding: "20px",
                background: "#F7FAFF",
                borderRadius: "8px",
                boxShadow: "1px 1px 5px 0px rgba(1, 87, 184, 0.2)",
              }}
            >
              <Box
                sx={{
                  padding: "20px",
                  background: "#FFFFFF",
                  borderRadius: "8px",
                  border: "1px solid #D5E1F9",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    size={{ xs: 12, sm: 7, lg: 8 }}
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Grid size={{ xs: 12, sm: 3, md: 2.3, lg: 1.8, xl: 1.3 }}>
                      <AvatarIcon />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 9, md: 9.7, lg: 10.2, xl: 11.3 }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: "#374151",
                        }}
                      >
                        Client:
                        <Typography
                          component="span"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#374151",
                          }}
                        >
                          &nbsp;{billingInfo.businessName}
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: "#374151",
                        }}
                      >
                        Email:
                        <Typography
                          component="span"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#374151",
                          }}
                        >
                          &nbsp;{billingInfo.contactEmail}
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: "#374151",
                        }}
                      >
                        Contact No:
                        <Typography
                          component="span"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#374151",
                          }}
                        >
                          &nbsp;{billingInfo.contactPhone}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    size={{ xs: 12, sm: 5, lg: 4 }}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: { xs: "flex-start", sm: "flex-end" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "16px",
                        marginBottom: "10px",
                        fontWeight: "400",
                        color: "#374151",
                      }}
                    >
                      Invoice ID:
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#374151",
                        }}
                      >
                        &nbsp;#{billingInfo.id}
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        marginBottom: "10px",
                        fontWeight: "400",
                        color: "#374151",
                      }}
                    >
                      Invoice Date:
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#374151",
                        }}
                      >
                        &nbsp;
                        {billingInfo.invoiceDate === null
                          ? "-"
                          : moment(billingInfo.invoiceDate).format(
                              "MM/DD/YYYY"
                            )}
                      </Typography>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        marginBottom: "10px",
                        fontWeight: "400",
                        color: "#374151",
                      }}
                    >
                      Status:
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#374151",
                        }}
                      >
                        &nbsp;{downloadState ? renderStatusText(billingInfo.status) : renderStatus(billingInfo.status)}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid size={12} rowSpacing={2} rowGap={5} marginTop={2}>
                  <DataTable
                    columns={[
                      {
                        label: "Product",
                        id: "product",
                        sortable: false,
                        render: (row) => {
                          return (
                            <TruncatedTooltipText text={""}>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  fontWeight: "600",
                                  textDecoration: "underline",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {row.product}
                              </Box>
                            </TruncatedTooltipText>
                          );
                        },
                      },
                      {
                        label: "No. of Transactions",
                        id: "numberOfTransactions",
                        sortable: false,
                      },
                      {
                        label: "Price",
                        id: "totalAmount",
                        sortable: false,
                        render: (row) => (
                            <Typography
                            component="span"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#374151",
                            }}
                            >
                            &nbsp;${row.totalAmount?.toFixed(2)}
                            </Typography>
                        ),
                      },
                    ]}
                    rows={billingInfo.invoiceDetails || []}
                    isLoading={loading}
                    rowCount={3}
                    orderBy={query.orderBy}
                    order={query.order}
                    createSortHandler={createSortHandler}
                    footerRow={[
                      {
                        label: "Total Products:",
                        value: `${billingInfo.invoiceDetails?.length || 0}`,
                      },
                      {
                        label: "Total Transactions:",
                        value: `${billingInfo.totalTransaction || 0}`,
                      },
                      {
                        label: "Total Amount:",
                        value: `$${billingInfo.totalAmount?.toFixed(2) || 0}`,
                      },
                    ]}
                  />
                </Grid>
              </Box>
            </Box>
          </Panel>
          {loading && <CircularSpinner progress={progress} />}
        </Grid>
      </Box>
    </>
  );
};

export default ViewBilling;
