import { Navigate, RouteObject } from 'react-router-dom';
import Page from '../components/@extended/Page';
import Billing from '../pages/Billing';
import AddBilling from '../pages/Billing/AddBilling';
import ViewBilling from '../pages/Billing/ViewBilling';
import Client from '../pages/Client';
import AddClient from '../pages/Client/AddClient';
import ViewClient from '../pages/Client/ViewClient';
import DataSimulatorDashboard from '../pages/dataSimulator/dataSmdashboard';
import GenerateSimulationPage from '../pages/dataSimulator/GenerateSimulation';

const AdminPortalRouter: RouteObject[] = [
    // {
    //     path: 'dashboard',
    //     element: <div>Admin Dashboard</div>,
    // },
    {
        path: 'client',
        element: <Page component={<Client />} title='Clients' />,
    },
    {
        path: 'client/add-client',
        element: <Page component={<AddClient />} title='Add Client' />,
    },
    {
        path: 'client/:clientId/edit',
        element: <Page component={<ViewClient />} title='Clients' />,
    },
    {
        path: 'billing',
        element: <Page component={<Billing />} title='Billing' />,
    },
    {
        path: 'billing/generate-billing',
        element: <Page component={<AddBilling />} title='Billing' />,
    },
    {
        path: 'billing/:billingId/viewbill',
        element: <Page component={<ViewBilling />} title='Billing' />,
    },
    {
        path: 'datasimulator',
        element: <Page component={<DataSimulatorDashboard />} title='Data Simulator' />,
      },
      {
        path: 'datasimulator/generatesimulation',
        element: <Page component={<GenerateSimulationPage />} title='Data Simulator' />,
      },
    // {
    //     path: 'bank',
    //     element: <div>Bank Page</div>,
    // },
    // {
    //     path: 'users',
    //     element: <div>Users Page</div>,
    // },
    // {
    //     path: 'activity-logs',
    //     element: <div>Activity Logs Page</div>,
    // },
    // {
    //     path: 'settings',
    //     element: <div>Settings Page</div>,
    // },
    // {
    //     path: 'report',
    //     element: <div>Client Report</div>,
    // },
    // {
    //     path: '',
    //     element: <Navigate to='/admin/dashboard' />
    // },
];

export default AdminPortalRouter;
