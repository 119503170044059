import { RouteObject, Navigate } from 'react-router-dom';
import DashboardPage from '../pages/dashboard';
import IBAReportPage from '../pages/ibaReport';
import CustomersPage from '../pages/customers';
import PaymentProcessingPage from '../pages/paymentProcessing';
import InvoiceRecordsPage from '../pages/invoiceRecords';
import UsersPage from '../pages/users';
import ActivityLogsPage from '../pages/activityLogs';
import ConnectPage from '../pages/connect';
import IBAReportAnalysis from '../pages/ibaReport/ibaAnalysis';
import IBARequest from '../pages/ibaReport/IBARequest';
import AddNewCustomerPage from '../pages/customers/AddNewCustomer';
import Page from '../components/@extended/Page';
import InvoiceReport from '../pages/invoiceRecords/InvoiceReport';
import EditCustomer from '../pages/customers/EditCustomer';
import DTICalculator from '../pages/dti';
import IBASetting from '../pages/settings/IBASetting';
import NotificationSetting from '../pages/settings/NotificationSetting';
import DTISetting from '../pages/settings/DTISetting';
import SettingsLayout from '../pages/settings';
import AddNewUser from '../pages/users/AddNewUser';
import UsersProfile from '../pages/users/UsersProfile';

const MainRouter: RouteObject[] = [
  {
    path: 'dashboard',
    element: <Page component={<DashboardPage />} title='Dashboard' />,
  },
  {
    path: 'report',
    element: <Page component={<IBAReportPage />} title='AcuCheck Report' />,
  },
  {
    path: 'report/request',
    element: <Page component={<IBARequest />} title='AcuCheck Report Request' />,
  },
  {
    path: 'report/analysis/:reportId',
    element: <Page component={<IBAReportAnalysis />} title='AcuCheck Report Analysis' />,
  },
  {
    path: 'customers',
    element: <Page component={<CustomersPage />} title='Customer’s Listing' />,
  },
  {
    path: 'customers/new',
    element: <Page component={<AddNewCustomerPage />} title='Create New Customer' />,
  },
  {
    path: 'customers/:customerId/edit',
    element: <Page component={<EditCustomer />} title='Edit Customer' />,
  },
  {
    path: 'customers/:customerId/requested-report',
    element: <Page component={<EditCustomer />} title='Edit Customer' />,
  },
  {
    path: 'dti',
    element: <Page component={<DTICalculator />} title='DTI Calculator' />,
  },
  {
    path: 'payment-processing',
    element: <Page component={<PaymentProcessingPage />} title='Payment Processing' />,
  },
  {
    path: 'invoice',
    element: <Page component={<InvoiceRecordsPage />} title='Invoice Records' />,
  },
  {
    path: 'invoice/report',
    element: <Page component={<InvoiceReport />} title='Invoice Records' />,
  },
  {
    path: 'users/add-users',
    element: <Page component={<AddNewUser />} title='Add User' />,
  },
  {
    path: 'users',
    element: <Page component={<UsersPage />} title='Users' />,
  },
  {
    path: 'users/:userId/edit',
    element: <Page component={<UsersProfile />} title='User' />,
  },
  {
    path: 'activity-logs',
    element: <Page component={<ActivityLogsPage />} title='Activity Logs' />,
  },
  {
    path: 'connect',
    element: <Page component={<ConnectPage />} title='Connect' />,
  },
  {
    path: "settings",
    element: (
      <SettingsLayout>
        <div>Settings Overview</div>
      </SettingsLayout>
    ),
  },
  {
    path: "settings/IBA",
    element: (
      <SettingsLayout>
        <IBASetting />
      </SettingsLayout>
    ),
  },
  {
    path: "settings/notification",
    element: (
      <SettingsLayout>
        <NotificationSetting />
      </SettingsLayout>
    ),
  },
  {
    path: "settings/dti",
    element: (
      <SettingsLayout>
        <Page component={<DTISetting />} title='Setting' />
      </SettingsLayout>
    ),
  },
  {
    path: '',
    element: <Navigate to="/acucheck/dashboard" />,
  },
];

export default MainRouter;
