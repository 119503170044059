import AxiosInstance from './http';

//  User Query 
export interface UserQuery {
    pageNumber: number;
    pageSize: number;
    orderBy?: string;
    order?: string;
    status?: string;
    nameQuery?: string;
}

// Create/Update User
export interface User {
    userName: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    status: string;
    email: string;
    phone: string;
    secondaryPhone: string;
    secondaryEmail: string;
    password?: string;
}

//  Get All Users 
export async function GetUsers(pageQuery: UserQuery): Promise<any> {
    try {
        const query = structuredClone(pageQuery);
        if (query.orderBy) {
            query.order = query?.order?.toUpperCase();
        }
        if (query.status == 'all') {
            delete query.status;
        }
        const response = await AxiosInstance.get('/User/all', {
            params: query
        });
        return response;
    } catch (err) {
        console.error('Error fetching users:', err);
        throw err;
    }
}

// Get User by ID
export async function GetUserById(userId: string): Promise<any> {
    try {
        const response = await AxiosInstance.get(`/User/${userId}`);
        return response;
    } catch (err) {
        console.error(`Error fetching user with ID ${userId}:`, err);
        throw err;
    }
}

// Create New User
export async function CreateUser(user: User): Promise<any> {
    try {
        const response = await AxiosInstance.post('/User', {
            userName: user.userName,
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle,
            status: user.status === 'active' ? 'active' : 'inactive',
            email: user.email,
            phone: user.phone,
            secondaryPhone: user.secondaryPhone,
            secondaryEmail: user.secondaryEmail,
            password: user.password ?? 'Default@123'
        });

        return response;
    } catch (err) {
        console.error('Error creating user:', err);
        throw err;
    }
}

//Update User
export async function UpdateUser(id: string, user: Partial<User>): Promise<any> {
    try {
        const response = await AxiosInstance.put(`/User/${id}`, {
            id,
            userName: user.userName,
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle,
            status: user.status,
            email: user.email,
            phone: user.phone,
            secondaryPhone: user.secondaryPhone,
            secondaryEmail: user.secondaryEmail,
            password: user.password
        });

        return response;
    } catch (err) {
        console.error(`Error updating user with ID ${id}:`, err);
        throw err;
    }
}

// Get User by ID
export async function DeleteUserById(userId: string): Promise<any> {
    try {
        const response = await AxiosInstance.delete(`/User/${userId}`);
        return response;
    } catch (err) {
        console.error(`Error deleting user with ID ${userId}:`, err);
        throw err;
    }
}