import {
  Box,
  Grid2 as Grid,
  Typography,
  useTheme,
  Skeleton,
} from "@mui/material";
import Panel from "../../../components/Panel";
import BarChart from "../../../components/charts/BarChart";
import LineCharts from "../../../components/charts/LineCharts";
import { useState, useEffect } from "react";

const LoanInstalmentAmount = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  const labels = ["$750", "$550", "$350", "$0"];
  const dataPoints = [0, 350, 550, 750];
  const threshold = 550;

  const dtiData = {
    labels: [
      "100%",
      "90%",
      "80%",
      "70%",
      "60%",
      "50%",
      "40%",
      "30%",
      "20%",
      "10%",
      "0%",
    ],
    datasets: [
      {
        label: "DTI Score",
        data: [500, 450, 400, 350, 300, 250, 200, 150, 100, 50, 0],
        backgroundColor: [],
      },
    ],
  };

  return (
    <Grid size={12} sx={{ marginY: 5 }}>
      <Panel
        sx={{
          backgroundColor: theme?.palette?.primary?.main,
          padding: "3px",
          borderRadius: 0,
          borderBottom: "3px solid #D6C64D",
        }}
      ></Panel>
      <Box sx={{ border: "1.78px solid #F1F1F1", padding: "20px" }}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: "700", marginBottom: "10px" }}
        >
          Loan Instalment Amount Potential
        </Typography>

        <Box
          sx={{
            display: { sm: "", md: "flex" },
            flexDirection: { md: "column", lg: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              width: { md: "100%", lg: "50%" },
              flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
            }}
          >
            <Box
              display={"flex"}
              flexDirection={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "column",
              }}
              justifyContent={{ md: "space-between", lg: "" }}
              gap={{ md: 2, lg: 0 }}
            >
              <Box>
                <LineCharts
                  labels={labels}
                  dataPoints={dataPoints}
                  threshold={threshold}
                  title={"Bi-Monthly Frequency :"}
                  XLabel="Bi-Monthly Installment"
                  subTitle=" 39 - 54"
                  isLoading={loading}
                />
              </Box>
              <Box>
                <LineCharts
                  labels={labels}
                  dataPoints={dataPoints}
                  threshold={threshold}
                  title={"Weekly Frequency :"}
                  XLabel="Weekly Installment"
                  subTitle=" 15 - 22"
                  isLoading={loading}
                />
              </Box>
            </Box>

            <Box
              display={"flex"}
              flexDirection={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "column",
              }}
              justifyContent={{ md: "space-between", lg: "" }}
              gap={{ md: 2, lg: 0 }}
            >
              <Box>
                <LineCharts
                  labels={labels}
                  dataPoints={dataPoints}
                  threshold={threshold}
                  title={"Bi-Weekly Frequency :"}
                  XLabel="Bi-Weekly Installment"
                  subTitle=" 20 - 30"
                  isLoading={loading}
                />
              </Box>
              <Box>
                <LineCharts
                  labels={labels}
                  dataPoints={dataPoints}
                  threshold={threshold}
                  title={"Bi-Weekly Frequency :"}
                  XLabel="Bi-Weekly Installment"
                  subTitle=" 20 - 30"
                  isLoading={loading}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: { md: "80%", lg: "50%" } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
                <BarChart
                  data={dtiData}
                  title="DTI Score Threshold:"
                  subTitle=" 80%"
                  threshold={450}
                  axis="y"
                  isLoading={loading}
                />
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default LoanInstalmentAmount;
