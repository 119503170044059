import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Groups2Icon from "@mui/icons-material/Groups2";
import Panel from "../../components/Panel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import profileBackground from "../../assets/media/icons/profileBackground.png";
import { Fragment, useEffect, useId, useState } from "react";
import ActiveEllipseIcon from "../../assets/IconComponents/ActiveEllipseIcon";
import InactiveIcon from "../../assets/IconComponents/InactiveIcon";
import AddressIcon from "../../assets/IconComponents/AddressIcon";
import EmailIcon from "../../assets/IconComponents/EmailIcon";
import StoreIcon from "../../assets/IconComponents/StoreIcon";
import CardIcon from "../../assets/IconComponents/CardIcon";
import Homework from "../../assets/IconComponents/Homework";
import Location from "../../assets/IconComponents/Location";
import MyLocation from "../../assets/IconComponents/MyLocation";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import { Controller, useForm } from "react-hook-form";
import EditModal from "../../components/form/EditModal";
import ToggleSwitch from "../../components/form/ToggleSwitch";
import InputComponent from "../../components/form/input";
import SelectComponent from "../../components/form/SelectComponent";
import States from "../../assets/json/states.json";
import CellIcon from "../../assets/media/icons/PhoneIcon.png";
import AcuPayIcon from "../../assets/IconComponents/Client/AddClient/AcuPayIcon";
import { ReusableSection } from "./CardInformation";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import DriverLicense from "../../assets/IconComponents/DriverLicense";
import PhoneIcon from "../../assets/IconComponents/PhoneIcon";
import SsnNumber from "../../assets/IconComponents/SsnNumber";
import AcuScoreIcon from "../../assets/IconComponents/Client/AddClient/AcuScoreIcon";
import AcuView from "../../assets/IconComponents/Client/AddClient/AcuView";
import CreditTransaction from "../../assets/IconComponents/Client/AddClient/CreditTransaction";
import ACHTransaction from "../../assets/IconComponents/Client/AddClient/ACHTransaction";
import FeatureCard from "./FeaturedCard";
import BankIcon from "../../assets/IconComponents/Client/AddClient/BankIcon";
import PasswordIcon from "../../assets/IconComponents/PasswordIcon";
import {
  CreateClientBank,
  CreateClientCard,
  CreateClientContact,
  GetClientByClientId,
  UpdateClient,
} from "../../api/clientAPI";
import { useAlertMessage } from "../../utils/useAlertMessage";
import CircularSpinner from "../../components/Loader/CircularLoader";
import CombinedMonthYearPicker from "../../components/form/MonthYearSelection";
import Bank from "../../assets/media/icons/Bank.png";
import Card from "../../assets/media/icons/Card.png";
import Product from "../../assets/media/icons/Product.png";

interface Contact {
  id: number;
  title: string;
  subtitle: string;
  details: { label: string; value: string }[];
  isPrimary: boolean;
  icon: JSX.Element;
}

interface Bank {
  id: number;
  title: string;
  subtitle: string;
  details: { label: string; value: string }[];
  isPrimary: boolean;
  icon: JSX.Element;
}

interface Card {
  id: number;
  title: string;
  subtitle: string;
  details: { label: string; value: string }[];
  isPrimary: boolean;
  icon: JSX.Element;
}

const statesList = Object.keys(States).map((state) => ({
  value: state,
  label: state,
}));

interface SectionProps {
  clientInfo?: any;
  title: string;
  details: ProfileDetailRowProps[];
  getClientInfo: () => void;
}
interface ProfileDetailRowProps {
  icon?: React.ReactElement;
  label?: string;
  value?: string;
  subtitle?: string;
  endIcon?: React.ReactElement;
  component?: React.ReactElement;
}

const UsersprofileHeader = ({ clientInfo }: any) => {
  return (
    <Box sx={{ position: "relative", textAlign: "center", width: "100%" }}>
      <Box
        component="img"
        src={profileBackground}
        alt="background"
        sx={{ width: "100%", height: "130px" }}
      />
      <Box
        sx={{
          background: "#142848",
          borderRadius: "50%",
          height: "175px",
          width: "175px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "20px solid #ffffff",
          position: "absolute",
          top: "50px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Typography
          sx={{ fontSize: "48px", fontWeight: "600", color: "#FFFFFF" }}
        >
          {clientInfo?.contactName
            ?.split(" ")
            .map((name: string) => name[0])
            .join("")}
        </Typography>
      </Box>
      <Box sx={{ marginTop: "90px", gap: "16px" }}>
        <Typography
          sx={{
            fontWeight: "800",
            fontSize: "30px",
            color: "#1E293B",
            textWrap: "wrap",
          }}
        >
          {clientInfo?.contactName}
        </Typography>
        <Typography
          sx={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}
        >
          E-Mail ID: {clientInfo?.contactEmail}
        </Typography>
      </Box>
    </Box>
  );
};

const ViewClient = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  const { clientId = "" } = useParams();
  const [clientInfo, setClientInfo] = useState<any>({});
  const notification = useAlertMessage();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const userDetails: ProfileDetailRowProps[] = [
    {
      label: "Company Name",
      value: clientInfo.businessName ? clientInfo.businessName : "-",
      icon: <AddressIcon />,
    },
    {
      label: "Company Email",
      value: clientInfo.contactEmail ? clientInfo.contactEmail : "-",
      icon: <EmailIcon />,
    },
    {
      label: "Company Phone",
      value: clientInfo.contactPhone ? clientInfo.contactPhone : "-",
      icon: <PhoneIcon />,
    },
    {
      label: "Merchant ID",
      value: clientInfo.merchantID ? clientInfo.merchantID : "-",
      icon: <StoreIcon />,
    },
    {
      label: "EIN",
      value: clientInfo.ein ? `XX-XXX${clientInfo.ein.slice(-4)}` : "-",
      icon: <CardIcon />,
    },
    {
      label: "State",
      value: clientInfo.state ? clientInfo.state : "-",
      icon: <Homework />,
    },
    {
      label: "City",
      value: clientInfo.city ? clientInfo.city : "-",
      icon: <Location />,
    },
    {
      label: "Zip Code",
      value: clientInfo.zipCode,
      icon: <MyLocation />,
    },
    {
      icon:
        clientInfo?.accountStatus?.toLowerCase() === "active" ? (
          <ActiveEllipseIcon />
        ) : (
          <InactiveIcon />
        ),
      label: "Account Status",
      value:
        clientInfo?.accountStatus?.toLowerCase() === "active"
          ? "Active"
          : "Inactive",
    },
  ];

  const userUpdatePassword = [
    {
      icon: <PasswordIcon />,
      label: "Password",
      value: "********",
    },
  ];
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [cards, setCards] = useState<Card[]>([]);
  const [products, setProducts] = useState([
    {
      id: 1,
      title: "AcuCheck",
      subtitle: "Product",
      details: [{ label: "Service", value: "Card Transaction" }],
      isPrimary: true,
      labelActive: "Active",
      icon: (
        <Box
          component={"img"}
          src={Product}
          sx={{ width: "62px", height: "62px" }}
        />
      ),
    },
  ]);

  const getClientInfo = async () => {
    const formatCardNumber = (number: any) => {
      return number.replace(/(\d{4})(?=\d)/g, "$1-");
    };
    setLoading(true);
    setProgress(90);
    let timer;
    try {
      if (clientId) {
        const { data, status } = await GetClientByClientId(clientId);
        console.log(data, status);
        if (status === 200) {
          setClientInfo(data);
        } else {
          navigate("/admin/client");
        }

        const contacts = data?.contacts || [];
        console.log(contacts);

        const formattedContacts = contacts
          .map((contact: any) => ({
            id: contact.id,
            title: `${contact.firstName} ${contact.lastName}`,
            subtitle: contact.title,
            details: [
              { label: "Phone Number", value: contact.phoneNumber },
              { label: "Email", value: contact.email },
            ],
            isPrimary: contact.isPrimary,
            icon: (
              <Box
                component={"img"}
                src={CellIcon}
                sx={{ width: "62px", height: "62px" }}
              />
            ),
          }))
          .sort(
            (a: any, b: any) => (b.isPrimary ? 1 : 0) - (a.isPrimary ? 1 : 0)
          );

        setContacts(formattedContacts);

        const cards = data?.cards || [];
        const formattedCards = cards
          .map((card: any) => ({
            id: card.id,
            title: card.cardHolderName,
            subtitle: `Expiry Date: ${card.expiryDate}`,
            details: [
              { label: "Card No", value: formatCardNumber(card.cardNumber) },
            ],
            isPrimary: card.isPrimary,
            icon: (
              <Box
                component={"img"}
                src={Card}
                sx={{ width: "62px", height: "62px" }}
              />
            ),
          }))
          .sort(
            (a: any, b: any) => (b.isPrimary ? 1 : 0) - (a.isPrimary ? 1 : 0)
          );

        setCards(formattedCards);

        const bankAccount = data?.accounts || [];
        const formattedAccounts = bankAccount
          .map((account: any) => ({
            id: account.id,
            title: account.bankName,
            subtitle: "",
            isPrimary: account.isPrimary,
            details: [
              {
                label: "Account Number",
                value: `XXXXXXX${account.accountNumber}` || "",
              },
              {
                label: "Routing Number",
                value: `XXXXX${account.routingNumber}` || "",
              },
            ],
            icon: (
              <Box
                component={"img"}
                src={Bank}
                sx={{ width: "62px", height: "62px" }}
              />
            ),
          }))
          .sort(
            (a: any, b: any) => (b.isPrimary ? 1 : 0) - (a.isPrimary ? 1 : 0)
          );
        setBanks(formattedAccounts);
      }
      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
    } catch (err) {
      navigate("/admin/client");
      notification.errorNotification("Error", "Something went wrong");
      console.error("");
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  useEffect(() => {
    getClientInfo();
  }, []);

  const [expirationDate, setExpirationDate] = useState<string | null>(null);

  const handleDateChange = (month: number, year: number) => {
    const formattedDate = `${(month + 1).toString().padStart(2, "0")}/${year
      .toString()
      .slice(-2)}`;
    setExpirationDate(formattedDate);
  };

  const handleCancelDate = () => {
    setExpirationDate(null);
  };

  const handleTogglePrimaryActive = (id: number) => {
    setProducts((prev) =>
      prev.map((product) =>
        product.id === id
          ? { ...product, isPrimary: true }
          : { ...product, isPrimary: false }
      )
    );
  };

  const handleTogglePrimaryCard = async (id: number) => {
    const updatedCards = cards.map((card) =>
      card.id === id
        ? { ...card, isPrimary: true }
        : { ...card, isPrimary: false }
    );

    setCards(updatedCards);
    try {
      await Promise.all(
        updatedCards.map((card) =>
          CreateClientCard(card.id.toString(), {
            tenantId: clientInfo.id,
            id: card.id,
            cardHolderName: card.title,
            cardNumber: card.details[0]?.value.replace(/-/g, ""),
            expiryDate: card.subtitle.split(": ")[1],
            isPrimary: card.isPrimary,
          })
        )
      );
      notification.successNotification(
        "Success",
        "Primary card updated successfully."
      );
      getClientInfo();
    } catch (error) {
      notification.errorNotification("Error", "Failed to update primary card.");
      const previousState = cards.map((card) => ({ ...card }));
      setCards(previousState);
    }
  };

  const handleTogglePrimaryContact = async (id: number) => {
    const updatedContacts = contacts.map((contact) =>
      contact.id === id
        ? { ...contact, isPrimary: true }
        : { ...contact, isPrimary: false }
    );

    setContacts(updatedContacts);

    try {
      await Promise.all(
        updatedContacts.map((contact) => {
          const phoneNumber = contact.details.find(
            (detail) => detail.label === "Phone Number"
          )?.value;
          const email = contact.details.find(
            (detail) => detail.label === "Email"
          )?.value;

          return CreateClientContact(contact.id.toString(), {
            tenantId: clientInfo.id,
            id: contact.id,
            title: contact.subtitle,
            firstName: contact.title.split(" ")[0],
            lastName: contact.title.split(" ").slice(1).join(" "),
            email: email || "",
            phoneNumber: phoneNumber || "",
            isPrimary: contact.isPrimary,
          });
        })
      );
      notification.successNotification(
        "Success",
        "Primary contact updated successfully."
      );
      getClientInfo();
    } catch (error) {
      console.error("Error updating primary contact:", error);
      notification.errorNotification(
        "Error",
        "Failed to update primary contact."
      );
      const previousState = contacts.map((contact) => ({ ...contact }));
      setContacts(previousState);
    }
  };

  const handleTogglePrimaryBank = async (id: number) => {
    const updatedBanks = banks.map((bank) =>
      bank.id === id
        ? { ...bank, isPrimary: true }
        : { ...bank, isPrimary: false }
    );

    setBanks(updatedBanks);

    try {
      await Promise.all(
        updatedBanks.map((bank) =>
          CreateClientBank(bank.id.toString(), {
            tenantId: clientInfo.id,
            id: bank.id,
            bankName: bank.title,
            accountNumber: "",
            routingNumber: "",
            isPrimary: bank.isPrimary,
          })
        )
      );

      notification.successNotification(
        "Success",
        "Primary bank updated successfully."
      );
      getClientInfo();
    } catch (error) {
      console.error("Error updating primary bank:", error);
      notification.errorNotification("Error", "Failed to update primary bank.");
      const previousState = banks.map((bank) => ({ ...bank }));
      setBanks(previousState);
    }
  };

  const featureData = [
    {
      id: "AcuCheck",
      title: "AcuCheck",
      icon: <AcuScoreIcon />,
      disable: false,
    },
    { id: "AcuPay", title: "AcuPay", icon: <AcuPayIcon />, disable: true },
    { id: "AcuView", title: "AcuView", icon: <AcuView />, disable: true },
  ];

  const serviceData = [
    {
      id: "CreditTransaction",
      title: "Credit Transaction",
      icon: <CreditTransaction />,
    },
    {
      id: "ACHTransaction",
      title: "ACH Transaction",
      icon: <ACHTransaction />,
    },
  ];

  const [selectedFeature, setSelectedFeature] = useState<string | null>(null);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  const handleFeatureChange = (id: string) => {
    if (id === "AcuPay") {
      setSelectedFeature((prev) => (prev === id ? null : id));
      setSelectedServices((prev) =>
        prev.length ? [] : ["CreditTransaction", "ACHTransaction"]
      );
    } else {
      setSelectedFeature((prev) => (prev === id ? null : id));
      setSelectedServices([]);
    }
  };

  const handleServiceChange = (id: string) => {
    setSelectedServices((prev) =>
      prev.includes(id)
        ? prev.filter((service) => service !== id)
        : [...prev, id]
    );
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/admin/client"}
      sx={{ width: "auto", display: "flex" }}
    >
      <Groups2Icon sx={{ color: "#142A73", marginRight: "10px" }} />
      <Typography sx={{ color: theme.palette.primary.main }}>
        Clients
      </Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      {clientInfo.businessName}
    </Typography>,
  ];

  const {
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
  });

  const handleSubmit = async () => {
    const formData = watch();
    setLoading(true);
    setProgress(0);
    let timer, requestData;
    let response;
    try {
      switch (formFields) {
        case "Contact Information":
          requestData = {
            id: null,
            tenantId: clientInfo.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            title: formData.title,
            phoneNumber: formData.phone,
            email: formData.email,
          };
          timer = setInterval(() => {
            setProgress((prev) => (prev < 90 ? prev + 10 : prev));
          }, 200);
          response = await CreateClientContact(clientInfo.id, requestData);
          if (response.status === 200) {
            handleClose();
            getClientInfo();
          }
          break;
        case "Card Information":
          requestData = {
            id: null,
            tenantId: clientInfo.id,
            cardHolderName: formData.cardholdername,
            cardNumber: formData?.cardnumber?.replace(/-/g, ""),
            expiryDate: expirationDate,
          };
          timer = setInterval(() => {
            setProgress((prev) => (prev < 90 ? prev + 10 : prev));
          }, 200);
          response = await CreateClientCard(clientInfo.id, requestData);
          console.log(response);
          if (response.status === 200) {
            handleClose();
            getClientInfo();
          }
          break;
        case "Bank Account Information":
          requestData = {
            id: null,
            tenantId: clientInfo.id,
            bankName: formData.bankName,
            accountNumber: formData?.accountNumber?.replace(/-/g, "") || "",
            routingNumber: formData.routingNumber,
          };
          timer = setInterval(() => {
            setProgress((prev) => (prev < 90 ? prev + 10 : prev));
          }, 200);
          response = await CreateClientBank(clientInfo.id, requestData);
          if (response.status === 200) {
            handleClose();
            getClientInfo();
          }
          break;
        case "Client Password":
          requestData = {
            id: clientInfo.id,
            password: formData.password,
          };
          timer = setInterval(() => {
            setProgress((prev) => (prev < 90 ? prev + 10 : prev));
          }, 200);
          response = await UpdateClient(clientInfo.id, requestData);
          setProgress(100);

          notification.successNotification(
            "Success",
            `${
              formFields === "Client Password"
                ? "Password updated successfully"
                : "Client data added successfully"
            }`
          );
          navigate("/admin/client");
          break;
        default:
          throw new Error("Invalid form action");
      }
    } catch (err) {
      console.error(err);
      notification.errorNotification(
        "Error",
        (err as any).response?.data.message
      );
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  const [open, setOpen] = useState(false);
  const [formFields, setFormFields] = useState<React.ReactNode>(null);

  const handleClose = () => {
    reset();
    setOpen(false);
    setFormFields(null);
    setExpirationDate(null);
  };

  const handleOpen = (title: string) => {
    setFormFields(title);
    setOpen(true);
  };

  const getFieldNamesForForm = (formFields: string | null): string[] => {
    switch (formFields) {
      case "Contact Information":
        return ["firstName", "lastName", "title", "phone", "email"];
      case "Bank Account Information":
        return ["bankName", "accountNumber", "routingNumber"];
      case "Card Information":
        return ["cardholdername", "cardnumber"];
      case "Client Password":
        return ["password", "confirmPassword"];
      default:
        return [];
    }
  };

  const allFieldsValid = (
    fieldNames: string[],
    additionalChecks: boolean = true
  ): boolean => {
    return (
      fieldNames.every((fieldName) => {
        if (fieldName === "title") {
          return !errors[fieldName];
        }
        return watch(fieldName) && !errors[fieldName];
      }) && additionalChecks
    );
  };

  const isButtonDisabled = (() => {
    switch (formFields) {
      case "Contact Information":
        return !allFieldsValid(
          getFieldNamesForForm(typeof formFields === "string" ? formFields : "")
        );
      case "Bank Account Information":
        return !allFieldsValid(
          getFieldNamesForForm(typeof formFields === "string" ? formFields : "")
        );
      case "Card Information":
        return !allFieldsValid(
          getFieldNamesForForm(
            typeof formFields === "string" ? formFields : ""
          ),
          !!expirationDate
        );
      case "Client Password":
        return !allFieldsValid(
          getFieldNamesForForm(typeof formFields === "string" ? formFields : "")
        );
      case "Product & Service Information":
        return true;
      default:
        return false;
    }
  })();

  return (
    <>
      {loading && <CircularSpinner progress={progress} />}
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Panel>
      <Panel>
        <Box sx={{ padding: "20px" }}>
          <UsersprofileHeader clientInfo={clientInfo} />
          <Section
            title="Client Information"
            details={[...userDetails]}
            clientInfo={clientInfo}
            getClientInfo={getClientInfo}
          />
          <ReusableSection
            title="Contact Information"
            addTitle="Contact"
            data={contacts}
            onTogglePrimary={handleTogglePrimaryContact}
            handleAddClick={() => {
              handleOpen("Contact Information");
            }}
            getClientInfo={getClientInfo}
          />
          <ReusableSection
            title="Bank Account Information"
            addTitle="Bank Account"
            data={banks}
            onTogglePrimary={handleTogglePrimaryBank}
            handleAddClick={() => {
              handleOpen("Bank Account Information");
            }}
            getClientInfo={getClientInfo}
          />
          <ReusableSection
            title="Card Information"
            addTitle="Card"
            data={cards}
            onTogglePrimary={handleTogglePrimaryCard}
            handleAddClick={() => {
              handleOpen("Card Information");
            }}
            getClientInfo={getClientInfo}
          />
          <ReusableSection
            title="Product & Service Information"
            addTitle="Product & Service"
            data={products}
            onTogglePrimary={handleTogglePrimaryActive}
            handleAddClick={() => {
              handleOpen("Product & Service Information");
            }}
          />
          <Section
            title="Client Password"
            details={[...userUpdatePassword]}
            clientInfo={clientInfo}
            getClientInfo={getClientInfo}
          />
        </Box>
        {/* <Box
          sx={{ display: "flex", justifyContent: "flex-end", padding: "20px" }}
        >
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              handleOpen("Client Password");
            }}
            sx={{
              borderRadius: "10px",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "#1F3A91",
              color: "white",
              "&:hover": { backgroundColor: "#1F3A91" },
            }}
          >
            Reset Password
          </Button>
        </Box> */}
      </Panel>
      <EditModal
        open={open}
        onClose={handleClose}
        disabled={isButtonDisabled}
        title={`${formFields === "Client Password" ? "" : "Add"} ${
          formFields === "Client Password" ? "Reset Password" : formFields
        }`}
        handleUpdate={() => handleSubmit()}
        buttonStyle={{
          background: isButtonDisabled ? "#BCBCBD" : "#1F3A91",
          color: "#ffffff !important",
        }}
        updateText="Add"
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #DBE9FE",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{ padding: "20px", background: "#F7FAFF", borderRadius: "6px" }}
          >
            {formFields === "Contact Information" && (
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "First name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "First name can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<UserIconGray />}
                        label="First Name"
                        placeholder="Enter first name"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Last name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Last name can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<UserIconGray />}
                        label="Last Name"
                        placeholder="Enter last name"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={6} xl={12}>
                  <Controller
                    name="title"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Title can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        label="Title"
                        icon={<DriverLicense />}
                        placeholder="Enter Title"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={6}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Mobile Number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Mobile Number must be exactly 10 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        maxLength={10}
                        minLength={10}
                        label="Mobile Number"
                        icon={<PhoneIcon />}
                        placeholder="Enter mobile number"
                        error={(errors.phone?.message as string) || null}
                        isRequired
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={6}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Enter a valid email address",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Email Address"
                        icon={<EmailIcon />}
                        placeholder="Enter email address"
                        isRequired
                        error={(errors.email?.message as string) || null}
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {formFields === "Bank Account Information" && (
              <FormInputRow>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="bankName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Bank name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Bank name can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<BankIcon />}
                        label="Bank Name"
                        placeholder="Enter bank name"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="accountNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Account Number is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Account Number can only contain numbers",
                      },
                      minLength: {
                        value: 6,
                        message:
                          "Account Number must be at least 6 digits long",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        minLength={6}
                        maxLength={17}
                        {...field}
                        icon={<CardIcon />}
                        label="Account Number"
                        placeholder="Enter account number"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="routingNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Routing Number is required",
                      pattern: {
                        value: /^[0-9]{9}$/,
                        message: "Routing Number contains exactly 9 numbers",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        maxLength={9}
                        {...field}
                        icon={<SsnNumber />}
                        label="Routing Number"
                        placeholder="Enter rounting number"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {formFields === "Card Information" && (
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="cardholdername"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Card Holder name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Card Holder name can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<CardIcon />}
                        label="Name on Card"
                        placeholder="Enter Name on Card"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="cardnumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Card Number is required",
                      pattern: {
                        value: /^\d{4}-\d{4}-\d{4}-\d{4}$/,
                        message:
                          "Card Number must be in the format xxxx-xxxx-xxxx-xxxx",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<CardIcon />}
                        label="Card Number"
                        placeholder="Enter Card Number"
                        error={error?.message}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          const formattedValue = value
                            .replace(
                              /(\d{4})(\d{0,4})(\d{0,4})(\d{0,4})/,
                              "$1-$2-$3-$4"
                            )
                            .replace(/-+$/, "");
                          field.onChange(formattedValue);
                        }}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="expirationDate"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Expiration Date is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Expiration Date can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <CombinedMonthYearPicker
                        label="Expiration Date"
                        backgroundColor="#f7f9fc"
                        required
                        monthyear={expirationDate || undefined}
                        placeholder="Enter Expiration Date"
                        style={{
                          height: "40px",
                        }}
                        onDateChange={(month, year) =>
                          handleDateChange(month, year)
                        }
                        onCancel={handleCancelDate}
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {formFields === "Product & Service Information" && (
              <Box className="accordianbox">
                <Typography
                  sx={{
                    color: "#374151",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: "20px",
                  }}
                >
                  Select Product from below
                </Typography>
                <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                  {featureData?.map((feature) => (
                    <FeatureCard
                      key={feature.id}
                      title={feature.title}
                      icon={feature.icon}
                      checked={selectedFeature === feature.id}
                      onChange={() => handleFeatureChange(feature.id)}
                      disabled={feature.disable}
                    />
                  ))}
                </Box>
                {selectedFeature === "AcuPay" && (
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography
                      sx={{
                        color: "#374151",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginBottom: "20px",
                      }}
                    >
                      Select Services from below
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                      {serviceData?.map((service) => (
                        <FeatureCard
                          key={service.id}
                          title={service.title}
                          icon={service.icon}
                          checked={selectedServices.includes(service.id)}
                          onChange={() => handleServiceChange(service.id)}
                          disabled={true}
                        />
                      ))}
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        marginTop: "10px",
                      }}
                    >
                      Note :&nbsp;
                      <Typography
                        component={"span"}
                        sx={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        AcuFi will serve as the default processor.
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {formFields === "Client Password" && (
              <FormInputRow>
                <FormInputCol>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "New Password is required",
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                        message:
                          "Password must include at least one uppercase letter, one lowercase letter, one special character, and one number",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        icon={<PasswordIcon />}
                        label="New Password"
                        type="password"
                        placeholder="Enter new password"
                        password
                        error={error?.message || null}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Confirm Password is required",
                      validate: (value) =>
                        value === watch("password") || "Passwords do not match",
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        icon={<PasswordIcon />}
                        label="Confirm Password"
                        type="password"
                        placeholder="Re-enter new password"
                        password
                        error={error?.message || null}
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
          </Box>
        </Box>
      </EditModal>
    </>
  );
};

export default ViewClient;

const ProfileDetailRow: React.FC<ProfileDetailRowProps> = ({
  icon,
  label,
  value,
  endIcon,
}) => {
  return (
    <FormInputCol sm={6} md={4} lg={4} xl={4}>
      <Typography
        sx={{
          marginBottom: "20px",
          fontSize: "14px",
          fontWeight: "600",
          color: "#374151",
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <Box sx={{ paddingTop: "5px", marginRight: "10px" }}>{icon}</Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#6B7280",
              textWrap: "wrap",
            }}
          >
            {value}
          </Typography>
        </Box>
        {endIcon && <Box>{endIcon}</Box>}
      </Box>
    </FormInputCol>
  );
};

const Section: React.FC<SectionProps> = ({
  title,
  details,
  clientInfo,
  getClientInfo,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [formFields, setFormFields] = useState<React.ReactNode>(null);
  const [cityList, setCityList] = useState<any[]>([]);
  const notification = useAlertMessage();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    reset: resetClientForm,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
  });

  const handleOpen = () => {
    setFormFields(title);
    setOpen(true);
  };

  const handleClose = () => {
    resetClientForm();
    setOpen(false);
  };

  const [isActive, setIsActive] = useState(true);

  const onStateSelect = (state: string) => {
    if (!state) return;

    const updatedCityList = (States as any)[state].map((city: string) => ({
      value: city,
      label: city,
    }));
    setCityList(updatedCityList);
    const currentCity = watch("city");
    const isCurrentCityValid = updatedCityList.some(
      (city: any) => city.value === currentCity
    );
    if (!isCurrentCityValid) {
      setValue("city", "");
    }
  };

  useEffect(() => {
    if (clientInfo) {
      resetClientForm({
        businessName: clientInfo.businessName || "",
        contactEmail: clientInfo.contactEmail || "",
        merchantID: clientInfo.merchantID || "",
        EIN: clientInfo.ein || "",
        state: clientInfo.state || "",
        city: clientInfo.city || "",
        zipCode: clientInfo.zipCode || "",
        status: clientInfo.accountStatus || "inactive",
      });
      onStateSelect(clientInfo.state);
      setIsActive(clientInfo.accountStatus === "active");
    }
  }, [clientInfo, resetClientForm]);

  const handleSubmit = async () => {
    const formData = watch();

    setLoading(true);
    setProgress(0);
    let timer;

    try {
      const requestData = {
        id: clientInfo.id,
        businessName: formData.businessName,
        merchantID: formData.merchantID,
        ein: formData.EIN,
        state: formData.state,
        city: formData.city,
        zipCode: formData.zipCode,
        accountStatus: isActive ? "active" : "inactive",
        contactName: formData.businessName,
        contactEmail: formData.companyEmail,
        contactPhone: formData.contactPhone,
        password: formData.password,
      };
      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const { status } = await UpdateClient(clientInfo.id, requestData);
      setProgress(100);
      console.log(status);
      if (status === 200) {
        notification.successNotification(
          "Success",
          "Client Updated successfully"
        );
        handleClose();
        getClientInfo();
      }
    } catch (err) {
      console.error();
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  const getFieldNamesForForm = (formFields: string | null): string[] => {
    switch (formFields) {
      case "Client Information":
        return ["zipCode"];
      default:
        return [];
    }
  };

  const isButtonDisabled = (() => {
    if (!formFields || typeof formFields !== "string") {
      return true;
    }

    const requiredFields = getFieldNamesForForm(formFields);
    const allFieldsFilled = requiredFields.every((fieldName) => {
      const value = watch(fieldName);
      return value !== undefined && value !== "";
    });

    const einHasError = errors["EIN"] !== undefined;

    return !(allFieldsFilled && !einHasError);
  })();

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: "700", color: "#000000" }}
        >
          {title}
        </Typography>
        {title !== "Client Password" && (
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#264D8E",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            EDIT DETAILS
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          padding: "20px",
          background: "#F7FAFF",
          margin: "20px 0 0 0",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{ borderRadius: "4px", background: "#FFFFFF", padding: "20px" }}
        >
          {details.map((_, index) => {
            if (index % 3 !== 0) return null;
            const rowDetails = details.slice(index, index + 3);
            return (
              <Fragment key={index}>
                <FormInputRow>
                  {rowDetails.map((detail, subIndex) => (
                    <ProfileDetailRow
                      key={subIndex}
                      icon={detail.icon}
                      label={detail.label}
                      value={detail.value}
                      endIcon={detail.endIcon}
                    />
                  ))}
                </FormInputRow>
                {index < details.length - 3 && (
                  <Divider
                    sx={{
                      width: "100%",
                      border: "1px dashed #D5E1F9",
                      margin: "20px 0",
                    }}
                  />
                )}
              </Fragment>
            );
          })}
        </Box>
      </Box>
      <EditModal
        open={open}
        onClose={handleClose}
        title={`Edit ${title}`}
        handleUpdate={() => handleSubmit()}
        disabled={isButtonDisabled}
        buttonStyle={{
          background: isButtonDisabled ? "#BCBCBD" : "#1F3A91",
          color: "#ffffff !important",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #DBE9FE",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{ padding: "20px", background: "#F7FAFF", borderRadius: "6px" }}
          >
            {formFields === "Client Information" && (
              <FormInputRow>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="businessName"
                    control={control}
                    defaultValue={clientInfo.businessName}
                    rules={{
                      required: "Company name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Company name can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        isRequired
                        icon={<AddressIcon />}
                        label="Company Name"
                        placeholder="Enter company name"
                        error={error?.message}
                        disabled
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="contactEmail"
                    control={control}
                    defaultValue={clientInfo.contactEmail}
                    rules={{
                      required: "Company Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Enter a valid email address",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        isRequired
                        disabled
                        icon={<EmailIcon />}
                        label="Company Email"
                        placeholder="Enter company email"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="contactPhone"
                    control={control}
                    defaultValue={clientInfo.contactPhone}
                    rules={{
                      required: "Company Phone is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Company Phone must be exactly 10 digits",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        maxLength={10}
                        minLength={10}
                        isRequired
                        icon={<PhoneIcon />}
                        label="Company Phone"
                        placeholder="Enter company phone number"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="merchantID"
                    control={control}
                    defaultValue={clientInfo.merchantID}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        icon={<StoreIcon />}
                        label="Merchant ID"
                        placeholder="Enter Merchant ID"
                        error={error?.message}
                        disabled
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="EIN"
                    control={control}
                    defaultValue={clientInfo.ein}
                    rules={{
                      pattern: {
                        value: /^[0-9]{9}$/,
                        message: "EIN must contain exactly 9 numbers",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        maxLength={9}
                        icon={<CardIcon />}
                        label="EIN"
                        placeholder="Enter EIN"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue={clientInfo.state}
                    render={({ field }) => {
                      const stateOptions = statesList;
                      const selectedOption = stateOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={stateOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                            onStateSelect(newValue.value);
                          }}
                          label="State"
                          placeholder="Select State"
                          fullWidth
                          icon={<Homework />}
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue={clientInfo.city}
                    render={({ field }) => {
                      const cityOptions = cityList;
                      const selectedOption = cityOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={cityOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                          }}
                          icon={<Location />}
                          label="City"
                          placeholder="Select City"
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="zipCode"
                    control={control}
                    defaultValue={clientInfo.zipCode}
                    rules={{
                      required: "Zip Code is required",
                      pattern: {
                        value: /^[0-9]{5}$/,
                        message:
                          "Zip Code must be exactly 5 digits",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        maxLength={5}
                        minLength={5}
                        icon={<MyLocation />}
                        label="Zip Code"
                        placeholder="Enter zip code"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="status"
                    control={control}
                    defaultValue="active"
                    render={({ field }) => {
                      const handleToggleChange = (checked: boolean) => {
                        const newValue = checked ? "active" : "inactive";
                        setIsActive(checked);
                        field.onChange(newValue);
                      };

                      return (
                        <ToggleSwitch
                          checked={isActive}
                          onChange={handleToggleChange}
                          labelActive="Active"
                          labelInactive="Inactive"
                          label="Account Access"
                        />
                      );
                    }}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
          </Box>
        </Box>
      </EditModal>
      {loading && <CircularSpinner progress={progress} />}
    </Box>
  );
};
