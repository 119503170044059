const TransportIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.50426 1.08991e-06C10.0694 1.08991e-06 10.2747 0.0572925 10.4848 0.168155C10.695 0.279018 10.8642 0.44345 10.9883 0.65625C11.1117 0.869792 11.1859 1.08333 11.2428 1.686L11.9897 9.62046C12.1013 10.8087 11.2948 11.869 10.1874 11.9888C10.1201 11.9963 10.0521 12 9.98489 12H2.01533C0.902302 12 0 11.032 0 9.83772C0 9.76554 0.00346766 9.69263 0.0104026 9.62046L0.757276 1.686C0.814141 1.08331 0.888344 0.869772 1.01178 0.656248C1.13592 0.443449 1.30512 0.279011 1.51525 0.168154C1.72537 0.0572914 1.93063 0 2.49582 0L9.50426 1.08991e-06ZM8.94045 2.54171C8.67831 2.39737 8.35794 2.50897 8.22339 2.78948C7.81631 3.64291 6.99801 4.19053 6.08893 4.19053C5.18047 4.19053 4.36285 3.64365 3.955 2.79097C3.82047 2.51045 3.49939 2.3996 3.23794 2.5432C2.97649 2.68754 2.87316 3.03203 3.00701 3.31255C3.59577 4.54319 4.77678 5.33331 6.08877 5.33331C7.40152 5.33331 8.58326 4.54165 9.17124 3.31102C9.30578 3.02977 9.2019 2.68531 8.94045 2.54171Z" fill="#6B7280"/>
        </svg>
        
    );
  };
  
  export default TransportIcon;
  