import React from "react";
import {
  Grid2 as Grid,
  Button,
  Typography,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import CancleIcon from "../../assets/IconComponents/CancleIcon";
import DeleteModalIcon from "../../assets/IconComponents/DeleteModalIcon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
  onConfirm:() => void;
  confirmText?: string;
  cancelText?: string;
  modalStyle?: object;
  subMessage?: string;
}

const DeleteModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title = "Delete Confirmation",
  message = "Are you sure?",
  onConfirm,
  confirmText = "Delete",
  cancelText = "Cancel",
  modalStyle,
  subMessage = "This action cannot be undone. All data associated with this user will be lost.",
}) => {
  const defaultModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#F7FAFF",
    borderRadius: "8px",
    border: "10.15px solid #FFFFFF",
    width: { xs: "90%",sm:500, md:  500, lg: 500 },
    // maxHeight: "95vh",
    display: "flex",
    flexDirection: "column",
    zIndex: 1200,
    ...modalStyle,
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        borderRadius: 2,
        alignItems: "center",
      }}
    >
      <Box sx={defaultModalStyle}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ padding: "20px" }}
          flexDirection={"column"}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mb: 2,
            }}
          >
            <Typography fontSize={18} fontWeight={700} color="#374151">
              {title}
            </Typography>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton onClick={onClose} sx={{ padding: 0 }}>
                <CancleIcon />
              </IconButton>
            </Grid>
          </Box>

          <Box
            sx={{ padding: "20px", backgroundColor: "#FFFFFF", width: "100%" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #D5E1F9",
                borderRadius: "8px",
                paddingX: "20px",
                paddingTop: "10px",
                paddingBottom: "20px",
                flexDirection: "column",
              }}
            >
              <DeleteModalIcon />
              <Typography
                fontSize={16}
                fontWeight={700}
                color="#374151"
                sx={{ textAlign: "center"  }}
              >
                {message}
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#374151"
                sx={{ textAlign: "center",my: 1  }}
              >
                {subMessage}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1,width:"100%",gap:2,flexDirection:{xs:"column",sm:"row"} }}>
                <Button
                  sx={{
                    background: "#fff",
                    color: "#001F3D",
                    border: "1px solid #001F3D",
                    marginRight: "10px",
                    width:{xs:"100%",sm:"50%"},
                    height:"48px",
                    borderRadius:"8px",
                    fontSize: "16px",
                    fontWeight:"600"
                  }}
                  onClick={onClose}
                >
                  {cancelText}
                </Button>
                <Button
                  sx={{
                    background: "#E11E4F",
                    color: "#ffffff",
                    height:"48px",
                    borderRadius:"8px",
                    fontSize: "16px",
                    fontWeight:"600",
                    width:{xs:"100%",sm:"50%"},
                  }}
                  onClick={onConfirm}
                >
                  {confirmText}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
