import { Box, Typography, useMediaQuery } from "@mui/material";
import ToggleSwitch from "../../components/form/ToggleSwitch";
import EditPenIcon from "../../assets/IconComponents/Client/ViewClient/EditPenIcon";
import AddIcon from "../../assets/IconComponents/AddIcon";
import EditModal from "../../components/form/EditModal";
import { useEffect, useState } from "react";
import FeatureCard from "./FeaturedCard";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import { Controller, useForm } from "react-hook-form";
import InputComponent from "../../components/form/input";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import DriverLicense from "../../assets/IconComponents/DriverLicense";
import PhoneIcon from "../../assets/IconComponents/PhoneIcon";
import EmailIcon from "../../assets/IconComponents/EmailIcon";
import BankIcon from "../../assets/IconComponents/Client/AddClient/BankIcon";
import CardIcon from "../../assets/IconComponents/CardIcon";
import SsnNumber from "../../assets/IconComponents/SsnNumber";
import AcuScoreIcon from "../../assets/IconComponents/Client/AddClient/AcuScoreIcon";
import AcuPayIcon from "../../assets/IconComponents/Client/AddClient/AcuPayIcon";
import AcuView from "../../assets/IconComponents/Client/AddClient/AcuView";
import CreditTransaction from "../../assets/IconComponents/Client/AddClient/CreditTransaction";
import ACHTransaction from "../../assets/IconComponents/Client/AddClient/ACHTransaction";
import DeleteIcon from "../../assets/IconComponents/Client/ViewClient/DeleteIcon";
import CombinedMonthYearPicker from "../../components/form/MonthYearSelection";
import {
  CreateClientBank,
  CreateClientCard,
  CreateClientContact,
  DeleteCard,
  DeleteClientBankAccount,
  DeleteContact,
} from "../../api/clientAPI";
import { useParams } from "react-router-dom";
import { useAlertMessage } from "../../utils/useAlertMessage";
import CircularSpinner from "../../components/Loader/CircularLoader";
import DeleteModal from "../../components/form/DeleteModal";
interface CardData {
  id: number;
  title: string;
  subtitle: string;
  details: { label: string; value: string }[];
  isPrimary: boolean;
  labelActive?: string;
  icon: React.ReactNode;
  handleEditClick?: string;
}

export const ReusableCard = ({
  data,
  onTogglePrimary,
  handleEditClick,
  getClientInfo,
}: {
  data: CardData;
  onTogglePrimary?: (id: number) => void;
  getClientInfo?: () => void;
  handleEditClick?: string;
}) => {
  const [open, setOpen] = useState(false);
  const [formFields, setFormFields] = useState<string | null>(null);
  const isMobile = useMediaQuery("(max-width:500px)");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handlDeleteClose = () => {
    setIsDeleteOpen(false);
  };

  const handleOpen = (title: string) => {
    setFormFields(title);
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setFormFields(null);
    setOpen(false);
  };

  const [expirationDate, setExpirationDate] = useState<string | null>(null);

  const handleDateChange = (month: number, year: number) => {
    const formattedDate = `${(month + 1).toString().padStart(2, "0")}/${year
      .toString()
      .slice(-2)}`;
    setExpirationDate(formattedDate);
  };
  const handleCancelDate = () => {
    setExpirationDate(null);
  };
  const [selectedFeature, setSelectedFeature] = useState<string | null>(null);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  const featureData = [
    {
      id: "AcuCheck",
      title: "AcuCheck",
      icon: <AcuScoreIcon />,
      disable: false,
    },
    { id: "AcuPay", title: "AcuPay", icon: <AcuPayIcon />, disable: true },
    { id: "AcuView", title: "AcuView", icon: <AcuView />, disable: true },
  ];

  const serviceData = [
    {
      id: "CreditTransaction",
      title: "Credit Transaction",
      icon: <CreditTransaction />,
    },
    {
      id: "ACHTransaction",
      title: "ACH Transaction",
      icon: <ACHTransaction />,
    },
  ];
  const handleFeatureChange = (id: string) => {
    if (id === "AcuPay") {
      setSelectedFeature((prev) => (prev === id ? null : id));
      setSelectedServices((prev) =>
        prev.length ? [] : ["CreditTransaction", "ACHTransaction"]
      );
    } else {
      setSelectedFeature((prev) => (prev === id ? null : id));
      setSelectedServices([]);
    }
  };

  const handleServiceChange = (id: string) => {
    setSelectedServices((prev) =>
      prev.includes(id)
        ? prev.filter((service) => service !== id)
        : [...prev, id]
    );
  };

  const {
    control,
    watch,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
  });
  const { clientId = "" } = useParams();
  const [deleteUserObj, setUserToDelete] = useState<any>(null);

  const handleDeleteConfirmation = (row: any) => {
    setUserToDelete(row);
    setIsDeleteOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    setProgress(0);
    let timer;

    try {
      if (deleteUserObj) {
        switch (handleEditClick) {
          case "Contact Information":
            await DeleteContact(clientId, deleteUserObj.id);
            timer = setInterval(() => {
              setProgress((prev) => (prev < 90 ? prev + 10 : prev));
            }, 200);
            await new Promise((resolve) => setTimeout(resolve, 100));
            clearInterval(timer);
            setProgress(100);
            if (getClientInfo) getClientInfo();
            break;
          case "Card Information":
            await DeleteCard(clientId, deleteUserObj.id);
            timer = setInterval(() => {
              setProgress((prev) => (prev < 90 ? prev + 10 : prev));
            }, 200);
            await new Promise((resolve) => setTimeout(resolve, 100));
            clearInterval(timer);
            setProgress(100);
            if (getClientInfo) getClientInfo();
            break;
          case "Bank Account Information":
            await DeleteClientBankAccount(clientId, deleteUserObj.id);
            timer = setInterval(() => {
              setProgress((prev) => (prev < 90 ? prev + 10 : prev));
            }, 200);
            await new Promise((resolve) => setTimeout(resolve, 100));
            clearInterval(timer);
            setProgress(100);
            if (getClientInfo) getClientInfo();
            break;
          default:
            console.error("Unsupported formFields value:", formFields);
            break;
        }
      }
    } catch (error) {
      notification.errorNotification(
        "Error",
        (error as any).response?.data.message
      );
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      const title = data?.title || "";
      const [firstNameFromTitle = "", lastNameFromTitle = ""] =
        title.split(" ");
      const phone = data.details?.[0]?.value || "";
      const email = data.details?.[1]?.value || "";
      const cardNumber = data.details?.[0]?.value || "";
      const expirationDate = data.details?.[1]?.value || "";
      reset({
        firstName: firstNameFromTitle,
        lastName: lastNameFromTitle,
        title : data.subtitle,
        phone,
        email,
        cardholdername: title,
        cardnumber: cardNumber,
        expirationDate,
      });
    }
  }, [data, reset]);

  useEffect(() => {
    if (data?.subtitle) {
      const date = data.subtitle.split("Expiry Date: ")[1];
      setExpirationDate(date);
    }
  }, [data]);

  const notification = useAlertMessage();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFormSubmit = async () => {
    const formData = watch();

    setLoading(true);
    setProgress(0);
    let timer;

    try {
      let requestData, response;
      switch (formFields) {
        case "Contact Information":
          requestData = {
            id: data.id,
            tenantId: clientId,
            firstName: formData.firstName,
            lastName: formData.lastName,
            title: formData.title,
            phoneNumber: formData.phone,
            email: formData.email,
            isPrimary: data.isPrimary,
          };
          timer = setInterval(() => {
            setProgress((prev) => (prev < 90 ? prev + 10 : prev));
          }, 200);
          response = await CreateClientContact(clientId, requestData);
          handleClose();
          if (getClientInfo) getClientInfo();
          break;
        case "Card Information":
          requestData = {
            id: data.id,
            tenantId: clientId,
            cardHolderName: formData.cardholdername,
            cardNumber: formData?.cardnumber?.replace(/-/g, ""),
            expiryDate: expirationDate,
            isPrimary: data.isPrimary,
          };
          timer = setInterval(() => {
            setProgress((prev) => (prev < 90 ? prev + 10 : prev));
          }, 200);
          response = await CreateClientCard(clientId, requestData);
          handleClose();
          if (getClientInfo) getClientInfo();
          break;
        case "Bank Account Information":
          requestData = {
            id: data.id,
            tenantId: clientId,
            bankName: formData.bankName,
            accountNumber: formData?.accountNumber?.replace(/-/g, "") || "",
            routingNumber: formData.routingNumber,
            isPrimary: data.isPrimary,
          };
          timer = setInterval(() => {
            setProgress((prev) => (prev < 90 ? prev + 10 : prev));
          }, 200);
          response = await CreateClientBank(clientId, requestData);
          handleClose();
          if (getClientInfo) getClientInfo();
          break;
        default:
          throw new Error("Invalid form action");
      }
    } catch (err) {
      console.error(err);
      notification.errorNotification(
        "Error",
        (err as any).response?.data.message
      );
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  const getFieldNamesForForm = (formFields: string | null): string[] => {
    switch (formFields) {
      case "Contact Information":
        return ["firstName", "lastName", "title", "phone", "email"];
      case "Bank Account Information":
        return ["bankName", "accountNumber", "routingNumber"];
      case "Card Information":
        return ["cardholdername", "cardnumber"];
      case "Client Password":
        return ["password", "confirmPassword"];
      default:
        return [];
    }
  };

  const allFieldsValid = (
    fieldNames: string[],
    additionalChecks: boolean = true
  ): boolean => {
    const fieldValues = fieldNames.map((fieldName) => getValues(fieldName));
    const allFieldsFilled = fieldValues.every(
      (value) => value !== undefined && value !== ""
    );
    const noErrors = fieldNames.every((fieldName) => !errors[fieldName]);
    return allFieldsFilled && noErrors && additionalChecks;
  };

  const isButtonDisabled = (() => {
    if (formFields === "Product & Service Information") {
      return true;
    }

    if (!formFields || typeof formFields !== "string") {
      return true;
    }
    const requiredFields = getFieldNamesForForm(formFields);
    return !allFieldsValid(
      requiredFields,
      formFields === "Card Information" ? !!expirationDate : true
    );
  })();

  return (
    <Box
      sx={{
        width: "350px",
        display: "flex",
        flexDirection: "column",
        padding: "16px",
        border: "1px solid #D5E1F9",
        borderRadius: "8px",
        background: "#FFFFFF",
        boxShadow: "0px 3.19px 15.97px 0 rgba(67, 127, 227, 0.15)",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: isMobile ? "" : "center",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Box sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Box>{data.icon}</Box>
          <Box>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "700", color: "#374151" }}
            >
              {data.title}
            </Typography>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "400", color: "#6B7280" }}
            >
              {data.subtitle}
            </Typography>
          </Box>
        </Box>
        <ToggleSwitch
          labelActive={data.labelActive || "Primary"}
          checked={data.isPrimary}
          onChange={() => !data.isPrimary && onTogglePrimary?.(data.id)}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
          marginTop: isMobile ? "10px" : "",
          alignItems: "center",
        }}
      >
        <Box>
          {data?.details?.map((detail, index) => (
            <Typography
              key={index}
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#374151",
              }}
            >
              {detail.label}: {detail.value}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            marginTop: isMobile ? "10px" : "",
          }}
        >
          {data.isPrimary === false && (
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => handleDeleteConfirmation(data)}
            >
              <DeleteIcon />
            </Box>
          )}
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => handleEditClick && handleOpen(handleEditClick)}
          >
            <EditPenIcon />
          </Box>
        </Box>
      </Box>
      <EditModal
        open={open}
        onClose={handleClose}
        title={`Edit ${formFields}`}
        handleUpdate={() => handleFormSubmit()}
        disabled={isButtonDisabled}
        buttonStyle={{
          background: isButtonDisabled ? "#BCBCBD" : "#1F3A91",
          color: "#ffffff !important",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #DBE9FE",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{ padding: "20px", background: "#F7FAFF", borderRadius: "6px" }}
          >
            {formFields === "Contact Information" && (
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: "First name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "First name can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<UserIconGray />}
                        label="First Name"
                        placeholder="Enter first name"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: "Last name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Last name can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<UserIconGray />}
                        label="Last Name"
                        placeholder="Enter last name"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={6} xl={12}>
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Title can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        label="Title"
                        icon={<DriverLicense />}
                        placeholder="Enter Title"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={6}>
                  <Controller
                    name="phone"
                    control={control}
                    defaultValue={data?.details[0]?.value}
                    rules={{
                      required: "Mobile Number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Mobile Number must be exactly 10 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Mobile Number"
                        maxLength={10}
                        minLength={10}
                        icon={<PhoneIcon />}
                        placeholder="Enter mobile number"
                        error={(errors.phone?.message as string) || null}
                        isRequired
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={6}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Enter a valid email address",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Email Address"
                        icon={<EmailIcon />}
                        placeholder="Enter email address"
                        isRequired
                        error={(errors.email?.message as string) || null}
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {formFields === "Bank Account Information" && (
              <FormInputRow>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="bankName"
                    control={control}
                    defaultValue={data.title}
                    rules={{
                      required: "Bank name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Bank name can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<BankIcon />}
                        label="Bank Name"
                        placeholder="Enter bank name"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="accountNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Account Number is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Account Number can only contain numbers",
                      },
                      minLength: {
                        value: 6,
                        message:
                          "Account Number must be at least 6 digits long",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        minLength={6}
                        maxLength={17}
                        icon={<CardIcon />}
                        label="Account Number"
                        placeholder="Enter account number"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={4}>
                  <Controller
                    name="routingNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Routing Number is required",
                      pattern: {
                        value: /^[0-9]{9}$/,
                        message: "Routing Number contains exactly 9 numbers",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        maxLength={9}
                        icon={<SsnNumber />}
                        label="Routing Number"
                        placeholder="Enter rounting number"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {formFields === "Card Information" && (
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="cardholdername"
                    control={control}
                    defaultValue={data.title}
                    rules={{
                      required: "Card Holder name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Card Holder name can only contain alphabets",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<CardIcon />}
                        label="Name on Card"
                        placeholder="Enter Name on Card"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="cardnumber"
                    control={control}
                    defaultValue={data.details[0]?.value}
                    rules={{
                      required: "Card Number is required",
                      pattern: {
                        value: /^\d{4}-\d{4}-\d{4}-\d{4}$/,
                        message:
                          "Card Number must be in the format xxxx-xxxx-xxxx-xxxx",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        isRequired
                        {...field}
                        icon={<CardIcon />}
                        label="Card Number"
                        placeholder="Enter Card Number"
                        error={error?.message}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          const formattedValue = value
                            .replace(
                              /(\d{4})(\d{0,4})(\d{0,4})(\d{0,4})/,
                              "$1-$2-$3-$4"
                            )
                            .replace(/-+$/, "");
                          field.onChange(formattedValue);
                        }}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="expirationDate"
                    control={control}
                    defaultValue={expirationDate}
                    rules={{
                      required: "Expiration Date is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Expiration Date can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <CombinedMonthYearPicker
                        label="Expiration Date"
                        backgroundColor="#f7f9fc"
                        required
                        monthyear={expirationDate || undefined}
                        placeholder="Enter Expiration Date"
                        style={{
                          height: "40px",
                        }}
                        onDateChange={(month, year) =>
                          handleDateChange(month, year)
                        }
                        onCancel={handleCancelDate}
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {formFields === "Product & Service Information" && (
              <Box className="accordianbox">
                <Typography
                  sx={{
                    color: "#374151",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginBottom: "20px",
                  }}
                >
                  Select Product from below
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  {featureData.map((feature) => (
                    <FeatureCard
                      key={feature.id}
                      title={feature.title}
                      icon={feature.icon}
                      checked={data.title === feature.title}
                      onChange={() => handleFeatureChange(feature.id)}
                      disabled={feature.disable}
                    />
                  ))}
                </Box>
                {selectedFeature === "AcuPay" && (
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography
                      sx={{
                        color: "#374151",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginBottom: "20px",
                      }}
                    >
                      Select Services from below
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      {serviceData.map((service) => (
                        <FeatureCard
                          key={service.id}
                          title={service.title}
                          icon={service.icon}
                          checked={selectedServices.includes(service.id)}
                          onChange={() => handleServiceChange(service.id)}
                          disabled={true}
                        />
                      ))}
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        marginTop: "10px",
                      }}
                    >
                      Note :&nbsp;
                      <Typography
                        component={"span"}
                        sx={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        AcuFi will serve as the default processor.
                      </Typography>
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </EditModal>
      {loading && <CircularSpinner progress={progress} />}
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={handlDeleteClose}
        onConfirm={handleDelete}
      />
    </Box>
  );
};

export const ReusableSection = ({
  title,
  data,
  addTitle,
  onTogglePrimary,
  handleAddClick,
  getClientInfo,
}: {
  title: string;
  addTitle: string;
  data: CardData[];
  onTogglePrimary: (id: number) => void;
  labelActive?: string;
  handleAddClick?: () => void;
  getClientInfo?: () => void;
  handleEditClick?: string;
}) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: "700", color: "#000000" }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {!isMobile && <AddIcon fill="#264D8E" />}
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#264D8E",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleAddClick}
          >
            ADD {addTitle.toUpperCase()}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "20px",
          background: "#F7FAFF",
          margin: "20px 0 0 0",
          borderRadius: "8px",
          display: "flex",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        {data.map((item) => (
          <ReusableCard
            getClientInfo={getClientInfo}
            key={item.id}
            data={item}
            onTogglePrimary={onTogglePrimary}
            handleEditClick={title}
          />
        ))}
      </Box>
    </Box>
  );
};
