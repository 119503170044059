import AxiosInstance from "./http";

export async function CreateClients(
  requestData: any
): Promise<{ data: any; status: number }> {
  try {
    const response = await AxiosInstance.post("/Tenant", requestData);
    console.log(response, "defneu");
    return response;
  } catch (err) {
    console.error("Error creating client:", err);
    throw err;
  }
}

export async function GetClients(query: any): Promise<any> {
  try {
    if (query.orderBy) {
      query.order = query?.order?.toUpperCase();
      query.orderBy = query.orderBy;
    }
    if (query.customerType === "all") {
      delete query.customerType;
    }
    const response = await AxiosInstance.get("/Tenant/all", {
      params: query,
    });
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}

export async function GetClientByClientId(clientId: string): Promise<any> {
  try {
    const response = await AxiosInstance.get(`/Tenant/${clientId}`);
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}

export async function CreateClientContact(
  tenantId: string,
  requestData: any
): Promise<any> {
  try {
    const response: any = await AxiosInstance.post(
      `/Tenant/${tenantId}/contact`,
      requestData
    );
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}

export async function CreateClientCard(
  tenantId: string,
  requestData: any
): Promise<any> {
  try {
    const response: any = await AxiosInstance.post(
      `/Tenant/${tenantId}/card`,
      requestData
    );
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}

export async function UpdateClient(
  tenantId: string,
  requestData: any
): Promise<any> {
  try {
    const response: any = await AxiosInstance.put(
      `/Tenant/${tenantId}`,
      requestData
    );
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}

export async function DeleteContact(
  tenantId: string,
  contactId: string
): Promise<any> {
  try {
    const response: any = await AxiosInstance.delete(
      `/Tenant/${tenantId}/contact/${contactId}`
    );
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}

export async function DeleteCard(
  tenantId: string,
  cardId: string
): Promise<any> {
  try {
    const response: any = await AxiosInstance.delete(
      `/Tenant/${tenantId}/card/${cardId}`
    );
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}

export async function CreateClientBank(
  tenantId: string,
  requestData: any
): Promise<any> {
  try {
    const response: any = await AxiosInstance.post(
      `/Tenant/${tenantId}/bank`,
      requestData
    );
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}

export async function UpdateClientBank(
  tenantId: string,
  requestData: any
): Promise<any> {
  try {
    const response: any = await AxiosInstance.put(
      `/Tenant/${tenantId}/bank`,
      requestData
    );
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}

export async function DeleteClientBankAccount(
  tenantId: string,
  contactId: string
): Promise<any> {
  try {
    const response: any = await AxiosInstance.delete(
      `/Tenant/${tenantId}/bank/${contactId}`
    );
    return response;
  } catch (err) {
    console.error("err", err);
    throw err;
  }
}
