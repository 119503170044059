const AccountNumberIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.57552 13.872L4.16957 14.189L4.49857 14.5854C6.03229 16.433 8.18248 17.166 9.99996 17.166C11.8174 17.166 13.9676 16.433 15.5013 14.5854L15.8303 14.189L15.4244 13.872C13.9326 12.7068 12.0503 11.9993 9.99996 11.9993C7.9496 11.9993 6.06733 12.7068 4.57552 13.872ZM2.16663 9.99935C2.16663 5.67549 5.6761 2.16602 9.99996 2.16602C14.3238 2.16602 17.8333 5.67549 17.8333 9.99935C17.8333 14.3232 14.3238 17.8327 9.99996 17.8327C5.6761 17.8327 2.16663 14.3232 2.16663 9.99935ZM13.4166 7.91602C13.4166 6.03154 11.8844 4.49935 9.99996 4.49935C8.11548 4.49935 6.58329 6.03154 6.58329 7.91602C6.58329 9.80049 8.11548 11.3327 9.99996 11.3327C11.8844 11.3327 13.4166 9.80049 13.4166 7.91602Z" fill="#6B7280" stroke="#6B7280"/>
        </svg>
        
    );
  };
  
  export default AccountNumberIcon;
  