import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import Panel from "../../components/Panel";
import "./IbaReport.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import SelectComponent from "../../components/form/SelectComponent";
import UserIcon from "../../assets/IconComponents/UserIcon";
import EditModal from "../../components/form/EditModal";
import InputComponent from "../../components/form/input";
import YearPicker from "../../components/form/YearPicker";
import CustomMonthPicker from "../../components/form/MonthPicker";
import {
  DataTable,
  PaginationControl,
} from "../../components/form/TableComponent";
import TruncatedTooltipText from "../../components/form/ToolTip";
import EditIcon from "../../assets/media/icons/EditIcon.png";
import ExpiryIcon from "../../assets/media/icons/Expiry.png";
import PendingIcon from "../../assets/media/icons/Pending.png";
import ExpandMoreIcon from "../../assets/IconComponents/ExpandMoreIcon";
import FileDownloadIcon from "../../assets/IconComponents/FileDownloadIcon";
import { CreateNewIBAReqest, GetReportRequestsList } from "../../api/IBAReport";
import { useAlertMessage } from "../../utils/useAlertMessage";
import { GetCustomers } from "../../api/customerAPI";
import moment from "moment";
import CircularSpinner from "../../components/Loader/CircularLoader";

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  color: "#ff0000",
  border: "none",
  height: "48px !important",
  "& .MuiButtonGroup-grouped": {
    border: "1px solid #E5E7EB !important",
    // width:"max-content !important"
  },
  "&:hover": {
    boxShadow: "none !important",
  },
}));

interface OptionType {
  label: string;
  value: string | number;
}

const IBAReportPage = (props: any) => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState("id");
  const theme = useTheme();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [totalRecordsCount, setTotalRecordsCount] = useState<any>(0);
  const [customersList, setCustomersList] = useState<any[]>([]);
  const [customersCount, setCustomersCount] = useState<any>(0);
  const [query, setQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    status: "All",
    orderBy: "ordered",
    order: "desc",
    month: "",
    year: "",
  });
  const [customerQuery, setCustomerQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    orderBy: "createdDate",
    order: "desc",
  });
  const [reportsList, setReportsList] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  const handleMonthChange = (monthIndex: number | null) => {
    setSelectedMonth(monthIndex);
    const updatedQuery = {
      ...query,
      month: monthIndex !== null ? monthIndex + 1 : "",
      pageNumber: 1,
    };
    setQuery(updatedQuery);
    getReportList(updatedQuery);
  };

  const handleYearChange = (year: number | null) => {
    setSelectedYear(year);
    const updatedQuery = { ...query, year, pageNumber: 1 };
    setQuery(updatedQuery);
    getReportList(updatedQuery);
  };

  const notification = useAlertMessage();
  useEffect(() => {
    getReportList(query, true);
    getCustomersList(customerQuery);
  }, []);

  const getReportList = async (query: any, onInitialCall?: boolean) => {
    toggleLoading(true);
    try {
      if (!onInitialCall) setQuery(query);
      const result: any = await GetReportRequestsList(query);
      result.data.data = (result?.data?.data || []).map((report: any) => ({
        id: report.id,
        name: `${report?.customer?.firstName} ${report?.customer?.lastName}`,
        phone: report?.customer?.phoneNumber,
        ordered: moment(report?.createdDate).format("MM/DD/YYYY"),
        score: "-",
        sendVia: report?.sentVia?.join(","),
        requestedBy: "Admin",
        status: report?.status,
      }));
      setReportsList(result?.data?.data);
      setTotalRecordsCount(result?.data.totalCount || 0);
      setTimeout(() => toggleLoading(false), 500);
    } catch (err) {
      notification.errorNotification("Error", "Error fetching reports.");
      toggleLoading(false);
    }
  };
  const getCustomersList = async (query: any = {}) => {
    let customerList: any = await GetCustomers(query);
    console.log(customerList.data.data);
    let list: any = [];
    if (customerList.data.data.length) {
      list =
        customerList?.data?.data.map((customer: any) => {
          return {
            label: `${customer.firstName} ${customer.lastName}`,
            value: customer.id,
          };
        }) || [];
    }

    setCustomersList((prevList) => {
      const combinedList = [...prevList, ...list];
      const uniqueList = combinedList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );
      return uniqueList;
    });

    setCustomersCount(customerList.totalCount);
  };

  console.log(customersList);

  const fetchAllCustomers = async () => {
    let totalPages = Math.ceil(customersCount / customerQuery.pageSize);
    let currentPage = customerQuery.pageNumber;
    while (currentPage <= totalPages) {
      await getCustomersList({
        pageSize: customerQuery.pageSize,
        pageNumber: currentPage,
        nameQuery: customerQuery.nameQuery,
        orderBy: customerQuery.orderBy,
        order: customerQuery.order,
      });

      setCustomerQuery((prevQuery: any) => ({
        ...prevQuery,
        pageNumber: prevQuery.pageNumber + 1,
      }));

      currentPage++;
    }
  };

  useEffect(() => {
    fetchAllCustomers();
  }, [customersCount]);

  const renderStatus = (status: string) => {
    const statusConfig = {
      completed: {
        iconColor: "#01B6A8",
        backgroundColor: "#EFFFFE",
        textColor: "#01B6A8",
      },
      expired: {
        iconColor: "#ED9902",
        backgroundColor: "#FFF5E4",
        textColor: "#ED9902",
      },
      pending: {
        iconColor: "#AF6DD1",
        backgroundColor: "rgba(168, 0, 255, 0.06)",
        textColor: "#AF6DD1",
      },
    };

    const config = statusConfig[status as keyof typeof statusConfig];
    if (!config) return null;
    return (
      <Chip
        icon={
          <FiberManualRecordIcon
            sx={{ color: `${config.iconColor} !important`, height: "10px" }}
          />
        }
        label={status.charAt(0).toUpperCase() + status.slice(1)}
        sx={{
          backgroundColor: config.backgroundColor,
          color: config.textColor,
          minWidth: "110px",
        }}
      />
    );
  };

  const createSortHandler = (key: string) => {
    getReportList({
      ...query,
      pageNumber: 1,
      order: query.order?.toLowerCase() === "asc" ? "desc" : "asc",
      orderBy: key,
    });
  };

  const [open, setOpen] = useState(false);
  const [verification, setVerification] = useState("SMS");
  const [selectedCustomer, setSelectedCustomer] = useState<OptionType | null>(
    null
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleVerificationChange = (event: any) => {
    setVerification(event.target.value);
  };

  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    getReportList({
      ...query,
      pageNumber: value,
    });
  };
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      getReportList({ ...query, nameQuery: searchValue, pageNumber: 1 });
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchValue]);

  const selectReportStatus = (type: string) => {
    getReportList({
      ...query,
      pageNumber: 1,
      status: type,
    });
  };

  const CreateIBARequest = async () => {
    setLoading(true);
    setProgress(0);
    try {
      const timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const { data, status } = await CreateNewIBAReqest(
        selectedCustomer?.value || "",
        verification
      );
      console.log(status);
      clearInterval(timer);
      setProgress(100);
      if (status === 200) {
        notification.successNotification(
          "Success",
          "Create report request submitted successfully!"
        );
        closeModel();
      }
    } catch (err) {
      notification.errorNotification(
        "Error",
        (err as any).response?.data.message
      );
    } finally {
      setLoading(false);
    }
    getReportList(query);
  };

  const closeModel = () => {
    setSelectedCustomer(null);
    handleClose();
  };

  return (
    <Grid container>
      <Grid container size={12}>
        <Grid
          size={{ xs: 6, md: 8 }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography>
            All AcuCheck related data can be managed from this screen
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }} textAlign={"right"}>
          <Button
            variant="contained"
            color="primary"
            startIcon={
              <AddIcon sx={{ color: theme.palette.primary.contrastText }} />
            }
            onClick={handleOpen}
            sx={{
              color: theme.palette.primary.contrastText,
              background: theme.palette.primary.light,
              padding: "10px 16px",
              borderRadius: "10px",
            }}
          >
            New Request
          </Button>
        </Grid>
        <Grid size={12} container marginTop={"20px"}>
          <Panel sx={{ width: "100%" }}>
            <Box sx={{ padding: "20px 15px 10px" }}>
              <Box
                display="flex"
                justifyContent={"space-between"}
                sx={{
                  gap: { lg: 1, xl: 2 },
                  flexDirection: { xs: "column", sm: "column", lg: "row" },
                }}
              >
                <Box
                  display="flex"
                  justifyContent={"space-between"}
                  gap={2}
                  sx={{
                    width: { sm: "100%", lg: "calc(100% - 330px)" },
                    marginBottom: { sm: "10px", lg: "0px" },
                    flexDirection: { xs: "column", sm: "row", lg: "row" },
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: {
                        xs: "100%",
                        sm: "300px",
                        lg: "250px",
                        xl: "300px",
                      },
                    }}
                  >
                    <InputComponent
                      onChange={handleInputChange}
                      placeholder="Search"
                      icon={<SearchIcon />}
                      backgroundColor="#fff"
                      borderColor="#E5E7EB"
                    />
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    gap={1}
                  >
                    <Box sx={{ maxWidth: { md: "200px", lg: "150px" } }}>
                      <CustomMonthPicker
                        selectedMonthProp={selectedMonth}
                        onMonthSelect={handleMonthChange}
                        borderColor="#E5E7EB"
                        backgroundColor="#fff"
                      />
                    </Box>
                    <Box sx={{ maxWidth: { md: "200px", lg: "150px" } }}>
                      <YearPicker
                        borderColor="#E5E7EB"
                        backgroundColor="#fff"
                        selectedYearProp={selectedYear}
                        onYearSelect={handleYearChange}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  sx={{
                    gap: { xs: 2, lg: 1, xl: 2 },
                    marginTop: { xs: "15px", sm: "0px" },
                    flexWrap: { xs: "wrap", sm: "nowrap" },
                  }}
                >
                  <Box>
                    <StyledButtonGroup>
                      {[
                        { value: "All", label: "All" },
                        { value: "expired", label: "Expired Requests" },
                        { value: "pending", label: "Pending Requests" },
                      ].map((type) => (
                        <Button
                          key={type.label}
                          onClick={() => selectReportStatus(type.value)}
                          sx={{
                            border: "1px solid #D5E1F9",
                            backgroundColor:
                              query.status === type.value
                                ? "#F1F4FF"
                                : "transparent",
                            color:
                              query.status === type.value
                                ? "#1F3A91"
                                : "#6B7280",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight:
                                query.status === type.value ? 600 : 500,
                            }}
                          >
                            {type.label}
                          </Typography>
                        </Button>
                      ))}
                    </StyledButtonGroup>
                  </Box>
                  {/* <Box>
                    <Button
                      size="large"
                      sx={{
                        borderRadius: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        border: "1px solid #1D3DB0",
                        color: "#1D3DB0",
                      }}
                      startIcon={<FileDownloadIcon />}
                      endIcon={<ExpandMoreIcon />}
                    >
                      Download
                    </Button>
                  </Box> */}
                </Box>
              </Box>
            </Box>

            <DataTable
              columns={[
                { label: "ID", id: "id", render: (row) => `#${row.id}` },
                { label: "Name", id: "name" },
                { label: "Phone", id: "phone" },
                // { label: "Score", id: "score" },
                { label: "Ordered on", id: "ordered" },
                { label: "Requested By", id: "requestedBy" },
                {
                  label: "Sent Via",
                  id: "sendVia",
                  render: (row) => {
                    if (row.sendVia === "email") return "E-mail";
                    if (row.sendVia === "sms") return "SMS";
                    if (
                      row.sendVia.includes("sms") &&
                      row.sendVia.includes("email")
                    )
                      return "E-mail,SMS";
                    return row.sendVia;
                  },
                },
                {
                  label: "Status",
                  id: "status",
                  sortable: false,
                  render: (row) => renderStatus(row.status),
                },
                {
                  label: "Action",
                  id: "action",
                  sortable: true,
                  render: (row) => {
                    return (
                      <TruncatedTooltipText
                        text={
                          row.status === "completed"
                            ? "View Report Details"
                            : ""
                        }
                        forceTooltip={true}
                      >
                        <Box
                          component="img"
                          src={
                            row.status === "completed"
                              ? EditIcon
                              : row.status === "expired"
                                ? ExpiryIcon
                                : PendingIcon
                          }
                          onClick={() => {
                            if (row.status === "completed") {
                              navigate(`/acucheck/report/analysis/${row.id}`);
                            }
                          }}
                          sx={{
                            width: { xs: "50px", md: "3.2rem" },
                            height: { xs: "50px", md: "3.2rem" },
                          }}
                        />
                      </TruncatedTooltipText>
                    );
                  },
                },
              ]}
              rows={reportsList || []}
              isLoading={isLoading}
              orderBy={query.orderBy}
              order={query.order}
              createSortHandler={createSortHandler}
              checkboxSelection={true}
              NodataTitle="AcuCheck Report's"
              onGetStartedClick={() => setOpen(true)}
            />
            <PaginationControl
              page={query.pageNumber}
              rowsPerPage={query.pageSize}
              totalItems={totalRecordsCount || 0}
              handlePageChange={handlePageChange}
            />
          </Panel>
        </Grid>
      </Grid>
      <EditModal
        open={open}
        onClose={closeModel}
        handleUpdate={CreateIBARequest}
        updateText="Send Request"
        title="New Request"
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #DBE9FE",
            borderRadius: "10px",
          }}
        >
          <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
            AcuCheck Request
          </Typography>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "400", color: "#303132" }}
          >
            Manage all AcuCheck request related details here
          </Typography>
          <Box
            sx={{
              padding: "20px",
              background: "#F7FAFF",
              borderRadius: "6px",
              margin: "20px 0 0 0",
            }}
          >
            <SelectComponent
              options={customersList}
              label="Select Customer"
              value={selectedCustomer}
              onChange={(newValue: any) => setSelectedCustomer(newValue)}
              fullWidth
              icon={<UserIcon />}
            />
            <Typography
              sx={{
                marginTop: 2,
                marginBottom: "25px",
                fontSize: "14px",
                fontWeight: "600",
                color: "#111928",
              }}
            >
              Note: Customer does not exist in the database?&nbsp;
              <Typography
                component={"span"}
                sx={{
                  textDecoration: "underline",
                  padding: 0,
                  color: "#264D8E",
                  fontSize: "14px",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/acucheck/customers/new")}
              >
                Create New Customer
              </Typography>
            </Typography>
            <Divider sx={{ border: "0.5px dashed #B1C6F1" }} />
            <FormLabel component="legend" sx={{ marginTop: "20px" }}>
              Send verification via
            </FormLabel>
            <RadioGroup
              value={verification}
              onChange={handleVerificationChange}
              row
            >
              <FormControlLabel value="SMS" control={<Radio />} label="SMS" />
              <FormControlLabel
                value="Email"
                control={<Radio />}
                label="E-mail"
              />
              <FormControlLabel value="Both" control={<Radio />} label="Both" />
            </RadioGroup>
          </Box>
        </Box>
      </EditModal>
      {loading && <CircularSpinner progress={progress} />}
    </Grid>
  );
};

export default IBAReportPage;
