const EditPenIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.6" fill="#E9F1FF" stroke="#F5FAFF" stroke-width="0.8"/>
    <path d="M7.49896 19.3761V22.5011H10.624L19.8406 13.2844L16.7156 10.1594L7.49896 19.3761Z" fill="#1F3A91"/>
    <path d="M22.2573 9.69277L20.3073 7.74277C19.9823 7.41777 19.4573 7.41777 19.1323 7.74277L17.6073 9.26777L20.7323 12.3928L22.2573 10.8678C22.5823 10.5428 22.5823 10.0178 22.2573 9.69277Z" fill="#1F3A91"/>
    </svg>    
  )
}

export default EditPenIcon