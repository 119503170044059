const PencilIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99902 13.5007L1.99902 16.0007H4.49902L11.8724 8.62736L9.37236 6.12736L1.99902 13.5007ZM13.8057 6.69402C14.0657 6.43402 14.0657 6.01402 13.8057 5.75402L12.2457 4.19402C11.9857 3.93402 11.5657 3.93402 11.3057 4.19402L10.0857 5.41402L12.5857 7.91402L13.8057 6.69402Z" fill="#737373"/>
    </svg>
    
  );
};

export default PencilIcon;
