import {
  Box,
  Button,
  Divider,
  Grid2 as Grid,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import Panel from "../../components/Panel";
import SelectComponent from "../../components/form/SelectComponent";
import { useEffect, useState } from "react";
import { GetCustomers } from "../../api/customerAPI";
import { useNavigate } from "react-router-dom";
import profileBackground from "../../assets/media/icons/profileBackground.png";
import DTIIcon from "../../assets/IconComponents/DTI/DTIIcon";
import DTIArrow from "../../assets/IconComponents/DTI/DTIArrow";
import DTIThreshold from "../../assets/IconComponents/DTI/DTIThreshold";
import DTIAmount from "../../assets/IconComponents/DTI/DTIAmount";
import InputComponent from "../../components/form/input";
import DtiPie from "../../components/charts/DtiPie";
import SaveResultDTiTable from "./SaveResultDTiTable";

interface OptionType {
  label: string;
  value: string | number;
}

const ButtonComponent = styled(Button)(({ theme }) => ({
  borderRadius: "0px",
  background: "#ffffff",
  "&:hover": {
    background: "#ffffff",
  },
}));

const ProfileHeader = () => (
  <Box sx={{ position: "relative", textAlign: "center", width: "100%" }}>
    <Box
      component="img"
      src={profileBackground}
      alt="background"
      sx={{ width: "100%", height: "130px" }}
    />
    <Box
      sx={{
        background: "#142848",
        borderRadius: "50%",
        height: "175px",
        width: "175px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "20px solid #ffffff",
        position: "absolute",
        top: "50px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Typography
        sx={{ fontSize: "48px", fontWeight: "600", color: "#FFFFFF" }}
      >
        BW
      </Typography>
    </Box>
    <Box sx={{ marginTop: "90px", gap: "16px" }}>
      <Typography
        sx={{
          fontWeight: "800",
          fontSize: "30px",
          color: "#1E293B",
          textWrap: "wrap",
        }}
      >
        Bruce Wayne
      </Typography>
      <Typography
        sx={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}
      >
        E-Mail ID: brucewayne@gmail.com
      </Typography>
    </Box>
  </Box>
);

const DTICalculator = () => {
  const navigate = useNavigate();
  const [customersList, setCustomersList] = useState<any[]>([]);
  const [customersCount, setCustomersCount] = useState<any>(0);
  const [customerQuery, setCustomerQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    orderBy: "createdDate",
    order: "desc",
  });
  const [selectedCustomer, setSelectedCustomer] = useState<OptionType | null>(
    null
  );
  const [activeButton, setActiveButton] = useState("amount");
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  const options: OptionType[] = [
    { label: "Monthly", value: "monthly" },
    { label: "Bi-Monthly", value: "bi-monthly" },
    { label: "Weekly", value: "weekly" },
    { label: "Bi-Weekly", value: "bi-weekly" },
  ];

  useEffect(() => {
    getCustomersList(customerQuery);
  }, []);
  const getCustomersList = async (query: any = {}) => {
    let customerList: any = await GetCustomers(query);
    let list: any = [];

    if (customerList?.data?.length) {
      list =
        customerList?.data?.map((customer: any) => {
          return {
            label: `${customer.firstName} ${customer.lastName}`,
            value: customer.id,
          };
        }) || [];
    }

    setCustomersList((prevList) => {
      const combinedList = [...prevList, ...list];
      const uniqueList = combinedList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );
      return uniqueList;
    });

    setCustomersCount(customerList.totalCount);
  };

  const fetchAllCustomers = async () => {
    let totalPages = Math.ceil(customersCount / customerQuery.pageSize);
    let currentPage = customerQuery.pageNumber;
    while (currentPage <= totalPages) {
      await getCustomersList({
        pageSize: customerQuery.pageSize,
        pageNumber: currentPage,
        nameQuery: customerQuery.nameQuery,
        orderBy: customerQuery.orderBy,
        order: customerQuery.order,
      });

      setCustomerQuery((prevQuery: any) => ({
        ...prevQuery,
        pageNumber: prevQuery.pageNumber + 1,
      }));

      currentPage++;
    }
  };

  const itemStyles = [
    {
      title: "70%",
      subtitle: "Payment Installment",
      borderColor: "rgba(23, 178, 255, 0.2)",
      backgroundColor: "#E8F7FF",
      typographyColor: "#17B2FF",
    },
    {
      title: "60%",
      subtitle: "Project Completion",
      borderColor: "#8064FF",
      backgroundColor: "#F3F0FF",
      typographyColor: "#8064FF",
    },
    {
      title: "50%",
      subtitle: "Project Completion",
      borderColor: "rgba(75, 125, 204, 0.2)",
      backgroundColor: "#E6F6F9",
      typographyColor: "#4B7DCC",
    },
  ];

  const DTICalculations = [
    {
      title: "DTI Score",
      subtitle: "60%",
      backgroundColor: "rgba(75, 125, 204, 0.1)",
      typographyColor: "#4B7DCC",
    },
    {
      title: "Payment installment",
      subtitle: "9 Months",
      backgroundColor: "rgba(9, 160, 194, 0.1)",
      typographyColor: "#09A0C2",
    },
    {
      title: "Installment Amount",
      subtitle: "$140.00",
      backgroundColor: "rgba(100, 146, 255, 0.1)",
      typographyColor: "#6492FF",
    },
    {
      title: "Frequency",
      subtitle: " Monthly",
      backgroundColor: "rgba(128, 100, 255, 0.1)",
      typographyColor: "#8064FF",
    },
    {
      title: "Disposable Income",
      subtitle: "$1,733.27",
      backgroundColor: "rgba(23, 178, 255, 0.1)",
      typographyColor: "#17B2FF",
    },
  ];

  const columns = [
    "Results (n)",
    "DTI Score (%)",
    "No. of Installments (n)",
    "Installment Frequency",
    "Disposable Income ($)",
    "Installment Amount ($)",
  ];

  const rows = [
    {
      "Results (n)": "Result 1",
      "DTI Score (%)": "60%",
      "No. of Installments (n)": 19,
      "Installment Frequency": "Monthly",
      "Disposable Income ($)": "$1,733.27",
      "Installment Amount ($)": "$140.00",
    },
    {
      "Results (n)": "Result 2",
      "DTI Score (%)": "50%",
      "No. of Installments (n)": 19,
      "Installment Frequency": "Monthly",
      "Disposable Income ($)": "$1,733.27",
      "Installment Amount ($)": "$140.00",
    },
  ];

  useEffect(() => {
    fetchAllCustomers();
  }, [customersCount]);
  const theme = useTheme();
  return (
    <Grid container>
      <Grid container size={12}>
        <Grid
          size={{ xs: 6, md: 8 }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography>
            All DTI calculator related data can be managed from this screen
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }} textAlign={"right"}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/acucheck/settings/dti")}
            sx={{
              color: theme.palette.primary.contrastText,
              background: theme.palette.primary.light,
              padding: "10px 16px",
              borderRadius: "10px",
            }}
          >
            DTI Settings
          </Button>
        </Grid>
        <Grid container size={12}>
          <Panel
            sx={{
              border: "1px solid #D5E1F9",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <Box sx={{ width: "100%", padding: "20px" }}>
              <Typography
                sx={{ color: "#374151", fontSize: "16px", fontWeight: "700s" }}
              >
                Apply Filters
              </Typography>
              <Box
                sx={{
                  padding: "20px",
                  background: "#F7FAFF",
                  borderRadius: "6px",
                  margin: "20px 0",
                  //   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid container>
                  <Grid size={{ xs: 12, sm: 8, md: 4 }}>
                    <SelectComponent
                      required
                      options={customersList}
                      label="Select Customer"
                      value={selectedCustomer}
                      onChange={(newValue: any) =>
                        setSelectedCustomer(newValue)
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    gap={2}
                    size={{ xs: 12, sm: 4, md: 8 }}
                    sx={{
                      marginTop: { xs: "15px", md: "" },
                      display: "flex",
                      justifyContent: { xs: "flex-start", sm: "flex-end" },
                      alignItems: { xs: "flex-start", sm: "flex-end" },
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        color: " #001F3D",
                        background: "#ffffff",
                        padding: "10px 16px",
                        borderRadius: "10px",
                        border: "1px solid #000000",
                        fontSize: "14px",
                        fontWeight: "700",
                        height: "48px",
                      }}
                      onClick={() => setSelectedCustomer(null)}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        color: theme.palette.primary.contrastText,
                        background: theme.palette.primary.light,
                        padding: "10px 16px",
                        borderRadius: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        height: "48px",
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#374151",
                }}
              >
                Note :&nbsp;
                <Typography
                  component={"span"}
                  sx={{ color: "#6B7280", fontSize: "14px", fontWeight: "600" }}
                >
                  If customer not found click on one of the button to get
                  customer verified.
                </Typography>
                <ButtonComponent
                  sx={{
                    padding: 0,
                    marginLeft: 1,
                    color: "#1F3A91",
                    fontSize: "14px",
                    fontWeight: "700",
                    cursor: "pointer",
                    borderBottom: "1px solid #1F3A91",
                  }}
                  onClick={() => navigate("/acucheck/customers/new")}
                >
                  Add New Customer
                </ButtonComponent>
              </Typography>
            </Box>
          </Panel>
          <Panel
            sx={{
              padding: "20px",
              margin: "20px 0",
              borderRadius: "0px",
              width: "100%",
            }}
          >
            <ProfileHeader />
            <Grid container sx={{ marginTop: "20px" }} spacing={2}>
              <Grid size={{ xs: 12, lg: 8 }}>
                <Box
                  sx={{
                    border: "1px solid #D5E1F9",
                    background: "#F7FAFF",
                    boxShadow: "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                    padding: "20px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#374151",
                      fontWeight: "700",
                      fontSize: "16px",
                      marginBottom: "15px",
                    }}
                  >
                    Bank Account Details:
                  </Typography>
                  <Box
                    sx={{
                      background: "#FFFFFF",
                      borderRadius: "8px",
                      padding: "20px",
                      display: "flex",
                      justifyContent: "space-evenly",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: "700",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        $1,873.27
                      </Typography>
                      <Typography
                        sx={{
                          color: "#121828",
                          fontWeight: "400",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        Current Account Balance
                      </Typography>
                    </Box>
                    <Divider
                      orientation="vertical"
                      sx={{
                        border: "1px dashed #D5E1F9",
                        height: { xs: "", sm: "auto" },
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: "700",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        $1,873.27
                      </Typography>
                      <Typography
                        sx={{
                          color: "#121828",
                          fontWeight: "400",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        Monthly Income
                      </Typography>
                    </Box>
                    <Divider
                      orientation="vertical"
                      sx={{
                        border: "1px dashed #D5E1F9",
                        height: { xs: "", sm: "auto" },
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: "700",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        $1,873.27
                      </Typography>
                      <Typography
                        sx={{
                          color: "#121828",
                          fontWeight: "400",
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        Disposable Income
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, lg: 4 }}>
                <Box
                  sx={{
                    background: "#E9F1FF",
                    boxShadow: "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                    padding: "20px",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#FFFFFF",
                      borderRadius: "6px",
                      padding: "20px 30px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: { xs: "column", sm: "row" },
                      }}
                      gap={2}
                    >
                      <DTIIcon />
                      <Box>
                        <Typography
                          sx={{
                            color: "#0233D3",
                            fontWeight: "700",
                            fontSize: "34px",
                          }}
                        >
                          60%
                        </Typography>
                        <Typography
                          sx={{
                            color: "#121828",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          DTI Score
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(0, 0, 0, 0.6)",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        >
                          Mar 02,2023
                        </Typography>
                      </Box>
                    </Box>
                    <DTIArrow />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Panel>
        </Grid>
        <Panel sx={{ background: "#ffffff", padding: "20px", width: "100%" }}>
          <Typography
            sx={{ color: "#374151", fontWeight: "700", fontSize: "18px" }}
          >
            DTI Calculator
          </Typography>
          <Box sx={{ margin: { xs: "10px 0 0", lg: "20px 0" } }}>
            <Button
              sx={{
                background: activeButton === "amount" ? "#F0F5FF" : "#ffffff",
                color: activeButton === "amount" ? "#1F3A91" : "#374151",
                fontSize: "16px",
                fontWeight: activeButton === "amount" ? "700" : "600",
                padding: "12px 16px",
                borderRadius: "8px",
                marginRight: "20px",
                marginBottom: { xs: "10px", sm: "" },
              }}
              onClick={() => setActiveButton("amount")}
              startIcon={<DTIAmount />}
            >
              Amount
            </Button>
            <Button
              sx={{
                background:
                  activeButton === "threshold" ? "#F0F5FF" : "#ffffff",
                color: activeButton === "threshold" ? "#1F3A91" : "#374151",
                fontSize: "16px",
                fontWeight: activeButton === "threshold" ? "700" : "600",
                padding: "12px 16px",
                borderRadius: "8px",
                border: "1px solid #D3E1FD",
                marginBottom: { xs: "10px", sm: "" },
              }}
              onClick={() => setActiveButton("threshold")}
              startIcon={<DTIThreshold />}
            >
              DTI Threshold
            </Button>
          </Box>
          <Box sx={{ padding: "20px" }}>
            <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
              <Grid size={{ xs: 12, lg: 4, xl: 6 }}>
                <Box>
                  <Box>
                    <InputComponent
                      label="Enter Amount Owed"
                      isRequired={true}
                      backgroundColor="#ffffff"
                      value="$3456"
                    />
                  </Box>
                  <Box margin={"20px 0"}>
                    <InputComponent
                      label="Select or Enter Down Payment"
                      isRequired={true}
                      value="$800"
                      backgroundColor="#ffffff"
                    />
                  </Box>
                  <Box>
                    <SelectComponent
                      label="Installment Frequency"
                      required
                      options={options}
                      onChange={(newValue: any) => setSelectedOption(newValue)}
                      value={selectedOption}
                      backgroundColor="#ffffff !important"
                    />
                  </Box>
                </Box>
                <Box sx={{ margin: "20px 0" }} gap={2}>
                  {activeButton === "amount" && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        color: "#1F3A91",
                        background: "#ffffff",
                        padding: "10px 16px",
                        borderRadius: "10px",
                        border: "1px solid #1F3A91",
                        fontSize: "14px",
                        fontWeight: "700",
                        height: "48px",
                        marginRight: "20px",
                        marginBottom: { xs: "10px", md: "" },
                      }}
                    >
                      Save Results
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      color: theme.palette.primary.contrastText,
                      background: theme.palette.primary.light,
                      padding: "10px 16px",
                      borderRadius: "10px",
                      fontSize: "14px",
                      fontWeight: "700",
                      height: "48px",
                      marginBottom: { xs: "10px", md: "" },
                    }}
                  >
                    Calculate
                  </Button>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#374151",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Note : Click on this button to calculate the Amount Due ,
                    DTI Score & Installment recommendation.
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 12, lg: 8, xl: 6 }}>
                <Box
                  sx={{
                    border: "1px solid #E7F6F9",
                    background: "#E7F6F9",
                    borderRadius: "8px",
                    boxShadow: "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                    padding: "20px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#ffffff",
                      boxShadow: "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                      borderRadius: "8px",
                      padding: "20px 20px 0",
                    }}
                  >
                    {activeButton === "amount" ? (
                      <>
                        <Grid container>
                          <Grid
                            size={{ xs: 12, md: 8 }}
                            sx={{
                              borderRight: { xs: "", lg: "1px dashed #D5E1F9" },
                            }}
                          >
                            <Grid size={{ xs: 12, lg: 10 }}>
                              <InputComponent
                                label="Payment Installment"
                                isRequired={true}
                                value="26"
                                backgroundColor="#ffffff"
                              />
                            </Grid>
                          </Grid>
                          <Grid size={{ xs: 0, md: 0.5 }}></Grid>
                          <Grid
                            size={3.5}
                            sx={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginTop: { xs: "10px", sm: "" },
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  color: "#111928",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  whiteSpace: { xs: "nowrap", lg: "wrap" },
                                }}
                              >
                                Installment Amount
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#374151",
                                  fontSize: "20px",
                                  fontWeight: "700",
                                }}
                              >
                                $150
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box sx={{ margin: "20px 0" }}>
                          <Typography
                            sx={{
                              color: "#374151",
                              fontSize: "14px",
                              fontWeight: "700",
                            }}
                          >
                            Note :
                            <Typography
                              component={"span"}
                              sx={{
                                color: "#6B7280",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                            >
                              Based on entered months in the Payment
                              Installment, Installment Amount will be reflected.
                            </Typography>
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            marginBottom: "20px",
                            color: "#111928",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          DTI Score:
                        </Typography>
                        <Grid
                          container
                          spacing={1}
                          sx={{ marginBottom: "20px" }}
                        >
                          {itemStyles.map((item, index) => (
                            <Grid size={{ xs: 12, sm: 4 }} key={index}>
                              <Box
                                sx={{
                                  border: `1px solid ${item.borderColor}`,
                                  backgroundColor: item.backgroundColor,
                                  borderRadius: "8px",
                                  padding: "20px 10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: item.typographyColor,
                                    fontSize: "18px",
                                    fontWeight: "700",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.title}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: item.typographyColor,
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.subtitle}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                    <Divider
                      sx={{
                        border: "1px dashed #D5E1F9",
                        marginBottom: "20px",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                      }}
                      gap={2}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <DtiPie />
                      </Box>
                      <Divider
                        // orientation="vertical"
                        sx={{
                          border: "1px dashed #D5E1F9",
                        }}
                      />
                      <Box sx={{ marginBottom: { xs: "10px", md: "" } }}>
                        <Typography
                          sx={{
                            color: "#111928",
                            fontSize: "14px",
                            fontWeight: "600",
                            marginBottom: "20px",
                          }}
                        >
                          Suggested Plans:
                        </Typography>
                        <Box
                          sx={{
                            padding: { xs: "10px", md: "20px" },
                            background: "#E9F1FF",
                            borderRadius: "8px",
                          }}
                        >
                          <Box
                            sx={{
                              padding: "10px",
                              background: "#FFFFFF",
                              borderRadius: "8px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: { xs: "column", md: "row" },
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#0233D3",
                                fontSize: "34px",
                                fontWeight: "700",
                                marginRight: "10px",
                              }}
                            >
                              $150
                            </Typography>
                            <Box>
                              <Typography
                                sx={{
                                  color: "#121828",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                Payment Amount
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#01B6A8",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                Best Suggested
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Divider
              sx={{
                border: "1px dashed #D5E1F9",
                marginBottom: "20px",
              }}
            />
            <Box>
              <Typography
                sx={{
                  marginBottom: "20px",
                  color: "#111928",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
              >
                Calculated Results :
              </Typography>
              <Grid
                container
                spacing={1}
                sx={{
                  marginBottom: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {DTICalculations.map((item, index) => (
                  <Grid size={{ xs: 12, sm: 6, md: 4, xl: 2.4 }} key={index}>
                    <Box
                      sx={{
                        backgroundColor: item.backgroundColor,
                        borderRadius: "8px",
                        padding: "20px 10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: item.typographyColor,
                          fontSize: "16px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: item.typographyColor,
                          fontSize: "32px",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        {item.subtitle}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            {activeButton === "amount" && (
              <>
                <Divider
                  sx={{
                    border: "1px dashed #D5E1F9",
                    marginBottom: "20px",
                  }}
                />
                <Box
                  sx={{
                    padding: "20px",
                    border: "1px solid #D5E1F9",
                    background: "#F7FAFF",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#374151",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Saved Results
                  </Typography>
                  <Box
                    sx={{
                      padding: "20px",
                      marginTop: "20PX",
                      background: "#FFFFFF",
                      boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.04)",
                    }}
                  >
                    <SaveResultDTiTable rows={rows} columns={columns} />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Panel>
      </Grid>
    </Grid>
  );
};

export default DTICalculator;
