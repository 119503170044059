const SubscriptionIcon = () => {
    return (
        <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1309_32815)">
        <path d="M2.97919 0.5L5.55265 7.79192V7.78813L5.75631 8.36229C6.88731 11.563 7.4956 13.279 7.49885 13.2828C7.5021 13.2866 7.67219 13.2936 7.87802 13.3045C8.50419 13.3315 9.28094 13.402 9.86973 13.4724C9.93581 13.4832 10.0116 13.4892 10.0886 13.4892C10.1032 13.4892 10.1173 13.4892 10.1319 13.4886H10.1297L7.57794 6.25087L7.34177 5.58463L6.02931 1.87367C5.78015 1.1695 5.5689 0.569333 5.55806 0.546583L5.54235 0.500542H2.98244L2.97919 0.5Z" fill="#6B7280"/>
        <path d="M7.5893 0.5065L7.58389 3.38112L7.57955 6.25575L7.34285 5.58842L7.03572 11.9855C7.33635 12.8343 7.49722 13.2855 7.49885 13.2893C7.50047 13.2931 7.67218 13.3023 7.87801 13.312C8.50472 13.3391 9.28093 13.4095 9.87189 13.48C9.93797 13.4908 10.0138 13.4967 10.0913 13.4967C10.1059 13.4967 10.12 13.4968 10.1341 13.4962H10.1319C10.1389 13.4908 10.1427 10.5598 10.1411 6.98971L10.1357 0.505417H7.5893V0.5065ZM2.97918 0.5V6.99567C2.97918 10.568 2.98297 13.494 2.9873 13.4967C2.99164 13.4995 3.21264 13.4805 3.4786 13.4502C3.74455 13.4198 4.11235 13.3798 4.29651 13.3635C4.5771 13.3364 5.41668 13.2823 5.51418 13.2823C5.54235 13.2823 5.54451 13.136 5.5483 10.53L5.55264 7.77783L5.75847 8.352L5.82889 8.55567L6.13764 2.16292L6.03472 1.86717L5.5418 0.5H2.97864L2.97918 0.5Z" fill="#6B7280"/>
        </g>
        <defs>
        <clipPath id="clip0_1309_32815">
        <rect width="13" height="13" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
        </defs>
        </svg>
        
        
    );
  };
  
  export default SubscriptionIcon;
  