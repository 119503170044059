import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Grid2 as Grid,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Panel from "../../components/Panel";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import DownArrow from "../../assets/IconComponents/DownArrow";
import YearPicker from "../../components/form/YearPicker";
import CustomMonthPicker from "../../components/form/MonthPicker";
import PaidIcon from "../../assets/IconComponents/PaidIcon";
import InfoIcon from "../../assets/IconComponents/InfoIcon";
import DollarIcon from "../../assets/IconComponents/DollarIcon";
import RoundDollarIcon from "../../assets/IconComponents/RoundDollarIcon";
import ThreeDots from "../../assets/IconComponents/ThreeDots";
import UpArrow from "../../assets/IconComponents/UpArrow";
import TableEye from "../../assets/IconComponents/TableEye";

const InvoiceRecordsPage = (props: any) => {
  const [InvoiceRecordList, setInvoiceRecordList] = useState([
    {
      invoiceId: 345,
      totalReports: 180,
      invoiceDate: "07/06/2023",
      amount: 297.0,
      status: "Paid",
    },
    {
      invoiceId: 795,
      totalReports: 120,
      invoiceDate: "07/06/2023",
      amount: 198.0,
      status: "Paid",
    },
    {
      invoiceId: 605,
      totalReports: 155,
      invoiceDate: "07/06/2023",
      amount: 255.75,
      status: "Paid",
    },
    {
      invoiceId: 345,
      totalReports: 180,
      invoiceDate: "07/06/2023",
      amount: 297.0,
      status: "Paid",
    },
    {
      invoiceId: 795,
      totalReports: 120,
      invoiceDate: "07/06/2023",
      amount: 198.0,
      status: "Paid",
    },
    {
      invoiceId: 605,
      totalReports: 155,
      invoiceDate: "07/06/2023",
      amount: 255.75,
      status: "Paid",
    },
    {
      invoiceId: 345,
      totalReports: 180,
      invoiceDate: "07/06/2023",
      amount: 297.0,
      status: "Paid",
    },
    {
      invoiceId: 795,
      totalReports: 120,
      invoiceDate: "07/06/2023",
      amount: 198.0,
      status: "Paid",
    },
    {
      invoiceId: 605,
      totalReports: 155,
      invoiceDate: "07/06/2023",
      amount: 255.75,
      status: "Paid",
    },
  ]);

  const stats = [
    {
      title: "Total Invoice Monthly",
      value: 30,
      change: "up from 20 since last month",
      changeValue: "2.6%",
    },
    {
      title: "Total Paid",
      value: 24,
      change: "up from 12 since last month",
      changeValue: "2.6%",
    },
    {
      title: "Total Unpaid",
      value: 6,
      change: "up from 3 since last month",
      changeValue: "2.6%",
    },
  ];

  return (
    <Box sx={{ padding: 1 }}>
      <Grid container size={12} rowSpacing={2} spacing={2}>
        <Grid size={12} container spacing={2}>
          <Grid size={5}>
            <Typography variant="h5" fontWeight={700}>
              Invoice Record
            </Typography>
            <Typography>
              All Invoice related data can be tracked from here
            </Typography>
          </Grid>
          <Grid
            container
            size={7}
            spacing={2}
            display={"flex"}
            justifyContent={"space-around"}
          >
            <Grid size={3.5}>
              <CustomMonthPicker />
            </Grid>
            <Grid size={3.5}>
              <YearPicker />
            </Grid>
            <Grid size={2.5}>
              <Button
                sx={{
                  backgroundColor: "#fff",
                  color: "#1F2A37",
                  border: "1px solid #001F3D",
                }}
                fullWidth
              >
                Send Email
              </Button>
            </Grid>
            <Grid size={2.5}>
              <Button
                endIcon={<DownArrow />}
                sx={{ backgroundColor: "#274d8e", color: "white" }}
              >
                Download
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Box width={"100%"}>
          <Card sx={{ display: "flex", widows: "97%" }}>
            <CardContent sx={{ width: "33%" }}>
              <Box borderRight={"1px solid #E5E7EB"} paddingRight={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"}>
                    <DollarIcon />
                    <Typography sx={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "6B7280",
                      fontWeight: "500",
                    }}>
                      Total Invoice Monthly
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <InfoIcon />
                  </Box>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: "600", marginX: "5px", marginY: "10px" }}>
                  30
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                  <Button
                    size="medium"
                    startIcon={<UpArrow />}
                    sx={{
                      border: "1px solid #2E933C",
                      background: "#F2FBF3",
                      color: "#2E933C",
                    }}
                  >
                    2.6%
                  </Button>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "6B7280",
                      fontWeight: "500",
                    }}
                  >
                    up from 3 since last month
                  </Typography>
                </Box>
              </Box>
            </CardContent>
            <CardContent sx={{ width: "33%" }}>
              <Box borderRight={"1px solid #E5E7EB"} paddingRight={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"}>
                    <RoundDollarIcon />
                    <Typography sx={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "6B7280",
                      fontWeight: "500",
                    }}>
                      Total Invoice Monthly
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <InfoIcon />
                  </Box>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: "600", marginX: "5px", marginY: "10px" }}>
                  30
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                  <Button
                    size="medium"
                    startIcon={<UpArrow />}
                    sx={{
                      border: "1px solid #2E933C",
                      background: "#F2FBF3",
                      color: "#2E933C",
                    }}
                  >
                    2.6%
                  </Button>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "6B7280",
                      fontWeight: "500",
                    }}
                  >
                    up from 3 since last month
                  </Typography>
                </Box>
              </Box>
            </CardContent>

            <CardContent sx={{ width: "33%" }}>
              <Box paddingRight={2}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"}>
                    <ThreeDots />
                    <Typography sx={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "6B7280",
                      fontWeight: "500",
                    }}>
                      Total Invoice Monthly
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"}>
                    <InfoIcon />
                  </Box>
                </Box>
                <Typography variant="h4" sx={{ fontWeight: "600", marginX: "5px", marginY: "10px" }}>
                  30
                </Typography>
                <Box display={"flex"} alignItems={"center"}>
                  <Button
                    size="medium"
                    startIcon={<UpArrow />}
                    sx={{
                      border: "1px solid #2E933C",
                      background: "#F2FBF3",
                      color: "#2E933C",
                    }}
                  >
                    2.6%
                  </Button>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      marginLeft: "10px",
                      color: "6B7280",
                      fontWeight: "500",
                    }}
                  >
                    up from 3 since last month
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        {/* </Grid> */}
        <Grid size={12} container spacing={2}>
          <Panel sx={{ width: "100%" }}>
            <Box sx={{ padding: 1.5 }}>
              <Grid size={12} sx={{ marginY: 3 }}>
                <Grid container>
                  <Grid size={6}>
                    <Grid size={7}>
                      <OutlinedInput
                        fullWidth
                        placeholder="Search"
                        startAdornment={<SearchIcon />}
                      />
                    </Grid>
                  </Grid>
                  <Grid size={6} display={"flex"} justifyContent={"flex-end"}>
                    <ButtonGroup
                      variant="outlined"
                      aria-label="Basic button group"
                      sx={{ height: "53px" }}
                    >
                      <Button>
                        <Typography sx={{}} variant="caption">
                          All
                        </Typography>
                      </Button>
                      <Button>
                        <Typography sx={{}} variant="caption">
                          Paid
                        </Typography>
                      </Button>
                      <Button>
                        <Typography sx={{}} variant="caption">
                          Unpaid
                        </Typography>
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid size={12} rowSpacing={2} rowGap={5}>
                <Table>
                  <TableHead sx={{ backgroundColor: "#eef7ff" }}>
                    <TableRow>
                      <TableCell>Invoice ID</TableCell>
                      <TableCell>Total AcuCheck Reports</TableCell>
                      <TableCell>Invoice Date</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {InvoiceRecordList.map((element, index: number) => (
                      <TableRow key={`table_row_${index}`}>
                        <TableCell>
                          <Typography>{element.invoiceId}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{element.totalReports}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{element.invoiceDate}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{element.amount}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography><PaidIcon /></Typography>
                        </TableCell>
                        <TableCell>
                          <RouterLink to="/invoice/report">
                            <TableEye />
                          </RouterLink>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Box>
          </Panel>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceRecordsPage;
