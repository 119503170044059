import { Box, Tabs, Tab, Grid2 as Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Breadcrumbs, Typography, Link, useTheme } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link as RouterLink } from "react-router-dom";
import { DTISetting } from "../../assets/IconComponents/DTI/DTISetting";
import Panel from "../../components/Panel";
import Notification from "../../assets/IconComponents/Setting/Notification";
import IBALink from "../../assets/IconComponents/Setting/IBALink";

const BreadcrumbNavigation = () => {
  const theme = useTheme();
  const location = useLocation();

  const breadcrumbsMap: { [key: string]: string } = {
    "/settings": "IBA Link Settings",
    "/settings/IBA": "IBA",
    "/settings/notification": "Notification",
    "/settings/dti": "DTI Calculator",
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to="/acucheck/settings/dti"
      sx={{ width: "fit-content", display: "flex" }}
    >
      <DTISetting selected />
      <Typography sx={{ color: theme.palette.primary.main, marginLeft: 1 }}>
        {breadcrumbsMap[location.pathname]}
      </Typography>
    </Link>,
    <Typography
      key="2"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      {"Settings"}
    </Typography>,
  ];

  return (
    <Breadcrumbs
      separator={
        <>
          <NavigateNextIcon fontSize="small" />
          <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
        </>
      }
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

const SettingsLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const activeTab = location.pathname.includes("/dti")
  // ? 2
  // : location.pathname.includes("/notification")
  // ? 1
  // : location.pathname.includes("/IBA")
  // ? 0
  ? 0 : 0;


  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) navigate(`/acucheck/settings/IBA`);
    else if (newValue === 1) navigate(`/acucheck/settings/notification`);
    else if (newValue === 2) navigate(`/acucheck/settings/dti`);
  };

  return (
    <>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          <BreadcrumbNavigation />
        </Breadcrumbs>
      </Panel>
      <Box sx={{ marginBottom: "20px" }}>
        <Grid
          size={12}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography>
            Customize all settings here as per your convinience
          </Typography>
        </Grid>
      </Box>
      <Panel>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{
              ".Mui-selected": {
                color: "#264D8E !important",
                fontWeight: "700 !important",
                background: "linear-gradient(180deg, #EAEFFF 0%, #FFFFFF 100%)",
                minHeight: "56px !important",
                minWidth: " 100% !important",
                height: "56px !important",
                padding: "16px !important",
              },
              ".MuiTabs-flexContainer": {
                borderBottom: "1px solid #d5e1f9",
              },
              "MuiBox-root": {
                borderBottom: "none !important",
              },
              ".css-1gsv261": {
                borderBottom: "none",
              },
              ".MuiTab-root": {
                fontSize: "16px",
                fontWeight: "600",
                minWidth: "100% !important",
                minHeight: "56px !important",
                "*:first-of-type": {
                  marginRight: "8px",
                },
              },
              ".MuiTabs-indicator": {
                backgroundColor: "#264D8E",
              },
            }}
            value={activeTab}
            onChange={handleTabChange}
          >
            {/* <Tab label="IBA Link Settings" icon={<IBALink selected={activeTab === 0 ? true : false} />} iconPosition="start" />
            <Tab label="Notification Template Settings" icon={<Notification selected={activeTab === 1 ? true : false} />} iconPosition="start" /> */}
            <Tab label="DTI Score Settings" icon={<DTISetting selected={activeTab === 0 ? true : false} />} iconPosition="start" />
          </Tabs>
        </Box>
        <Box>{children}</Box>
      </Panel>
    </>
  );
};

export default SettingsLayout;
