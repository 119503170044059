const DeleteIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_436_68437)">
        <circle cx="27" cy="27" r="19" fill="white"/>
        </g>
        <g filter="url(#filter1_d_436_68437)">
        <rect x="12.375" y="12.375" width="29.25" height="29.25" rx="14.625" fill="#EFFFFE"/>
        <path d="M34 19H30.5L29.5 18H24.5L23.5 19H20V21H34M21 34C21 34.5304 21.2107 35.0391 21.5858 35.4142C21.9609 35.7893 22.4696 36 23 36H31C31.5304 36 32.0391 35.7893 32.4142 35.4142C32.7893 35.0391 33 34.5304 33 34V22H21V34Z" fill="#01B6A8"/>
        </g>
        <defs>
        <filter id="filter0_d_436_68437" x="0.6875" y="0.6875" width="54.875" height="54.875" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="0.5625" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_436_68437"/>
        <feOffset dx="1.125" dy="1.125"/>
        <feGaussianBlur stdDeviation="3.9375"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.24975 0 0 0 0 0.405 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_436_68437"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_436_68437" result="shape"/>
        </filter>
        <filter id="filter1_d_436_68437" x="5.0625" y="5.0625" width="46.125" height="46.125" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="0.5625" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_436_68437"/>
        <feOffset dx="1.125" dy="1.125"/>
        <feGaussianBlur stdDeviation="3.9375"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.24975 0 0 0 0 0.405 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_436_68437"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_436_68437" result="shape"/>
        </filter>
        </defs>
        </svg>
        
    );
  };
  
  export default DeleteIcon;