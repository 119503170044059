import React from "react";
import ReactDOM from "react-dom";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

interface DotProps {
  color: string;
}

const Dot = styled(Box, {
  shouldForwardProp: (prop) => prop !== "color",
})<DotProps>(({ color }) => ({
  width: "12px",
  height: "12px",
  borderRadius: "50%",
  backgroundColor: color,
  position: "absolute",
}));

interface CircularSpinnerProps {
  progress: number;
}

const CircularSpinner: React.FC<CircularSpinnerProps> = ({ progress }) => {
  const totalDots = 20;
  const activeDots = Math.round((progress / 100) * totalDots);
  const radius = 45;

  const positions = Array.from({ length: totalDots }, (_, index) => {
    const angle = (360 / totalDots) * index - 360;
    const top = `${50 - radius * Math.cos((angle * Math.PI) / 180)}%`;
    const left = `${50 + radius * Math.sin((angle * Math.PI) / 180)}%`;
    const color = index < activeDots ? "#1F3A91" : "#B3B3B3";
    return { top, left, color };
  });

  return ReactDOM.createPortal(
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      bgcolor="rgba(0, 0, 0, 0.7)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex={9999} 
    >
      <Box position="relative" display="inline-flex">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          width="120px"
          height="120px"
        >
          {positions.map((pos, index) => (
            <Dot
              key={index}
              color={pos.color}
              sx={{
                top: pos.top,
                left: pos.left,
              }}
            />
          ))}
          <Typography
            variant="h5"
            sx={{
              position: "absolute",
              top: "58%",
              left: "58%",
              transform: "translate(-58%, -58%)",
              color: "#FFFFFF !important",
              fontSize: "22px",
              fontWeight: "600",
            }}
          >
            {progress}%
          </Typography>
        </Box>
      </Box>
    </Box>,
    document.body 
  );
};

export default CircularSpinner;
