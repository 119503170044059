import React, { Fragment, useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import profileBackground from "../../assets/media/icons/profileBackground.png";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import AddressIcon from "../../assets/IconComponents/AddressIcon";
import Homework from "../../assets/IconComponents/Homework";
import Location from "../../assets/IconComponents/Location";
import MyLocation from "../../assets/IconComponents/MyLocation";
import PhoneIcon from "../../assets/IconComponents/PhoneIcon";
import EmailIcon from "../../assets/IconComponents/EmailIcon";
import BusinessIcon from "../../assets/IconComponents/BussinessIcon";
import { Controller, useForm } from "react-hook-form";
import InputComponent from "../../components/form/input";
import StoreIcon from "../../assets/IconComponents/StoreIcon";
import SelectComponent from "../../components/form/SelectComponent";
import {
  FormData,
  ProfileDetailRowProps,
  ProfileInformationProps,
  SectionProps,
} from "./types";
import { UpdateCustomer } from "../../api/customerAPI";
import { useAlertMessage } from "../../utils/useAlertMessage";
import { useNavigate, useParams } from "react-router-dom";
import EditModal from "../../components/form/EditModal";
import States from "../../assets/json/states.json";
import DriverLicense from "../../assets/IconComponents/DriverLicense";
import SsnNumber from "../../assets/IconComponents/SsnNumber";
import CountryIcon from "../../assets/IconComponents/Country";
import CalenderIcon from "../../assets/IconComponents/CalenderIcon";
import AccountNumberIcon from "../../assets/IconComponents/AccountNumberIcon";
import CircularSpinner from "../../components/Loader/CircularLoader";
import moment from "moment";
import { get } from "node:https";

const ProfileHeader = ({ userInfo }: any) => (
  <Box sx={{ position: "relative", textAlign: "center", width: "100%" }}>
    <Box
      component="img"
      src={profileBackground}
      alt="background"
      sx={{ width: "100%", height: "130px" }}
    />
    <Box
      sx={{
        background: "#142848",
        borderRadius: "50%",
        height: "175px",
        width: "175px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "20px solid #ffffff",
        position: "absolute",
        top: "50px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Typography
        sx={{ fontSize: "48px", fontWeight: "600", color: "#FFFFFF" }}
      >
        {(userInfo.firstName || "")[0]}
        {(userInfo.lastName || "")[0]}
      </Typography>
    </Box>
    <Box sx={{ marginTop: "90px", gap: "16px" }}>
      <Typography
        sx={{
          fontWeight: "800",
          fontSize: "30px",
          color: "#1E293B",
          textWrap: "wrap",
        }}
      >
        {userInfo.firstName} {userInfo.lastName}
      </Typography>
      <Typography
        sx={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}
      >
        E-Mail ID: {userInfo.email}
      </Typography>
    </Box>
  </Box>
);

const ProfileInformation: React.FC<ProfileInformationProps> = ({
  type,
  userInfo,
  getCustomerInfo
}: any) => {
  const customerDetails = [
    {
      icon: <UserIconGray />,
      label: "First Name",
      value: userInfo.firstName || "-",
    },
    {
      icon: <UserIconGray />,
      label: "Last Name",
      value: userInfo.lastName || "-",
    },
    {
      icon: <CalenderIcon />,
      label: "DOB",
      value: userInfo.dob
        ? new Date(userInfo.dob).toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
        : "-",
    },

    {
      icon: <DriverLicense />,
      label: "Driver’s License Number",
      value: userInfo.driversLicense || "-",
    },
    {
      icon: <SsnNumber />,
      label: "SSN Number(Last 4 digits)",
      value: userInfo.ssn || "-",
    },
  ];

  const businessDetails = [
    {
      icon: <BusinessIcon />,
      label: "Business Name",
      value: userInfo.companyName || "-",
    },
    {
      icon: <SsnNumber />,
      label: "EIN Number",
      value: userInfo.ein ? `XX-XXX${userInfo.ein.slice(-4)}` : "-",
    },
  ];
  const businessConcactDetails = [
    {
      icon: <UserIconGray />,
      label: "First Name",
      value: userInfo.firstName || "-",
    },
    {
      icon: <UserIconGray />,
      label: "Last Name",
      value: userInfo.lastName || "-",
    },
  ];

  const addressDetails = [
    {
      icon: <AddressIcon />,
      label: "Address Line 1",
      value: userInfo.addressLine1 || "-",
    },
    {
      icon: <AddressIcon />,
      label: "Address Line 2",
      value: userInfo.addressLine2 || "-",
    },
    { icon: <CountryIcon />, label: "Country", value: userInfo.country || "-" },
    { icon: <Homework />, label: "State", value: userInfo.state || "-" },
    { icon: <Location />, label: "City", value: userInfo.city || "-" },
    {
      icon: <MyLocation />,
      label: "Zip Code",
      value: userInfo.postalCode || "-",
    },
  ];

  const contactDetails = [
    {
      icon: <PhoneIcon />,
      label: "Phone Number",
      value: userInfo.phoneNumber || "-",
    },
    {
      icon: <EmailIcon />,
      label: "Email Address",
      value: userInfo.email || "-",
    },
  ];
  const employeeDetails = [
    {
      icon: <UserIconGray />,
      label: "Name of Employer",
      value: userInfo.employerName || "-",
    },
    {
      icon: <Homework />,
      label: "Pay Frequency",
      value: userInfo.payFrequency || "-",
    },
  ];
  const bankDetails = [
    {
      icon: <UserIconGray />,
      label: "Bank Name",
      value: userInfo.bankName || "-",
    },
    {
      icon: <AccountNumberIcon />,
      label: "Account Number",
      value: userInfo.bankAccNo || "-",
    },
    {
      icon: <SsnNumber />,
      label: "Routing Number",
      value: userInfo.routingNo || "-",
    },
  ];
  return (
    <Box sx={{ paddingX: "20px" }}>
      <ProfileHeader userInfo={userInfo} />
      <Section
        type={type}
        title="Customer Information"
        userInfo={userInfo}
        details={
          type === "Customer"
            ? [...customerDetails, ...addressDetails]
            : [...businessDetails, ...addressDetails]
        }
        getCustomerInfo={getCustomerInfo}
      />
      <Section
        userInfo={userInfo}
        type={type}
        title="Contact Information"
        details={
          type === "Customer"
            ? contactDetails
            : [...businessConcactDetails, ...contactDetails]
        }
        getCustomerInfo={getCustomerInfo}
      />

      <Section
        userInfo={userInfo}
        type={type}
        title={type === "Customer" ? "Employer Information" : ""}
        details={type === "Customer" ? employeeDetails : []}
        getCustomerInfo={getCustomerInfo}
      />
      <Section
        userInfo={userInfo}
        type={type}
        title="Bank Information"
        details={type === "Customer" ? bankDetails : [...bankDetails]}
        getCustomerInfo={getCustomerInfo}
      />
    </Box>
  );
};

const ProfileDetailRow: React.FC<ProfileDetailRowProps> = ({
  icon,
  label,
  value,
}) => (
  <FormInputCol>
    <Typography
      sx={{
        marginBottom: "20px",
        fontSize: "14px",
        fontWeight: "600",
        color: "#374151",
      }}
    >
      {label}
    </Typography>
    <Box sx={{ display: "flex" }}>
      <Box sx={{ marginX: "10px" }}>{icon}</Box>
      <Typography
        sx={{ fontSize: "16px", fontWeight: "400", color: "#6B7280" }}
      >
        {value}
      </Typography>
    </Box>
  </FormInputCol>
);

const countries = Object.keys(States).map((state) => ({
  value: state,
  label: state,
}));

const Section: React.FC<SectionProps> = ({
  title,
  details,
  type,
  userInfo,
  getCustomerInfo
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [cityList, setCityList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
   const { customerId }: any = useParams();

  useEffect(() => {
    if (userInfo.state) {
      onStateSelect(userInfo.state,type === "Customer" ? "customer" : "business");
    }
  }, [userInfo]);

  const onStateSelect = (state: string, userType: "customer" | "business") => {
    if (!state) return;
  
    const updatedCityList =
      (States as any)[state]?.map((city: string) => ({
        value: city,
        label: city,
      })) || [];
  
    setCityList(updatedCityList);
    const currentCity = userType === "customer" ? userInfo.city : userInfo.city;
  
    const isCurrentCityValid = updatedCityList.some(
      (city:any) => city.value === currentCity
    );
  
    if (isCurrentCityValid) {
      userType === "customer"
        ? setCustomerValue("customer.city", currentCity)
        : setBusinessValue("business.city", currentCity);
    } else {
      userType === "customer"
        ? setCustomerValue("customer.city", "")
        : setBusinessValue("business.city", "");
    }
  };

  const notification = useAlertMessage();

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    if (type === "Customer") {
      resetCustomerForm();
    } else {
      resetBusinessForm();
    }
  };

  const handleUpdate = () => {
    if (type === "Customer") {
      handleCustomerSubmit(onSubmit)();
    } else {
      handleBusinessSubmit(onSubmit)();
    }
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    setProgress(0);
    let customerObj;
    if (type === "Customer") {
      customerObj = {
        ...userInfo,
        id: userInfo.id,
        customerType: "Customer",
        firstName: data.customer?.firstName,
        lastName: data.customer?.lastName,
        phoneType: data.customer?.selectedPhone,
        phoneNumber: data.customer?.phoneNumber,
        email: data.customer?.email,
        addressLine1: data.customer?.address1,
        addressLine2: data.customer?.address2,
        city: data.customer?.city,
        state: data.customer?.state,
        postalCode: data.customer?.postalCode,
        userName: userInfo.userName,
        payFrequency: data.customer?.payFrequency,
        driversLicense: data.customer.driversLicense,
        dob: data.customer?.dob,
        ssn: data.customer?.ssn,
        country: data.customer?.country,
        bankName: data.customer?.bankName,
        bankAccNo: data.customer?.bankAccNo,
        routingNo: data.customer?.routingNo,
        employerName: data.customer?.employerName,
      };
    } else {
      customerObj = {
        id: userInfo.id,
        customerType: "Business",
        companyName: data.business?.companyName,
        firstName: data.business?.firstName,
        lastName: data.business?.lastName,
        phoneType: data.business?.selectedPhone,
        phoneNumber: data.business?.phoneNumber,
        email: data.business?.email,
        addressLine1: data.business?.address1,
        addressLine2: data.business?.address2,
        city: data.business?.city,
        state: data.business?.state,
        postalCode: data.business?.postalCode,
        payFrequency: data.business?.payFrequency,
        country: data.business?.country,
        bankName: data.business?.bankName,
        bankAccNo: data.business?.bankAccNo,
        routingNo: data.business?.routingNo,
        ein: data.business?.ein,
        employerName: data.business?.employerName,
      };
    }
    try {
      const timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const {data,status} = await UpdateCustomer(userInfo.id, customerObj);
      clearInterval(timer);
      setProgress(100);
      if (status === 200) {
        notification.successNotification(
          "Success",
          "Customer Updated successfully"
        );
       getCustomerInfo(customerId);
       handleClose();
      } else {
        notification.errorNotification(
          "Error",
          data?.content || "Error in updating customer"
        );
      }
    } catch (err) {
      notification.errorNotification(
        "Error",
        (err as any).response.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  const {
    control: customerControl,
    handleSubmit: handleCustomerSubmit,
    watch: watchCustomer,
    formState: { errors: customerErrors, isValid: isCustomerValid },
    reset: resetCustomerForm,
    trigger: triggerCustomerForm,
    clearErrors: clearCustomerErrors,
    setValue: setCustomerValue,
  } = useForm<FormData>({ mode: "onChange" });

  const {
    control: businessControl,
    handleSubmit: handleBusinessSubmit,
    watch: watchBusiness,
    formState: { errors: businessErrors, isValid: isBusinessValid },
    reset: resetBusinessForm,
    setValue: setBusinessValue,
  } = useForm<FormData>({ mode: "onChange" });

  useEffect(() => {
    const bankAccNo = watchCustomer("customer.bankAccNo");
    if (bankAccNo) {
      triggerCustomerForm("customer.bankName"); 
    } else {
      setCustomerValue("customer.bankName", "");
      clearCustomerErrors("customer.bankName"); 
    }
  }, [watchCustomer("customer.bankAccNo")]);

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      {title && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: "700", color: "#000000" }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#264D8E",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            EDIT DETAILS
          </Typography>
        </Box>
      )}
      {details.length !== 0 && (
        <Box
          sx={{ padding: "20px", background: "#F7FAFF", margin: "20px 0 0 0" }}
        >
          <Box
            sx={{ borderRadius: "4px", background: "#FFFFFF", padding: "20px" }}
          >
            {details.map((detail, index) => (
              <Fragment key={index}>
                {index % 2 === 0 && (
                  <FormInputRow>
                    <ProfileDetailRow
                      icon={detail.icon}
                      label={detail.label}
                      value={detail.value}
                    />
                    {details[index + 1] && (
                      <ProfileDetailRow
                        icon={details[index + 1].icon}
                        label={details[index + 1].label}
                        value={details[index + 1].value}
                      />
                    )}
                  </FormInputRow>
                )}
                {index < details.length - 2 && index % 2 === 0 && (
                  <Divider
                    sx={{
                      width: "100%",
                      border: "1px dashed #D5E1F9",
                      margin: "20px 0",
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Box>
        </Box>
      )}
      <EditModal
        open={open}
        onClose={handleClose}
        title={`Edit ${title}`}
        handleUpdate={handleUpdate}
        disabled={!!customerErrors.customer || !!businessErrors.business}
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #DBE9FE",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{ padding: "20px", background: "#F7FAFF", borderRadius: "6px" }}
          >
            {type === "Customer" && title === "Customer Information" && (
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.firstName"
                    control={customerControl}
                    defaultValue={userInfo?.firstName || ""}
                    render={({ field }) => (
                      <InputComponent
                        isRequired={true}
                        {...field}
                        icon={<UserIconGray />}
                        label="First Name"
                        placeholder="Enter first name"
                        value={userInfo?.firstName || ""}
                        disabled={true}
                        error={
                          customerErrors.customer?.firstName
                            ? customerErrors.customer.firstName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.lastName"
                    control={customerControl}
                    defaultValue={userInfo?.lastName || ""}
                    render={({ field }) => (
                      <InputComponent
                        isRequired={true}
                        {...field}
                        icon={<UserIconGray />}
                        label="Last Name"
                        placeholder="Enter last name"
                        value={userInfo?.lastName || ""}
                        disabled={true}
                        error={
                          customerErrors.customer?.lastName
                            ? customerErrors.customer.lastName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="customer.dob"
                    control={customerControl}
                    defaultValue={userInfo?.dob || ""}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<UserIconGray />}
                        label="DOB"
                        value={
                          userInfo?.dob
                            ? moment(userInfo.dob).format("MM/DD/YYYY") 
                            : "-"
                        }
                        disabled={true}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.address1"
                    control={customerControl}
                    defaultValue={userInfo?.addressLine1 || ""}
                    rules={{ required: "Address Line 1 is required" }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<AddressIcon />}
                        isRequired
                        label="Address Line 1 (Street Address)"
                        placeholder="Enter address"
                        error={
                          customerErrors.customer?.address1
                            ? customerErrors.customer.address1.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.address2"
                    control={customerControl}
                    defaultValue={userInfo?.addressLine2 || ""}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<StoreIcon />}
                        label="Address Line 2 (Post office number, Suite Number, etc)"
                        placeholder="Enter post office number, suite number, etc."
                        error={
                          customerErrors.customer?.address2
                            ? customerErrors.customer.address2.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.driversLicense"
                    control={customerControl}
                    defaultValue={userInfo?.driversLicense || ""}
                    rules={{
                      pattern: {
                        value: /^[a-zA-Z0-9]{4,13}$/,
                        message:
                          "Driver’s License must be 4-10 alphanumeric characters.",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<DriverLicense />}
                        label="Driver’s License Number"
                        placeholder="Enter Driver’s License Number"
                        error={
                          customerErrors.customer?.driversLicense
                            ? customerErrors.customer.driversLicense.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>

                <FormInputCol md={12}>
                  <Controller
                    name="customer.ssn"
                    control={customerControl}
                    defaultValue={userInfo?.ssn || ""}
                    rules={{
                      pattern: {
                        value: /^\d{4}$/, 
                        message: "SSN Number must be exactly 4 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<SsnNumber />}
                        maxLength={4}
                        label="SSN Number(Last 4 digits)"
                        placeholder="Enter SSN Number"
                        error={
                          customerErrors.customer?.ssn
                            ? customerErrors.customer.ssn.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.country"
                    control={customerControl}
                    defaultValue="USA"
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        isRequired={true}
                        icon={<CountryIcon />}
                        label="Country"
                        disabled={true}
                        error={
                          customerErrors.customer?.country
                            ? customerErrors.customer.country.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>

                <FormInputCol md={12}>
                  <Controller
                    name="customer.state"
                    control={customerControl}
                    defaultValue={userInfo?.state || ""}
                    render={({ field }) => {
                      const stateOptions = countries;
                      const selectedOption = stateOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={stateOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                            onStateSelect(newValue.value,"customer");
                          }}
                          label="State"
                          placeholder="Select State"
                          fullWidth={true}
                          icon={<Homework />}
                          error={
                            customerErrors.customer?.state
                              ? customerErrors.customer?.state.message
                              : null
                          }
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.city"
                    control={customerControl}
                    defaultValue={userInfo?.city || ""}
                    render={({ field }) => {
                      const cityOptions = cityList;
                      const selectedOption = cityOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={cityOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                           
                          }}
                          icon={<Location />}
                          label="City"
                          placeholder="Select City"
                          error={
                            customerErrors.customer?.city
                              ? customerErrors.customer?.city.message
                              : null
                          }
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.postalCode"
                    control={customerControl}
                    defaultValue={userInfo?.postalCode || ""}
                    rules={{
                      required: "Zip Code is required",
                      pattern: {
                        value: /^[0-9]{5}$/,
                        message:
                          "Zip Code must be exactly 5 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        isRequired={true}
                        {...field}
                        maxLength={5}
                        icon={<MyLocation />}
                        label="Zip Code"
                        placeholder="Enter zip code"
                        error={
                          customerErrors.customer?.postalCode
                            ? customerErrors.customer?.postalCode.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}

            {type === "Customer" && title === "Contact Information" && (
              <FormInputRow>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Controller
                    name="customer.phoneNumber"
                    control={customerControl}
                    defaultValue={userInfo?.phoneNumber || ""}
                    rules={{
                      required: "Phone Number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Phone Number must be exactly 10 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        maxLength={10}
                        minLength={10}
                        label="Phone Number"
                        icon={<PhoneIcon />}
                        placeholder="Enter phone number"
                        error={
                          customerErrors.customer?.phoneNumber
                            ? customerErrors.customer?.phoneNumber.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Divider sx={{ border: "1px dashed #D5E1F9" }} />
                </FormInputCol>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Controller
                    name="customer.email"
                    control={customerControl}
                    defaultValue={userInfo?.email || ""}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Enter a valid email address",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Email Address"
                        icon={<EmailIcon />}
                        placeholder="Enter email"
                        disabled={true}
                        error={
                          customerErrors.customer?.email
                            ? customerErrors.customer?.email.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}

            {type === "Customer" && title === "Employer Information" && (
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.employerName"
                    control={customerControl}
                    defaultValue={userInfo?.employerName || ""}
                    rules={{
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message:
                          "Name of Employer can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<UserIconGray />}
                        label="Name of Employer"
                        placeholder="Enter name of employer"
                        error={
                          customerErrors.customer?.employerName
                            ? customerErrors.customer.employerName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.payFrequency"
                    control={customerControl}
                    defaultValue={userInfo?.payFrequency || ""}
                    render={({ field }) => {
                      const payFrequencyOptions = [
                        { value: "Weekly", label: "Weekly" },
                        { value: "Bi-Weekly", label: "Bi-Weekly" },
                        { value: "Monthly", label: "Monthly" },
                        { value: "Bi-Monthly", label: "Bi-Monthly" },
                      ];
                      const selectedOption = payFrequencyOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={payFrequencyOptions}
                          value={selectedOption}
                          onChange={(newValue) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                          }}
                          label="Pay Frequency"
                          fullWidth={true}
                          error={
                            customerErrors.customer?.payFrequency
                              ? customerErrors.customer?.payFrequency.message
                              : null
                          }
                        />
                      );
                    }}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {type === "Customer" && title === "Bank Information" && (
              <FormInputRow>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Controller
                    name="customer.bankName"
                    control={customerControl}
                    defaultValue={userInfo?.bankName || ""}
                    rules={{
                      required: {
                        value: !!watchCustomer("customer.bankAccNo"),
                        message:
                          "Bank Name is required when Account Number is entered",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message:
                          "Bank Name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Bank Name"
                        icon={<UserIconGray />}
                        placeholder="Enter bank name"
                        error={
                          customerErrors.customer?.bankName
                            ? customerErrors.customer?.bankName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.bankAccNo"
                    control={customerControl}
                    defaultValue={userInfo?.bankAccNo || ""}
                    rules={{
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Account Number can only contain numbers",
                      },
                      minLength: {
                        value: 6,
                        message: "Account Number must be at least 6 digits long",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        minLength={6}
                        maxLength={17}
                        icon={<AccountNumberIcon />}
                        label="Account Number"
                        placeholder="Enter account number"
                        error={
                          customerErrors.customer?.bankAccNo
                            ? customerErrors.customer.bankAccNo.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.routingNo"
                    control={customerControl}
                    defaultValue={userInfo?.routingNo || ""}
                    rules={{
                      pattern: {
                        value: /^[0-9]{9}$/,
                        message:
                          "Routing Number must contain exactly 9 numbers",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        icon={<SsnNumber />}
                        maxLength={9}
                        label="Routing Number"
                        placeholder="Enter routing number"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}

            {type === "Business" && title === "Customer Information" && (
              <FormInputRow>
                <FormInputCol  md={12} xl={12}>
                  <Controller
                    name="business.companyName"
                    control={businessControl}
                    defaultValue={userInfo?.companyName || ""}
                    rules={{
                      required: "Buiness name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Buiness name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        isRequired={true}
                        icon={<BusinessIcon />}
                        label="Business Name"
                        placeholder="Enter business name"
                        disabled
                        error={
                          businessErrors.business?.companyName
                            ? businessErrors.business.companyName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                            <Controller
                              name="business.ein"
                              control={businessControl}
                              defaultValue={userInfo?.ein|| ""}
                              rules={{
                                pattern: {
                                  value: /^[0-9]{9}$/,
                                  message: "EIN must contain exactly 9 numbers",
                                },
                              }}
                              render={({ field, fieldState: { error } }) => (
                                <InputComponent
                                  {...field}
                                  maxLength={9}
                                  icon={<SsnNumber />}
                                  label="EIN"
                                  placeholder="Enter EIN"
                                  error={error?.message}
                                />
                              )}
                            />
                          </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="business.address1"
                    control={businessControl}
                    defaultValue={userInfo?.addressLine1 || ""}
                    rules={{ required: "Address Line 1 is required" }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        isRequired={true}
                        icon={<AddressIcon />}
                        label="Address Line 1 (Street Address)"
                        placeholder="Enter address"
                        error={
                          businessErrors.business?.address1
                            ? businessErrors.business.address1.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="business.address2"
                    control={businessControl}
                    defaultValue={userInfo?.addressLine2 || ""}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<StoreIcon />}
                        label="Address Line 2 (Post office number, Suite Number, etc)"
                        placeholder="Enter post office number, suite number, etc."
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.country"
                    control={customerControl}
                    defaultValue="USA"
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<CountryIcon />}
                        label="Country"
                        disabled={true}
                        isRequired={true}
                        error={
                          customerErrors.business?.country
                            ? customerErrors.business?.country.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.state"
                    control={businessControl}
                    defaultValue={userInfo?.state || ""}
                    render={({ field }) => {
                      const stateOptions = countries;
                      const selectedOption = stateOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={stateOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                             onStateSelect(newValue.value,"business");
                          }}
                          label="State"
                          placeholder="Select State"
                          fullWidth={true}
                          icon={<Homework />}
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.city"
                    control={businessControl}
                    defaultValue={userInfo?.city || ""}
                    render={({ field }) => {
                      const cityOptions = cityList;
                      const selectedOption = cityOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={cityOptions}
                          value={selectedOption || null}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                          }}
                          icon={<Location />}
                          label="City"
                          placeholder="Select City"
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.postalCode"
                    control={businessControl}
                    defaultValue={userInfo?.postalCode || ""}
                    rules={{
                      required: "Zip Code is required",
                      pattern: {
                        value: /^[0-9]{5}$/,
                        message:
                          "Zip Code must be exactly 5 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        maxLength={5}
                        minLength={5}
                        isRequired={true}
                        icon={<MyLocation />}
                        label="Zip Code"
                        placeholder="Enter zip code"
                        error={
                          businessErrors.business?.postalCode
                            ? businessErrors.business?.postalCode.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {type === "Business" && title === "Contact Information" && (
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="business.firstName"
                    control={businessControl}
                    defaultValue={userInfo?.firstName || ""}
                    rules={{
                      required: "First name is required",
                      pattern: {
                        value: /^[A-Za-z]+$/i,
                        message: "First name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<UserIconGray />}
                        isRequired={true}
                        label="First Name"
                        placeholder="Enter first name"
                        disabled={true}
                        error={
                          businessErrors.business?.firstName
                            ? businessErrors.business.firstName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.lastName"
                    control={businessControl}
                    defaultValue={userInfo?.lastName || ""}
                    rules={{
                      required: "Last name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Last name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<UserIconGray />}
                        label="Last Name"
                        isRequired={true}
                        placeholder="Enter last name"
                        disabled={true}
                        error={
                          businessErrors.business?.lastName
                            ? businessErrors.business.lastName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Controller
                    name="business.phoneNumber"
                    control={businessControl}
                    defaultValue={userInfo?.phoneNumber || ""}
                    rules={{
                      required: "Phone Number is required",
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Phone Number must be exactly 10 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Phone Number"
                        isRequired={true}
                        maxLength={10}
                        minLength={10}
                        icon={<PhoneIcon />}
                        placeholder="Enter phone number"
                        error={
                          businessErrors.business?.phoneNumber
                            ? businessErrors.business?.phoneNumber.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Divider sx={{ border: "1px dashed #D5E1F9" }} />
                </FormInputCol>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Controller
                    name="business.email"
                    control={businessControl}
                    defaultValue={userInfo?.email || ""}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Enter a valid email address",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Email Address"
                        icon={<EmailIcon />}
                        isRequired={true}
                        placeholder="Enter Email"
                        disabled={true}
                        error={
                          businessErrors.business?.email
                            ? businessErrors.business?.email.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {type === "Business" && title === "Bank Information" && (
              <FormInputRow>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Controller
                    name="business.bankName"
                    control={businessControl}
                    defaultValue={userInfo?.bankName || ""}
                    rules={{
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Only alphabets are allowed",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        label="Bank Name"
                        icon={<UserIconGray />}
                        placeholder="Enter bank name"
                        error={
                          businessErrors.business?.bankName
                            ? businessErrors.business?.bankName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.bankAccNo"
                    control={businessControl}
                    defaultValue={userInfo?.bankAccNo || ""}
                    rules={{
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Account Number can only contain numbers",
                      },
                      minLength: {
                        value: 6,
                        message: "Account Number must be at least 6 digits long",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        minLength={7}
                        maxLength={17}
                        icon={<AccountNumberIcon />}
                        label="Account Number"
                        placeholder="Enter account number"
                        error={
                          businessErrors.business?.bankAccNo
                            ? businessErrors.business?.bankAccNo.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={6}>
                  <Controller
                    name="business.routingNo"
                    control={businessControl}
                    defaultValue={userInfo?.routingNo || ""}
                    rules={{
                      pattern: {
                        value: /^[0-9]{9}$/,
                        message:
                          "Routing Number must contain exactly 9 numbers",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        maxLength={9}
                        icon={<SsnNumber />}
                        label="Routing Number"
                        placeholder="Enter rounting number"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
          </Box>
        </Box>
      </EditModal>
      {loading && <CircularSpinner progress={progress} />}
    </Box>
  );
};

export default ProfileInformation;
