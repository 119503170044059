interface IconProps {
    selected?: boolean;
  }
  
  const Edit = ({ selected }: IconProps) => {
    return (
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="21" cy="21" r="21" fill="#F1F4FF"/>
        <path d="M19 23.0005V20.3855L27.944 11.4415C28.0547 11.3308 28.1707 11.2541 28.292 11.2115C28.4133 11.1688 28.5417 11.1478 28.677 11.1485C28.803 11.1485 28.9257 11.1698 29.045 11.2125C29.1643 11.2551 29.273 11.3251 29.371 11.4225L30.483 12.5005C30.5897 12.6111 30.6703 12.7328 30.725 12.8655C30.7797 12.9981 30.8073 13.1315 30.808 13.2655C30.8087 13.3995 30.7883 13.5268 30.747 13.6475C30.707 13.7675 30.6317 13.8825 30.521 13.9925L21.52 23.0005H19ZM28.466 14.6465L29.813 13.2855L28.702 12.1155L27.315 13.4965L28.466 14.6465ZM14.615 29.0005C14.1543 29.0005 13.77 28.8465 13.462 28.5385C13.154 28.2305 13 27.8458 13 27.3845V14.6165C13 14.1558 13.1543 13.7715 13.463 13.4635C13.7717 13.1555 14.156 13.0011 14.616 13.0005H23.002L16.616 19.3875V25.3855H22.512L29 18.8955V27.3845C29 27.8445 28.846 28.2288 28.538 28.5375C28.23 28.8461 27.8453 29.0005 27.384 29.0005H14.615Z" fill="#1F3A91"/>
        </svg>
    );
  };
  
  export default Edit;
  