import React, { ChangeEvent } from "react";
import { styled } from "@mui/material/styles";
import { Switch, Box, Typography, SwitchProps } from "@mui/material";

interface ToggleSwitchProps {
  label?: string;
  labelInactive?: string;
  labelActive?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void; 
  isRequired?: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  label,
  labelInactive,
  labelActive,
  checked,
  onChange,
  isRequired,
}) => {
  return (
    <>
      {label && (
        <Typography
          sx={{
            fontSize: "14px",
            color: "#374151",
            fontWeight: "600",
            marginBottom: "10px",
          }}
        >
          {label}{" "}
          {isRequired && (
            <span
              style={{
                color: "#FF220C",
                fontWeight: 400,
                fontSize: "14px", 
              }}
            >
              *
            </span>
          )}
        </Typography>
      )}
      <Box display="flex" alignItems="center">
        <Typography
          variant="body1"
          sx={{
            fontSize: "13px",
            color: "#4a4a4a",
            fontWeight: "600",
            marginRight: "12px",
          }}
        >
          {labelInactive}
        </Typography>
        <StyledSwitch
          checked={checked}
          onChange={(e) => onChange && onChange(e.target.checked)}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "13px",
            color: "#4a4a4a",
            fontWeight: "600",
            marginLeft: "12px",
          }}
        >
          {labelActive}
        </Typography>
      </Box>
    </>
  );
};

const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#01B6A8" : "#01B6A8",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 22,
    height: 20,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default ToggleSwitch;
