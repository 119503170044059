import AxiosInstance from "./http";

export interface UserQuery {
  pageNumber: number;
  pageSize: number;
  orderBy?: string;
  order?: string;
  status?: string;
  nameQuery?: string;
}

export interface FormState {
  year?:string;
  clientId?:string;
  name?: string;
  profession?: string;
  income?: string;
  age?: string;
  gender?: string;
  savinghabit?: string;
  spendinghabit?: string;
  medicalCondition?: string;
  familyType?: string;
  housing?: string;
  livingStyle?: string;
  location?: string;
  lifestylePreference?: string;
  subscription?: string;
  transport?: string;
  utilities?: string;
}
export interface MultiFormState {
  clientId?:string;
  years?:string;
  reportCount?:number;
  maxamount?:string;
  minamount?:string;
}

export async function GenerateSimulation(formState: FormState): Promise<any> {
  try {
    console.log("Final formState being sent to API:", formState);
    const response = await AxiosInstance.post("/Simulator/run", {
      // command: "GenerateSimulation",
      numberOfYears:formState.year ?? "",
      clientId:formState.clientId ?? "",
      name: formState.name ?? "",
      profession: formState.profession ?? "",
      income: formState.income ?? "",
      age: formState.age ?? "",
      gender: formState.gender ?? "",
      savingHabit: formState.savinghabit ?? "",
      spendingHabit: formState.spendinghabit ?? "",
      medicalConditions: formState.medicalCondition ?? "",
      familyType: formState.familyType ?? "",
      housing: formState.housing ?? "",
      location: formState.location ?? "",
      habits: formState.lifestylePreference ?? "",  
      subscription: formState.subscription ?? "",
      transport: formState.transport ?? "",
      livingStyle:formState.livingStyle??"",
      lifestylePreferences:formState.lifestylePreference ?? "",
      utilities: formState.utilities ?? "",
    });
    return response;
  } catch (err) {
    console.error("Error:", err);
    throw err;
  }
}

export async function MultiSimulation(multiFormState: MultiFormState): Promise<any> {
  try {
    const response = await AxiosInstance.post("/Simulator/run-multiple", {
      command: "MultiReportGenerateSimulation",
      clientId:multiFormState.clientId ?? "",
      numOfPeople: multiFormState.reportCount ?? "",
      numOfYears: multiFormState.years ?? "",
      incomeRangeMin: multiFormState.minamount ?? "",
      incomeRangeMax: multiFormState.maxamount ?? "",
    });

    return response;
  } catch (err) {
    console.error("Error:", err);
    throw err;
  }
}

export async function GetAllTenantNames(): Promise<any> {
  try {
    const response = await AxiosInstance.get("/Tenant/getAllTenantNames");
      return response
  } catch (err) {
    console.error("Error fetching tenants:", err);
    return []; 
  }
}
