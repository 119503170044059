const Payment = () => {
  return (
    <svg width="44" height="46" viewBox="0 0 44 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="46" rx="8" fill="#F1F4FF"/>
    <path d="M22 10.5C15.1 10.5 9.5 16.1 9.5 23C9.5 29.9 15.1 35.5 22 35.5C28.9 35.5 34.5 29.9 34.5 23C34.5 16.1 28.9 10.5 22 10.5ZM23.7625 30.6125V33H20.425V30.5875C18.2875 30.1375 16.475 28.7625 16.3375 26.3375H18.7875C18.9125 27.65 19.8125 28.675 22.1 28.675C24.55 28.675 25.1 27.45 25.1 26.6875C25.1 25.65 24.55 24.675 21.7625 24.0125C18.6625 23.2625 16.5375 21.9875 16.5375 19.425C16.5375 17.275 18.275 15.875 20.425 15.4125V13H23.7625V15.4375C26.0875 16 27.25 17.7625 27.325 19.675H24.875C24.8125 18.2875 24.075 17.3375 22.1 17.3375C20.225 17.3375 19.1 18.1875 19.1 19.3875C19.1 20.4375 19.9125 21.125 22.4375 21.775C24.9625 22.425 27.6625 23.5125 27.6625 26.6625C27.65 28.95 25.9375 30.2 23.7625 30.6125Z" fill="#1F3A91"/>
    </svg>
    
  )
}

export default Payment