import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import Groups2Icon from '@mui/icons-material/Groups2';


const AdminMenus: any = [
    // {
    //     label: 'Admin Dashboard',
    //     icon: DashboardIcon,
    //     to: '/admin/dashboard',
    //     breadcrump: false
    // },
    {
        label: 'Clients',
        icon: Groups2Icon,
        to: '/admin/client',
        breadcrump: false
    },
    {
        label: 'Billing',
        icon: ReceiptLongIcon,
        to: '/admin/billing',
        breadcrump: false
    },
    {
        label: 'Data Simulator',
        icon: DashboardIcon,
        to: '/admin/datasimulator',
        breadcrump: false
    },
    // {
    //     label: 'Banks',
    //     icon: AccountBalanceIcon,
    //     to: '/admin/bank',
    //     breadcrump: false
    // },
    // {
    //     label: 'Client Report',
    //     icon: ArticleIcon,
    //     to: '/admin/report',
    //     breadcrump: false
    // },
    // {
    //     label: 'Users',
    //     icon: PersonIcon,
    //     to: '/admin/users',
    //     breadcrump: false
    // },
    // {
    //     label: 'Activity Logs',
    //     icon: ReceiptLongIcon,
    //     to: '/admin/activity-logs',
    //     breadcrump: false
    // },
    // {
    //     label: 'Settings',
    //     icon: SettingsIcon,
    //     to: '/admin/settings',
    //     breadcrump: false
    // },
];


export default AdminMenus;