const LockIcon = () => {
    return (
        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_436_56460)">
        <rect x="9" y="9.1875" width="35.625" height="35.625" rx="17.8125" fill="#FAEFFF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M21.75 25.5413V24C21.75 22.6573 22.2834 21.3697 23.2328 20.4203C24.1822 19.4709 25.4698 18.9375 26.8125 18.9375C28.1552 18.9375 29.4428 19.4709 30.3922 20.4203C31.3416 21.3697 31.875 22.6573 31.875 24V25.5413C32.7112 25.6035 33.255 25.761 33.6532 26.1592C34.3125 26.8177 34.3125 27.879 34.3125 30C34.3125 32.121 34.3125 33.1823 33.6532 33.8407C32.9947 34.5 31.9335 34.5 29.8125 34.5H23.8125C21.6915 34.5 20.6302 34.5 19.9717 33.8407C19.3125 33.1823 19.3125 32.121 19.3125 30C19.3125 27.879 19.3125 26.8177 19.9717 26.1592C20.3692 25.761 20.9137 25.6035 21.75 25.5413ZM22.875 24C22.875 22.9557 23.2898 21.9542 24.0283 21.2158C24.7667 20.4773 25.7682 20.0625 26.8125 20.0625C27.8568 20.0625 28.8583 20.4773 29.5967 21.2158C30.3352 21.9542 30.75 22.9557 30.75 24V25.503C30.463 25.5005 30.1505 25.4995 29.8125 25.5H23.8125C23.474 25.4995 23.1615 25.5005 22.875 25.503V24Z" fill="#A869C9"/>
        </g>
        <defs>
        <filter id="filter0_d_436_56460" x="0.0937504" y="0.28125" width="56.1779" height="56.1779" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="0.685096" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_436_56460"/>
        <feOffset dx="1.37019" dy="1.37019"/>
        <feGaussianBlur stdDeviation="4.79567"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.24975 0 0 0 0 0.405 0 0 0 0.2 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_436_56460"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_436_56460" result="shape"/>
        </filter>
        </defs>
        </svg>
  
    )
  }
  
  export default LockIcon