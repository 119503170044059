const DTIAmount = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#8064FF"/>
    <path d="M8.99862 11.3356C10.2853 11.3356 11.3319 10.2889 11.3319 9.00226C11.3319 7.7156 10.2853 6.66893 8.99862 6.66893C7.71195 6.66893 6.66528 7.7156 6.66528 9.00226C6.66528 10.2889 7.71195 11.3356 8.99862 11.3356ZM8.99862 8.00226C9.55195 8.00226 9.99862 8.44893 9.99862 9.00226C9.99862 9.5556 9.55195 10.0023 8.99862 10.0023C8.44528 10.0023 7.99862 9.5556 7.99862 9.00226C7.99862 8.44893 8.44528 8.00226 8.99862 8.00226Z" fill="white"/>
    <path d="M16.3919 6.66602L6.66695 16.391L7.60975 17.3338L17.3347 7.60882L16.3919 6.66602Z" fill="white"/>
    <path d="M14.9986 12.6689C13.7119 12.6689 12.6653 13.7156 12.6653 15.0023C12.6653 16.2889 13.7119 17.3356 14.9986 17.3356C16.2853 17.3356 17.3319 16.2889 17.3319 15.0023C17.3319 13.7156 16.2853 12.6689 14.9986 12.6689ZM14.9986 16.0023C14.4453 16.0023 13.9986 15.5556 13.9986 15.0023C13.9986 14.4489 14.4453 14.0023 14.9986 14.0023C15.5519 14.0023 15.9986 14.4489 15.9986 15.0023C15.9986 15.5556 15.5519 16.0023 14.9986 16.0023Z" fill="white"/>
    </svg>
    
  )
}

export default DTIAmount