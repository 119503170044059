import AxiosInstance from './http';

export async function GetCustomers(query: any): Promise<any> {
    try {

        if (query.orderBy) {
            query.order = query?.order?.toUpperCase();
            query.orderBy = query.orderBy;
        }

        if (query.customerType === 'all') {
            delete query.customerType;
        }
        const response = await AxiosInstance.get('/Customer/all', {
            params: query
        });
        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};


export async function GetCustomerListPagination(query: any): Promise<any> {
    try {
        const response = await AxiosInstance.post('/Customer/List', query);

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
}

export async function GetCustomerByCustomerID(customerId: string): Promise<any> {
    try {
        const response = await AxiosInstance.get(`/Customer/${customerId}`);
        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

export async function CreateCustomer(customerObj: any): Promise<any> {
    try {
        const response = await AxiosInstance.post('/Customer', {
            "command": "CreateCustomer",
            "UserName": customerObj.userName ?? "drs",
            "Password": customerObj.password ?? "cashedge",
            "CompanyName": customerObj.companyName,
            "CustomerType": customerObj.customerType,
            "LastName": customerObj.lastName,
            "FirstName": customerObj.firstName,
            "PhoneType": customerObj.phoneType,
            "PhoneNumber": customerObj.phoneNumber,
            "Email": customerObj.email,
            "AddressLine1": customerObj.addressLine1,
            "AddressLine2": customerObj.addressLine2,
            "City": customerObj.city,
            "State": customerObj.state,
            "PostalCode": customerObj.postalCode,
            "employer": customerObj.employer ?? "yes",
            "Occupation": customerObj.Occupation ?? "Salaried",
            "PayFrequency": customerObj.PayFrequency ?? "",
            "DriversLicense": customerObj.driversLicense || '',
            "SSN": customerObj.ssn || '',
            "Country": customerObj.country ?? "USA",
            "DOB": customerObj.dob || null,
            "BankName": customerObj.bankName,
            "BankAccNo": customerObj.bankAccNo,
            "RoutingNo": customerObj.routingNo,
            "EIN": customerObj.ein,
            "EmployerName": customerObj.employerName || ''
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

export async function UpdateCustomer(customerId: string, customerObj: any): Promise<any> {
    try {
        const response = await AxiosInstance.put(`/Customer/${customerId}`, {
            Id: customerId,
            "UserName": customerObj.userName,
            "Password": customerObj.password,
            "CompanyName": customerObj.companyName,
            "CustomerType": customerObj.customerType,
            "LastName": customerObj.lastName,
            "FirstName": customerObj.firstName,
            "PhoneType": customerObj.phoneType,
            "PhoneNumber": customerObj.phoneNumber,
            "Email": customerObj.email,
            "AddressLine1": customerObj.addressLine1,
            "AddressLine2": customerObj.addressLine2,
            "City": customerObj.city,
            "State": customerObj.state,
            "PostalCode": customerObj.postalCode,
            "employer": customerObj.employer,
            "Occupation": customerObj.occupation,
            "PayFrequency": customerObj.payFrequency,
            "DriversLicense": customerObj.driversLicense,
            "SSN": customerObj.ssn,
            "Country": customerObj.country,
            "DOB": customerObj.dob,
            "BankName": customerObj.bankName,
            "BankAccNo": customerObj.bankAccNo,
            "RoutingNo": customerObj.routingNo,
            "EIN": customerObj.ein,
            "EmployerName": customerObj.employerName
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};
