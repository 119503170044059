
import DashboardIcon from '@mui/icons-material/Dashboard';
import PieChartIcon from '@mui/icons-material/PieChart';
import Person3Icon from '@mui/icons-material/Person3';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Calculate } from '@mui/icons-material';

const AcuCheckMenus: any = [
    // {
    //     label: 'Client Dashboard',
    //     icon: DashboardIcon,
    //     to: '/acucheck/dashboard',
    //     breadcrump: false
    // },
    {
        label: 'AcuCheck Report',
        icon: PieChartIcon,
        to: '/acucheck/report',
        breadcrump: false
    },
    {
        label: 'Customers',
        icon: Person3Icon,
        to: '/acucheck/customers',
        breadcrump: false
    },
    {
        label: 'DTI Calculator',
        icon: Calculate,
        to: '/acucheck/dti',
        breadcrump: false
    },
    // {
    //     label: 'Invoice Records',
    //     icon: RequestQuoteIcon,
    //     to: '/acucheck/invoice',
    //     breadcrump: false
    // },
    {
        label: 'Users',
        icon: AssignmentIndIcon,
        to: '/acucheck/users',
        breadcrump: false
    },
    // {
    //     label: 'Activity Logs',
    //     icon: TextSnippetIcon,
    //     to: '/acucheck/activity-logs',
    //     breadcrump: false
    // },
    // {
    //     label: 'Connect',
    //     icon: ChatIcon,
    //     to: '/acucheck/connect',
    //     breadcrump: false
    // },
    {
        label: 'Settings',
        icon: SettingsSuggestIcon,
        to: '/acucheck/settings/dti',
        breadcrump: false
    }
];


export default AcuCheckMenus;