import DashboardIcon from '@mui/icons-material/Dashboard';
import PieChartIcon from '@mui/icons-material/PieChart';
import Person3Icon from '@mui/icons-material/Person3';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ChatIcon from '@mui/icons-material/Chat';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { Calculate } from '@mui/icons-material';

const AcuPayMenus: any = [
    {
        label: 'AcuPay Dashboard',
        icon: DashboardIcon,
        to: '/acupay/dashboard',
        breadcrump: false
    },
    // {
    //     label: 'acupay Report',
    //     icon: PieChartIcon,
    //     to: '/acupay/report',
    //     breadcrump: false
    // },
    // {
    //     label: 'Customers',
    //     icon: Person3Icon,
    //     to: '/acupay/customers',
    //     breadcrump: false
    // },
    // {
    //     label: 'DTI Calculator',
    //     icon: Calculate,
    //     to: '/acupay/dti',
    //     breadcrump: false
    // },
    // {
    //     label: 'Invoice Records',
    //     icon: RequestQuoteIcon,
    //     to: '/acupay/invoice',
    //     breadcrump: false
    // },
    // {
    //     label: 'Users',
    //     icon: AssignmentIndIcon,
    //     to: '/acupay/users',
    //     breadcrump: false
    // },
    // {
    //     label: 'Activity Logs',
    //     icon: TextSnippetIcon,
    //     to: '/acupay/activity-logs',
    //     breadcrump: false
    // },
    // {
    //     label: 'Connect',
    //     icon: ChatIcon,
    //     to: '/acupay/connect',
    //     breadcrump: false
    // },
    // {
    //     label: 'Settings',
    //     icon: SettingsSuggestIcon,
    //     to: '/acupay/settings',
    //     breadcrump: false
    // }
];


export default AcuPayMenus;