import React, { useState, MouseEvent, useEffect } from "react";
import {
  Button,
  Popover,
  Grid2 as Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import CalenderIcon from "../../assets/IconComponents/CalenderIcon";

const monthsAbbreviated = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthsFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const currentYear = new Date().getFullYear();

interface CombinedMonthYearPickerProps {
  useAbbreviatedMonths?: boolean;
  placeholder?: string;
  label?: string;
  required?: boolean;
  style?: React.CSSProperties;
  backgroundColor?: string;
  monthyear?: string;
  onDateChange?: (month: number, year: number) => void;
  onCancel?: () => void;
}

const CombinedMonthYearPicker: React.FC<CombinedMonthYearPickerProps> = ({
  useAbbreviatedMonths = false,
  placeholder = "From",
  label,
  required,
  style,
  backgroundColor = "",
  monthyear,
  onDateChange,
  onCancel,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [tempMonth, setTempMonth] = useState<number | null>(null);
  const [tempYear, setTempYear] = useState<number | null>(null);
  const [step, setStep] = useState<"month" | "year">("month");

  const [yearRange, setYearRange] = useState<[number, number]>([
    currentYear - 6,
    currentYear + 5,
  ]);

  const theme = useTheme();

  useEffect(() => {
    if (monthyear) {
      const [month, year] = monthyear
        .split("/")
        .map((part) => parseInt(part, 10));
      if (month >= 1 && month <= 12 && year) {
        setSelectedMonth(month - 1);
        setSelectedYear(2000 + year);
      }
    }
  }, [monthyear]);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMonthSelect = (monthIndex: number) => {
    setTempMonth(monthIndex);
    setStep("year");
  };

  const currentMonth = new Date().getMonth();
  const currentYearFull = new Date().getFullYear();

  const handleYearSelect = (year: number) => {
    if (year < currentYearFull) {
      return;
    }
    if (
      tempMonth !== null &&
      year === currentYearFull &&
      tempMonth < currentMonth
    ) {
      return;
    }

    setTempYear(year);
  };

  const handleNextRange = () => {
    setYearRange([yearRange[0] + 12, yearRange[1] + 12]);
  };

  const handlePreviousRange = () => {
    setYearRange([yearRange[0] - 12, yearRange[1] - 12]);
  };

  const handleOk = () => {
    if (tempMonth !== null && tempYear !== null) {
      setSelectedMonth(tempMonth);
      setSelectedYear(tempYear);
      if (onDateChange) {
        onDateChange(tempMonth, tempYear);
      }

      setAnchorEl(null);
      setStep("month");
    }
  };

  const handleClose = () => {
    setTempMonth(null);
    setTempYear(null);
    setSelectedMonth(null);
    setSelectedYear(null);
    setAnchorEl(null);
    setStep("month");
    if (onCancel) {
      onCancel();
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "combined-month-year-popover" : undefined;

  const yearsToDisplay = Array.from(
    { length: yearRange[1] - yearRange[0] + 1 },
    (_, i) => yearRange[0] + i
  );

  const monthsToDisplay = useAbbreviatedMonths ? monthsAbbreviated : monthsFull;

  return (
    <div>
      {label && (
        <Typography
          sx={{ fontSize: "14px", color: "#111928", fontWeight: "600" }}
        >
          {label}&nbsp;
          {required && (
            <Typography component={"span"} color="#FF220C">
              *
            </Typography>
          )}
        </Typography>
      )}
      <TextField
        placeholder={placeholder}
        fullWidth
        sx={{
          fontSize: "14px",
          borderRadius: "8px",
          background: backgroundColor,
          "& .MuiOutlinedInput-root": {
            color: "#6B7280 !important",
            height: "48px !important",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D5E1F9",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D5E1F9",
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D5E1F9",
            borderRadius: "8px",
          },
          ...style,
        }}
        size="small"
        variant="outlined"
        onClick={handleClick}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalenderIcon />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        value={
          monthyear
            ? monthyear
            : selectedMonth !== null && selectedYear !== null
            ? `${monthsAbbreviated[selectedMonth]} ${selectedYear}`
            : ""
        }
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: 300,
            height: "fit-content",
            gap: "10px",
            borderRadius: "8px",
            opacity: 1,
          },
        }}
      >
        {step === "month" && (
          <Box sx={{ marginTop: "10px", padding: "5px" }}>
            <Grid
              container
              display={"flex"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Button sx={{ width: "20px", height: "20px" }}>{"<"}</Button>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "700", lineHeight: "18px" }}
              >
                January - December
              </Typography>
              <Button sx={{ width: "20px", height: "20px" }}>{">"}</Button>
            </Grid>
            <Grid container justifyContent="center" sx={{ marginTop: "10px" }}>
              {monthsToDisplay.map((month, index) => {
                return (
                  <Grid key={month} p={"0"}>
                    <Button
                      variant={tempMonth === index ? "contained" : "text"}
                      onClick={() => handleMonthSelect(index)}
                      sx={{
                        width: "36px",
                        height: "34px",
                        borderRadius: "1vw",
                        padding: tempMonth === index ? "16px" : "0",
                        backgroundColor:
                          tempMonth === index
                            ? theme.palette.primary.main
                            : "transparent",
                        color: tempMonth === index ? "#fff" : "#374151",
                        fontSize: "12px",
                        fontWeight: "700",
                        lineHeight: "18px",
                        cursor: "pointer",
                      }}
                    >
                      {monthsAbbreviated[index]}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}

        {step === "year" && (
          <Box sx={{ marginTop: "10px", padding: "5px" }}>
            <Grid
              container
              display={"flex"}
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                sx={{ width: "20px", height: "20px" }}
                onClick={handlePreviousRange}
              >
                {"<"}
              </Button>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "700", lineHeight: "18px" }}
              >
                {yearRange[0]} - {yearRange[1]}
              </Typography>
              <Button
                sx={{ width: "20px", height: "20px" }}
                onClick={handleNextRange}
              >
                {">"}
              </Button>
            </Grid>

            <Grid container justifyContent="center" sx={{ marginTop: "10px" }}>
              {yearsToDisplay.map((year) => {
                const isDisabled =
                  year < currentYearFull ||
                  (tempMonth !== null &&
                    year === currentYearFull &&
                    tempMonth < currentMonth);

                return (
                  <Grid key={year} p={"0"}>
                    <Button
                      variant={tempYear === year ? "contained" : "text"}
                      onClick={() => handleYearSelect(year)}
                      sx={{
                        width: "36px",
                        height: "34px",
                        borderRadius: "1vw",
                        padding: tempYear === year ? "16px" : "0",
                        backgroundColor:
                          tempYear === year
                            ? theme.palette.primary.main
                            : "transparent",
                        color: tempYear === year ? "#fff" : "#374151",
                        fontSize: "12px",
                        fontWeight: "700",
                        lineHeight: "18px",
                      }}
                      disabled={isDisabled} // Disable past years
                    >
                      {year}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}

        <Box
          sx={{ width: "100%", marginBottom: "5px" }}
          display={"flex"}
          justifyContent={"center"}
        >
          <Box display="flex" justifyContent="center" mt={2} width={"90%"}>
            <Button
              variant="contained"
              onClick={handleOk}
              sx={{
                background: theme.palette.primary.main,
                fontSize: "14px",
                fontWeight: "700",
                width: "45%",
              }}
            >
              OK
            </Button>
            <Button
              variant="text"
              onClick={handleCancel}
              sx={{
                marginRight: "10px",
                fontSize: "14px",
                fontWeight: "700",
                width: "45%",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default CombinedMonthYearPicker;
