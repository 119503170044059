import React, { useState, useEffect, MouseEvent } from "react";
import {
  Button,
  Popover,
  Grid2 as Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import CalenderIcon from "../../assets/IconComponents/CalenderIcon";

const monthsAbbreviated = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthsFull = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface CustomMonthPickerProps {
  selectedMonthProp?: number | null;
  onMonthSelect?: (monthIndex: number | null) => void;
  useAbbreviatedMonths?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
  popoverWidth?: string;
  popoverHeight?: string;
  borderColor?: string;
  backgroundColor?: string;
  label?: string;
  required?: boolean;
}

const CustomMonthPicker: React.FC<CustomMonthPickerProps> = ({
  selectedMonthProp = null,
  onMonthSelect = () => {},
  useAbbreviatedMonths = false,
  placeholder = "Select Month",
  style = {},
  popoverWidth = "300px",
  popoverHeight = "fit-content",
  borderColor = "#D5E1F9",
  backgroundColor = "#f7f9fc",
  label,
  required,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(
    selectedMonthProp
  );
  const [tempMonth, setTempMonth] = useState<number | null>(selectedMonthProp);

  const theme = useTheme();

  useEffect(() => {
    setSelectedMonth(selectedMonthProp);
    setTempMonth(selectedMonthProp);
  }, [selectedMonthProp]);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMonthSelect = (monthIndex: number) => {
    setTempMonth(monthIndex);
  };

  const handleOk = () => {
    setSelectedMonth(tempMonth);
    handleClose();
    if (onMonthSelect && tempMonth !== null) {
      onMonthSelect(tempMonth);
    }
  };

  const handleCancel = () => {
    setTempMonth(null);
    setSelectedMonth(null);
    handleClose();
    if (onMonthSelect) {
      onMonthSelect(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "month-popover" : undefined;
  const monthsToDisplay = useAbbreviatedMonths ? monthsAbbreviated : monthsFull;

  return (
    <div>
      {label && (
        <Typography
          sx={{ fontSize: "14px", color: "#111928", fontWeight: "600" }}
        >
          {label}&nbsp;
          {required && (
            <Typography component={"span"} color="#FF220C">
              *
            </Typography>
          )}
        </Typography>
      )}

      <TextField
        size="small"
        placeholder={placeholder}
        fullWidth
        sx={{
          fontSize: "14px",
          background: backgroundColor,
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            color: "#6B7280 !important",
            height: "48px !important",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: borderColor,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: borderColor,
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor,
            borderRadius: "8px",
          },
          ...style,
        }}
        variant="outlined"
        onClick={handleClick}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                display: "flex",
                alignItems: "center",
                width: "18px",
                height: "18px",
              }}
            >
              <CalenderIcon />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        value={
          selectedMonth !== null
            ? useAbbreviatedMonths
              ? monthsAbbreviated[selectedMonth]
              : monthsFull[selectedMonth]
            : ""
        }
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            width: popoverWidth,
            height: popoverHeight,
            gap: "10px",
            borderRadius: "8px 0 0 0",
            opacity: 1,
          },
        }}
      >
        <Box sx={{ marginTop: "10px", padding: "5px" }}>
          <Grid
            container
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Button sx={{ width: "20px", height: "20px" }}>{"<"}</Button>
            <Typography
              sx={{ fontSize: "12px", fontWeight: "700", lineHeight: "18px" }}
            >
              January - December
            </Typography>
            <Button sx={{ width: "20px", height: "20px" }}>{">"}</Button>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginTop: "10px" }}>
            {monthsToDisplay.map((month, index) => (
              <Grid key={month} p={"0"}>
                <Button
                  variant={tempMonth === index ? "contained" : "text"}
                  onClick={() => handleMonthSelect(index)}
                  sx={{
                    width: "36px",
                    height: "34px",
                    borderRadius: "1vw",
                    padding: tempMonth === index ? "16px" : "0",
                    backgroundColor:
                      tempMonth === index
                        ? theme.palette.primary.main
                        : "transparent",
                    color: tempMonth === index ? "#fff" : "#374151",
                    fontSize: "12px",
                    fontWeight: "700",
                    lineHeight: "18px",
                  }}
                >
                  {useAbbreviatedMonths
                    ? monthsFull[index]
                    : monthsAbbreviated[index]}
                </Button>
              </Grid>
            ))}
          </Grid>

          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"center"}
          >
            <Box display="flex" justifyContent="center" mt={2} width={"90%"}>
              <Button
                variant="contained"
                onClick={handleOk}
                sx={{
                  background: theme.palette.primary.main,
                  fontSize: "14px",
                  fontWeight: "700",
                  width: "45%",
                }}
              >
                OK
              </Button>
              <Button
                variant="text"
                onClick={handleCancel}
                sx={{
                  marginRight: "10px",
                  fontSize: "14px",
                  fontWeight: "700",
                  width: "45%",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Popover>
    </div>
  );
};

export default CustomMonthPicker;
