import { lazy } from 'react';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import AuthRouter from './authRouter';
import MainRouter from './AcuScoreRouter';
import AdminPortalRouter from './AdminPortalRouter';

// import AuthLayout from '../layout/AuthLayout';
// import DashboardLayout from '../layout/dashboard';
import NotFoundPage from '../pages/misc/NotFound';
// import ValidateSesion from '../pages/auth/validate/ValidateSesion';

import { IsAcuCheck, IsAdminPortal } from '../utils/constants/AppConfig';

import Loadable from '../components/Lodable';
import AcuScoreMenus from '../menu-items/AcuScoreMenuItems';
import AdminMenus from '../menu-items/AdminPortalMenuItems';
import AcuPayMenus from '../menu-items/AcuPayMenuItems';
import AcuPayRouter from './AcuPayRouter';

const AppsLandingLayout = Loadable(lazy(() => import('../layout/appsLandingLayout')));
const AuthLayout = Loadable(lazy(() => import('../layout/AuthLayout')));
const AdminLayout = Loadable(lazy(() => import('../layout/adminLayout')));
const DashboardLayout = Loadable(lazy(() => import('../layout/dashboard')));
const ExternalApps = Loadable(lazy(() => import('../pages/external/fiservWidget/ValidateSesion')));
const ErrorResultResponse = Loadable(lazy(() => import('../pages/external/fiservWidget/ErrorResultResponse')));
const SuccessResultResponse = Loadable(lazy(() => import('../pages/external/fiservWidget/SuccessResultResponse')));

const Routes: RouteObject[] = [
    // For Authentication related routes
    {
        path: 'auth',
        element: <AuthLayout />,
        children: AuthRouter
    },
    // For admin related routes
    {
        path: 'admin',
        element: <AdminLayout menuItems={AdminMenus} />,
        children: AdminPortalRouter
    },
    // For apps listing related routes
    {
        path: 'apps',
        element: <AppsLandingLayout />
    },
    // For AcuScore product app route
    {
        path: 'acucheck',
        element: <DashboardLayout menuItems={AcuScoreMenus} />,
        children: MainRouter
    },
    // For AcuPay product app route
    {
        path: 'acupay',
        element: <DashboardLayout menuItems={AcuPayMenus} />,
        children: AcuPayRouter
    },
    // To validated the session from the fiserv
    {
        path: 'external/session/validate/token',
        element: <ExternalApps />,
    },
    {
        path: 'external/session/success',
        element: <SuccessResultResponse />,
    },
    {
        path: 'external/session/error',
        element: <ErrorResultResponse />,
    },
    {
        path: '',
        element: <Navigate to='/auth' />,
    },
    {
        path: '*',
        Component: NotFoundPage
    }
];

// Initialize all app routes statically based on target app variant
const AppRouter = createBrowserRouter(Routes);

export default AppRouter;
