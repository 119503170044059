import { Box, Divider, IconButton, Typography } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import CheckIcon from "../../assets/IconComponents/CheckIcon";
import {
  DashboardRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useState } from "react";

type Detail = {
  label: string;
  value: string;
  done?: boolean;
  type?: string;
};

interface CustomerOverviewProps {
  section: string;
  details?: Detail[];
  src: string;
  sectionDetails?: string;
  primaryDetails?: Detail[];
  secondaryDetails?: Detail[];
  isChecked?: boolean;
  detailsName?: string;
  visibility?: Record<string, boolean>;
  onToggleVisibility?: (field: string) => void;
}

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "rgb(1, 182, 168)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "rgb(1, 182, 168)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#245FECE5",
    borderWidth: "0 0 0 2px",
    borderStyle: "dashed",
    margin: "-12px 0 -12px -3.5px",
  },
}));

const CustomStepIconRoot = styled("div")<{
  ownerState: { active: boolean; completed: boolean };
}>(({ ownerState }) => ({
  color:
    ownerState.active || ownerState.completed ? "rgb(1, 182, 168)" : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  "& .CustomStepIcon-completedIcon": {
    color: "rgb(1, 182, 168)",
    zIndex: 1,
    fontSize: 18,
    display: "flex",
  },
  "& .CustomStepIcon-circle": {
    color: "#245FECE5",
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: ownerState.completed ? "currentColor" : "transparent",
    border: ownerState.completed ? "none" : "4px solid currentColor",
  },
}));

function CustomStepIcon(props: {
  active?: boolean;
  completed?: boolean;
  className?: string;
}) {
  const { active = false, completed = false, className } = props;

  return (
    <CustomStepIconRoot
      ownerState={{ active, completed }}
      className={className}
    >
      {completed ? (
        <div className="CustomStepIcon-completedIcon">
          <CheckIcon />
        </div>
      ) : (
        <div className="CustomStepIcon-circle" />
      )}
    </CustomStepIconRoot>
  );
}

const CustomerOverview: React.FC<CustomerOverviewProps> = ({
  section,
  details = [],
  src,
  sectionDetails = "",
  primaryDetails = [],
  secondaryDetails = [],
  isChecked = false,
  detailsName = "",
  visibility = {},
  onToggleVisibility,
}) => {
  const isDisabled =
    details.length === 0 &&
    primaryDetails.length === 0 &&
    secondaryDetails.length === 0;

  const renderDetails = (details: Detail[]) =>
    details.map((detail, index) => {
      const isSensitive =
        detail.label === "CVV" ||
        detail.label === "Password" ||
        detail.label === "Confirm Password";
      const isPrimary = detail.type === "Primary";
      const isSecondary = detail.type === "Secondary";
      const visibilityKey = isPrimary
        ? "PrimaryCVV"
        : isSecondary
        ? "SecondaryCVV"
        : detail.label;
      const isVisible = visibility[visibilityKey] ?? false;

      return (
        <Step
          key={index}
          completed={detail.done}
          sx={{
            position: "relative",
            "& .MuiStepConnector-root": {
              display: index < details.length - 1 ? "block" : "none",
            },
          }}
        >
          <StepLabel StepIconComponent={CustomStepIcon} sx={{ padding: "0" }}>
            <Box sx={{ margin: "10px 0 0 10px", width: "85%" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#374151",
                  height: "20px",
                  overflow: "hidden",
                  marginTop: "5px",
                }}
              >
                {detail.label}:
                {isSensitive && (
                  <IconButton
                    onClick={() =>
                      onToggleVisibility && onToggleVisibility(visibilityKey)
                    }
                    sx={{ padding: 0, marginLeft: "10px" }}
                  >
                    {isVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                )}
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#6B7280",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                title={detail.value}
              >
                {detail.value}
              </Typography>
            </Box>
          </StepLabel>
        </Step>
      );
    });

  return (
    <Box
      sx={{
        padding: "10px",
        pb: 0,
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        mb: "20px",
        border: isDisabled ? "1.5px solid #DBE9FE" : "1.5px solid #245FECE5",
        borderTop: isDisabled ? "8px solid #DBE9FE" : "8px solid #245FECE5",
        pointerEvents: isDisabled ? "none" : "auto",
        opacity: isDisabled ? 0.6 : 1,
        ":last-child": {
          mb: "0",
        },
      }}
    >
      <Box sx={{ display: "flex", position: "relative" }}>
        <img
          src={src}
          alt="information"
          style={{
            width: "48px",
            height: "48px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{ fontWeight: "600", color: "#1A369E", fontSize: "14px" }}
          >
            {section}
          </Typography>
          {sectionDetails && (
            <Typography
              sx={{ fontWeight: "400", color: "#6B7280", fontSize: "14px" }}
            >
              {sectionDetails}
            </Typography>
          )}
        </Box>
      </Box>

      {details.length > 0 && (
        <Box position={"relative"}>
          <Box
            sx={{
              position: "absolute",
              height: "18px",
              width: "2px",
              borderLeft: `2px dashed ${
                details[0].done ? "rgb(1, 182, 168)" : "#245FECE5"
              }`,
              top: "-9px",
              left: "20.5px",
              zIndex: 0,
            }}
          />
          <Box sx={{ marginLeft: "12px" }}>
            <Stepper
              orientation="vertical"
              connector={<CustomStepConnector />}
              sx={{ padding: "0" }}
            >
              {renderDetails(details)}
            </Stepper>
          </Box>
        </Box>
      )}

      {primaryDetails.length > 0 && (
        <Box position="relative">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingX: "14px",
            }}
          >
            <Box
              sx={{
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                background: "#366DEE",
                mr: 2.5,
              }}
            />

            <Typography
              sx={{
                fontWeight: "600",
                color: "#374151",
                fontSize: "14px",
              }}
            >
              Primary {detailsName}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              height: "16px",
              width: "2px",
              borderLeft: `2px dashed ${
                primaryDetails[0].done ? "rgb(1, 182, 168)" : "#245FECE5"
              }`,
              top: "19px",
              left: "20.5px",
              zIndex: 0,
            }}
          />
          <Box sx={{ marginLeft: "12px" }}>
            <Stepper
              orientation="vertical"
              connector={<CustomStepConnector />}
              sx={{ padding: "0" }}
            >
              {renderDetails(primaryDetails)}
            </Stepper>
          </Box>
        </Box>
      )}

      {isChecked === true && secondaryDetails.length > 0 && (
        <Box position="relative">
          <Divider sx={{ margin: "5px", border: "1px dashed #D5E1F9" }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingX: "14px",
            }}
          >
            <Box
              sx={{
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                background: "#366DEE",
                mr: 2.5,
              }}
            />

            <Typography
              sx={{
                fontWeight: "600",
                color: "#374151",
                fontSize: "14px",
              }}
            >
              Secondary {detailsName}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "absolute",
              height: "16px",
              width: "2px",
              borderLeft: `2px dashed ${
                secondaryDetails[0].done ? "rgb(1, 182, 168)" : "#245FECE5"
              }`,
              top: "26px",
              left: "20.5px",
              zIndex: 0,
            }}
          />
          <Box sx={{ marginLeft: "12px" }}>
            <Stepper
              orientation="vertical"
              connector={<CustomStepConnector />}
              sx={{ padding: "0" }}
            >
              {renderDetails(secondaryDetails)}
            </Stepper>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CustomerOverview;
