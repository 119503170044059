import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Pagination,
  TableSortLabel,
  Skeleton,
  Checkbox,
  Button,
  TableFooter,
  Collapse,
  IconButton,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import NoData from "../../assets/media/icons/NoData.png";
import AddIcon from "../../assets/IconComponents/AddIcon";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const CustomCheckbox = styled(Checkbox)({
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
});

interface Column {
  label: string;
  id: string;
  sortable?: boolean;
  render?: (row: any) => React.ReactNode;
  fontWeight?: string | number;
  textDecoration?: string;
}

interface DataTableProps {
  columns: Column[];
  rows?: any[];
  isLoading?: boolean;
  orderBy?: string;
  order?: "asc" | "desc";
  createSortHandler: (columnId: string) => void;
  checkboxSelection?: boolean;
  NodataTitle?: string;
  onGetStartedClick?: () => void;
  onRowSelection?: (value: string[]) => void;
  resetTableSelection?: any;
  footerRow?: any[];
  rowCount?: number;
  getStarted?: boolean;
  showExpandRow?: boolean;
}

const DataTable: React.FC<DataTableProps> = ({
  columns,
  rows = [],
  isLoading,
  orderBy,
  order,
  createSortHandler,
  checkboxSelection = false,
  NodataTitle = "",
  onGetStartedClick,
  onRowSelection,
  resetTableSelection = null,
  footerRow,
  rowCount = 10,

  showExpandRow
}) => {
  const [allSelected, setAllSelected] = useState<any>(false);
  const [rowSelections, setRowSelections] = useState<Set<string>>(new Set());

  // const [rowSelections, setRowSelections] = useState(rows.map(() => false));


  useEffect(() => {
    if (resetTableSelection) {
      setAllSelected(false);
      setRowSelections(new Set());
    }
  }, [resetTableSelection]);

  const handleSelectAll = () => {
    const newState = !allSelected;
    const selectedItems = newState ? new Set(rows.map((row) => row.id)) : new Set();
    setAllSelected(newState);
    setRowSelections(selectedItems);
    
    if (onRowSelection) {
      onRowSelection(Array.from(selectedItems));
    }
  };
  

  const handleRowSelect = (row: any = {}, index: number) => {
    const selectedSet = new Set(rowSelections);
    if (!selectedSet.has(row.id)) {
      selectedSet.add(row.id);
    } else {
      selectedSet.delete(row.id);
    }
    setAllSelected(selectedSet.size === rows.length);


    setRowSelections(selectedSet);
    if (onRowSelection) {
      onRowSelection(Array.from(selectedSet) as any);
    }
  };

  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleExpandClick = (index: number) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <TableContainer
      component={Box}
      sx={{
        borderBottom: footerRow ? "none" : "1px solid #D5E1F980",
        paddingX: "5px",
      }}
    >
      <Table
        stickyHeader
        sx={{
          borderSpacing: "0 8px",
          borderCollapse: 'separate', 
          '& .MuiTableCell-root': {
            border: 'none',
          },
          '& .MuiTableRow-root': {
            borderBottom: 'none',
          },

        }}
      >
        <TableHead>
          <TableRow>
            {checkboxSelection && (
              <TableCell
                padding="checkbox"
                sx={{
                  border: "none !important",
                  borderTop: "1px solid #D5E1F950 !important",
                  borderBottom: "1px solid #D5E1F950 !important",
                  background: "#FCFCFD",
                }}
              >
                <CustomCheckbox
                  disableRipple
                  checked={allSelected}
                  onChange={handleSelectAll}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "#d3d3d3",
                    "&.Mui-checked": {
                      color: "#1F3A91",
                    },
                  }}
                />
              </TableCell>
            )}
            {columns.map((column) => (
              <TableCell
                key={column.id}
                sx={{
                  borderTop: "1px solid #D5E1F950 !important",
                  borderBottom: "1px solid #D5E1F950 !important",
                  borderLeft: "none !important",
                  borderRight: "none !important",
                  fontSize: "15px",
                  color: "#6B7280",
                  fontWeight: "400",
                  background: "#FCFCFD",
                  whiteSpace: "nowrap",
                  padding: {
                    xs: "15px 20px !important",
                    md: "1rem 1.5rem !important",
                  },
                }}
              >
                {column.id === "action" || column.sortable === false ? (
                  column.label
                ) : (
                  <TableSortLabel
                    active={column?.id === orderBy}
                    direction={order?.toLowerCase() as "asc" | "desc"}
                    onClick={() => createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableSkeletonComponent
              rows={rowCount}
              columns={checkboxSelection ? columns.length + 1 : columns.length}
            />
          ) : rows.length === 0 ? (
            <TableRow>
              <TableCell
                colSpan={
                  checkboxSelection ? columns.length + 1 : columns.length
                }
                sx={{
                  textAlign: "center",
                  padding: "20px",
                  color: "#6B7280",
                  border: "none !important",
                }}
              >
                {NodataTitle ? (
                  <>
                    <Box component={"img"} src={NoData} />
                    <Typography
                      sx={{
                        marginY: "10px",
                        color: "#374151",
                        fontSize: "18px",
                        fontWeight: "400",
                      }}
                    >
                      No {NodataTitle} related data available managed from this
                      screen
                    </Typography>
                    {onGetStartedClick && (
                      <Box>
                        <Button
                          sx={{
                            padding: "12px 16px",
                            background: "#FFFFFF",
                            color: "#1F3A91",
                            border: "1px solid #1F3A91",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "14px",
                            fontWeight: "700",
                          }}
                          startIcon={<AddIcon fill={"#1F3A91"} />}
                          onClick={onGetStartedClick}
                        >
                          Get Started
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  <Typography
                    sx={{
                      marginY: "10px",
                      color: "#374151",
                      fontSize: "18px",
                      fontWeight: "400",
                    }}
                  >
                    No Data Available.
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row, index) => (
              <>
                <TableRow
                  key={`table_row_${index}`}
                  hover={false}
                  sx={{
                    borderRadius: rowSelections.has(row.id) ? "50px" : "0",
                    boxShadow: rowSelections.has(row.id)
                      ? " 0px -6px 14.7px 0px rgba(0, 0, 0, 0.04), 0px 6px 14.7px 0px rgba(0, 0, 0, 0.04)"
                      : "none",
                    marginY: rowSelections.has(row.id) ? "10px" : "0",
                    overflow: "hidden",
                  }}
                >
                  {checkboxSelection && (
                    <TableCell
                      padding="checkbox"
                      sx={{ border: "none !important" }}
                    >
                      <Box display="flex" alignItems="center">

                        <CustomCheckbox
                          disableRipple
                          checked={!!rowSelections.has(row.id)}
                          onChange={() => handleRowSelect(row, index)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            color: "#d3d3d3",
                            borderRadius: "8px",
                            "&.Mui-checked": {
                              color: "#1F3A91",
                            },
                          }}
                        />
                        {showExpandRow && (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleExpandClick(index)}
                          >
                            {expandedRow === index ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        )}

                      </Box>

                    </TableCell>
                  )}


                  {columns.map((column) => (
                    <TableCell
                      key={`${column.id}_${index}`}
                      sx={{
                        width: "max-content",
                        border: "none !important",
                        fontSize: {
                          xs: "15px !important",
                          md: "1rem !important",
                        },
                        fontWeight: column.fontWeight || "400",
                        color: "#374151",
                        whiteSpace: "nowrap",
                        padding: {
                          xs: "2px 20px !important",
                          md: "0.1rem 1.5rem !important",
                        },
                        textDecoration: column.textDecoration || "",
                      }}
                    >
                      {column.render ? column.render(row) : row[column.id]}
                    </TableCell>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCell
                    colSpan={columns.length + (checkboxSelection ? 2 : 1)}
                    style={{ paddingBottom: 0, paddingTop: 0 , background:"#F7FAFF",}}
                  >
                    <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="body2" gutterBottom>
                          {row.details || "Additional details for this row."}
                        </Typography>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))
          )}
        </TableBody>
        <TableFooter>
          {footerRow && (
            <TableRow
              sx={{
                border: "1px solid #F3F4F6 !important",
                backgroundColor: "#F1F4FF",
              }}
            >
              {footerRow.map((footerItem, index) => (
                <TableCell
                  key={`footer_cell_${index}`}
                  sx={{ border: "none !important" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "flex-start", sm: "center" },
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "600",
                        color: "#374151",
                      }}
                    >
                      {footerItem.label}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color: "#374151",
                      }}
                    >
                      &nbsp;{footerItem.value}
                    </Typography>
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

interface PaginationControlProps {
  page: number;
  rowsPerPage: number;
  totalItems: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const PaginationControl: React.FC<PaginationControlProps> = ({
  page,
  rowsPerPage,
  totalItems,
  handlePageChange,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "16px",
        "@media (max-width: 435px)": {
          flexDirection: "column",
          alignItems: "flex-start",
        },
      }}
    >
      <Typography variant="body2" sx={{ color: "#6B7280", fontSize: "14px" }}>
        Showing{" "}
        <Typography
          component={"span"}
          sx={{ color: "#374151", fontSize: "14px", fontWeight: "600" }}
        >
          {(page - 1) * rowsPerPage + 1} -{" "}
          {Math.min(page * rowsPerPage, totalItems)}
        </Typography>{" "}
        of{" "}
        <Typography
          component={"span"}
          sx={{ color: "#374151", fontSize: "14px", fontWeight: "600" }}
        >
          {totalItems}
        </Typography>
      </Typography>

      <Box
        sx={{
          display: "flex",
          border: "1px solid #D5E1F9",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Pagination
          count={Math.ceil(totalItems / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          shape="rounded"
          siblingCount={0}
          boundaryCount={1}
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "14px",
              color: "#6B7280",
              fontWeight: "500",
              width: "100%",
              height: "33px",
              borderRadius: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderLeft: "1px solid #D5E1F9",
              margin: "0",
            },
            "& .Mui-selected": {
              backgroundColor: "#F1F4FF !important",
              color: "#1F3A91",
            },
            "& .MuiPaginationItem-icon": {
              color: "#6B7280",
            },
          }}
        />
      </Box>
    </Box>
  );
};

const TableSkeletonComponent = ({ columns, rows }: any): React.ReactElement => {
  const tableRow = useMemo(() => {
    const rowsElements: any[] = [];
    for (let row = 0; row < rows; row++) {
      const rowElements = [];
      for (let column = 0; column < columns; column++) {
        rowElements.push(
          <TableCell key={`cell-${row}-${column}`}>
            <Skeleton />
          </TableCell>
        );
      }
      rowsElements.push(<TableRow key={`row-${row}`}>{rowElements}</TableRow>);
    }
    return rowsElements;
  }, [columns, rows]);

  return <Fragment>{tableRow}</Fragment>;
};

export { DataTable, PaginationControl, TableSkeletonComponent };
