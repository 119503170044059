import React from "react";
import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";

interface TableProps {
  columns: string[];
  rows: { [key: string]: string | number }[];
}

const SaveResultDTiTable: React.FC<TableProps> = ({ columns, rows }) => {
  const CustomTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: "1px solid rgba(213, 225, 249, 0.5) !important",
    borderRight: "none !important",
    borderLeft: "none !important",
    borderTop: "none !important",
    fontSize: "0.8rem",
    color: "#374151",
    fontWeight: "600",
  }));

  return (
    <TableContainer component={Paper}>
      <Table sx={{ border: "1px solid #D5E1F9 !important" }}>
        <TableHead sx={{ background: "#F7FAFF !important" }}>
          <TableRow>
            {columns.map((column, index) => (
              <CustomTableCell key={index}>
                <Box
                  sx={{
                    width: "fit-content",
                    background: "#E9F1FF !important",
                    padding: "12px 14px",
                    borderRadius: "30px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column}
                </Box>
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: "#374151",
                    borderBottom:
                      "1px solid rgba(213, 225, 249, 0.5)  !important",
                    borderRight: "none !important",
                    borderLeft: "none !important",
                    borderTop: "none !important",
                    padding: "5px 15px",
                  }}
                  key={colIndex}
                >
                  <Box sx={{ padding: "12px 14px" }}>{row[column] || "-"}</Box>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SaveResultDTiTable;
