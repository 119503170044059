import {
  Breadcrumbs,
  Typography,
  useTheme,
  Link,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import Panel from "../../components/Panel";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import UserIcon from "../../assets/IconComponents/UserIcon";
import BusinessIcon from "../../assets/IconComponents/BussinessIcon";
import ProfileInformation from "./ProfileInformation";
import { GetCustomerByCustomerID } from "../../api/customerAPI";
import { useAlertMessage } from "../../utils/useAlertMessage";
import RequestedReport from "./RequestedReport";
import CircularSpinner from "../../components/Loader/CircularLoader";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box sx={{ p: "20px", pl: 0, pr: 0 }}>{children}</Box>
      )}
    </div>
  );
};

const EditCustomer = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { customerId }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [userInfo, setUserInfo] = useState<any>({});
  const notification = useAlertMessage();

  const currentPath = location.pathname;
  const isProfileTab = currentPath.endsWith("/edit");
  const isReportsTab = currentPath.endsWith("/requested-report");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      navigate(`/acucheck/customers/${customerId}/edit`);
    } else if (newValue === 1) {
      navigate(`/acucheck/customers/${customerId}/requested-report`);
    }
  };

  const getCustomerInfo = async (customerId: string) => {
    setLoading(true);
    setProgress(90);
    let timer;
    try {
      const {data,status} = await GetCustomerByCustomerID(customerId);
      if (status === 200) {
        setUserInfo(data);
      } else {
        navigate("/acucheck/customers")
      }
      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
    } catch (err) {
      notification.errorNotification(
        "Error",
        "Error in fetching customer information."
      );
      navigate('/acucheck/customers');
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomerInfo(customerId);
  }, [isProfileTab]);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/acucheck/customers"}
      sx={{ width: "120px", display: "flex" }}
    >
      <PersonAddIcon
        sx={{ marginRight: 1, color: theme.palette.primary.main }}
      />
      <Typography sx={{ color: theme.palette.primary.main }}>
        Customers
      </Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      {userInfo?.firstName} {userInfo?.lastName}
    </Typography>,
  ];

  return (
    <>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Panel>
      <Panel>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={isProfileTab ? 0 : 1}
            onChange={handleChange}
            aria-label="icon position tabs example"
            sx={{
              ".Mui-selected": {
                color: "#264D8E !important",
                fontWeight: "700 !important",
                background: "linear-gradient(180deg, #EAEFFF 0%, #FFFFFF 100%)",
                minHeight: "56px !important",
                minWidth: "100% !important",
                height: "56px !important",
                padding: "16px !important",
              },
              ".MuiTabs-flexContainer": {
                borderBottom: "1px solid #d5e1f9",
              },
              "MuiBox-root": {
                borderBottom: "none !important",
              },
              ".css-1gsv261": {
                borderBottom: "none",
              },
              ".MuiTab-root": {
                fontSize: "16px",
                fontWeight: "600",
                minWidth: "100% !important",
                minHeight: "56px !important",
                "*:first-of-type": {
                  marginRight: "8px",
                },
              },
              ".MuiTabs-indicator": {
                backgroundColor: "#264D8E",
              },
            }}
          >
            <Tab
              icon={<UserIcon selected={isProfileTab} />}
              iconPosition="start"
              label="Profile"
              value={0}
            />
            {/* <Tab
              icon={
                <BusinessIcon
                  selected={isReportsTab}
                  width="20px"
                  height="20px"
                />
              }
              iconPosition="start"
              label="Requested Reports"
              value={1}
            /> */}
          </Tabs>
        </Box>

        <CustomTabPanel value={isProfileTab ? 0 : 1} index={0}>
          <ProfileInformation type={userInfo.customerType} userInfo={userInfo} getCustomerInfo={getCustomerInfo}/>
        </CustomTabPanel>
        {/* <CustomTabPanel value={isProfileTab ? 0 : 1} index={1}>
          <RequestedReport />
        </CustomTabPanel> */}
          {loading && <CircularSpinner progress={progress} />}
      </Panel>
    </>
  );
};

export default EditCustomer;
