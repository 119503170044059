import Panel from "../../components/Panel";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SelectComponent from "../../components/form/SelectComponent";
import YearPicker from "../../components/form/YearPicker";
import CustomMonthPicker from "../../components/form/MonthPicker";
import FileDownloadIcon from "../../assets/IconComponents/FileDownloadIcon";
import { useState } from "react";
import AvatarIcon from "../../assets/IconComponents/AvatarIcon";
import { DataTable } from "../../components/form/TableComponent";
import TruncatedTooltipText from "../../components/form/ToolTip";
import moment, { Moment } from "moment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PencilIcon from "../../assets/IconComponents/Billing/PencilIcon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import InputComponent from "../../components/form/input";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SelectIcon from "../../assets/IconComponents/Billing/SelectIcon";
import CloseIcon from "../../assets/IconComponents/Billing/CloseIcon";
import RefreshIcon from "../../assets/IconComponents/Billing/RefreshIcon";
import { Controller, useForm } from "react-hook-form";

const AddBilling = () => {
  const theme = useTheme();
  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      client: "",
      month: "",
      year: null,
    },
  });
  const [BillingData, setBillingData] = useState([
    {
      id: 1,
      product: "ACH/Credit",
      noOfTransaction: "03",
      totalTransaction: "160",
      paymentDate: "2023-06-15",
      dueDate: "2023-06-15",
      totalPrice: "297.00",
      status: "Failed",
    },
  ]);
  const [query, setQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    orderBy: "invoiceDate",
    order: "desc",
  });
  const [generateData, setGenerateData] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());
  const [originalDate, setOriginalDate] = useState<Moment | null>(moment());
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingText, setIsEditingText] = useState(false);
  const [editingValue, setEditingValue] = useState("");
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [originalPrice, setOriginalPrice] = useState("");
  const watchedValues = watch();

  const clearAll = () => {
    reset({ client: "", month: "", year: null });
    setSelectedDate(moment());
    setOriginalDate(moment());
    setBillingData([]);
    setGenerateData(false);
    setIsEditing(false);
    setIsEditingText(false);
    setEditingValue("");
    setIsValueChanged(false);
  };

  const isFormValid =
    !!watchedValues.client && !!watchedValues.month && !!watchedValues.year;

  const onSubmit = (data: any) => {
    setGenerateData(true);
  };

  const [downloadState, toggleDownloadLoader] = useState(false);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/admin/billing"}
      sx={{ width: "auto", display: "flex" }}
    >
      <ReceiptLongIcon sx={{ color: "#142A73", marginRight: "10px" }} />
      <Typography sx={{ color: theme.palette.primary.main }}>
        Billing
      </Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      Generate Invoice
    </Typography>,
  ];

  const createSortHandler = (key: string) => {
    setQuery({
      ...query,
      pageNumber: 1,
      order: query.order?.toLowerCase() === "asc" ? "desc" : "asc",
      orderBy: key,
    });
  };

  const renderStatus = (status: string) => {
    const statusConfig = {
      Paid: {
        iconColor: "#01B6A8",
        backgroundColor: "#EFFFFE",
        textColor: "#01B6A8",
      },
      Failed: {
        iconColor: "#AF6DD1",
        backgroundColor: "#A800FF0F",
        textColor: "#AF6DD1",
      },
    };
    const config = statusConfig[status as keyof typeof statusConfig];
    if (!config) return null;
    return (
      <Chip
        icon={
          <FiberManualRecordIcon
            sx={{ color: `${config.iconColor} !important`, height: "10px" }}
          />
        }
        label={status}
        sx={{
          backgroundColor: config.backgroundColor,
          color: config.textColor,
          minWidth: "110px",
        }}
      />
    );
  };

  const handleDateChange = (newDate: Moment | null) => {
    if (newDate && !newDate.isSame(originalDate, "day")) {
      setSelectedDate(newDate);
    }
  };

  const cancelEditing = () => {
    setSelectedDate(originalDate);
    setIsEditing(false);
  };

  const startEditingDate = () => {
    setOriginalDate(selectedDate);
    setIsEditing(true);
  };

  const confirmDateChange = () => {
    setOriginalDate(selectedDate);
    setIsEditing(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditingValue(event.target.value);
  };

  const handleSaveValue = (id: number) => {
    setBillingData((prevList: any) =>
      prevList.map((item: any) =>
        item.id === id ? { ...item, totalPrice: editingValue } : item
      )
    );
    setIsEditingText(false);
    setIsValueChanged(true);
  };

  const handleRefresh = (id: number) => {
    setBillingData((prevList: any) =>
      prevList.map((item: any) =>
        item.id === id ? { ...item, totalPrice: originalPrice } : item
      )
    );
    setEditingValue(originalPrice);
    setIsValueChanged(false);
  };

  const startEditing = (id: number, value: string) => {
    setIsEditingText(true);
    setEditingValue(value);
    setOriginalPrice(value);
  };
  return (
    <>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Panel>
      {/* <Grid container size={12}> */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          All New Invoices can be generated from this screen
        </Typography>
        {generateData && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: { xs: "flex-start", sm: "flex-end" },
              marginTop: { xs: "10px", sm: "" },
              // flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: { xs: "row", sm: "row" },
              }}
            >
              <Button
                size="large"
                sx={{
                  borderRadius: "10px",
                  fontSize: "14px",
                  fontWeight: "700",
                  border: "1px solid #374151",
                  color: "#374151",
                  height: "48px",
                  width: { md: "180px", lg: "150px" },
                }}
              >
                Send Invoice
              </Button>
              <Button
                size="large"
                sx={{
                  height: "48px",
                  background: "#1F3A91",
                  borderRadius: "10px",
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#FFFFFF",
                  width: { sm: "150px", md: "180px", lg: "150px" },
                }}
                startIcon={
                  downloadState ? (
                    <CircularProgress
                      size={20}
                      sx={{
                        height: "20px",
                        width: "20px",
                        color: "#FFFFFF !important",
                      }}
                    />
                  ) : (
                    <FileDownloadIcon fill="#FFFFFF" />
                  )
                }
              >
                Download
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      {/* </Grid> */}
      <Grid container size={12}>
        <Panel
          sx={{
            border: "1px solid #D5E1F9",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              padding: "20px",
              background: "#F7FAFF",
              borderRadius: "8px",
              boxShadow: "1px 1px 5px 0px rgba(1, 87, 184, 0.2)",
            }}
          >
            <Box
              sx={{
                padding: "20px",
                background: "#FFFFFF",
                borderRadius: "8px",
                border: "1px solid #D5E1F9",
              }}
            >
              <Grid container>
                <Grid
                  size={{ xs: 12, sm: 12, md: 9 }}
                  sx={{ display: { xs: "", sm: "flex" } }}
                  gap={2}
                >
                  <Grid size={{ xs: 12, sm: 8, md: 4 }}>
                    <Controller
                      name="client"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        const clientOptions = [
                          { value: "1", label: "DRS Lending" },
                          { value: "2", label: "ABC Lending" },
                        ];
                        const selectedOption =
                          clientOptions.find(
                            (option) => option.value === value
                          ) || null;
                        return (
                          <SelectComponent
                            options={clientOptions}
                            label="Search & Select Client"
                            fullWidth
                            backgroundColor="#ffffff"
                            value={selectedOption}
                            onChange={(newValue: any) => {
                              onChange(newValue ? newValue.value : null);
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid
                    size={{ xs: 12, sm: 8, md: 2.5 }}
                    sx={{ paddingTop: { xs: "10px", sm: "25px" } }}
                  >
                    <Controller
                      name="month"
                      control={control}
                      render={({ field }) => (
                        <CustomMonthPicker
                          {...field}
                          selectedMonthProp={
                            field.value ? Number(field.value) : null
                          }
                          onMonthSelect={(monthIndex) => {
                            field.onChange(
                              monthIndex !== null ? monthIndex.toString() : null
                            );
                          }}
                          style={{ height: "40px" }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    size={{ xs: 12, sm: 8, md: 2.5 }}
                    sx={{ paddingTop: { xs: "10px", sm: "25px" } }}
                  >
                    <Controller
                      name="year"
                      control={control}
                      render={({ field }) => (
                        <YearPicker
                          selectedYearProp={field.value}
                          onYearSelect={(selectedYear) => {
                            field.onChange(selectedYear);
                          }}
                          placeholder="Select Year"
                          style={{ height: "40px" }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  size={{ xs: 12, sm: 12, md: 3 }}
                  gap={2}
                  sx={{
                    marginTop: { xs: "15px", md: "" },
                    display: "flex",
                    justifyContent: { xs: "flex-start", sm: "flex-end" },
                    alignItems: { xs: "flex-start", sm: "flex-end" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      color: " #001F3D",
                      background: "#ffffff",
                      padding: "10px 16px",
                      borderRadius: "10px",
                      border: "1px solid #000000",
                      fontSize: "14px",
                      fontWeight: "700",
                      height: "48px",
                    }}
                    onClick={clearAll}
                  >
                    Clear
                  </Button>
                  <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={!isFormValid}
                    variant="contained"
                    color="primary"
                    sx={{
                      color: theme.palette.primary.contrastText,
                      background: theme.palette.primary.light,
                      padding: "10px 16px",
                      borderRadius: "10px",
                      fontSize: "14px",
                      fontWeight: "700",
                      height: "48px",
                    }}
                  >
                    Generate Invoice
                  </Button>
                </Grid>
              </Grid>
              {generateData && (
                <>
                  <Divider sx={{ marginY: "20px" }} />
                  <Grid container spacing={2}>
                    <Grid
                      size={{ xs: 12, sm: 7, lg: 8 }}
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                      }}
                    >
                      <Grid size={{ xs: 12, sm: 3, md: 2.3, lg: 1.8, xl: 1.3 }}>
                        <AvatarIcon />
                      </Grid>
                      <Grid
                        size={{ xs: 12, sm: 9, md: 9.7, lg: 10.2, xl: 11.3 }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            marginBottom: "10px",
                            fontWeight: "400",
                            color: "#374151",
                          }}
                        >
                          Client Name:
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#374151",
                            }}
                          >
                            &nbsp;ABC Lending
                          </Typography>
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            marginBottom: "10px",
                            fontWeight: "400",
                            color: "#374151",
                          }}
                        >
                          Email ID:
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#374151",
                            }}
                          >
                            &nbsp;abclending@gmail.com
                          </Typography>
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            marginBottom: "10px",
                            fontWeight: "400",
                            color: "#374151",
                          }}
                        >
                          Mobile No:
                          <Typography
                            component="span"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "600",
                              color: "#374151",
                            }}
                          >
                            &nbsp;(808) 762 9622
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      size={{ xs: 12, sm: 5, lg: 4 }}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: { xs: "flex-start", sm: "flex-end" },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: "#374151",
                        }}
                      >
                        Invoice ID:
                        <Typography
                          component="span"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#374151",
                          }}
                        >
                          &nbsp;#605
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: "#374151",
                        }}
                      >
                        Invoice Date:
                        <Typography
                          component="span"
                          sx={{
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "#374151",
                          }}
                        >
                          &nbsp;09/06/2023
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          marginBottom: "10px",
                          fontWeight: "400",
                          color: "#374151",
                          display: "flex",
                          alignItems: { xs: "", md: "center" },
                          flexDirection: { xs: "column", md: "row" },
                        }}
                      >
                        Due Date:
                        {!isEditing ? (
                          <>
                            <Typography
                              component="span"
                              sx={{
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "#374151",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              &nbsp;{selectedDate?.format("MM/DD/YYYY")}
                              <Box
                                ml={1}
                                sx={{ cursor: "pointer" }}
                                onClick={startEditingDate}
                              >
                                <PencilIcon />
                              </Box>
                            </Typography>
                          </>
                        ) : (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <DatePicker
                                value={selectedDate}
                                onChange={handleDateChange}
                                sx={{
                                  width: "150px",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  ml: { xs: 0, md: 1 },
                                  ".Mui-selected": {
                                    backgroundColor:
                                      theme.palette.primary.light,
                                    color: "inherit",
                                  },
                                  ".Mui-selected:hover": {
                                    backgroundColor:
                                      theme.palette.primary.light,
                                  },
                                }}
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: 1,
                                  flexDirection: { xs: "column", md: "row" },
                                }}
                              >
                                <Box
                                  onClick={confirmDateChange}
                                  sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <SelectIcon />
                                </Box>
                                <Box
                                  onClick={cancelEditing}
                                  sx={{
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <CloseIcon />
                                </Box>
                              </Box>
                            </Box>
                          </LocalizationProvider>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid size={12} rowSpacing={2} rowGap={5} marginTop={2}>
                    <DataTable
                      columns={[
                        {
                          label: "Product",
                          id: "product",
                          render: (row) => {
                            return (
                              <TruncatedTooltipText text={""}>
                                <Box
                                  sx={{
                                    cursor: "pointer",
                                    fontWeight: "600",
                                    textDecoration: "underline",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {row.product}
                                </Box>
                              </TruncatedTooltipText>
                            );
                          },
                        },
                        { label: "No. of Transaction", id: "noOfTransaction" },
                        {
                          label: "Price",
                          id: "totalPrice",
                          render: (row) => (
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                color: "#374151",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {!isEditingText ? (
                                <>
                                  ${row.totalPrice}
                                  <Box
                                    ml={1}
                                    sx={{ cursor: "pointer", display: "flex" }}
                                    onClick={() => {
                                      if (isValueChanged) {
                                        handleRefresh(row.id); // Handle refresh
                                      } else {
                                        startEditing(row.id, row.totalPrice); // Start editing
                                      }
                                    }}
                                  >
                                    {isValueChanged ? (
                                      <RefreshIcon />
                                    ) : (
                                      <PencilIcon />
                                    )}
                                  </Box>
                                </>
                              ) : (
                                <Box
                                  sx={{
                                    width: "200px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <InputComponent
                                    value={editingValue}
                                    onChange={handleInputChange}
                                    icon={<AttachMoneyIcon />}
                                    sx={{ width: "150px" }}
                                  />
                                  <Box
                                    sx={{
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      handleSaveValue(row.id);
                                      setIsEditingText(false);
                                    }}
                                  >
                                    <SelectIcon />
                                  </Box>
                                  <Box
                                    onClick={() => {
                                      setIsEditingText(false);
                                    }}
                                    sx={{
                                      cursor: "pointer",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CloseIcon />
                                  </Box>
                                </Box>
                              )}
                            </Typography>
                          ),
                        },
                      ]}
                      rows={BillingData || []}
                      // isLoading={isLoading}
                      orderBy={query.orderBy}
                      order={query.order}
                      createSortHandler={createSortHandler}
                      NodataTitle="Billing's"
                      footerRow={[
                        {
                          label: "Total Products:",
                          value: "03",
                        },
                        {
                          label: "Total Transactions:",
                          value: "09",
                        },
                        {
                          label: "Total Amount:",
                          value: "$891.00",
                        },
                      ]}
                    />
                  </Grid>
                  <Box
                    mt={2}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: theme.palette.primary.contrastText,
                        background: theme.palette.primary.light,
                        padding: "10px 16px",
                        borderRadius: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        height: "48px",
                      }}
                    >
                      Process Invoice Charges
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Panel>
      </Grid>
    </>
  );
};

export default AddBilling;
