import "./DtiPie.scss";

const DtiPie = ({ percentage = 60, label = "DTI Score" }) => {
  return (
   
<div className="progress">
  <svg className="progress_circle" viewBox="0 0 100 100">
    <circle className="progress_circle_bottom" cx="50" cy="50" r="45" />
    <circle
      className="progress_circle_top"
      cx="50"
      cy="50"
      r="45"
      style={{
        strokeDasharray: '283', 
        strokeDashoffset: `calc(283 - (283 * ${percentage}) / 100)`,
      }}
    />
  </svg>
  <div className="progress_content">
    <span className="progress_content_percentage">{percentage}%</span>
    <span className="progress_content_label">{label}</span>
  </div>
</div>

 
   
  );
};

export default DtiPie;