import { Suspense } from 'react';

import CircularLoader from './Loader/CircularLoader';

const Loadable = (Component: React.FC) => (props: any) => (
    <Suspense fallback={<CircularLoader progress={100} />}>
        <Component {...props} />
    </Suspense>
);

export default Loadable;
