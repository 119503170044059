import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Divider,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  useTheme,
  Link,
  Breadcrumbs,
} from "@mui/material";
import profileBackground from "../../assets/media/icons/profileBackground.png";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import Panel from "../../components/Panel";
import ActiveEllipseIcon from "../../assets/IconComponents/ActiveEllipseIcon";
import InactiveIcon from "../../assets/IconComponents/InactiveIcon";
import JobTtileIcon from "../../assets/IconComponents/JobTitleIcon";
import EmailIcon from "../../assets/IconComponents/EmailIcon";
import PhoneIcon from "../../assets/IconComponents/PhoneIcon";
import PasswordIcon from "../../assets/IconComponents/PasswordIcon";
import { Controller, useForm } from "react-hook-form";
import InputComponent from "../../components/form/input";
import EditModal from "../../components/form/EditModal";
import ToggleSwitch from "../../components/form/ToggleSwitch";
import { GetUserById, UpdateUser } from "../../api/UsersApi";
import { useAlertMessage } from "../../utils/useAlertMessage";
import { useParams } from "react-router-dom";
import CircularSpinner from "../../components/Loader/CircularLoader";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

interface UserProps {}
interface SectionProps {
  userInfo?: any;
  title: string;
  details: ProfileDetailRowProps[];
  fetchUserData: () => void;
}
interface ProfileDetailRowProps {
  icon?: React.ReactElement;
  label?: string;
  value?: string;
  subtitle?: string;
  endIcon?: React.ReactElement;
  component?: React.ReactElement;
}
interface User {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  email: string;
  secondaryPhone?: string;
  secondaryEmail?: string;
  status: string;
  password: string;
}

interface FormData {
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  email: string;
  addContact: boolean;
  secondaryPhone: string;
  secondaryEmail: string;
  password: string;
  confirmPassword: string;
  status: string;
}

const UsersprofileHeader = ({ userInfo }: any) => {
  return (
    <Box sx={{ position: "relative", textAlign: "center", width: "100%" }}>
      <Box
        component="img"
        src={profileBackground}
        alt="background"
        sx={{ width: "100%", height: "130px" }}
      />
      <Box
        sx={{
          background: "#142848",
          borderRadius: "50%",
          height: "175px",
          width: "175px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "20px solid #ffffff",
          position: "absolute",
          top: "50px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Typography
          sx={{ fontSize: "48px", fontWeight: "600", color: "#FFFFFF" }}
        >
          {(userInfo?.firstName || "")[0]}
          {(userInfo?.lastName || "")[0]}
        </Typography>
      </Box>
      <Box sx={{ marginTop: "90px", gap: "16px" }}>
        <Typography
          sx={{
            fontWeight: "800",
            fontSize: "30px",
            color: "#1E293B",
            textWrap: "wrap",
          }}
        >
          {userInfo?.firstName} {userInfo?.lastName}
        </Typography>
        <Typography
          sx={{ color: "#6B7280", fontWeight: "400", fontSize: "14px" }}
        >
          E-Mail ID: {userInfo?.email}
        </Typography>
      </Box>
    </Box>
  );
};

const UsersProfile: React.FC = () => {
  const {
    control: userControl,
    handleSubmit: handleUserSubmit,
    watch: watchUser,
    formState: { errors: userErrors, isValid: isUserValid },
    reset: resetUserForm,
  } = useForm<FormData>({ mode: "onChange" });
  const [userPassword, setUserPassword] = useState("");
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [userInfo, setUserData] = useState<User | null>(null);
  const { userId }: any = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const notification = useAlertMessage();

  const handleResetPasswordModalOpen = () => {
    setOpenResetPasswordModal(true);
  };

  const handleResetPasswordModalClose = () => {
    setOpenResetPasswordModal(false);
  };

  const handlePasswordReset = async (formData: FormData) => {
    setLoading(true);
    setProgress(0);
    try {
      if (!userInfo) {
        console.error("User data is not available");
        return;
      }
      const timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const updatedData = { ...userInfo, password: formData.password };
      const { status } = await UpdateUser(userInfo.id, updatedData);
      clearInterval(timer);
      setProgress(100);
      if (status === 200) {
        setOpenResetPasswordModal(false);
        fetchUserData();
        notification.successNotification(
          "Success",
          "User Updated successfully"
        );
      }
    } catch (error) {
      notification.errorNotification(
        "Error",
        (error as any).response.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async () => {
    setLoading(true);
    setProgress(90);
    let timer: NodeJS.Timeout | number = 0;
      try {
        const { data, status } = await GetUserById(userId);
        if (status === 200) {
          setUserData(data);
          setUserPassword(data.password || "-");
          resetUserForm(data);
        } else {
          navigate("/acucheck/users");
        }
        timer = setInterval(() => {
          setProgress((prev) => (prev < 90 ? prev + 10 : prev));
        }, 200);
      } catch (error) {
        notification.errorNotification(
          "Error",
          (error as any).response?.data.message
        );
      } finally {
        clearInterval(timer);
        setLoading(false);
      }
    }

  useEffect(() => {    
    fetchUserData();
  }, [resetUserForm]);


  const theme = useTheme();

  const userDetails = [
    {
      icon: <UserIconGray />,
      label: "First Name",
      value: userInfo?.firstName || "-",
    },
    {
      icon: <UserIconGray />,
      label: "Last Name",
      value: userInfo?.lastName || "-",
    },
    {
      icon: <JobTtileIcon />,
      label: "Job Title",
      value: userInfo?.jobTitle || "-",
    },
    {
      icon:
        userInfo?.status?.toLowerCase() === "active" ? (
          <ActiveEllipseIcon />
        ) : (
          <InactiveIcon />
        ),
      label: "Account Status",
      value:
        userInfo?.status?.toLowerCase() === "active" ? "Active" : "Inactive",
    },
  ];

  const contactDetails = [
    {
      icon: <PhoneIcon />,
      label: "Phone",
      value: userInfo?.phone || "-",
      subtitle: "Primary Contact Information",
    },
    {
      icon: <EmailIcon />,
      label: "Email",
      value: userInfo?.email || "-",
    },
    userInfo?.secondaryPhone
      ? {
          icon: <PhoneIcon />,
          label: "Phone",
          value: userInfo?.secondaryPhone,
          subtitle: "Secondary Contact Information",
        }
      : null,
    userInfo?.secondaryEmail
      ? {
          icon: <EmailIcon />,
          label: "Email",
          value: userInfo?.secondaryEmail,
        }
      : null,
  ].filter(Boolean) as ProfileDetailRowProps[];
  const userUpdatePassword = [
    {
      icon: <PasswordIcon />,
      label: "Password",
      value: "********",
    },
  ];

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/acucheck/users"}
      sx={{ width: "fit-content", display: "flex" }}
    >
      <PersonAddIcon
        sx={{ marginRight: 1, color: theme.palette.primary.main }}
      />
      <Typography sx={{ color: theme.palette.primary.main }}>Users</Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      {userInfo?.firstName} {userInfo?.lastName}
    </Typography>,
  ];

  return (
    <>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Panel>
      <Panel>
        <Box sx={{ padding: "20px" }}>
          <UsersprofileHeader userInfo={userInfo} />
          <Section
            title="Users Information"
            details={[...userDetails]}
            userInfo={userInfo}
           fetchUserData={fetchUserData}
          />
          <Section
            title="Contact Information"
            details={[...contactDetails]}
            userInfo={userInfo}
            fetchUserData={fetchUserData}
          />
          <Section
            title="User Password"
            details={[...userUpdatePassword]}
            userInfo={userInfo}
            fetchUserData={fetchUserData}
          />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", padding: "20px" }}
        >
          <Button
            size="large"
            variant="contained"
            onClick={handleResetPasswordModalOpen}
            sx={{
              borderRadius: "10px",
              fontSize: "16px",
              fontWeight: "600",
              backgroundColor: "#1F3A91",
              color: "white",
              "&:hover": { backgroundColor: "#1F3A91" },
            }}
          >
            Reset Password
          </Button>
        </Box>
        <EditModal
          open={openResetPasswordModal}
          onClose={handleResetPasswordModalClose}
          title="Reset Password"
          disabled={Object.keys(userErrors).length > 0}
          handleUpdate={handleUserSubmit((formData: FormData) => {
            handlePasswordReset(formData);
          })}
        >
          <Box
            sx={{ padding: "20px", background: "#F7FAFF", borderRadius: "6px" }}
          >
            <FormInputRow>
              <FormInputCol>
                <Controller
                  name="password"
                  control={userControl}
                  defaultValue=""
                  rules={{
                    required: "New Password is required",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                      message:
                        "Password must include at least one uppercase letter, one lowercase letter, one special character, and one number",
                    },
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputComponent
                      {...field}
                      icon={<PasswordIcon />}
                      label="New Password"
                      type="password"
                      placeholder="Enter new password"
                      password
                      error={error?.message || null}
                    />
                  )}
                />
              </FormInputCol>
              <FormInputCol>
                <Controller
                  name="confirmPassword"
                  control={userControl}
                  defaultValue=""
                  rules={{
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === watchUser("password") ||
                      "Passwords do not match",
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <InputComponent
                      {...field}
                      icon={<PasswordIcon />}
                      label="Confirm Password"
                      type="password"
                      placeholder="Re-enter new password"
                      password
                      error={error?.message || null}
                    />
                  )}
                />
              </FormInputCol>
            </FormInputRow>
          </Box>
        </EditModal>
        {loading && <CircularSpinner progress={progress} />}
      </Panel>
    </>
  );
};

const ProfileDetailRow: React.FC<ProfileDetailRowProps> = ({
  icon,
  label,
  value,
  endIcon,
}) => {
  return (
    <FormInputCol>
      <Typography
        sx={{
          marginBottom: "20px",
          fontSize: "14px",
          fontWeight: "600",
          color: "#374151",
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ paddingTop: "5px", marginRight: "10px" }}>{icon}</Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#6B7280",
            }}
          >
            {value}
          </Typography>
        </Box>
        {endIcon && <Box>{endIcon}</Box>}
      </Box>
    </FormInputCol>
  );
};

const Section: React.FC<SectionProps> = ({ userInfo, title, details,fetchUserData }) => {
  const {
    control: userControl,
    watch: watchUser,
    setValue,
    reset: resetUserForm,
    formState: { errors: userErrors },
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      addContact: !!(userInfo?.secondaryPhone || userInfo?.secondaryEmail),
    },
  });

  useEffect(() => {
    if (userInfo) {
      resetUserForm({
        addContact: !!(userInfo.secondaryPhone || userInfo.secondaryEmail),
        secondaryPhone: userInfo.secondaryPhone || "",
        secondaryEmail: userInfo.secondaryEmail || "",
      });
    }
  }, [userInfo, resetUserForm]);

  const [addContact, setAddContact] = useState(
    !!(userInfo?.secondaryPhone || userInfo?.secondaryEmail)
  );

  useEffect(() => {
    if (!!(userInfo?.secondaryPhone || userInfo?.secondaryEmail)) {
      setAddContact(!!(userInfo?.secondaryPhone || userInfo?.secondaryEmail));
    }
  }, [userInfo]);

  useEffect(() => {
    if (addContact === false) {
      setValue("secondaryPhone", "");
      setValue("secondaryEmail", "");
    } else {
      setValue("secondaryPhone", userInfo.secondaryPhone ?? "");
      setValue("secondaryEmail", userInfo.secondaryEmail ?? "");
    }
  }, [addContact]);

  const navigate = useNavigate();
  useEffect(() => {}, [userInfo]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [formFields, setFormFields] = useState<React.ReactNode>(null);

  const handleClose = () => {
    resetUserForm();
    setOpen(false);
  };

  const notification = useAlertMessage();

  const handleUpdate = async () => {
    const userId = userInfo?.id;
    const updatedUserData: Partial<User> = {
      id: userId,
      firstName: watchUser("firstName"),
      lastName: watchUser("lastName"),
      jobTitle: watchUser("jobTitle"),
      status: watchUser("status"),
      phone: watchUser("phone"),
      email: watchUser("email"),
      secondaryPhone: watchUser("secondaryPhone"),
      secondaryEmail: watchUser("secondaryEmail"),
    };

    try {
      setLoading(true);
      setProgress(0);
      const timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const { data, status } = await UpdateUser(userInfo.id, updatedUserData);
      clearInterval(timer);
      setProgress(100);
      if (status === 200) {
        notification.successNotification(
          "Success",
          "User Updated successfully"
        );
        handleClose();
        fetchUserData();
      } else {
        notification.errorNotification("Error", data.content);
      }
    } catch (err) {
      console.error("Error updating user:", err);
      notification.errorNotification(
        "Error",
        (err as any).response.data.message
      );
    } finally {
      setLoading(false);
    }
  };
  const handleOpen = () => {
    setFormFields(title);
    setOpen(true);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{ fontSize: "16px", fontWeight: "700", color: "#000000" }}
        >
          {title}
        </Typography>
        {title !== "User Password" && (
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              color: "#264D8E",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleOpen}
          >
            EDIT DETAILS
          </Typography>
        )}
      </Box>
      <Box
        sx={{ padding: "20px", background: "#F7FAFF", margin: "20px 0 0 0" }}
      >
        <Box
          sx={{ borderRadius: "4px", background: "#FFFFFF", padding: "20px" }}
        >
          {details.map((detail, index) => (
            <Fragment key={index}>
              {detail.subtitle && (
                <Typography
                  sx={{
                    marginBottom: "10px",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#000000",
                  }}
                >
                  {detail.subtitle}
                </Typography>
              )}
              {index % 2 === 0 && (
                <FormInputRow>
                  <ProfileDetailRow
                    icon={detail.icon}
                    label={detail.label}
                    value={detail.value}
                    endIcon={detail.endIcon}
                  />
                  {details[index + 1] && (
                    <ProfileDetailRow
                      icon={details[index + 1].icon}
                      label={details[index + 1].label}
                      value={details[index + 1].value}
                      endIcon={details[index + 1].endIcon}
                    />
                  )}
                </FormInputRow>
              )}
              {index < details.length - 2 && index % 2 === 0 && (
                <Divider
                  sx={{
                    width: "100%",
                    border: "1px dashed #D5E1F9",
                    margin: "20px 0",
                  }}
                />
              )}
            </Fragment>
          ))}
        </Box>
      </Box>

      <EditModal
        open={open}
        onClose={handleClose}
        title={`Edit ${title}`}
        handleUpdate={handleUpdate}
        disabled={Object.keys(userErrors).length > 0}
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #DBE9FE",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{ padding: "20px", background: "#F7FAFF", borderRadius: "6px" }}
          >
            {formFields === "Users Information" && (
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="firstName"
                    control={userControl}
                    defaultValue={userInfo?.firstName}
                    rules={{
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "First name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<UserIconGray />}
                        label="First Name"
                        placeholder="Enter first name"
                        error={userErrors.firstName?.message || null}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol>
                  <Controller
                    name="lastName"
                    control={userControl}
                    defaultValue={userInfo?.lastName}
                    rules={{
                      required: "Last name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Last name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<UserIconGray />}
                        label="Last Name"
                        placeholder="Enter last name"
                        error={userErrors.lastName?.message || null}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol>
                  <Controller
                    name="jobTitle"
                    control={userControl}
                    defaultValue={userInfo?.jobTitle}
                    rules={{
                      required: "Job title is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Job title can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<JobTtileIcon />}
                        label="Job Title"
                        placeholder="Enter job title"
                        error={userErrors.jobTitle?.message || null}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol>
                  <Controller
                    name="status"
                    control={userControl}
                    defaultValue={userInfo?.status || "inactive"}
                    render={({ field: { onChange, value } }) => (
                      <ToggleSwitch
                        checked={value === "active"}
                        onChange={(checked) => {
                          const newValue = checked ? "active" : "inactive";
                          onChange(newValue);
                        }}
                        labelActive="Active"
                        labelInactive="Inactive"
                        label="Account Access"
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
            {formFields === "Contact Information" && (
              <FormInputRow>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Typography
                    gutterBottom
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#374151",
                    }}
                  >
                    Primary Contact Information
                  </Typography>
                </FormInputCol>
                <FormInputCol md={6}>
                              <Controller
                                name="email"
                                control={userControl}
                                defaultValue={userInfo.email}
                                rules={{
                                  required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Enter a valid email address",
                                  },
                                  validate: (value) => {
                                    return (
                                      value !== watchUser("secondaryEmail") ||
                                      "Primary and secondary emails cannot be the same"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <InputComponent
                                    {...field}
                                    label="Email Address"
                                    icon={<EmailIcon />}
                                    placeholder="Enter email address"
                                    error={
                                      (userErrors.email?.message as string) || null
                                    }
                                  />
                                )}
                              />
                            </FormInputCol>
                            <FormInputCol md={6}>
                              <Controller
                                name="phone"
                                control={userControl}
                                defaultValue={userInfo.phone}
                                rules={{
                                  required: "Mobile Number is required",
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Mobile Number must be exactly 10 digits",
                                  },
                                  validate: (value) => {
                                    return (
                                      value !== watchUser("secondaryPhone") ||
                                      "Primary and secondary phone numbers cannot be the same"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <InputComponent
                                    {...field}
                                    label="Mobile Number"
                                    maxLength={10}
                                    minLength={10}
                                    icon={<PhoneIcon />}
                                    placeholder="Enter mobile number"
                                    error={
                                      (userErrors.phone?.message as string) || null
                                    }
                                  />
                                )}
                              />
                            </FormInputCol>
                <FormInputCol lg={12} md={12} xl={12}>
                  <Divider sx={{ border: "1px dashed #D5E1F9" }} />
                </FormInputCol>
                <Controller
                  name="addContact"
                  control={userControl}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={watchUser("addContact")}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setAddContact(isChecked);
                            field.onChange(isChecked);
                          }}
                          sx={{ paddingLeft: "20px" }}
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ fontSize: 14 }}>
                          Do you want to add contact? (additional contacts are
                          marked as secondary contact in the database)
                        </Typography>
                      }
                    />
                  )}
                />

                {addContact && (
                  <>
                    <FormInputCol lg={12} md={12} xl={12}>
                      <Typography
                        gutterBottom
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#374151",
                        }}
                      >
                        Secondary Contact Information
                      </Typography>
                    </FormInputCol>
                    <FormInputCol md={6}>
                              <Controller
                                name="secondaryEmail"
                                control={userControl}
                                defaultValue=""
                                rules={{
                                  required: "Email is required",
                                  pattern: {
                                    value:
                                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: "Enter a valid email address",
                                  },
                                  validate: (value) => {
                                    return (
                                      value !== watchUser("email") ||
                                      "Primary and secondary emails cannot be the same"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <InputComponent
                                    {...field}
                                    label="Email Address"
                                    icon={<EmailIcon />}
                                    placeholder="Enter email address"
                                    error={
                                      (userErrors.secondaryEmail
                                        ?.message as string) || null
                                    }
                                  />
                                )}
                              />
                            </FormInputCol>
                            <FormInputCol md={6}>
                              <Controller
                                name="secondaryPhone"
                                control={userControl}
                                defaultValue=""
                                rules={{
                                  required: "Mobile Number is required",
                                  pattern: {
                                    value: /^[0-9]{10}$/,
                                    message: "Mobile Number must be exactly 10 digits",
                                  },
                                  validate: (value) => {
                                    return (
                                      value !== watchUser("phone") ||
                                      "Primary and secondary phone numbers cannot be the same"
                                    );
                                  },
                                }}
                                render={({ field }) => (
                                  <InputComponent
                                    {...field}
                                    maxLength={10}
                                    minLength={10}
                                    label="Mobile Number"
                                    icon={<PhoneIcon />}
                                    placeholder="Enter mobile number"
                                    error={
                                      (userErrors.secondaryPhone
                                        ?.message as string) || null
                                    }
                                  />
                                )}
                              />
                            </FormInputCol>
                  </>
                )}
              </FormInputRow>
            )}
          </Box>
        </Box>
      </EditModal>
      {loading && <CircularSpinner progress={progress} />}
    </Box>
  );
};

export default UsersProfile;
