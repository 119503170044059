const NoDetailsIcon = () => {
  return (
    <svg width="142" height="118" viewBox="0 0 142 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M93 6.35254C94.6569 6.35254 96 5.00939 96 3.35254C96 1.69568 94.6569 0.352539 93 0.352539C91.3431 0.352539 90 1.69568 90 3.35254C90 5.00939 91.3431 6.35254 93 6.35254Z" fill="#EFF6FF" />
      <path d="M126.299 90.9514C129.226 90.9514 131.599 88.5788 131.599 85.652C131.599 82.7252 129.226 80.3525 126.299 80.3525C123.373 80.3525 121 82.7252 121 85.652C121 88.5788 123.373 90.9514 126.299 90.9514Z" fill="#EFF6FF" />
      <path d="M18.7853 13.1661C20.8759 13.1661 22.5706 11.6408 22.5706 9.75932C22.5706 7.87781 20.8759 6.35254 18.7853 6.35254C16.6947 6.35254 15 7.87781 15 9.75932C15 11.6408 16.6947 13.1661 18.7853 13.1661Z" fill="#EFF6FF" />
      <path d="M44.5424 95.4373C47.0511 95.4373 49.0847 93.4036 49.0847 90.8949C49.0847 88.3862 47.0511 86.3525 44.5424 86.3525C42.0337 86.3525 40 88.3862 40 90.8949C40 93.4036 42.0337 95.4373 44.5424 95.4373Z" fill="#EFF6FF" />
      <path d="M126.138 9.05322V6.67023L124.919 6.5594C124.755 5.76518 124.435 5.01156 123.976 4.34266L124.752 3.40055L123.09 1.738L122.148 2.51385C121.479 2.05551 120.725 1.73523 119.931 1.57174L119.82 0.352539H117.437L117.326 1.57174C116.535 1.74582 115.784 2.06527 115.109 2.51385L114.167 1.738L112.505 3.40055L113.281 4.34266C112.841 5.01522 112.539 5.76885 112.394 6.5594L111.175 6.67023V9.05322L112.394 9.16406C112.539 9.93747 112.841 10.673 113.281 11.3254L112.505 12.3229L114.167 13.9855L115.109 13.2096C115.787 13.641 116.538 13.9417 117.326 14.0963L117.437 15.3155H119.82L119.931 14.0963C120.721 13.9512 121.475 13.6497 122.148 13.2096L123.09 13.9855L124.752 12.3229L123.976 11.3254C124.435 10.6768 124.755 9.94124 124.919 9.16406L126.138 9.05322ZM118.656 11.0483C118.021 11.0483 117.399 10.8598 116.871 10.5066C116.342 10.1534 115.93 9.65139 115.687 9.06406C115.443 8.47673 115.38 7.83045 115.504 7.20695C115.628 6.58344 115.934 6.01071 116.383 5.56119C116.833 5.11167 117.406 4.80554 118.029 4.68151C118.653 4.55749 119.299 4.62114 119.886 4.86443C120.474 5.10771 120.976 5.51968 121.329 6.04827C121.682 6.57685 121.871 7.1983 121.871 7.83402C121.871 8.68649 121.532 9.50405 120.929 10.1068C120.326 10.7096 119.509 11.0483 118.656 11.0483Z" fill="#A7C8FF" />
      <path d="M135.78 15.7598V13.8756H134.838C134.7 13.2654 134.456 12.684 134.118 12.1576L134.727 11.3818L133.397 10.0517L132.677 10.6059C132.135 10.2705 131.533 10.0445 130.904 9.94089V8.94336H129.019V9.94089C128.39 10.0445 127.788 10.2705 127.246 10.6059L126.526 9.99631L125.196 11.3264L125.805 12.1022C125.467 12.6286 125.223 13.21 125.085 13.8202H124.143V15.7044L125.085 15.8152C125.243 16.4189 125.486 16.9972 125.805 17.5332L125.196 18.2536L126.526 19.5837L127.246 18.9741C127.797 19.3037 128.395 19.5467 129.019 19.6945V20.6366H130.904V19.7499C131.528 19.6021 132.126 19.3591 132.677 19.0295L133.397 19.6391L134.727 18.3091L134.118 17.5886C134.437 17.0526 134.68 16.4743 134.838 15.8707L135.78 15.7598ZM129.906 17.367C129.234 17.3528 128.594 17.0797 128.119 16.6046C127.644 16.1296 127.371 15.4894 127.357 14.8177C127.357 14.1416 127.625 13.4932 128.103 13.0151C128.582 12.537 129.23 12.2685 129.906 12.2685C130.582 12.2685 131.231 12.537 131.709 13.0151C132.187 13.4932 132.455 14.1416 132.455 14.8177C132.441 15.4894 132.168 16.1296 131.693 16.6046C131.218 17.0797 130.578 17.3528 129.906 17.367Z" fill="#A7C8FF" />
      <path d="M126.137 21.0245V19.4728H125.306C125.22 18.9508 125.011 18.4569 124.697 18.0319L125.251 17.3669L124.142 16.2585L123.477 16.8127C123.045 16.5109 122.554 16.3033 122.036 16.2031V15.3164H120.485L120.374 16.1477C119.863 16.2691 119.376 16.4752 118.933 16.7573L118.323 16.2031L117.215 17.3115L117.714 17.9765C117.422 18.4145 117.216 18.9032 117.104 19.4174H116.328V21.0245L117.104 21.1353C117.226 21.6462 117.432 22.1333 117.714 22.5762L117.215 23.1858L118.323 24.2942L118.933 23.7954C119.376 24.0775 119.863 24.2836 120.374 24.405L120.485 25.1809H122.036V24.405C122.554 24.3049 123.045 24.0972 123.477 23.7954L124.142 24.2942L125.251 23.1858L124.697 22.5762C125.001 22.1455 125.209 21.654 125.306 21.1353L126.137 21.0245ZM121.205 22.41C120.642 22.3955 120.106 22.1614 119.713 21.7577C119.319 21.3539 119.099 20.8124 119.099 20.2486C119.099 19.6901 119.321 19.1545 119.716 18.7595C120.111 18.3646 120.647 18.1427 121.205 18.1427C121.764 18.1427 122.299 18.3646 122.694 18.7595C123.089 19.1545 123.311 19.6901 123.311 20.2486C123.311 20.8124 123.091 21.3539 122.698 21.7577C122.304 22.1614 121.769 22.3955 121.205 22.41Z" fill="#A7C8FF" />
      <path d="M24.0677 82.8501V80.4671L22.8485 80.3563C22.685 79.5621 22.3647 78.8084 21.9064 78.1395L22.6822 77.1974L21.0197 75.5349L20.0776 76.3107C19.4087 75.8524 18.655 75.5321 17.8608 75.3686L17.75 74.1494H15.367L15.2562 75.3686C14.4653 75.5427 13.7136 75.8621 13.0394 76.3107L12.0973 75.5349L10.4348 77.1974L11.2106 78.1395C10.7705 78.8121 10.4691 79.5657 10.3239 80.3563L9.10474 80.4671V82.8501L10.3239 82.9609C10.4689 83.7343 10.7706 84.4699 11.2106 85.1223L10.4348 86.1198L12.0973 87.7823L13.0394 87.0065C13.7165 87.4379 14.4684 87.7386 15.2562 87.8932L15.367 89.1124H17.75L17.8608 87.8932C18.6514 87.748 19.405 87.4466 20.0776 87.0065L21.0197 87.7823L22.6822 86.1198L21.9064 85.1223C22.3646 84.4736 22.6852 83.7381 22.8485 82.9609L24.0677 82.8501ZM16.5862 84.8452C15.9505 84.8452 15.329 84.6566 14.8005 84.3035C14.2719 83.9503 13.8599 83.4483 13.6166 82.8609C13.3733 82.2736 13.3097 81.6273 13.4337 81.0038C13.5577 80.3803 13.8639 79.8076 14.3134 79.3581C14.7629 78.9085 15.3356 78.6024 15.9591 78.4784C16.5826 78.3544 17.2289 78.418 17.8163 78.6613C18.4036 78.9046 18.9056 79.3166 19.2588 79.8451C19.612 80.3737 19.8005 80.9952 19.8005 81.6309C19.8005 82.4834 19.4618 83.3009 18.859 83.9037C18.2562 84.5065 17.4387 84.8452 16.5862 84.8452Z" fill="#A7C8FF" />
      <path d="M33.7101 89.5567V87.6725H32.768C32.6297 87.0622 32.3859 86.4809 32.0476 85.9545L32.6572 85.1786L31.3271 83.8486L30.6067 84.4028C30.0647 84.0674 29.4622 83.8414 28.8333 83.7378V82.7402H26.9491V83.7378C26.3202 83.8414 25.7177 84.0674 25.1757 84.4028L24.4552 83.7932L23.1252 85.1232L23.7348 85.8991C23.3965 86.4254 23.1527 87.0068 23.0144 87.6171H22.0723V89.5013L23.0144 89.6121C23.1727 90.2157 23.4153 90.7941 23.7348 91.3301L23.1252 92.0505L24.4552 93.3806L25.1757 92.771C25.7264 93.1006 26.3245 93.3435 26.9491 93.4914V94.4335H28.8333V93.5468C29.4579 93.399 30.056 93.156 30.6067 92.8264L31.3271 93.436L32.6572 92.1059L32.0476 91.3855C32.3671 90.8495 32.6097 90.2712 32.768 89.6675L33.7101 89.5567ZM27.8358 91.1638C27.1641 91.1497 26.5239 90.8766 26.0488 90.4015C25.5738 89.9265 25.3007 89.2862 25.2865 88.6146C25.2865 87.9385 25.5551 87.2901 26.0332 86.812C26.5113 86.3339 27.1597 86.0653 27.8358 86.0653C28.5119 86.0653 29.1603 86.3339 29.6384 86.812C30.1164 87.2901 30.385 87.9385 30.385 88.6146C30.3709 89.2862 30.0978 89.9265 29.6227 90.4015C29.1477 90.8766 28.5074 91.1497 27.8358 91.1638Z" fill="#A7C8FF" />
      <path d="M24.0671 94.8214V93.2697H23.2358C23.1501 92.7477 22.9411 92.2538 22.6262 91.8288L23.1804 91.1638L22.072 90.0554L21.407 90.6096C20.9747 90.3078 20.4838 90.1001 19.9661 90V89.1133H18.4144L18.3036 89.9446C17.7927 90.066 17.3057 90.2721 16.8627 90.5542L16.2531 90L15.1448 91.1083L15.6435 91.7734C15.3521 92.2114 15.1453 92.7001 15.0339 93.2142H14.2581V94.8214L15.0339 94.9322C15.1554 95.4431 15.3614 95.9301 15.6435 96.3731L15.1448 96.9827L16.2531 98.0911L16.8627 97.5923C17.3057 97.8744 17.7927 98.0804 18.3036 98.2019L18.4144 98.9778H19.9661V98.2019C20.4838 98.1017 20.9747 97.8941 21.407 97.5923L22.072 98.0911L23.1804 96.9827L22.6262 96.3731C22.931 95.9424 23.1389 95.4508 23.2358 94.9322L24.0671 94.8214ZM19.1349 96.2068C18.5713 96.1924 18.0357 95.9583 17.6423 95.5545C17.2489 95.1508 17.0288 94.6093 17.029 94.0455C17.029 93.487 17.2509 92.9514 17.6458 92.5564C18.0407 92.1615 18.5763 91.9396 19.1349 91.9396C19.6934 91.9396 20.229 92.1615 20.624 92.5564C21.0189 92.9514 21.2408 93.487 21.2408 94.0455C21.241 94.6093 21.0209 95.1508 20.6274 95.5545C20.234 95.9583 19.6984 96.1924 19.1349 96.2068Z" fill="#A7C8FF" />
      <path d="M69 117.648C107.108 117.648 138 115.847 138 113.627C138 111.406 107.108 109.605 69 109.605C30.8924 109.605 0 111.406 0 113.627C0 115.847 30.8924 117.648 69 117.648Z" fill="#EFEFEF" />
      <g filter="url(#filter0_d_72_15694)">
        <path d="M125.214 13.5596V85.1929C125.197 86.0401 124.855 86.8481 124.259 87.4506C123.663 88.0531 122.859 88.404 122.012 88.4312H18.9566C17.2485 88.4312 15.9674 86.901 15.9674 85.1929V13.5596C15.9579 13.1611 16.0282 12.7647 16.1742 12.3938C16.3201 12.0229 16.5388 11.6849 16.8173 11.3998C17.0959 11.1146 17.4286 10.888 17.796 10.7334C18.1634 10.5787 18.558 10.4991 18.9566 10.4992H122.012C122.84 10.4895 123.639 10.8043 124.237 11.3763C124.836 11.9484 125.187 12.7321 125.214 13.5596Z" fill="#D5E4FF" />
      </g>
      <g opacity="0.1" filter="url(#filter1_d_72_15694)">
        <path d="M125.214 22.4912V85.1926C125.197 86.0398 124.854 86.8478 124.259 87.4503C123.663 88.0528 122.859 88.4037 122.012 88.4309H18.9565C17.2484 88.4309 15.9673 86.9007 15.9673 85.1926V60.7811C25.0416 43.3442 46.6419 40.533 71.943 50.7104C107.777 65.1581 123.044 45.5505 125.214 22.4912Z" fill="#2068E1" />
      </g>
      <path opacity="0.2" d="M97.0661 80.2464H44.7912C44.3193 80.2464 43.8668 80.0589 43.5331 79.7253C43.1994 79.3916 43.012 78.939 43.012 78.4671C43.012 77.9952 43.1994 77.5427 43.5331 77.209C43.8668 76.8753 44.3193 76.6879 44.7912 76.6879H97.0661C97.3028 76.6831 97.538 76.7256 97.758 76.8129C97.978 76.9002 98.1783 77.0306 98.3473 77.1962C98.5163 77.3619 98.6506 77.5597 98.7422 77.7779C98.8339 77.9961 98.881 78.2304 98.881 78.4671C98.881 78.7038 98.8339 78.9381 98.7422 79.1563C98.6506 79.3746 98.5163 79.5723 98.3473 79.738C98.1783 79.9037 97.978 80.034 97.758 80.1213C97.538 80.2086 97.3028 80.2511 97.0661 80.2464Z" fill="black" />
      <path d="M44.7913 79.8898C44.4138 79.8898 44.0518 79.7398 43.7848 79.4729C43.5179 79.2059 43.3679 78.8439 43.3679 78.4664C43.3679 78.0889 43.5179 77.7268 43.7848 77.4599C44.0518 77.1929 44.4138 77.043 44.7913 77.043H75.715C76.0925 77.043 76.4546 77.1929 76.7215 77.4599C76.9885 77.7268 77.1384 78.0889 77.1384 78.4664C77.1384 78.8439 76.9885 79.2059 76.7215 79.4729C76.4546 79.7398 76.0925 79.8898 75.715 79.8898H44.7913Z" fill="#264D8E" />
      <path opacity="0.7" d="M44.7913 79.8898C44.4138 79.8898 44.0518 79.7398 43.7848 79.4729C43.5179 79.2059 43.3679 78.8439 43.3679 78.4664C43.3679 78.0889 43.5179 77.7268 43.7848 77.4599C44.0518 77.1929 44.4138 77.043 44.7913 77.043H75.715C76.0925 77.043 76.4546 77.1929 76.7215 77.4599C76.9885 77.7268 77.1384 78.0889 77.1384 78.4664C77.1384 78.8439 76.9885 79.2059 76.7215 79.4729C76.4546 79.7398 76.0925 79.8898 75.715 79.8898H44.7913Z" fill="white" />
      <path opacity="0.2" d="M51.4872 13.9521H32.5345C31.1814 13.9521 30.0845 15.1222 30.0845 16.5656V36.7909C30.0845 38.2343 31.1814 39.4043 32.5345 39.4043H51.4872C52.8403 39.4043 53.9372 38.2343 53.9372 36.7909V16.5656C53.9372 15.1222 52.8403 13.9521 51.4872 13.9521Z" fill="black" />
      <path d="M54.9335 10.6426H34.8634C33.4287 10.6426 32.2656 11.8056 32.2656 13.2403V33.3104C32.2656 34.7451 33.4287 35.9082 34.8634 35.9082H54.9335C56.3682 35.9082 57.5312 34.7451 57.5312 33.3104V13.2403C57.5312 11.8056 56.3682 10.6426 54.9335 10.6426Z" fill="#A5C3F8" />
      <path opacity="0.3" d="M54.9335 10.6426H34.8634C33.4287 10.6426 32.2656 11.8056 32.2656 13.2403V33.3104C32.2656 34.7451 33.4287 35.9082 34.8634 35.9082H54.9335C56.3682 35.9082 57.5312 34.7451 57.5312 33.3104V13.2403C57.5312 11.8056 56.3682 10.6426 54.9335 10.6426Z" fill="#9CBDF7" />
      <path opacity="0.2" d="M57.6679 10.6426H37.5977C36.163 10.6426 35 11.8056 35 13.2403V33.3104C35 34.7451 36.163 35.9082 37.5977 35.9082H57.6679C59.1026 35.9082 60.2656 34.7451 60.2656 33.3104V13.2403C60.2656 11.8056 59.1026 10.6426 57.6679 10.6426Z" fill="white" />
      <path opacity="0.2" d="M99.6552 44.1953H80.5738C79.1993 44.1953 78.085 45.329 78.085 46.7275V66.1503C78.085 67.5488 79.1993 68.6825 80.5738 68.6825H99.6552C101.03 68.6825 102.144 67.5488 102.144 66.1503V46.7275C102.144 45.329 101.03 44.1953 99.6552 44.1953Z" fill="black" />
      <path d="M102.215 42.7002H83.7108C82.3744 42.7002 81.291 43.7836 81.291 45.12V63.6351C81.291 64.9715 82.3744 66.0549 83.7108 66.0549H102.215C103.552 66.0549 104.635 64.9715 104.635 63.6351V45.12C104.635 43.7836 103.552 42.7002 102.215 42.7002Z" fill="#9ABDF9" />
      <path opacity="0.2" d="M102.215 42.7002H83.7108C82.3744 42.7002 81.291 43.7836 81.291 45.12V63.6351C81.291 64.9715 82.3744 66.0549 83.7108 66.0549H102.215C103.552 66.0549 104.635 64.9715 104.635 63.6351V45.12C104.635 43.7836 103.552 42.7002 102.215 42.7002Z" fill="#005FFF" />
      <path d="M93.8531 58.1089C93.8531 58.3354 93.7631 58.5527 93.603 58.7128C93.4428 58.873 93.2256 58.963 92.9991 58.963C92.7726 58.963 92.5553 58.873 92.3952 58.7128C92.235 58.5527 92.145 58.3354 92.145 58.1089C92.145 57.8824 92.235 57.6652 92.3952 57.505C92.5553 57.3449 92.7726 57.2549 92.9991 57.2549C93.2256 57.2549 93.4428 57.3449 93.603 57.505C93.7631 57.6652 93.8531 57.8824 93.8531 58.1089Z" fill="white" />
      <path d="M92.9275 49.71C90.7096 49.71 88.5826 50.591 87.0143 52.1593C85.446 53.7276 84.5649 55.8546 84.5649 58.0725H91.255C91.2444 57.8517 91.2807 57.6311 91.3615 57.4253C91.4423 57.2196 91.5658 57.0332 91.7238 56.8786C91.8818 56.7239 92.0707 56.6045 92.2782 56.5282C92.4857 56.4518 92.707 56.4203 92.9275 56.4356L93.4257 56.5068L96.5928 52.8771L94.3509 57.1829C94.5407 57.4402 94.6408 57.7528 94.6356 58.0725H101.326C101.316 55.8514 100.427 53.7244 98.8533 52.1571C97.2794 50.5899 95.1487 49.7099 92.9275 49.71Z" fill="white" />
      <path opacity="0.2" d="M103.212 42.7002H84.7072C83.3707 42.7002 82.2874 43.7836 82.2874 45.12V63.6351C82.2874 64.9715 83.3707 66.0549 84.7072 66.0549H103.212C104.548 66.0549 105.631 64.9715 105.631 63.6351V45.12C105.631 43.7836 104.548 42.7002 103.212 42.7002Z" fill="white" />
      <path opacity="0.2" d="M103.98 20.9619H87.3941C86.2008 20.9619 85.2334 21.9306 85.2334 23.1256V39.7436C85.2334 40.9386 86.2008 41.9073 87.3941 41.9073H103.98C105.173 41.9073 106.14 40.9386 106.14 39.7436V23.1256C106.14 21.9306 105.173 20.9619 103.98 20.9619Z" fill="black" />
      <path d="M106.639 18.791H90.3405C89.181 18.791 88.241 19.731 88.241 20.8906V37.1851C88.241 38.3446 89.181 39.2846 90.3405 39.2846H106.639C107.798 39.2846 108.738 38.3446 108.738 37.1851V20.8906C108.738 19.731 107.798 18.791 106.639 18.791Z" fill="#9CBDF7" />
      <path d="M103.667 23.667H94.3334C93.7501 23.667 93.1667 24.192 93.1667 24.8337V26.5895C93.1667 27.0095 93.4176 27.3712 93.7501 27.5753V34.167C93.7501 34.8087 94.3917 35.3337 94.9167 35.3337H103.083C103.608 35.3337 104.25 34.8087 104.25 34.167V27.5753C104.583 27.3712 104.833 27.0095 104.833 26.5895V24.8337C104.833 24.192 104.25 23.667 103.667 23.667ZM100.75 30.667H97.2501V29.5003H100.75V30.667ZM103.667 26.5837H94.3334V24.8337H103.667V26.5837Z" fill="white" />
      <path opacity="0.1" d="M106.639 18.791H90.3405C89.181 18.791 88.241 19.731 88.241 20.8906V37.1851C88.241 38.3446 89.181 39.2846 90.3405 39.2846H106.639C107.798 39.2846 108.738 38.3446 108.738 37.1851V20.8906C108.738 19.731 107.798 18.791 106.639 18.791Z" fill="#83ACF5" />
      <path opacity="0.2" d="M117.398 18.5H101.1C99.94 18.5 99 19.44 99 20.5995V36.8941C99 38.0536 99.94 38.9936 101.1 38.9936H117.398C118.557 38.9936 119.497 38.0536 119.497 36.8941V20.5995C119.497 19.44 118.557 18.5 117.398 18.5Z" fill="white" fillOpacity="0.4" />
      <path opacity="0.2" d="M69.3832 42.3232H48.8906C47.4275 42.3232 46.2415 43.5469 46.2415 45.0564V66.2082C46.2415 67.7177 47.4275 68.9413 48.8906 68.9413H69.3832C70.8462 68.9413 72.0323 67.7177 72.0323 66.2082V45.0564C72.0323 43.5469 70.8462 42.3232 69.3832 42.3232Z" fill="black" />
      <path d="M71.641 40.1875H51.5709C50.1362 40.1875 48.9731 41.3505 48.9731 42.7852V62.8554C48.9731 64.2901 50.1362 65.4531 51.5709 65.4531H71.641C73.0757 65.4531 74.2387 64.2901 74.2387 62.8554V42.7852C74.2387 41.3505 73.0757 40.1875 71.641 40.1875Z" fill="#D0D9EB" />
      <path opacity="0.5" d="M71.641 40.1875H51.5709C50.1362 40.1875 48.9731 41.3505 48.9731 42.7852V62.8554C48.9731 64.2901 50.1362 65.4531 51.5709 65.4531H71.641C73.0757 65.4531 74.2387 64.2901 74.2387 62.8554V42.7852C74.2387 41.3505 73.0757 40.1875 71.641 40.1875Z" fill="#3680FF" />
      <path opacity="0.2" d="M89.1927 7.58008H64.3824C62.6083 7.58008 61.1702 8.8869 61.1702 10.4989V33.0548C61.1702 34.6668 62.6083 35.9736 64.3824 35.9736H89.1927C90.9667 35.9736 92.4049 34.6668 92.4049 33.0548V10.4989C92.4049 8.8869 90.9667 7.58008 89.1927 7.58008Z" fill="black" />
      <path d="M66.2139 33.2021H90.4119C92.1414 33.2021 93.5435 31.8001 93.5435 30.0706V5.87971C93.5435 4.15022 92.1414 2.7482 90.4119 2.7482L66.2139 2.7482C64.4844 2.7482 63.0824 4.15022 63.0824 5.87971V30.0706C63.0824 31.8001 64.4844 33.2021 66.2139 33.2021Z" fill="#9CBDF7" />
      <path opacity="0.5" d="M66.2139 33.2021H90.4119C92.1414 33.2021 93.5435 31.8001 93.5435 30.0706V5.87971C93.5435 4.15022 92.1414 2.7482 90.4119 2.7482L66.2139 2.7482C64.4844 2.7482 63.0824 4.15022 63.0824 5.87971V30.0706C63.0824 31.8001 64.4844 33.2021 66.2139 33.2021Z" fill="#83ACF5" />
      <path opacity="0.2" d="M68.1314 33.2002H92.3294C94.0589 33.2002 95.4609 31.7982 95.4609 30.0687V5.87776C95.4609 4.14827 94.0589 2.74625 92.3294 2.74625L68.1314 2.74625C66.4019 2.74625 64.9999 4.14827 64.9999 5.87776V30.0687C64.9999 31.7982 66.4019 33.2002 68.1314 33.2002Z" fill="white" />
      <path d="M132.164 34.7034C131.945 34.6604 131.945 34.3723 132.164 34.3294L135.469 33.6804C135.549 33.6647 135.611 33.6073 135.628 33.5339L136.34 30.5032C136.387 30.3023 136.698 30.3023 136.745 30.5032L137.456 33.5339C137.473 33.6073 137.536 33.6647 137.615 33.6804L140.921 34.3294C141.139 34.3723 141.139 34.6604 140.921 34.7034L137.615 35.3524C137.536 35.3681 137.473 35.4254 137.456 35.4989L136.745 38.5296C136.698 38.7304 136.387 38.7304 136.34 38.5296L135.628 35.4989C135.611 35.4254 135.549 35.3681 135.469 35.3524L132.164 34.7034Z" fill="#A3BDEA" />
      <path d="M7.69703 17.5456C7.74825 17.2882 8.0936 17.2882 8.14482 17.5456L8.9098 21.396C8.92843 21.4897 8.99699 21.5633 9.08494 21.5834L12.6617 22.4137C12.9019 22.4695 12.9019 22.8345 12.6617 22.8902L9.08494 23.7201C8.99699 23.7406 8.92843 23.8142 8.9098 23.9079L8.14482 27.7583C8.0936 28.0158 7.74825 28.0158 7.69703 27.7583L6.93205 23.9079C6.91342 23.8142 6.84486 23.7406 6.75687 23.7201L3.18014 22.8902C2.93995 22.8345 2.93995 22.4695 3.18014 22.4137L6.75687 21.5834C6.84486 21.5633 6.91342 21.4897 6.93205 21.396L7.69703 17.5456Z" fill="#A3BDEA" />
      <path d="M113.865 90.6146C113.933 90.2652 114.394 90.2652 114.463 90.6146L115.059 93.6592C115.083 93.7864 115.175 93.886 115.293 93.9136L118.087 94.5719C118.408 94.6475 118.408 95.1424 118.087 95.218L115.293 95.876C115.175 95.9039 115.083 96.003 115.059 96.1306L114.463 99.1751C114.394 99.5247 113.933 99.5247 113.865 99.1751L113.269 96.1306C113.244 96.003 113.152 95.9039 113.035 95.876L110.241 95.218C109.92 95.1424 109.92 94.6475 110.241 94.5719L113.035 93.9136C113.152 93.886 113.244 93.7864 113.269 93.6592L113.865 90.6146Z" fill="#A3BDEA" />
      <path d="M65.5333 53.876C64.5583 53.4426 63.3583 53.126 62 53.126C60.6417 53.126 59.4417 53.451 58.4667 53.876C57.5667 54.276 57 55.176 57 56.1593V57.501H67V56.1593C67 55.176 66.4333 54.276 65.5333 53.876Z" fill="white" />
      <path d="M53.0167 54.651C52.4 54.9176 52 55.5176 52 56.1926V57.501H55.75V56.1593C55.75 55.4676 55.9417 54.8176 56.275 54.251C55.9667 54.201 55.6583 54.1676 55.3333 54.1676C54.5083 54.1676 53.725 54.3426 53.0167 54.651Z" fill="white" />
      <path d="M70.9833 54.651C70.275 54.3426 69.4917 54.1676 68.6667 54.1676C68.3417 54.1676 68.0333 54.201 67.725 54.251C68.0583 54.8176 68.25 55.4676 68.25 56.1593V57.501H72V56.1926C72 55.5176 71.6 54.9176 70.9833 54.651Z" fill="white" />
      <path d="M62 52.501C63.3833 52.501 64.5 51.3843 64.5 50.001C64.5 48.6176 63.3833 47.501 62 47.501C60.6167 47.501 59.5 48.6176 59.5 50.001C59.5 51.3843 60.6167 52.501 62 52.501Z" fill="white" />
      <path d="M55.3332 49.5817L53.2473 51.6676L55.3333 53.7536L57.4192 51.6676L55.3332 49.5817Z" fill="white" />
      <path d="M68.6667 50.001L66.5833 53.3343H70.75L68.6667 50.001Z" fill="white" />
      <path d="M74.25 16.876H72V22.126H74.25V16.876Z" fill="white" />
      <path d="M79.125 16.876H76.875V22.126H79.125V16.876Z" fill="white" />
      <path d="M85.5 23.626H70.5V25.876H85.5V23.626Z" fill="white" />
      <path d="M84 16.876H81.75V22.126H84V16.876Z" fill="white" />
      <path d="M78 10.126L70.5 13.876V15.376H85.5V13.876L78 10.126Z" fill="white" />
      <g clipPath="url(#clip0_72_15694)">
        <path d="M49.5 15.001H39.5V16.251H49.5V15.001ZM39.5 30.001H49.5V28.751H39.5V30.001ZM49.5 17.501H39.5C38.8125 17.501 38.25 18.0635 38.25 18.751V26.251C38.25 26.9385 38.8125 27.501 39.5 27.501H49.5C50.1875 27.501 50.75 26.9385 50.75 26.251V18.751C50.75 18.0635 50.1875 17.501 49.5 17.501ZM44.5 19.2197C45.275 19.2197 45.9062 19.851 45.9062 20.626C45.9062 21.401 45.275 22.0322 44.5 22.0322C43.725 22.0322 43.0938 21.401 43.0938 20.626C43.0938 19.851 43.725 19.2197 44.5 19.2197ZM47.625 25.626H41.375V24.6885C41.375 23.6447 43.4563 23.126 44.5 23.126C45.5437 23.126 47.625 23.6447 47.625 24.6885V25.626Z" fill="white" />
      </g>
      <defs>
        <filter id="filter0_d_72_15694" x="11.1745" y="6.66541" width="118.832" height="87.5167" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.958404" />
          <feGaussianBlur stdDeviation="2.39601" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_72_15694" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_72_15694" result="shape" />
        </filter>
        <filter id="filter1_d_72_15694" x="11.1753" y="18.6576" width="118.831" height="75.5235" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.958404" />
          <feGaussianBlur stdDeviation="2.39601" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_72_15694" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_72_15694" result="shape" />
        </filter>
        <clipPath id="clip0_72_15694">
          <rect width="15" height="15" fill="white" transform="translate(37 15.001)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default NoDetailsIcon