import React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid2 as Grid,
} from "@mui/material";
import { styled } from "@mui/system";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { ReactComponent as LoginSVG } from "../../assets/media/svg/22 (1).svg";
import { ReactComponent as LoginSVG1 } from "../../assets/media/svg/22 (2).svg";
import { ReactComponent as LoginSVG2 } from "../../assets/media/svg/22 (3).svg";
import bankSureLogo from "../../assets/media/icons/image 24.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import { Formik, Form, Field, ErrorMessage } from "formik"; // Import Formik components
import validationSchema from "../../utils/validations/registerFormValidator";
import { Link } from "react-router-dom";

import { ReactComponent as BankSureLogo } from "../../assets/media/svg/banksure-logo.svg";

// Styled component for the layout
const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  overflow: "hidden",
  backgroundColor: "#eff4f9", // Set background color here
}));

const ImageSection = styled(Box)(({ theme }) => ({
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  background: `url(/bg.svg) center center`,
  "& .slick-slide": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .slick-slide img": {
    width: "100%",
    height: "auto", // Maintain aspect ratio of images
  },
  "& .slick-list": {
    width: "100%", // Ensure slider uses full width of the container
  },
}));

const FormSection = styled(Box)(({ theme }) => ({
  height: "90%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(4),
  // backgroundColor: theme.palette.background.default,
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: "flex",
  justifyContent: "center",
}));

const Register = () => {
  return (
    <Container>
      <Grid container spacing={0} style={{ height: "100%" }}>
        <Grid size={5}>
          <ImageSection>
            <Carousel
              showArrows={false}
              // showStatus={false}
              // showIndicators={false}
              // showThumbs={false}
              autoPlay={true}
              interval={2000}
              infiniteLoop={true}
            >
              <div>
                {/* <img
                                    src="https://via.placeholder.com/800x1200?text=Image+1"
                                    alt="Image 1"
                                /> */}

                <LoginSVG />
                {/* <img src={bankSureLogo} alt="" /> */}
              </div>
              <div>
                {/* <img
                                    src="https://via.placeholder.com/800x1200?text=Image+1"
                                    alt="Image 1"
                                /> */}

                <LoginSVG1 />
                {/* <img src={bankSureLogo} alt="" /> */}
              </div>
              <div>
                {/* <img
                                    src="https://via.placeholder.com/800x1200?text=Image+1"
                                    alt="Image 1"
                                /> */}

                <LoginSVG2 />
                {/* <img src={bankSureLogo} alt="" /> */}
              </div>
            </Carousel>
          </ImageSection>
        </Grid>
        <Grid size={7}>
          <FormSection>
            <div style={{ height: 100, width: 200 }}>
              {/* <Link to={"https://banksure.ai/"} target="_blank"> */}
                <IconWrapper style={{ height: 100 }}>
                  {/* <img src={bankSureLogo} alt='BankSure Logo' /> */}
                  <BankSureLogo />
                </IconWrapper>
              {/* </Link> */}
            </div>
            <Paper
              elevation={3}
              style={{
                padding: "20px",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "60%",
              }}
            >
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  full_name: "",
                  phone_no: "",
                  company_name: "",
                  contact_sales: false,
                  role: "",
                  query: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {}}
              >
                {({ errors, touched, values }) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid size={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          type="email"
                          name="email"
                          label="Business Email Address"
                          margin="normal"
                          variant="outlined"
                          helperText={<ErrorMessage name="email" />}
                          error={touched.email && !!errors.email}
                        />
                      </Grid>
                      <Grid size={6}>
                        <Field
                          as={TextField}
                          type="password"
                          fullWidth
                          name="password"
                          label="Password"
                          margin="normal"
                          variant="outlined"
                          helperText={<ErrorMessage name="password" />}
                          error={touched.password && !!errors.password}
                        />
                      </Grid>
                      <Grid size={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="full_name"
                          label="Full Name"
                          margin="normal"
                          variant="outlined"
                          helperText={<ErrorMessage name="full_name" />}
                          error={touched.full_name && !!errors.full_name}
                        />
                      </Grid>
                      <Grid size={6}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="phone_no"
                          label="Phone number"
                          margin="normal"
                          variant="outlined"
                          helperText={<ErrorMessage name="phone_no" />}
                          error={touched.phone_no && !!errors.phone_no}
                        />
                      </Grid>

                      <Grid size={12}>
                        <Field
                          as={TextField}
                          fullWidth
                          name="company_name"
                          label="Company Name"
                          margin="normal"
                          variant="outlined"
                          helperText={<ErrorMessage name="company_name" />}
                          error={touched.company_name && !!errors.company_name}
                        />
                      </Grid>

                      <Grid size={12}>
                        <Typography>
                          <Field
                            fullWidth
                            type="checkbox"
                            name="contact_sales"
                            label="Contact Sales"
                            margin="normal"
                            variant="outlined"
                            helperText={<ErrorMessage name="contact_sales" />}
                            error={
                              touched.contact_sales && !!errors.contact_sales
                            }
                          />
                          Contact Sales
                        </Typography>
                      </Grid>

                      {values.contact_sales === true ? (
                        <>
                          {" "}
                          <Grid size={12}>
                            <Field
                              as={TextField}
                              fullWidth
                              type="dropdown"
                              name="role"
                              label="Role"
                              margin="normal"
                              variant="outlined"
                              helperText={<ErrorMessage name="role" />}
                              error={touched.role && !!errors.role}
                            />
                          </Grid>
                          <Grid size={12}>
                            <Field
                              as={TextField}
                              fullWidth
                              type="textbox"
                              name="query"
                              label="Query"
                              margin="normal"
                              variant="outlined"
                              helperText={<ErrorMessage name="query" />}
                              error={touched.query && !!errors.query}
                            />
                          </Grid>{" "}
                        </>
                      ) : null}

                      <Grid size={12}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          style={{
                            marginTop: "16px",
                            backgroundColor: "#264D8E",
                            height: 50,
                          }}
                        >
                          Create Account
                        </Button>
                      </Grid>

                      <Grid size={12}>
                        <Typography align="center">
                          Have an account?
                          <b>
                            <Link to={"/auth/login"}>Sign in with email</Link>
                          </b>
                        </Typography>
                      </Grid>

                      <Grid size={12}>
                        <Typography align="center">
                          By creating an account, you agree to BankSure's
                          <b> Terms of Use</b> and consent to BankSure's
                          <b>Privacy Policy</b>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Paper>
          </FormSection>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Register;
