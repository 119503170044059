interface IconProps {
  selected?: boolean;
}
export const DTISetting = ({ selected }: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 3.5H5C3.9 3.5 3 4.4 3 5.5V19.5C3 20.6 3.9 21.5 5 21.5H19C20.1 21.5 21 20.6 21 19.5V5.5C21 4.4 20.1 3.5 19 3.5ZM13.03 7.56L14.09 6.5L15.5 7.91L16.91 6.5L17.97 7.56L16.56 8.97L17.97 10.38L16.91 11.44L15.5 10.04L14.09 11.45L13.03 10.39L14.44 8.98L13.03 7.56ZM6.25 8.22H11.25V9.72H6.25V8.22ZM11.5 16.5H9.5V18.5H8V16.5H6V15H8V13H9.5V15H11.5V16.5ZM18 17.75H13V16.25H18V17.75ZM18 15.25H13V13.75H18V15.25Z"
        // fill="#1F3A91"
        fill={selected ? "#1F3A91" : "#6B7280"}
      />
    </svg>
  );
};
