const DeleteIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.6" fill="#E9F1FF" stroke="#F5FAFF" stroke-width="0.8"/>
    <path d="M10 20.8333C10 21.75 10.75 22.5 11.6667 22.5H18.3333C19.25 22.5 20 21.75 20 20.8333V10.8333H10V20.8333ZM20.8333 8.33333H17.9167L17.0833 7.5H12.9167L12.0833 8.33333H9.16667V10H20.8333V8.33333Z" fill="#1F3A91"/>
    </svg>
    
  )
}

export default DeleteIcon