const DeleteModalIcon = () => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_6298_134465)">
    <rect x="10.1494" y="10.291" width="40" height="40" rx="20" fill="#FFF1F0"/>
    <path d="M30.1493 23.7846L37.6793 36.7946H22.6193L30.1493 23.7846ZM20.8893 35.7946C20.1193 37.1246 21.0793 38.7946 22.6193 38.7946H37.6793C39.2193 38.7946 40.1793 37.1246 39.4093 35.7946L31.8793 22.7846C31.1093 21.4546 29.1893 21.4546 28.4193 22.7846L20.8893 35.7946ZM29.1493 28.7946V30.7946C29.1493 31.3446 29.5993 31.7946 30.1493 31.7946C30.6993 31.7946 31.1493 31.3446 31.1493 30.7946V28.7946C31.1493 28.2446 30.6993 27.7946 30.1493 27.7946C29.5993 27.7946 29.1493 28.2446 29.1493 28.7946ZM29.1493 33.7946H31.1493V35.7946H29.1493V33.7946Z" fill="#E11E4F"/>
    </g>
    <defs>
    <filter id="filter0_d_6298_134465" x="0.149415" y="0.291016" width="63.0769" height="63.0769" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="0.769231" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_6298_134465"/>
    <feOffset dx="1.53846" dy="1.53846"/>
    <feGaussianBlur stdDeviation="5.38461"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.24975 0 0 0 0 0.405 0 0 0 0.2 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6298_134465"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6298_134465" result="shape"/>
    </filter>
    </defs>
    </svg>
    
  )
}

export default DeleteModalIcon