const AcuView = () => {
  return (
    <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_3745_87678)">
    <path d="M8.97559 27.3496H13.4505V43.0119H8.97559V27.3496Z" fill="url(#paint0_linear_3745_87678)"/>
    <path d="M8.97573 43.0119H0.0258789V27.3496H8.97573V43.0119Z" fill="url(#paint1_linear_3745_87678)"/>
    <path d="M13.4507 27.3496H42.5377V63.149C42.5377 64.3846 41.5359 65.3865 40.3002 65.3865H15.6881C14.4525 65.3865 13.4507 64.3846 13.4507 63.149V27.3496Z" fill="url(#paint2_linear_3745_87678)"/>
    <path d="M8.97559 27.3496H17.9254L22.5784 31.2269C23.731 32.1905 23.9277 33.8877 23.0258 35.089C22.567 35.7019 21.8792 36.1017 21.1199 36.1979C20.3612 36.294 19.5953 36.0782 18.9983 35.5992L15.688 32.9433C14.2901 35.0403 11.9363 36.2995 9.41641 36.2995H8.97559V27.3496Z" fill="#4378D0"/>
    <path d="M29.1128 27.3495H8.97559L15.8 23.0859C17.1208 22.2595 18.7765 22.1792 20.1705 22.8745L29.1128 27.3495Z" fill="#4378D0"/>
    <path d="M26.8755 46.3682H29.113V49.7244H26.8755V46.3682Z" fill="#EBEBEB"/>
    <path d="M31.3506 53.0801H39.1817V55.3175H31.3506V53.0801Z" fill="#77A1E7"/>
    <path d="M34.7065 57.5557H39.1815V59.7931H34.7065V57.5557Z" fill="#77A1E7"/>
    <path d="M16.8066 53.0801H23.519V55.3175H16.8066V53.0801Z" fill="#77A1E7"/>
    <path d="M16.8066 57.5557H23.519V59.7931H16.8066V57.5557Z" fill="#77A1E7"/>
    <path d="M50.3688 10.5686H43.6564L41.4189 0.5L47.0126 1.61873L52.6063 0.5L50.3688 10.5686Z" fill="url(#paint3_linear_3745_87678)"/>
    <path d="M59.3188 27.3493H39.1816V10.5684H48.1315L51.4877 11.6871H59.3188V27.3493Z" fill="#1E50A3"/>
    <path d="M55.9624 11.6875H64.9123V27.3497H55.9624V11.6875Z" fill="url(#paint4_linear_3745_87678)"/>
    <path d="M31.3573 31.0412C31.62 37.4002 36.2709 42.7201 42.5381 43.8296V27.3496H31.7544C31.4518 28.5557 31.318 29.7985 31.3573 31.0412Z" fill="#9EC2FE"/>
    <path d="M54.215 13.9249L50.3688 8.43066L43.7034 8.63442V10.8719L39.9812 15.8177C39.0127 17.203 37.906 18.479 36.8075 19.7638C34.629 22.3006 33.4824 25.5634 33.5944 28.906C33.8266 36.2362 39.8982 42.0243 47.2311 41.9047C54.5646 41.785 60.4445 35.8024 60.4374 28.4684C60.4407 25.2329 59.2684 22.1062 57.138 19.671C55.6522 18.0622 54.64 16.0733 54.215 13.9249Z" fill="url(#paint5_linear_3745_87678)"/>
    <path d="M48.9449 26.8138L45.7886 25.7633C45.0992 25.5334 44.6781 24.8374 44.7944 24.1202C44.9108 23.403 45.5297 22.8753 46.2567 22.8747H47.7688C48.5865 22.8758 49.2491 23.5385 49.2502 24.3562H51.4877C51.4822 22.4481 50.0303 20.8558 48.1315 20.6744V19.5186H45.894V20.6744C44.1476 20.8394 42.7563 22.2067 42.5597 23.9498C42.363 25.6929 43.4151 27.3355 45.0806 27.8856L48.2369 28.936C48.9263 29.166 49.3475 29.8619 49.2311 30.5791C49.1147 31.2964 48.4958 31.8241 47.7688 31.8246H46.2567C45.439 31.8235 44.7764 31.1609 44.7753 30.3432H42.5378C42.5433 32.2512 43.9952 33.8436 45.894 34.0249V35.1808H48.1315V34.0249C49.8779 33.86 51.2692 32.4927 51.4658 30.7496C51.6625 29.0065 50.6104 27.3639 48.9449 26.8138Z" fill="white"/>
    <path d="M55.9626 11.6874L54.562 9.35271C53.3482 7.32993 51.1616 6.09266 48.8028 6.09375H41.4191C40.1835 6.09375 39.1816 7.09558 39.1816 8.33121C39.1816 9.56684 40.1835 10.5687 41.4191 10.5687H47.0128L47.7622 11.8174C49.3797 14.5132 52.2929 16.1623 55.4366 16.1623H55.9626V11.6874Z" fill="#4378D0"/>
    <path d="M35.8254 10.5684H39.1815C40.4172 10.5684 41.419 11.5702 41.419 12.8058C41.419 14.0415 40.4172 15.0433 39.1815 15.0433H35.8254C34.5897 15.0433 33.5879 14.0415 33.5879 12.8058C33.5879 11.5702 34.5897 10.5684 35.8254 10.5684Z" fill="#4378D0"/>
    <path d="M35.8254 15.0439H39.1815C40.4172 15.0439 41.419 16.0458 41.419 17.2814C41.419 18.517 40.4172 19.5189 39.1815 19.5189H35.8254C34.5897 19.5189 33.5879 18.517 33.5879 17.2814C33.5879 16.0458 34.5897 15.0439 35.8254 15.0439Z" fill="#4378D0"/>
    <path d="M35.8254 19.5186H39.1815C40.4172 19.5186 41.419 20.5204 41.419 21.756C41.419 22.9916 40.4172 23.9935 39.1815 23.9935H35.8254C34.5897 23.9935 33.5879 22.9916 33.5879 21.756C33.5879 20.5204 34.5897 19.5186 35.8254 19.5186Z" fill="#4378D0"/>
    <path d="M35.8254 23.9932H39.1815C40.4172 23.9932 41.419 24.995 41.419 26.2306C41.419 27.4663 40.4172 28.4681 39.1815 28.4681H35.8254C34.5897 28.4681 33.5879 27.4663 33.5879 26.2306C33.5879 24.995 34.5897 23.9932 35.8254 23.9932Z" fill="#4378D0"/>
    <path d="M30.9468 48.8423C34.0329 47.0935 35.117 43.1741 33.3682 40.088C31.6194 37.0018 27.6999 35.9177 24.6138 37.6665C21.5277 39.4153 20.4436 43.3348 22.1924 46.4209C23.9412 49.507 27.8606 50.5911 30.9468 48.8423Z" fill="url(#paint6_linear_3745_87678)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7779 36.8447C31.3252 36.8447 34.2008 39.7203 34.2008 43.2676C34.2008 46.8149 31.3252 49.6905 27.7779 49.6905C27.5926 49.6905 27.4093 49.6822 27.228 49.6668C30.5178 49.3878 33.1011 46.6297 33.1011 43.2676C33.1011 39.9056 30.5179 37.1474 27.228 36.8684C27.4093 36.853 27.5926 36.8447 27.7779 36.8447Z" fill="#0C306B"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.353 41.0292C30.5115 41.407 30.3336 41.8419 29.9559 42.0003C29.578 42.1588 29.1432 41.9809 28.9847 41.6032C28.8199 41.2107 28.5073 40.9843 28.164 40.8973C27.94 40.8404 27.7062 40.8369 27.4894 40.8805C27.2814 40.9224 27.0907 41.0064 26.9428 41.1266C26.7691 41.2678 26.6588 41.4709 26.671 41.7218C26.6817 41.9431 26.7992 42.0962 26.9632 42.2031C27.2132 42.3663 27.5656 42.4582 27.9027 42.5136C28.423 42.599 28.9922 42.7423 29.4727 43.0324C30.0396 43.3747 30.4673 43.8902 30.5636 44.6838C30.5707 44.7426 30.5753 44.8155 30.5773 44.9009C30.5947 45.6325 30.262 46.1769 29.7619 46.5563C29.404 46.8279 28.9629 47.0032 28.5202 47.0928V47.3317C28.5202 47.7416 28.1879 48.0739 27.7781 48.0739C27.3683 48.0739 27.036 47.7416 27.036 47.3317V47.0779C26.684 46.9969 26.3492 46.8584 26.0548 46.6623C25.5496 46.3259 25.1626 45.8293 24.9987 45.1719C24.901 44.7749 25.1437 44.3738 25.5408 44.2761C25.9378 44.1784 26.3389 44.4211 26.4366 44.8182C26.503 45.0845 26.6629 45.2878 26.8724 45.4273C27.142 45.6068 27.49 45.6924 27.8446 45.6839C27.9015 45.6826 27.9744 45.6765 28.0629 45.6658C28.3648 45.6292 28.6628 45.5359 28.8691 45.3794C29.0097 45.2728 29.1034 45.1258 29.0989 44.9358C29.0986 44.9255 29.0961 44.8999 29.0909 44.8579C29.0595 44.5988 28.9086 44.4238 28.7074 44.3023C28.4197 44.1286 28.0311 44.0349 27.6651 43.9748C27.1602 43.8919 26.6107 43.7401 26.1574 43.4441C25.6179 43.0919 25.2304 42.5703 25.1926 41.7915C25.1553 41.0213 25.4866 40.4038 26.0095 39.9787C26.3025 39.7404 26.6567 39.5677 27.036 39.4689V39.203C27.036 38.7932 27.3683 38.4609 27.7781 38.4609C28.188 38.4609 28.5202 38.7932 28.5202 39.203V39.4586L28.5236 39.4594C29.2898 39.6537 29.9869 40.1574 30.353 41.0292Z" fill="white"/>
    <path d="M45.894 45.249H48.1315V47.4865H45.894V45.249Z" fill="#77A1E7"/>
    <path d="M45.894 49.7246H48.1315V51.9621H45.894V49.7246Z" fill="#77A1E7"/>
    <path d="M45.894 54.1992H48.1315V58.6741H45.894V54.1992Z" fill="#77A1E7"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_3745_87678" x1="12" y1="37" x2="11.213" y2="43.0119" gradientUnits="userSpaceOnUse">
    <stop stop-color="#4378D0"/>
    <stop offset="1" stop-color="#184A9C"/>
    </linearGradient>
    <linearGradient id="paint1_linear_3745_87678" x1="4.5008" y1="27.3496" x2="4.5008" y2="43.0119" gradientUnits="userSpaceOnUse">
    <stop stop-color="#1F5FC7"/>
    <stop offset="1" stop-color="#0D3A84"/>
    </linearGradient>
    <linearGradient id="paint2_linear_3745_87678" x1="27.9942" y1="27.3496" x2="27.9942" y2="65.3865" gradientUnits="userSpaceOnUse">
    <stop stop-color="#EDF5FF"/>
    <stop offset="1" stop-color="#A1C3F8"/>
    </linearGradient>
    <linearGradient id="paint3_linear_3745_87678" x1="47.0126" y1="0.5" x2="47.0126" y2="10.5686" gradientUnits="userSpaceOnUse">
    <stop stop-color="#2F92E5"/>
    <stop offset="1" stop-color="#B3DCFF"/>
    </linearGradient>
    <linearGradient id="paint4_linear_3745_87678" x1="60.4373" y1="11.6875" x2="60.4373" y2="27.3497" gradientUnits="userSpaceOnUse">
    <stop stop-color="#2062CD"/>
    <stop offset="1" stop-color="#0D3983"/>
    </linearGradient>
    <linearGradient id="paint5_linear_3745_87678" x1="47" y1="15.5" x2="48" y2="40" gradientUnits="userSpaceOnUse">
    <stop offset="0.257742" stop-color="#71BEFF"/>
    <stop offset="1" stop-color="#0966CD"/>
    </linearGradient>
    <linearGradient id="paint6_linear_3745_87678" x1="24.6138" y1="37.6665" x2="30.9468" y2="48.8423" gradientUnits="userSpaceOnUse">
    <stop stop-color="#329BF0"/>
    <stop offset="1" stop-color="#16549A"/>
    </linearGradient>
    <clipPath id="clip0_3745_87678">
    <rect width="64.8864" height="64.8864" fill="white" transform="translate(0.0258789 0.5)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default AcuView