import React, { useState, MouseEvent } from "react";
import {
  Button,
  Popover,
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import CalenderIcon from "../../assets/IconComponents/CalenderIcon";

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();

interface DOBPickerProps {
  placeholder?: string;
  style?: React.CSSProperties;
  popoverWidth?: string;
  popoverHeight?: string;
  onDOBSelect?: (dob: Date | null) => void;
  borderColor?: string;
  backgroundColor?: string;
  error?: string | null;
  label?: string;
  isRequired?: boolean;
}

const DOBPicker: React.FC<DOBPickerProps> = ({
  label,
  placeholder = "Select Date of Birth",
  style = {},
  popoverWidth = "300px",
  popoverHeight = "fit-content",
  onDOBSelect = () => {},
  borderColor = "#D5E1F9",
  backgroundColor = "#f7f9fc",
  error = null,
  isRequired = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDOB, setSelectedDOB] = useState<Date | null>(null);
  const [tempDOB, setTempDOB] = useState<Date | null>(null);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [view, setView] = useState<"date" | "month" | "year">("date");
  const theme = useTheme();
  const [selectedYear, setSelectedYear] = useState<number | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [tempYear, setTempYear] = useState<number | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "dob-popover" : undefined;

  const today = new Date();
  const minYear = currentYear - 100;
  const maxYear = currentYear;

  const months = Array.from({ length: 12 }, (_, i) => i);

  const isLeapYear = (year: number) =>
    (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

  const getDaysInMonth = (year: number, month: number) => {
    if (month === 1) return isLeapYear(year) ? 29 : 28;
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(
    currentDate.getFullYear(),
    currentDate.getMonth()
  );
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const firstDayOffset = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  ).getDay();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateSelect = (date: number) => {
    const yearToUse = selectedYear || currentDate.getFullYear();
    const selectedDate = new Date(yearToUse, currentDate.getMonth(), date);

    if (selectedDate > today || selectedDate.getFullYear() < minYear) return;

    setTempDOB(selectedDate);
  };

  const handleYearSelect = (year: number) => {
    if (year > maxYear || year < minYear) return;
    setTempYear(year);
    setSelectedYear(year);
    setView("month");
  };

  const handleMonthSelect = (month: number) => {
    const today = new Date();
    if (selectedYear === today.getFullYear() && month > today.getMonth())
      return;

    const tempDate = new Date(
      selectedYear || currentDate.getFullYear(),
      month,
      1
    );
    setSelectedMonth(month);
    setCurrentDate(tempDate);
    setView("date");
  };

  const yearsPerPage = 12;
  const [yearStart, setYearStart] = useState(currentYear - (currentYear % 12));
  const yearEnd = Math.min(yearStart + yearsPerPage - 1, maxYear);

  const yearsToDisplay = Array.from(
    { length: yearsPerPage },
    (_, i) => yearStart + i
  ).filter((year) => year <= maxYear);

  const handleNextRange = () => {
    if (yearStart + yearsPerPage <= maxYear) {
      setYearStart((prev) => prev + yearsPerPage);
    }
  };

  const handlePreviousRange = () => {
    if (yearStart - yearsPerPage >= minYear) {
      setYearStart((prev) => prev - yearsPerPage);
    }
  };

  const handleOk = () => {
    if (tempDOB) {
      setSelectedDOB(tempDOB);
      onDOBSelect(tempDOB);
    }
    handleClose();
  };

  const handleCancel = () => {
    setTempDOB(null);
    setSelectedDOB(null); 
    onDOBSelect(null); 
    handleClose();
  };

  return (
    <div>
      {label && (
        <Typography
          sx={{ fontSize: "14px", color: "#374151", fontWeight: "600" }}
        >
          {label}
          {isRequired && (
            <span
              style={{
                color: "#FF220C",
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              *
            </span>
          )}
        </Typography>
      )}
      <TextField
        placeholder={placeholder}
        fullWidth
        sx={{
          fontSize: "14px",
          backgroundColor,
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            color: "#6B7280 !important",
            height: "40px !important",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: borderColor,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: borderColor,
            },
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: borderColor,
            borderRadius: "8px",
          },
          ...style,
        }}
        size="small"
        variant="outlined"
        onClick={handleClick}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalenderIcon />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        value={
          selectedDOB
            ? selectedDOB.toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              })
            : ""
        }
        error={!!error}
        helperText={error}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            maxWidth: popoverWidth,
            height: popoverHeight,
            gap: "10px",
            borderRadius: "8px",
            opacity: 1,
          },
        }}
      >
        <Box sx={{ marginTop: "10px", padding: "5px" }}>
          {view === "month" && (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Button sx={{ width: "20px", height: "20px" }}>{"<"}</Button>
                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                  January - December
                </Typography>
                <Button sx={{ width: "20px", height: "20px" }}>{">"}</Button>
              </Grid>

              <Grid
                container
                justifyContent="center"
                sx={{ marginTop: "10px" }}
              >
                {months.map((month, index) => (
                  <Grid item key={month} xs={3}>
                    <Button
                      variant={selectedMonth === index ? "contained" : "text"}
                      onClick={() => handleMonthSelect(index)}
                      disabled={
                        selectedYear === new Date().getFullYear() &&
                        index > new Date().getMonth()
                      } 
                      sx={{
                        width: "36px",
                        height: "34px",
                        borderRadius: "1vw",
                        padding: selectedMonth === index ? "16px" : "0",
                        backgroundColor:
                          selectedMonth === index
                            ? theme.palette.primary.main
                            : "transparent",
                        color: selectedMonth === index ? "#fff" : "#374151",
                        fontSize: "12px",
                        fontWeight: "700",
                        lineHeight: "18px",
                      }}
                    >
                      {new Date(0, month).toLocaleString("default", {
                        month: "short",
                      })}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {view === "year" && (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  onClick={handlePreviousRange}
                  disabled={yearStart <= minYear}
                >
                  {"<"}
                </Button>
                <Typography sx={{ fontSize: "14px", fontWeight: "700" }}>
                  {yearStart} -{" "}
                  {Math.min(yearStart + yearsPerPage - 1, maxYear)}
                </Typography>
                <Button
                  onClick={handleNextRange}
                  disabled={yearStart + yearsPerPage > maxYear}
                >
                  {">"}
                </Button>
              </Grid>

              <Grid
                container
                justifyContent="center"
                sx={{ marginTop: "10px" }}
              >
                {yearsToDisplay.map((year) => (
                  <Grid item key={year} xs={3}>
                    <Button
                      variant={tempYear === year ? "contained" : "text"}
                      onClick={() => handleYearSelect(year)}
                      sx={{
                        width: "50px",
                        height: "34px",
                        borderRadius: "1vw",
                        backgroundColor:
                          tempYear === year
                            ? theme.palette.primary.main
                            : "transparent",
                        color: tempYear === year ? "#fff" : "#374151",
                        fontSize: "12px",
                        fontWeight: "700",
                      }}
                    >
                      {year}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {view === "date" && (
            <>
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  sx={{ width: "20px", height: "20px" }}
                  onClick={() => {
                    let prevMonth = new Date(currentDate);
                    if (prevMonth.getMonth() === 0) {
                      prevMonth = new Date(prevMonth.getFullYear() - 1, 11, 1);
                    } else {
                      prevMonth.setMonth(prevMonth.getMonth() - 1);
                    }
                    setCurrentDate(prevMonth);
                  }}
                >
                  {"<"}
                </Button>
                <Typography sx={{ fontSize: "12px", fontWeight: "700" }}>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setView("month")}
                  >
                    {currentDate.toLocaleString("default", { month: "long" })}
                  </span>{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setView("year")}
                  >
                    {selectedYear || currentDate.getFullYear()}
                  </span>
                </Typography>
                <Button
                  sx={{ width: "20px", height: "20px" }}
                  onClick={() => {
                    const nextMonth = new Date(currentDate);
                    nextMonth.setMonth(currentDate.getMonth() + 1);
                    setCurrentDate(nextMonth);
                  }}
                  disabled={
                    currentDate.getFullYear() === new Date().getFullYear() &&
                    currentDate.getMonth() === new Date().getMonth()
                  }
                >
                  {">"}
                </Button>
              </Grid>

              <Grid
                container
                justifyContent="center"
                sx={{ marginTop: "10px" }}
              >
                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                  (weekday) => (
                    <Grid
                      item
                      key={weekday}
                      sx={{
                        width: "36px",
                        height: "34px",
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "700",
                        color: "#374151",
                      }}
                    >
                      {weekday}
                    </Grid>
                  )
                )}
              </Grid>

              <Grid
                container
                sx={{
                  paddingRight: "25px",
                  marginTop: "7px",
                  display: "grid",
                  gridTemplateColumns: "repeat(7, 36px)",
                  justifyContent: "center",
                  gap: "3px",
                }}
              >
                {Array.from({ length: firstDayOffset }).map((_, index) => {
                  const prevMonthDate = new Date(currentDate);
                  prevMonthDate.setMonth(currentDate.getMonth() - 1);
                  prevMonthDate.setDate(
                    prevMonthDate.getDate() - (firstDayOffset - index)
                  );
                  return (
                    <Button
                      key={`prev-month-${index}`}
                      variant="text"
                      sx={{
                        width: "36px",
                        height: "34px",
                        borderRadius: "1vw",
                        padding: "0",
                        backgroundColor: "transparent",
                        color: "#9E9E9E",
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                      disabled
                    >
                      {prevMonthDate.getDate()}
                    </Button>
                  );
                })}

                {daysArray.map((day) => (
                  <Button
                    key={day}
                    variant={
                      tempDOB && tempDOB.getDate() === day
                        ? "contained"
                        : "text"
                    }
                    onClick={() => handleDateSelect(day)}
                    disabled={
                      currentDate.getFullYear() === currentYear &&
                      currentDate.getMonth() === currentMonth &&
                      day > new Date().getDate()
                    }
                    sx={{
                      width: "36px",
                      height: "36px",
                      minWidth: "36px", 
                      minHeight: "36px",
                      margin: "2px",
                      borderRadius: "8px", 
                      padding: "6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor:
                        tempDOB && tempDOB.getDate() === day
                          ? theme.palette.primary.main
                          : "transparent",
                      color:
                        tempDOB && tempDOB.getDate() === day
                          ? "#fff"
                          : "#374151",
                      fontSize: "14px",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    {day}
                  </Button>
                ))}

                {Array.from({
                  length: (7 - ((firstDayOffset + daysArray.length) % 7)) % 7,
                }).map((_, index) => {
                  const nextMonthDate = new Date(currentDate);
                  nextMonthDate.setMonth(currentDate.getMonth() + 1);
                  nextMonthDate.setDate(index + 1);
                  return (
                    <Button
                      key={`next-month-${index}`}
                      variant="text"
                      sx={{
                        width: "36px",
                        height: "34px",
                        borderRadius: "1vw",
                        padding: "0",
                        backgroundColor: "transparent",
                        color: "#9E9E9E",
                        fontSize: "14px",
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                      disabled
                    >
                      {nextMonthDate.getDate()}
                    </Button>
                  );
                })}
              </Grid>

              <Grid
                container
                justifyContent="center"
                sx={{ marginTop: "10px" }}
              >
                <Button
                  variant="contained"
                  onClick={handleOk}
                  sx={{
                    background: theme.palette.primary.main,
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "45%",
                  }}
                >
                  OK
                </Button>
                <Button
                  variant="text"
                  onClick={handleCancel}
                  sx={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "45%",
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </>
          )}
        </Box>
      </Popover>
    </div>
  );
};

export default DOBPicker;
