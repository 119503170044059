import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid2 as Grid,
  styled,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddIcon from "../../assets/IconComponents/AddIcon";
import Panel from "../../components/Panel";
import InputComponent from "../../components/form/input";
import SearchIcon from "../../assets/IconComponents/SearchIcon";

import {
  DataTable,
  PaginationControl,
} from "../../components/form/TableComponent";
import TruncatedTooltipText from "../../components/form/ToolTip";
import EditIcon from "../../assets/IconComponents/EditIcon";
import LockIcon from "../../assets/IconComponents/LockIcon";
import UnlockIcon from "../../assets/IconComponents/UnlockIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ViewIcon from "../../assets/IconComponents/ViewIcon";
import DeleteIcon from "../../assets/IconComponents/DeleteIcon";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import { Controller, set, useForm } from "react-hook-form";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import JobTtileIcon from "../../assets/IconComponents/JobTitleIcon";
import EditModal from "../../components/form/EditModal";
import ToggleSwitch from "../../components/form/ToggleSwitch";
import { DeleteUserById, GetUsers, UpdateUser } from "../../api/UsersApi";
import { useAlertMessage } from "../../utils/useAlertMessage";
import CircularSpinner from "../../components/Loader/CircularLoader";
import DeleteModal from "../../components/form/DeleteModal";

interface UserProps {}

interface FormData {
  firstName: string;
  lastName: string;
  status: string;
  jobTitle: string;
  phone: string;
  email: string;
  secondaryPhone: string;
  secondaryEmail: string;
  password: string;
  confirmPassword: string;
}

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  color: "#ff0000",
  border: "none",
  height: "48px !important",
  "& .MuiButtonGroup-grouped": {
    border: "1px solid #E5E7EB !important",
  },
  "&:hover": {
    boxShadow: "none !important",
  },
}));

const User: React.FC<UserProps> = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [userList, setUserList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [deleteUserObj, setUserToDelete] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [tableSelectionReset, setTableSelectionReset] = useState<any>(null);
  const [searchValue, setSearchValue] = useState("");
  const [userInfo, setUserInfo] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const rowsPerPage = 10;
  const [query, setQuery] = useState<any>({
    pageNumber: 1,
    pageSize: 10,
    status: "all",
    nameQuery: "",
    orderBy: "createdDate",
    order: "desc",
  });

  const notification = useAlertMessage();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [totalRecordsCount, setTotalRecordsCount] = useState<any>(0);
  const {
    control: userControl,
    handleSubmit: handleUserSubmit,
    getValues,
    formState: { errors: userErrors, isValid: isUserValid },
    reset: resetUserForm,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      jobTitle: "",
      status: "inactive", 
    },
  });
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handlDeleteClose = () => {
    setIsDeleteOpen(false);
  };

  const handleEdit = (row: any) => {
    setUserInfo(row);
    resetUserForm({
      firstName: row.firstName || "",
      lastName: row.lastName || "",
      jobTitle: row.jobTitle || "",
      status: row.status || "",
    });
    handleOpen();
  };  

  const onFormSubmit = () => {
    handleUserSubmit(handleUpdate)();
  };

  const handleUpdate = async () => {
    setOpen(false);
    setLoading(true);
    setProgress(0);
    const updatedUserInfo = getValues(); 

    const userPayload = {
      ...updatedUserInfo, 
      id: userInfo.id, 
      userName: "",
      secondaryPhone: updatedUserInfo.secondaryPhone || "",
      secondaryEmail: updatedUserInfo.secondaryEmail || "",
      password: updatedUserInfo.password || "",
    };
    try {
      const timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const { status } = await UpdateUser(userInfo.id, userPayload);
      clearInterval(timer);
      setProgress(100);
      if (status === 200) {
        notification.successNotification(
          "Success",
          "User Updated successfully."
        );
        getData(query);
      } else {
        notification.errorNotification("Error", "Error in updating user.");
      }
    } catch (err) {
      notification.errorNotification("Error", "Error in updating user.");
    } finally {
      setLoading(false);
    }
  };

  const renderStatus = (status: string) => {
    const statusConfig = {
      active: {
        iconColor: "#01B6A8",
        backgroundColor: "#EFFFFE",
        textColor: "#01B6A8",
        label: "Active",
      },
      inactive: {
        iconColor: "#AF6DD1",
        backgroundColor: "#A800FF0F",
        textColor: "#AF6DD1",
        label: "Inactive",
      },
    };

    const config = statusConfig[status as keyof typeof statusConfig];
    if (!config) return null;
    return (
      <Chip
        icon={
          <FiberManualRecordIcon
            sx={{ color: `${config.iconColor} !important`, height: "10px" }}
          />
        }
        label={config.label}
        sx={{
          backgroundColor: config.backgroundColor,
          color: config.textColor,
          minWidth: "110px",
        }}
      />
    );
  };

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      getData({ ...query, nameQuery: searchValue, pageNumber: 1 });
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchValue]);

  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const createSortHandler = (key: string) => {
    getData({
      ...query,
      pageNumber: 1,
      order: query.order?.toLowerCase() === "asc" ? "desc" : "asc",
      orderBy: key,
    });
  };

  const handlePageChange = (event: any, value: any) => {
    getData({
      ...query,
      pageNumber: value,
    });
  };

  const selectUserStatus = (type: string) => {
    getData({
      ...query,
      pageNumber: 1,
      status: type,
    });
  };

  const renderAccessIcon = (access: string) => {
    return access === "active" ? <UnlockIcon /> : <LockIcon />;
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getData(query, true);
  }, []);

  const getData: any = async (query: any, onInitialCall: boolean) => {
    toggleLoading(true);
    if (!onInitialCall) {
      setQuery(query);
    }
    try {
      const result: any = await GetUsers(query);
      setUserList(result.data.data || []);
      setTotalRecordsCount(result.data.totalCount || 0);
      setTimeout(() => toggleLoading(false), 500);
    } catch (err) {
      notification.errorNotification(
        "Error",
        (err as any).response?.data.message
      );
    }
    toggleLoading(false);
  };

  const deleteUser = async () => {
    try {
      toggleLoading(true);
      const { status } = await DeleteUserById(deleteUserObj.id);
      if (status === 200) {
        notification.successNotification(
          "Success",
          "User deleted successfully."
        );
      } else {
        notification.errorNotification("Error", "Error in deleting user.");
      }
      getData(query);
      setUserToDelete(null);
      setIsDeleteOpen(false);
    } catch (err) {
      notification.errorNotification(
        "Error",
        (err as any).response?.data.message
      );
    }
    toggleLoading(false);
  };

  useEffect(() => {
    if (userInfo) {
      resetUserForm({
        firstName: userInfo.firstName || "",
        lastName: userInfo.lastName || "",
        jobTitle: userInfo.jobTitle || "",
        status: userInfo.status || "inactive",
      });
    }
  }, [userInfo, resetUserForm]);

  /**
   * @description Delete multiple selected items
   */
  const deleteUsers = async () => {
    try {
      if (!selectedRows.length) {
        return;
      }
      toggleLoading(true);

      const result = await Promise.allSettled(
        selectedRows.map((items) => {
          return DeleteUserById(items);
        })
      );

      if (
        result.filter((item) => item.status == "fulfilled").length ===
        selectedRows.length
      ) {
        notification.successNotification(
          "Success",
          "Selected users deleted successfully."
        );
        getData(query);
      } else {
        notification.warningNotification(
          "Error",
          "Error in deleting selected users."
        );
      }
    } catch (err) {
      notification.errorNotification(
        "Error",
        (err as any).response?.data.message
      );
    } finally {
      getData(query);
      setSelectedRows([]);
      setTableSelectionReset(new Date().toString());
      toggleLoading(false);
      setIsDeleteOpen(false);
    }
  };

  const handleDeleteConfirmation = (row: any) => {
    if (row?.isPrimaryUser) {
      notification.warningNotification(
        "Warning!",
        "Primary users are not allowed to be deleted."
      );
      return;
    }
    setUserToDelete(row);
    setIsDeleteOpen(true);
  };

  const handleDelete = () => {
    if (selectedRows.length) {
      deleteUsers();
    } else {
      deleteUser();
    }
  };

  return (
    <Grid container>
      <Grid container size={12}>
        <Grid
          size={{ xs: 6, md: 8 }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography>
            All users related data can be managed from here
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }} textAlign={"right"}>
          <Link to={"/acucheck/users/add-users"}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.light,
                padding: "10px 16px",
                borderRadius: "10px",
              }}
            >
              Add New User
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid size={12} container marginTop={"20px"}>
        <Panel sx={{ width: "100%" }}>
          <Box sx={{ padding: "20px 15px 10px" }}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              sx={{
                gap: { lg: 1, xl: 2 },
                flexDirection: { xs: "column", sm: "column", lg: "row" },
              }}
            >
              <Box
                display="flex"
                justifyContent={"space-between"}
                gap={2}
                sx={{
                  width: { sm: "100%", lg: "calc(100% - 530px)" },
                  marginBottom: { sm: "10px", lg: "0px" },
                  flexDirection: { xs: "column", sm: "row", lg: "row" },
                }}
              >
                <Box
                  sx={{
                    maxWidth: {
                      xs: "100%",
                      sm: "300px",
                      lg: "250px",
                      xl: "300px",
                    },
                  }}
                >
                  <InputComponent
                    onChange={handleInputChange}
                    placeholder="Search"
                    icon={<SearchIcon />}
                    backgroundColor="#fff"
                    borderColor="#E5E7EB"
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                sx={{
                  gap: { xs: 2, lg: 1, xl: 2 },
                  marginTop: { xs: "15px", sm: "0px" },
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                }}
              >
                <Box>
                  <StyledButtonGroup>
                    {[
                      { value: "all", label: "All" },
                      { value: "active", label: "Active Users" },
                      { value: "inactive", label: "Inactive Users" },
                    ].map((type) => (
                      <Button
                        key={type.label}
                        onClick={() => selectUserStatus(type.value)}
                        sx={{
                          border: "1px solid #D5E1F9",
                          backgroundColor:
                            query.status === type.value
                              ? "#F1F4FF"
                              : "transparent",
                          color:
                            query.status === type.value ? "#1F3A91" : "#6B7280",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: query.status === type.value ? 600 : 500,
                          }}
                        >
                          {type.label}
                        </Typography>
                      </Button>
                    ))}
                  </StyledButtonGroup>
                </Box>
                {selectedRows.length ? (
                  <Box>
                    <Button
                      size="large"
                      onClick={handleDeleteConfirmation}
                      sx={{
                        borderRadius: "10px",
                        fontSize: "16px",
                        fontWeight: "600",
                        border: "1px solid #1D3DB0",
                        color: "#1D3DB0",
                      }}
                    >
                      Delete All{" "}
                      {selectedRows.length ? `(${selectedRows.length})` : null}
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>

          <DataTable
            columns={[
              {
                label: "Name",
                id: "name",
                render: (row) => {
                  const fullName = `${row.firstName} ${row.lastName}`.trim();
                  return (
                    <TruncatedTooltipText
                      text={row.name}
                      tooltipPlacement="top"
                    >
                      <Box
                        onClick={() =>
                          navigate(`/acucheck/users/${row?.id}/edit`)
                        }
                        sx={{
                          cursor: "pointer",
                          fontWeight: "600",
                          textDecoration: "underline",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {fullName || "N/A"}
                      </Box>
                    </TruncatedTooltipText>
                  );
                },
              },
              { label: "Job Title", id: "jobTitle" },
              {
                label: "Status",
                id: "status",
                sortable: false,
                render: (row) => renderStatus(row.status),
              },
              {
                label: "Access",
                id: "access",
                sortable: false,
                render: (row) => renderAccessIcon(row.status),
              },
              {
                label: "Actions",
                id: "actions",
                sortable: false,
                render: (row) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <TruncatedTooltipText text="View User Details">
                      <Box
                        sx={{
                          width: { xs: "50px", md: "3.2rem" },
                          height: { xs: "50px", md: "3.2rem" },
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() =>
                          navigate(`/acucheck/users/${row?.id}/edit`)
                        }
                      >
                        <ViewIcon />
                      </Box>
                    </TruncatedTooltipText>
                    <TruncatedTooltipText text="Edit User">
                      <Box
                        key={row.id}
                        sx={{
                          width: { xs: "50px", md: "3.2rem" },
                          height: { xs: "50px", md: "3.2rem" },
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          if (row && row.id) {
                            handleEdit(row);
                          } else {
                            console.error("Invalid row data:", row);
                          }
                        }}
                      >
                        <EditIcon />
                      </Box>
                    </TruncatedTooltipText>
                    <TruncatedTooltipText text="Delete User">
                      <Box
                        sx={{
                          width: { xs: "50px", md: "3.2rem" },
                          height: { xs: "50px", md: "3.2rem" },
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        onClick={() => handleDeleteConfirmation(row)}
                      >
                        <DeleteIcon />
                      </Box>
                    </TruncatedTooltipText>
                  </Box>
                ),
              },
            ]}
            rows={userList || []}
            isLoading={isLoading}
            orderBy={query.orderBy}
            order={query.order}
            createSortHandler={createSortHandler}
            NodataTitle="User's"
            onGetStartedClick={() => navigate("/acucheck/users/add-users")}
            checkboxSelection={true}
            onRowSelection={setSelectedRows}
            resetTableSelection={tableSelectionReset}
          />
          <PaginationControl
            page={page}
            rowsPerPage={rowsPerPage}
            totalItems={totalRecordsCount || 0}
            handlePageChange={handlePageChange}
          />
        </Panel>
      </Grid>
      <EditModal
        open={open}
        onClose={handleClose}
        handleUpdate={onFormSubmit}
        title={`Edit User Information`}
        disabled={Object.keys(userErrors).length > 0}
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #DBE9FE",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{ padding: "20px", background: "#F7FAFF", borderRadius: "6px" }}
          >
         <FormInputRow>
  <FormInputCol md={12}>
    <Controller
      name="firstName"
      control={userControl}
      rules={{
        pattern: {
          value: /^[A-Za-z ]+$/i,
          message: "First name can only contain alphabets",
        },
      }}
      render={({ field }) => (
        <InputComponent
          {...field}
          icon={<UserIconGray />}
          label="First Name"
          placeholder="Enter first name"
          error={userErrors.firstName?.message || null}
        />
      )}
    />
  </FormInputCol>

  <FormInputCol>
    <Controller
      name="lastName"
      control={userControl}
      rules={{
        required: "Last name is required",
        pattern: {
          value: /^[A-Za-z ]+$/i,
          message: "Last name can only contain alphabets",
        },
      }}
      render={({ field }) => (
        <InputComponent
          {...field} // ✅ Fix: Ensure onChange works
          icon={<UserIconGray />}
          label="Last Name"
          placeholder="Enter last name"
          error={userErrors.lastName?.message || null}
        />
      )}
    />
  </FormInputCol>

  <FormInputCol>
    <Controller
      name="jobTitle"
      control={userControl}
      rules={{
        required: "Job title is required",
        pattern: {
          value: /^[A-Za-z ]+$/i,
          message: "Job title can only contain alphabets",
        },
      }}
      render={({ field }) => (
        <InputComponent
          {...field} // ✅ Fix: This will allow user input
          icon={<JobTtileIcon />}
          label="Job Title"
          placeholder="Enter job title"
          error={userErrors.jobTitle?.message || null}
        />
      )}
    />
  </FormInputCol>

  <FormInputCol>
    <Controller
      name="status"
      control={userControl}
      render={({ field: { onChange, value } }) => (
        <ToggleSwitch
          checked={value === "active"}
          onChange={(checked) => onChange(checked ? "active" : "inactive")}
          labelActive="Active"
          labelInactive="Inactive"
          label="Account Access"
        />
      )}
    />
  </FormInputCol>
</FormInputRow>

          </Box>
        </Box>
      </EditModal>
      {loading && <CircularSpinner progress={progress} />}
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={handlDeleteClose}
        onConfirm={handleDelete}
      />
    </Grid>
  );
};

export default User;
