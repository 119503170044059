const DTIArrow = () => {
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2739_59656)">
    <rect x="0.552273" y="0.884304" width="48.2318" height="48.2318" rx="6.44773" fill="white"/>
    <rect x="0.552273" y="0.884304" width="48.2318" height="48.2318" rx="6.44773" stroke="#D5E1F9" stroke-width="1.10455"/>
    <rect x="5.25" y="5.58203" width="38.8364" height="38.8364" rx="4.41818" fill="#F2F7FF"/>
    <path d="M31.7246 17.9375C29.9121 16.125 27.4246 15 24.6621 15C19.1371 15 14.6746 19.475 14.6746 25C14.6746 30.525 19.1371 35 24.6621 35C29.3246 35 33.2121 31.8125 34.3246 27.5H31.7246C30.6996 30.4125 27.9246 32.5 24.6621 32.5C20.5246 32.5 17.1621 29.1375 17.1621 25C17.1621 20.8625 20.5246 17.5 24.6621 17.5C26.7371 17.5 28.5871 18.3625 29.9371 19.725L25.9121 23.75H34.6621V15L31.7246 17.9375Z" fill="#1F3A91"/>
    </g>
    <defs>
    <clipPath id="clip0_2739_59656">
    <rect width="50" height="49.3364" fill="white" transform="translate(0 0.332031)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default DTIArrow