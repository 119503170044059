import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Grid2 as Grid,
  Button,
  Typography,
  useTheme,
  Chip,
  TableContainer,
  CardContent,
  Card,
  Divider,
} from "@mui/material";
import Panel from "../../components/Panel";
import { Link } from "react-router-dom";
import ExportIcon from "../../assets/IconComponents/ExportIcon";
import {
  DataTable,
  PaginationControl,
} from "../../components/form/TableComponent";
import { useState } from "react";
import { CreateNewIBAReqest, GetReportRequestsList } from "../../api/IBAReport";
import { useAlertMessage } from "../../utils/useAlertMessage";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import moment from "moment";
import { GetCustomers } from "../../api/customerAPI";
import TransactionHistoryComponent from "../ibaReport/ibaAnalysis/TransactionHistory";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationIcon from "../../assets/IconComponents/Setting/LocationIcon";
import TruncatedTooltipText from "../../components/form/ToolTip";
import DTIArrow from "../../assets/IconComponents/DTI/DTIArrow";
import DTIIcon from "../../assets/IconComponents/DTI/DTIIcon";

interface OptionType {
  label: string;
  value: string | number;
}

interface DataTableProps {
  columns: { label: string; id: string; render: (row: any) => any }[];
  rows: {
    id: string;
    name: string;
    income: number;
    familySize: string;
    medicalCondition: string;
    investment: number;
    spendingHabit: string;
    savingHabit: string;
    livingStyle: string;
  }[];
  checkboxSelection: boolean;
  createSortHandler: () => void;
  orderBy: string;
  order: "asc" | "desc";
  NodataTitle: string;
  onGetStartedClick: () => void;
  onRowSelectionChange?: (selectedRows: string[]) => void; // Add this line
}

const DataSimulatorDashboard = () => {
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  function createData(
    name: string,
    date: string,
    income: string,
    familySize: string,
    medicalCondition: string,
    investment: string,
    spendingHabit: string,
    savingHabit: string,
    livingStyle: string,
    history: { date: string; customerId: string; amount: number }[]
  ) {
    return {
      name,
      date,
      income,
      familySize,
      medicalCondition,
      investment,
      spendingHabit,
      savingHabit,
      livingStyle,
      history,
    };
  }

  function Row(props: {
    row: ReturnType<typeof createData>;
    selected: boolean;
    onSelect: (name: string) => void;
  }) {
    const { row, selected, onSelect } = props;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>{row.date}</TableCell>
          <TableCell>{row.income}</TableCell>
          <TableCell>{row.familySize}</TableCell>
          <TableCell>{row.medicalCondition}</TableCell>
          <TableCell>{row.investment}</TableCell>
          <TableCell>{row.spendingHabit}</TableCell>
          <TableCell>{row.savingHabit}</TableCell>
          <TableCell>{row.livingStyle}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom>
                  History
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell align="right">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.customerId}</TableCell>
                        <TableCell align="right">{historyRow.amount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const rows = [
    createData(
      "Marvin Hutchenson",
      "06-28-2024",
      "$89,090",
      "Couple",
      "Healthy",
      "Low",
      "Moderate",
      "Frugal",
      "Urban",
      [
        { date: "06-20-2024", customerId: "Test 1", amount: 3 },
        { date: "06-21-2024", customerId: "Test 2", amount: 1 },
      ]
    ),
    createData(
      "Marvin Hutchenson",
      "06-28-2024",
      "$89,090",
      "Individual",
      "Healthy",
      "Low",
      "Moderate",
      "Frugal",
      "Urban",
      [{ date: "06-22-2024", customerId: "Test 3", amount: 2 }]
    ),
  ];

  const handleSelectRow = (name: string) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(name)
        ? prevSelected.filter((row) => row !== name)
        : [...prevSelected, name]
    );
  };

  const handleSelectAll = () => {
    if (selectedRows.length === rows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(rows.map((row) => row.name)); // Select all row
    }
  };

  const [orderBy, setOrderBy] = useState("id");
  const theme = useTheme();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [totalRecordsCount, setTotalRecordsCount] = useState<any>(0);
  const [customersList, setCustomersList] = useState<any[]>([]);
  const [customersCount, setCustomersCount] = useState<any>(0);
  const [query, setQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    status: "All",
    orderBy: "ordered",
    order: "desc",
  });
  const [customerQuery, setCustomerQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    orderBy: "createdDate",
    order: "desc",
  });
  const [reportsList, setReportsList] = useState<any[]>([]);

  // const notification = useAlertMessage();
  // React.useEffect(() => {
  //   getReportList(query, true);
  //   getCustomersList(customerQuery);
  // }, []);

  // React.useEffect(() => {
  //   const delayInputTimeoutId = setTimeout(() => {
  //     getReportList({
  //       ...query,
  //       pageNumber: 1,
  //     });
  //   }, 500);
  //   return () => clearTimeout(delayInputTimeoutId);
  // }, [query.nameQuery, 500]);

  // const getReportList = async (query: any, onInitialCall?: boolean) => {
  //   toggleLoading(true);
  //   try {
  //     if (!onInitialCall) setQuery(query);
  //     const result: any = await GetReportRequestsList(query);
  //     result.data = (result?.data || []).map((report: any) => ({
  //       id: report.id,
  //       name: `${report?.customer?.firstName} ${report?.customer?.lastName}`,
  //       phone: report?.customer?.phoneNumber,
  //       ordered: moment(report?.createdDate).format("MM/DD/YYYY"),
  //       score: "-",
  //       sendVia: report?.sentVia?.join(","),
  //       requestedBy: "Admin",
  //       status: report?.status,
  //     }));
  //     setReportsList(result?.data);
  //     setTotalRecordsCount(result?.totalCount || 0);
  //     setTimeout(() => toggleLoading(false), 500);
  //   } catch (err) {
  //     notification.errorNotification("Error", "Error fetching reports.");
  //     toggleLoading(false);
  //   }
  // };

  const getCustomersList = async (query: any = {}) => {
    let customerList: any = await GetCustomers(query);
    let list: any = [];

    if (customerList?.data?.length) {
      list =
        customerList?.data?.map((customer: any) => {
          return {
            label: `${customer.firstName} ${customer.lastName}`,
            value: customer.id,
          };
        }) || [];
    }

    setCustomersList((prevList) => {
      const combinedList = [...prevList, ...list];
      const uniqueList = combinedList.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.value === value.value)
      );
      return uniqueList;
    });

    setCustomersCount(customerList.totalCount);
  };

  const fetchAllCustomers = async () => {
    let totalPages = Math.ceil(customersCount / customerQuery.pageSize);
    let currentPage = customerQuery.pageNumber;
    while (currentPage <= totalPages) {
      await getCustomersList({
        pageSize: customerQuery.pageSize,
        pageNumber: currentPage,
        nameQuery: customerQuery.nameQuery,
        orderBy: customerQuery.orderBy,
        order: customerQuery.order,
      });

      setCustomerQuery((prevQuery: any) => ({
        ...prevQuery,
        pageNumber: prevQuery.pageNumber + 1,
      }));

      currentPage++;
    }
  };

  React.useEffect(() => {
    fetchAllCustomers();
  }, [customersCount]);

  const renderStatus = (status: string) => {
    const statusConfig = {
      completed: {
        iconColor: "#01B6A8",
        backgroundColor: "#EFFFFE",
        textColor: "#01B6A8",
      },
      expired: {
        iconColor: "#ED9902",
        backgroundColor: "#FFF5E4",
        textColor: "#ED9902",
      },
      pending: {
        iconColor: "#AF6DD1",
        backgroundColor: "rgba(168, 0, 255, 0.06)",
        textColor: "#AF6DD1",
      },
    };

    const config = statusConfig[status as keyof typeof statusConfig];
    if (!config) return null;
    return (
      <Chip
        icon={
          <FiberManualRecordIcon
            sx={{ color: `${config.iconColor} !important`, height: "10px" }}
          />
        }
        label={status.charAt(0).toUpperCase() + status.slice(1)}
        sx={{
          backgroundColor: config.backgroundColor,
          color: config.textColor,
          minWidth: "110px",
        }}
      />
    );
  };

  // const createSortHandler = (key: string) => {
  //   getReportList({
  //     ...query,
  //     pageNumber: 1,
  //     order: query.order?.toLowerCase() === "asc" ? "desc" : "asc",
  //     orderBy: key,
  //   });
  // };

  const [open, setOpen] = useState(false);
  const [verification, setVerification] = useState("SMS");
  const [selectedCustomer, setSelectedCustomer] = useState<OptionType | null>(
    null
  );

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleVerificationChange = (event: any) => {
    setVerification(event.target.value);
  };

  // const [page, setPage] = useState
  // const handlePageChange = (
  //   event: React.ChangeEvent<unknown>,
  //   value: number
  // ) => {
  //   getReportList({
  //     ...query,
  //     pageNumber: value,
  //   });
  // };(1);
  const rowsPerPage = 10;

  const handleInputChange = (event: any) => {
    setQuery({
      ...query,
      nameQuery: event.target.value,
    });
  };

  // const selectReportStatus = (type: string) => {
  //   getReportList({
  //     ...query,
  //     pageNumber: 1,
  //     status: type,
  //   });
  // };

  // const CreateIBARequest = async () => {
  //   setLoading(true);
  //   setProgress(0);
  //   try {
  //     const timer = setInterval(() => {
  //       setProgress((prev) => (prev < 90 ? prev + 10 : prev));
  //     }, 200);
  //     const request = await CreateNewIBAReqest(
  //       selectedCustomer?.value || "",
  //       verification
  //     );
  //     clearInterval(timer);
  //     setProgress(100);
  //     notification.successNotification(
  //       "Success",
  //       "Create report request submitted successfully!"
  //     );
  //     closeModel();
  //   } catch (err) {
  //     console.log("Error", err);
  //     notification.errorNotification("Error", "Something went wrong");
  //   } finally {
  //     setLoading(false);
  //   }
  //   getReportList(query);
  // };

  const closeModel = () => {
    setSelectedCustomer(null);
    handleClose();
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: string
  ) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, rowId]);
    } else {
      setSelectedRows((prev) => prev.filter((id) => id !== rowId));
    }
  };
  const isExportDisabled = selectedRows.length === 0;

  const handleRowSelection = (selectedIds: string[]) => {
    setSelectedRows(selectedIds);
  };

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid size={12} columnSpacing={3} >
        <Panel
          sx={{
            width: "100%",
            height:"80vh",
            padding: "15px",
            boxShadow: "none",
            borderRadius: "8px",
            border: "1px dashed  #1D3DB0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingY: "35px",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "26px", sm: "36px" },
                fontWeight: "800",
                textAlign: "center",
              }}
            >
              Create Accurate Simulations, Instantly
            </Typography>
            <Link to={"/admin/datasimulator/generatesimulation"}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  background: theme.palette.primary.light,
                  padding: "10px 16px",
                  borderRadius: "10px",
                  fontSize: "14px",
                  fontWeight: "700",
                  height: "48px",
                  marginBottom: { xs: "10px", md: "" },
                }}
              >
                Generate Simulation
              </Button>
            </Link>
          </Box>
        </Panel>

        {/* <Grid
          size={{ xs: 6, md: 8 }}
          sx={{ display: "flex", alignItems: "center", width: "100%" }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "700",
              paddingY: "20px",
              width: { xs: "100%" },
            }}
          >
            Recently added Account lists
          </Typography>
        </Grid> */}

      </Grid>
      
      {/* <Grid size={12} container>
        <Panel sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              size="large"
              disabled={selectedRows.length === 0}
              sx={{
                borderRadius: "10px",
                fontSize: "14px",
                fontWeight: "700",
                background: selectedRows.length > 0 ? "#1D3DB0" : "#d3d3d3",
                color: "#fff",
                margin: "10px 15px 10px 0",
                "&:disabled": {
                  color: "#fff",
                },
              }}
              startIcon={<ExportIcon />}
            >
              Export
            </Button>
          </Box>

          <DataTable
            columns={[
              {
                label: "Name",
                id: "name",
                render: (row) => {
                  const name = row.name;
                  return (
                    <TruncatedTooltipText text={name} tooltipPlacement="top">
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TruncatedTooltipText
                          text={row.name}
                          tooltipPlacement="top"
                        >
                          <Box
                            sx={{
                              cursor: "pointer",
                              fontSize: "14px",
                              fontWeight: "600",
                              textDecoration: "underline",
                              textDecorationColor: " #374151",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {row.name}
                          </Box>
                        </TruncatedTooltipText>
                        <Box
                          sx={{
                            display: "inline-block",
                            width: "84px",
                            height: "24px",
                            margin: "4px 0",
                            padding: "5px",
                            backgroundColor:
                              row.name === "Alice Johnson"
                                ? "#9B51E0"
                                : "#1D3DB0",
                            color: "#ffffff",
                            fontWeight: "600",
                            fontStyle: "italic",
                            fontSize: "14px",
                            clipPath:
                              "polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%)",
                          }}
                        >
                          {row.name === "Alice Johnson"
                            ? "Individual"
                            : "Test 1"}
                        </Box>
                      </Box>
                    </TruncatedTooltipText>
                  );
                },
              },

              { label: "Date", id: "date", render: (row) => "01/15/2025" },
              {
                label: "Income",
                id: "income",
                render: (row) => `$${row.income}`,
              },
              {
                label: "Family size",
                id: "familySize",
                render: (row) => row.familySize,
              },
              {
                label: "Medical condition",
                id: "medicalCondition",
                render: (row) => row.medicalCondition,
              },
             
              {
                label: "Spending habit",
                id: "spendingHabit",
                render: (row) => row.spendingHabit,
              },
              {
                label: "Saving habit",
                id: "savingHabit",
                render: (row) => row.savingHabit,
              },
           
            ]}
            rows={[
              {
                id: "12345",
                name: "Marvin Hutchenson",
                income: 12000,
                familySize: "Couple",
                medicalCondition: "Healthy",
                investment: 5000,
                spendingHabit: "Moderate",
                savingHabit: "Frugal",
                livingStyle: "Urban",
                details: (
                  <>
                    <Box sx={{ background: "#F7FAFF", width: "100%" }}>
                      <Box
                        sx={{
                          marginBottom: 1,
                          padding: "16px",
                          background: "#F7FAFF",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "600", fontSize: "16px" }}
                        >
                          Marvin Hutchenson
                        </Typography>
                        <Typography
                          variant="body2"
                          color="#797979"
                          fontSize="14px"
                          fontWeight="500"
                          gap="10px"
                          display="flex"
                          alignItems="center"
                        >
                          <span>
                            <LocationIcon />
                          </span>{" "}
                          New York
                        </Typography>
                      </Box>
                      <Grid container sx={{ marginBottom: "20px", flexDirection: { xs: "column", lg:"row" }}} spacing={2}  >
                        <Grid size={{ xs: 3, md:4 ,lg: 6 }}>
                          <Box
                            sx={{
                              border: "1px solid #D5E1F9",
                              background: "#E9F1FF",
                              boxShadow:
                                "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                              padding: "20px",
                              borderRadius: "8px",
                            }}
                          >
                            <Typography
                                variant="subtitle2"
                                color="#374151"
                                fontSize="16px"
                                fontWeight="700"
                                marginBottom="5px"
                                padding="0px"
                              >
                                Dec 02, 2022{" "}
                                <span
                                  style={{
                                    color: "#9D9D9D",
                                    fontSize: "16px ",
                                    fontWeight: "700",
                                  }}
                                >
                                  to
                                </span>{" "}
                                Dec 02, 2024
                              </Typography>
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                padding: "20px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexDirection: { xs: "column", sm: "row" },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                 200
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                 Initial Balance
                                </Typography>
                              </Box>
                             
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                > 
                                  $1,575.27
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                  Balance at the end
                                </Typography>
                              </Box>
                            
                          
                            </Box>
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 3, md:4, lg:  6}}>
                          <Box
                            sx={{
                              border: "1px solid #D5E1F9",
                              background: "#E9F1FF",
                              boxShadow:
                                "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                              padding: "20px",
                              borderRadius: "8px",
                            }}
                          >
                           
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                padding: "35px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexDirection: { xs: "column", sm: "row" },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                 200
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                Total Transaction
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                $1,575.00
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                Total Amount
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                > 
                                  $1,575.27
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                               Avg Transaction Amount
                                </Typography>
                              </Box>
                            
                          
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

              
                    </Box>
                    <TransactionHistoryComponent />
                  </>
                ),
              },
              {
                id: "12346",
                name: "Jane Smith",
                income: 15000,
                familySize: "Couple",
                medicalCondition: "Healthy",
                investment: 7000,
                spendingHabit: "Moderate",
                savingHabit: "Frugal",
                livingStyle: "Suburban",
                details: (
                  <>
                    <Box sx={{ background: "#F7FAFF", width: "100%" }}>
                      <Box
                        sx={{
                          marginBottom: 1,
                          padding: "16px",
                          background: "#F7FAFF",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "600", fontSize: "16px" }}
                        >
                          Jane Smith
                        </Typography>
                        <Typography
                          variant="body2"
                          color="#797979"
                          fontSize="14px"
                          fontWeight="500"
                          gap="10px"
                          display="flex"
                          alignItems="center"
                        >
                          <span>
                            <LocationIcon />
                          </span>{" "}
                          New York
                        </Typography>
                      </Box>

                      <Grid container sx={{ marginTop: "20px" }} spacing={2}>
                        <Grid size={{ xs: 12, lg: 6 }}>
                          <Box
                            sx={{
                              border: "1px solid #D5E1F9",
                              background: "#E9F1FF",
                              boxShadow:
                                "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                              padding: "20px",
                              borderRadius: "8px",
                            }}
                          >
                            <Typography
                                variant="subtitle2"
                                color="#374151"
                                fontSize="16px"
                                fontWeight="700"
                                marginBottom="5px"
                                padding="0px"
                              >
                                Dec 02, 2022{" "}
                                <span
                                  style={{
                                    color: "#9D9D9D",
                                    fontSize: "16px ",
                                    fontWeight: "700",
                                  }}
                                >
                                  to
                                </span>{" "}
                                Dec 02, 2024
                              </Typography>
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                padding: "20px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexDirection: { xs: "column", sm: "row" },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                 200
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                 Initial Balance
                                </Typography>
                              </Box>
                             
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                > 
                                  $1,575.27
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                  Balance at the end
                                </Typography>
                              </Box>
                            
                          
                            </Box>
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 12, lg:  6}}>
                          <Box
                            sx={{
                              border: "1px solid #D5E1F9",
                              background: "#E9F1FF",
                              boxShadow:
                                "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                              padding: "20px",
                              borderRadius: "8px",
                            }}
                          >
                           
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                padding: "35px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexDirection: { xs: "column", sm: "row" },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                 200
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                Total Transaction
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                $1,575.00
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                Total Amount
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                > 
                                  $1,575.27
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                               Avg Transaction Amount
                                </Typography>
                              </Box>
                            
                          
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <TransactionHistoryComponent />
                  </>
                ),
              },
              {
                id: "12347",
                name: "Alice Johnson",
                income: 20000,
                familySize: "Couple",
                medicalCondition: "Healthy",
                investment: 10000,
                spendingHabit: "Moderate",
                savingHabit: "Frugal",
                livingStyle: "Urban",
                details: (
                  <>
                    <Box sx={{ background: "#F7FAFF", width: "100%" }}>
                      <Box
                        sx={{
                          marginBottom: 1,
                          padding: "16px",
                          background: "#F7FAFF",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "600", fontSize: "16px" }}
                        >
                          Alice Johnson
                        </Typography>
                        <Typography
                          variant="body2"
                          color="#797979"
                          fontSize="14px"
                          fontWeight="500"
                          gap="10px"
                          display="flex"
                          alignItems="center"
                        >
                          <span>
                            <LocationIcon />
                          </span>{" "}
                          New York
                        </Typography>
                      </Box>

                      <Grid container sx={{ marginTop: "20px" }} spacing={2}>
                        <Grid size={{ xs: 12, lg: 6 }}>
                          <Box
                            sx={{
                              border: "1px solid #D5E1F9",
                              background: "#E9F1FF",
                              boxShadow:
                                "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                              padding: "20px",
                              borderRadius: "8px",
                            }}
                          >
                            <Typography
                                variant="subtitle2"
                                color="#374151"
                                fontSize="16px"
                                fontWeight="700"
                                marginBottom="5px"
                                padding="0px"
                              >
                                Dec 02, 2022{" "}
                                <span
                                  style={{
                                    color: "#9D9D9D",
                                    fontSize: "16px ",
                                    fontWeight: "700",
                                  }}
                                >
                                  to
                                </span>{" "}
                                Dec 02, 2024
                              </Typography>
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                padding: "20px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexDirection: { xs: "column", sm: "row" },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                 200
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                 Initial Balance
                                </Typography>
                              </Box>
                             
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                > 
                                  $1,575.27
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                  Balance at the end
                                </Typography>
                              </Box>
                            
                          
                            </Box>
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 12, lg:  6}}>
                          <Box
                            sx={{
                              border: "1px solid #D5E1F9",
                              background: "#E9F1FF",
                              boxShadow:
                                "0px 9px 14.7px 0px rgba(0, 0, 0, 0.04)",
                              padding: "20px",
                              borderRadius: "8px",
                            }}
                          >
                           
                            <Box
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: "8px",
                                padding: "35px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                flexDirection: { xs: "column", sm: "row" },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                 200
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                Total Transaction
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                >
                                $1,575.00
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                                Total Amount
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    textAlign: "center",
                                  }}
                                > 
                                  $1,575.27
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#121828",
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                  }}
                                >
                               Avg Transaction Amount
                                </Typography>
                              </Box>
                            
                          
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <TransactionHistoryComponent />
                  </>
                ),
              },
            ]}
            isLoading={false}
            orderBy={"name"}
            order={"asc"}
            createSortHandler={() => {}}
            checkboxSelection={true}
            NodataTitle="AcuScore Report's"
            onGetStartedClick={() => setOpen(true)}
            showExpandRow={true}
            onRowSelection={(rows) => setSelectedRows(rows)}
          />
          <PaginationControl
            page={query.pageNumber}
            rowsPerPage={query.pageSize}
            totalItems={totalRecordsCount || 0}
            handlePageChange={handlePageChange}
          />
        </Panel>
      </Grid> */}

    </Grid>
  );
};

export default DataSimulatorDashboard;
