const ACHTransaction = () => {
  return (
    <svg width="61" height="64" viewBox="0 0 61 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4660_48145)">
    <path d="M46.0273 29.1512C46.0273 30.2952 45.9218 31.4147 45.6992 32.5096H43.6835C43.3554 32.5096 43.0976 32.7679 43.0976 33.0755V35.6219C42.0663 35.8803 41.1054 36.2739 40.2148 36.7783L38.3515 34.9822C38.1171 34.7608 37.7538 34.7608 37.5195 34.9822L34.2148 38.193C33.9804 38.4144 33.9804 38.7712 34.2148 39.0049L35.2109 39.9768L36.0663 40.801C35.539 41.6744 35.1406 42.6093 34.871 43.5935H32.246C31.9179 43.5935 31.6601 43.8518 31.6601 44.1717V47.0626C19.8476 50.1134 7.71868 41.0224 7.89447 29.1512C8.90228 4.6583 45.0195 4.6706 46.0273 29.1512Z" fill="url(#paint0_linear_4660_48145)"/>
    <path d="M40.8356 29.1506C40.8356 31.5249 40.2028 33.7515 39.0895 35.6952C38.6793 35.3631 38.1286 34.4281 37.5192 34.9817L34.2145 38.1925C33.9801 38.4139 33.9801 38.7707 34.2145 39.0044L35.2106 39.9762C26.2926 46.5208 12.9801 40.1238 13.0856 29.1629C13.8121 11.3253 40.109 11.3253 40.8356 29.1506Z" fill="#76ADF8"/>
    <path d="M28.0737 28.5855H27.6167V23.6033H31.2378C31.6011 23.6033 31.894 23.3204 31.894 22.9636C31.894 22.6192 31.6011 22.3239 31.2378 22.3239H27.6167V20.8477C27.6167 20.5033 27.3237 20.208 26.9605 20.208C26.5972 20.208 26.3042 20.4909 26.3042 20.8477V22.3239C20.6206 22.0533 20.6206 30.111 26.3042 29.8403V34.6995H22.6831C22.3198 34.6995 22.0269 34.9825 22.0269 35.3392C22.0269 35.6837 22.3198 35.9789 22.6831 35.9789H26.3042V37.4551C26.3042 37.7996 26.5972 38.0948 26.9605 38.0948C27.3237 38.0948 27.6167 37.8119 27.6167 37.4551V35.9789C32.9722 36.2865 33.4175 28.8439 28.0737 28.5855ZM25.8472 28.5855C22.2261 28.3887 22.6714 23.2466 26.3042 23.6033V28.5855H25.8472ZM28.0737 34.7118H27.6167V29.8526C31.2378 29.5328 31.6831 34.5027 28.0737 34.7118Z" fill="white"/>
    <path d="M59.7978 43.5931H57.1729C56.9033 42.5967 56.5049 41.6617 55.9775 40.8006L57.8408 39.0045C58.0752 38.7831 58.0752 38.4263 57.8408 38.1926L54.5244 34.9818C54.29 34.7604 53.915 34.7604 53.6924 34.9818L51.8291 36.7902C50.9385 36.2858 49.9658 35.8922 48.958 35.6338V33.0874C48.958 32.7675 48.6885 32.5215 48.3603 32.5215H43.6729C43.3447 32.5215 43.0869 32.7798 43.0869 33.0874V35.6338C42.0557 35.8922 41.0947 36.2858 40.2041 36.7902L38.3525 34.9818C38.1182 34.7604 37.7549 34.7604 37.5205 34.9818L34.2158 38.1926C33.9814 38.414 33.9814 38.7708 34.2158 39.0045L36.0674 40.8006C35.54 41.674 35.1416 42.609 34.8721 43.5931H32.2471C31.9189 43.5931 31.6611 43.8514 31.6611 44.1713V48.6983C31.6611 49.0182 31.9189 49.2765 32.2471 49.2765H34.8721C35.1416 50.273 35.54 51.2079 36.0674 52.069L34.2158 53.8774C33.9814 54.1111 33.9814 54.4556 34.2158 54.6893L37.5205 57.8878C37.7549 58.1092 38.1182 58.1092 38.3525 57.8878L40.2158 56.0917C41.1064 56.5961 42.0674 56.9775 43.0986 57.2481V59.7946C43.0986 60.1144 43.3564 60.3728 43.6846 60.3728H48.3721C48.7002 60.3728 48.9697 60.1144 48.9697 59.7946V57.2481C49.9893 56.9898 50.9619 56.6084 51.8408 56.0917L53.7041 57.8878C53.9268 58.1092 54.3018 58.1092 54.5361 57.8878L57.8525 54.6893C58.0869 54.4556 58.0869 54.1111 57.8525 53.8774L55.9893 52.069C56.5166 51.2079 56.915 50.273 57.1846 49.2765H59.7978C60.126 49.2765 60.3955 49.0182 60.3955 48.6983V44.1713C60.3955 43.8391 60.126 43.5931 59.7978 43.5931ZM46.0283 52.9179C42.3252 52.9179 39.3369 50.0023 39.3369 46.4348C39.6885 37.8359 52.3682 37.8482 52.7197 46.4348C52.7197 50.0146 49.7197 52.9179 46.0283 52.9179Z" fill="url(#paint1_linear_4660_48145)"/>
    <path d="M12.6628 9.11139C13.7058 8.74234 14.7487 7.85661 15.8386 7.41374C16.6003 7.04469 16.0261 5.91293 15.2526 6.28198C14.2331 6.77405 13.237 7.32763 12.2878 7.95502C11.7605 8.27487 12.0417 9.136 12.6628 9.11139Z" fill="#3E629C"/>
    <path d="M7.64846 13.3805C7.82424 13.3805 8.01174 13.3067 8.14065 13.159C8.85549 12.3717 9.62893 11.6213 10.4375 10.9324C10.7071 10.6987 10.7305 10.305 10.4961 10.0344C10.2617 9.77604 9.83986 9.75144 9.57033 9.97287C8.71486 10.6987 7.90627 11.486 7.14455 12.3225C6.78127 12.7162 7.1094 13.3928 7.64846 13.3805Z" fill="#3E629C"/>
    <path d="M18.6287 6.23204C19.742 5.9491 20.8436 5.56775 21.9803 5.37092C22.8123 5.21099 22.5662 3.96851 21.7225 4.12844C20.6092 4.34987 19.4959 4.64511 18.4178 5.00186C18.0779 5.11258 17.8904 5.48163 18.0076 5.80148C18.1014 6.07212 18.3592 6.23204 18.6287 6.23204Z" fill="#3E629C"/>
    <path d="M1.64825 24.8448C1.98809 24.9186 2.35137 24.6971 2.42169 24.3527C2.63262 23.3193 2.92559 22.2983 3.27716 21.3018C3.39434 20.9697 3.20684 20.6129 2.867 20.5022C2.52716 20.3915 2.15216 20.5637 2.03497 20.8959C1.65997 21.9415 1.367 23.0241 1.14434 24.1067C1.06231 24.4388 1.29669 24.771 1.64825 24.8448Z" fill="#3E629C"/>
    <path d="M3.64005 18.6701C3.95646 18.83 4.3549 18.707 4.51896 18.3871C4.99943 17.4522 5.55021 16.5296 6.15958 15.6561C6.3588 15.3609 6.27677 14.9672 5.9838 14.7827C5.67911 14.5859 5.28068 14.6597 5.08146 14.9549C4.44865 15.8776 3.86271 16.8371 3.3588 17.8212C3.18302 18.1411 3.32365 18.5225 3.64005 18.6701Z" fill="#3E629C"/>
    <path d="M26.9602 53.4228C12.5461 53.4843 0.932843 41.4039 1.98753 27.4783C2.03441 26.6541 0.745343 26.5557 0.686749 27.3922C-0.426532 42.0436 11.7844 54.7514 26.9602 54.6899C27.8039 54.6776 27.8274 53.4351 26.9602 53.4228Z" fill="#3363B3"/>
    <path d="M56.1522 25.8664C55.8827 25.6327 55.4725 25.6573 55.2265 25.9156L53.2694 28.0192C52.6952 13.8598 39.8515 2.65291 25.1327 3.68626C24.3124 3.74777 24.3476 4.95335 25.1796 4.95335C39.1835 3.9446 51.4179 14.598 51.9686 28.0807L49.8593 26.2478C49.5897 26.014 49.1796 26.0386 48.9335 26.297C48.6874 26.5553 48.7108 26.9613 48.9804 27.195L52.7655 30.4796L56.2108 26.7644C56.4452 26.5061 56.4218 26.1001 56.1522 25.8664Z" fill="#3363B3"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_4660_48145" x1="26.9599" y1="10.7861" x2="26.9599" y2="47.6576" gradientUnits="userSpaceOnUse">
    <stop stop-color="#4378D0"/>
    <stop offset="1" stop-color="#3161B1"/>
    </linearGradient>
    <linearGradient id="paint1_linear_4660_48145" x1="46.5" y1="21.495" x2="45.9802" y2="60.3721" gradientUnits="userSpaceOnUse">
    <stop stop-color="#219FDA"/>
    <stop offset="1" stop-color="#3466B7"/>
    </linearGradient>
    <clipPath id="clip0_4660_48145">
    <rect width="60" height="62.9852" fill="white" transform="translate(0.5 0.5)"/>
    </clipPath>
    </defs>
    </svg>
    
  )
}

export default ACHTransaction