const CloseIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#001F3D" />
      <path
        d="M6.73949 5.91683L5.91699 6.73933L9.17783 10.0002L5.91699 13.261L6.73949 14.0835L10.0003 10.8227L13.2612 14.0835L14.0837 13.261L10.8228 10.0002L14.0837 6.73933L13.2612 5.91683L10.0003 9.17766L6.73949 5.91683Z"
        fill="#F6F6F6"
      />
    </svg>
  );
};

export default CloseIcon;
