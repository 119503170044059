const UtilityIcon = () => {
    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0L0 6V18H7.11111V14.4444C5.64089 14.4444 4.44444 13.248 4.44444 11.7778V9.11111H5.33333V6.44444H7.11111V9.11111H8.88889V6.44444H10.6667V9.11111H11.5556V11.7778C11.5556 13.248 10.3591 14.4444 8.88889 14.4444V18H16V6L8 0Z" fill="#6B7280"/>
        <path d="M9.77778 11.7778V10.8889H6.22223V11.7778C6.22223 12.2685 6.62134 12.6667 7.11112 12.6667H8.8889C9.37867 12.6667 9.77778 12.2685 9.77778 11.7778Z" fill="#6B7280"/>
        </svg>
        
        
    );
  };
  
  export default UtilityIcon;
  