import { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Panel from "../../components/Panel";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Groups2Icon from "@mui/icons-material/Groups2";
import DownAccordianArrow from "../../assets/IconComponents/DownAccordianArrow";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import NoDeatilsInformation from "../../assets/IconComponents/NoDetailsIcon";
import { Controller, useForm } from "react-hook-form";
import InputComponent from "../../components/form/input";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import { SyntheticEvent, useState } from "react";
import ToggleSwitch from "../../components/form/ToggleSwitch";
import PhoneIcon from "../../assets/IconComponents/PhoneIcon";
import EmailIcon from "../../assets/IconComponents/EmailIcon";
import PasswordIcon from "../../assets/IconComponents/PasswordIcon";
import CustomerOverview from "../customers/CustomerOverview";
import customerInformationIcon from "../../assets/media/icons/Customer_Information.png";
import MyLocation from "../../assets/IconComponents/MyLocation";
import Location from "../../assets/IconComponents/Location";
import SelectComponent from "../../components/form/SelectComponent";
import Homework from "../../assets/IconComponents/Homework";
import States from "../../assets/json/states.json";
import CustomMonthPicker from "../../components/form/MonthPicker";
import YearPicker from "../../components/form/YearPicker";
import FeatureCard from "./FeaturedCard";
import AcuScoreIcon from "../../assets/IconComponents/Client/AddClient/AcuScoreIcon";
import StoreIcon from "../../assets/IconComponents/StoreIcon";
import CardIcon from "../../assets/IconComponents/CardIcon";
import DriverLicense from "../../assets/IconComponents/DriverLicense";
import SsnNumber from "../../assets/IconComponents/SsnNumber";
import BankIcon from "../../assets/IconComponents/Client/AddClient/BankIcon";
import CVVIcon from "../../assets/IconComponents/Client/AddClient/CVVIcon";
import AcuPayIcon from "../../assets/IconComponents/Client/AddClient/AcuPayIcon";
import AcuView from "../../assets/IconComponents/Client/AddClient/AcuView";
import CreditTransaction from "../../assets/IconComponents/Client/AddClient/CreditTransaction";
import ACHTransaction from "../../assets/IconComponents/Client/AddClient/ACHTransaction";
import contactInformation from "../../assets/media/icons/Contact_Information.png";
import BankInformation from "../../assets/media/icons/Bank_Information.png";
import CardInformation from "../../assets/media/icons/Card_Information.png";
import ProductInformation from "../../assets/media/icons/Product_Infomation.png";
import PasswordInformation from "../../assets/media/icons/Password_Information.png";
import AddressIcon from "../../assets/IconComponents/AddressIcon";
import { CreateClients } from "../../api/clientAPI";
import { useAlertMessage } from "../../utils/useAlertMessage";
import CircularSpinner from "../../components/Loader/CircularLoader";
import CombinedMonthYearPicker from "../../components/form/MonthYearSelection";

const statesList = Object.keys(States).map((state) => ({
  value: state,
  label: state,
}));

const fieldNames = [
  "businessName",
  "companyEmail",
  "contactPhone",
  "firstName",
  "zipCode",
  "lastName",
  "phone",
  "email",
  "bankName",
  "accountNumber",
  "cardnumber",
  "routingNumber",
  "cardholdername",
  "password",
  "confirmPassword",
];

const AddClient = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(max-width:1023px)");
  const isMiniDesktopScreen = useMediaQuery(
    "(min-width:1023px) and (max-width:1439px)"
  );
  const notification = useAlertMessage();
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const [cityList, setCityList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [merchantIDText, setMerchantIDText] = useState("");

  const handleAccordionChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      if (!isExpanded && expanded === panel) {
        return;
      }
      setExpanded(isExpanded ? panel : false);
    };

  const [expirationDate, setExpirationDate] = useState<string | null>(null);

  const handleDateChange = (month: number, year: number) => {
    const formattedDate = `${(month + 1).toString().padStart(2, "0")}/${year
      .toString()
      .slice(-2)}`;
    setExpirationDate(formattedDate);
  };

  const handleCancelDate = () => {
    setExpirationDate(null);
  };

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      component={RouterLink}
      to={"/admin/client"}
      sx={{ width: "fit-content", display: "flex" }}
    >
      <Groups2Icon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
      <Typography sx={{ color: theme.palette.primary.main }}>
        Clients
      </Typography>
    </Link>,
    <Typography
      key="3"
      sx={{
        color: theme.palette.primary.main,
        backgroundColor: "#dbe9fe",
        padding: "3px",
        borderRadius: "5px",
        paddingX: "8px",
        textDecoration: "underline",
      }}
    >
      Add New Client
    </Typography>,
  ];

  const {
    control,
    watch,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
  });

  const generateUniqueId = (businessName: string) => {
    const letters = businessName?.slice(0, 3).toUpperCase();
    if (!letters) return "";
    const digits = Math.floor(10000 + Math.random() * 90000).toString();
    return `${letters}${digits}`;
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "businessName") {
        const uniqueId = generateUniqueId(value.businessName);
        setMerchantIDText(uniqueId);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (getValues().businessName === "") {
      setValue("merchantId", "");
    } else {
      setValue("merchantId", merchantIDText);
    }
  }, [merchantIDText]);

  const [isActive, setIsActive] = useState(true);

  const onStateSelect = (state: string) => {
    if (!state) return;

    const updatedCityList = (States as any)[state].map((city: string) => ({
      value: city,
      label: city,
    }));
    setCityList(updatedCityList);
    const currentCity = watch("city");
    const isCurrentCityValid = updatedCityList.some(
      (city: any) => city.value === currentCity
    );
    if (!isCurrentCityValid) {
      setValue("city", "");
    }
  };

  const hasClientDetails =
    expirationDate || fieldNames.some((fieldName) => watch(fieldName));

  const hasNoErrors = Object.keys(errors).length === 0;

  const allFieldsFilled =
    expirationDate &&
    fieldNames.every((fieldName) => watch(fieldName)) &&
    hasNoErrors;

  const featureData = [
    {
      id: "AcuCheck",
      title: "AcuCheck",
      icon: <AcuScoreIcon />,
      disable: false,
    },
    { id: "AcuPay", title: "AcuPay", icon: <AcuPayIcon />, disable: true },
    { id: "AcuView", title: "AcuView", icon: <AcuView />, disable: true },
  ];

  const serviceData = [
    {
      id: "CreditTransaction",
      title: "Credit Transaction",
      icon: <CreditTransaction />,
    },
    {
      id: "ACHTransaction",
      title: "ACH Transaction",
      icon: <ACHTransaction />,
    },
  ];

  const [visibility, setVisibility] = useState<Record<string, boolean>>({});

  const handleToggleVisibility = (field: string) => {
    setVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const [selectedFeature, setSelectedFeature] = useState<string | null>(null);
  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  const handleFeatureChange = (id: string) => {
    if (id === "AcuPay") {
      setSelectedFeature((prev) => (prev === id ? null : id));
      setSelectedServices((prev) =>
        prev.length ? [] : ["CreditTransaction", "ACHTransaction"]
      );
    } else {
      setSelectedFeature((prev) => (prev === id ? null : id));
      setSelectedServices([]);
    }
  };

  const handleServiceChange = (id: string) => {
    setSelectedServices((prev) =>
      prev.includes(id)
        ? prev.filter((service) => service !== id)
        : [...prev, id]
    );
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const formData = watch();
    setLoading(true);
    setProgress(0);
    let timer;

    try {
      const requestData = {
        businessName: formData.businessName,
        merchantID: formData.merchantId,
        ein: formData?.EIN,
        state: formData.state,
        city: formData.city,
        zipCode: formData.zipCode,
        accountStatus: isActive ? "active" : "inactive",
        password: formData.password,
        contactName: formData.businessName,
        ContactEmail: formData.companyEmail,
        ContactPhone: formData.contactPhone,
        contacts: [
          {
            firstName: formData.firstName,
            lastName: formData.lastName,
            title: formData.title,
            phoneNumber: formData.phone,
            email: formData.email,
          },
        ],
        accounts: [
          {
            bankName: formData.bankName,
            accountNumber: formData?.accountNumber?.replace(/-/g, "") || "",
            routingNumber: formData.routingNumber,
          },
        ],
        cards: [
          {
            cardHolderName: formData.cardholdername,
            cardNumber: formData?.cardnumber?.replace(/-/g, "") || "",
            expiryDate: expirationDate,
          },
        ],
        clientProductSubscriptions: [
          {
            productSubscription: {
              productName: "AcuCheck",
              serviceName: "acucheck",
            },
            subscriptionStartDate: new Date(),
            // "subscriptionEndDate":
          },
        ],
      };
      timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const { data, status } = await CreateClients(requestData);
      setProgress(100);
      console.log(status);
      if (status === 200) {
        notification.successNotification("Success", data.message);
        navigate("/admin/client");
      } else {
        notification.errorNotification("Error", data.message);
      }
    } catch (err) {
      console.error();
      notification.errorNotification(
        "Error",
        (err as any).response?.data.message
      );
    } finally {
      clearInterval(timer);
      setLoading(false);
    }
  };

  const handleClientReset = () => {
    reset();
    setExpanded("panel1");
    setExpirationDate("");
    setSelectedFeature(null);
    setSelectedServices([]);
  };

  return (
    <>
      <Panel
        sx={{
          width: "100%",
          padding: "15px",
          boxShadow: "none",
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <Breadcrumbs
          separator={
            <>
              <NavigateNextIcon fontSize="small" />
              <NavigateNextIcon fontSize="small" sx={{ marginLeft: "-15px" }} />
            </>
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Panel>
      <Panel>
        <Box sx={{ padding: "20px" }}>
          <form>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  width: isLargeScreen
                    ? "100%"
                    : isMiniDesktopScreen
                    ? "55%"
                    : "65%",
                }}
              >
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<DownAccordianArrow />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    Client Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="accordianbox">
                      <FormInputRow>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="businessName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Company name is required",
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message:
                                  "Company name can only contain alphabets",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                {...field}
                                isRequired
                                icon={<AddressIcon />}
                                label="Company Name"
                                placeholder="Enter company name"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="companyEmail"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Company Email is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Enter a valid email address",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                {...field}
                                isRequired
                                icon={<EmailIcon />}
                                label="Company Email"
                                placeholder="Enter company email"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="contactPhone"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Company Phone is required",
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "Company Phone must be exactly 10 digits",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                {...field}
                                maxLength={10}
                                minLength={10}
                                isRequired
                                icon={<PhoneIcon />}
                                label="Company Phone"
                                placeholder="Enter company phone number"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="merchantId"
                            control={control}
                            defaultValue=""
                            rules={{
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Merchant ID can only contain numbers",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                {...field}
                                icon={<StoreIcon />}
                                label="Merchant ID"
                                disabled={true}
                                placeholder="Enter Merchant ID"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="EIN"
                            control={control}
                            defaultValue=""
                            rules={{
                              pattern: {
                                value: /^[0-9]{9}$/,
                                message: "EIN must contain exactly 9 numbers",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                {...field}
                                maxLength={9}
                                icon={<CardIcon />}
                                label="EIN"
                                placeholder="Enter EIN"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>

                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="state"
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                              const stateOptions = statesList;
                              const selectedOption = stateOptions.find(
                                (option) => option.value === field.value
                              );
                              return (
                                <SelectComponent
                                  options={stateOptions}
                                  value={selectedOption}
                                  onChange={(newValue: any) => {
                                    if (newValue) {
                                      field.onChange(newValue.value);
                                    }
                                    onStateSelect(newValue.value);
                                  }}
                                  label="State"
                                  placeholder="Select State"
                                  fullWidth
                                  icon={<Homework />}
                                />
                              );
                            }}
                          />
                        </FormInputCol>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="city"
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                              const cityOptions = cityList;
                              const selectedOption = cityOptions.find(
                                (option) => option.value === field.value
                              );
                              return (
                                <SelectComponent
                                  options={cityOptions}
                                  value={selectedOption}
                                  onChange={(newValue: any) => {
                                    if (newValue) {
                                      field.onChange(newValue.value);
                                    }
                                  }}
                                  icon={<Location />}
                                  label="City"
                                  placeholder="Select City"
                                />
                              );
                            }}
                          />
                        </FormInputCol>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="zipCode"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Zip Code is required",
                              pattern: {
                                value: /^[0-9]{5}$/,
                                message:
                                  "Zip Code must be exactly 5 digits",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                isRequired
                                maxLength={5}
                                minLength={5}
                                {...field}
                                icon={<MyLocation />}
                                label="Zip Code"
                                placeholder="Enter zip code"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12}>
                          <Controller
                            name="status"
                            control={control}
                            defaultValue="active"
                            render={({ field }) => {
                              const handleToggleChange = (checked: boolean) => {
                                const newValue = checked
                                  ? "active"
                                  : "inactive";
                                setIsActive(checked);
                                field.onChange(newValue);
                              };
                              return (
                                <ToggleSwitch
                                  checked={isActive}
                                  onChange={handleToggleChange}
                                  labelActive="Active"
                                  labelInactive="Inactive"
                                  label="Account Access"
                                />
                              );
                            }}
                          />
                        </FormInputCol>
                      </FormInputRow>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<DownAccordianArrow />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Contact Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="accordianbox">
                      <FormInputRow>
                        <FormInputCol md={12}>
                          <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "First name is required",
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message:
                                  "First name can only contain alphabets",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                isRequired
                                {...field}
                                icon={<UserIconGray />}
                                label="First Name"
                                placeholder="Enter first name"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12}>
                          <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Last name is required",
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message: "Last name can only contain alphabets",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                isRequired
                                {...field}
                                icon={<UserIconGray />}
                                label="Last Name"
                                placeholder="Enter last name"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={6} xl={12}>
                          <Controller
                            name="title"
                            control={control}
                            defaultValue=""
                            rules={{
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message: "Title can only contain alphabets",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                {...field}
                                label="Title"
                                icon={<DriverLicense />}
                                placeholder="Enter Title"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={6}>
                          <Controller
                            name="phone"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Mobile Number is required",
                              pattern: {
                                value: /^[0-9]{10}$/,
                                message:
                                  "Mobile Number must be exactly 10 digits",
                              },
                            }}
                            render={({ field }) => (
                              <InputComponent
                                {...field}
                                maxLength={10}
                                minLength={10}
                                label="Mobile Number"
                                icon={<PhoneIcon />}
                                placeholder="Enter mobile number"
                                error={
                                  (errors.phone?.message as string) || null
                                }
                                isRequired
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={6}>
                          <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Enter a valid email address",
                              },
                            }}
                            render={({ field }) => (
                              <InputComponent
                                {...field}
                                label="Email Address"
                                icon={<EmailIcon />}
                                placeholder="Enter email address"
                                isRequired
                                error={
                                  (errors.email?.message as string) || null
                                }
                              />
                            )}
                          />
                        </FormInputCol>
                      </FormInputRow>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleAccordionChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<DownAccordianArrow />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Bank Account Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="accordianbox">
                      <FormInputRow>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="bankName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Bank name is required",
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message: "Bank name can only contain alphabets",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                isRequired
                                {...field}
                                icon={<BankIcon />}
                                label="Bank Name"
                                placeholder="Enter bank name"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="accountNumber"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Account Number is required",
                              pattern: {
                                value: /^[0-9]+$/,
                                message:
                                  "Account Number can only contain numbers",
                              },
                              minLength: {
                                value: 6,
                                message:
                                  "Account Number must be at least 6 digits long",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                isRequired
                                {...field}
                                minLength={6}
                                maxLength={17}
                                icon={<CardIcon />}
                                label="Account Number"
                                placeholder="Enter account number"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12} xl={4}>
                          <Controller
                            name="routingNumber"
                            control={control}
                            defaultValue=""
                            rules={{
                              pattern: {
                                value: /^[0-9]{9}$/,
                                message:
                                  "Routing Number must contain exactly 9 numbers",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                isRequired
                                maxLength={9}
                                {...field}
                                icon={<SsnNumber />}
                                label="Routing Number"
                                placeholder="Enter rounting number"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                      </FormInputRow>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleAccordionChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<DownAccordianArrow />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Card Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="accordianbox">
                      <FormInputRow>
                        <FormInputCol md={12}>
                          <Controller
                            name="cardholdername"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Name on Card is required",
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message:
                                  "Name on Card can only contain alphabets",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                isRequired
                                {...field}
                                icon={<CardIcon />}
                                label="Name on Card"
                                placeholder="Enter Name on Card"
                                error={error?.message}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12}>
                          <Controller
                            name="cardnumber"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Card Number is required",
                              pattern: {
                                value: /^\d{4}-\d{4}-\d{4}-\d{4}$/,
                                message:
                                  "Card Number must be in the format xxxx-xxxx-xxxx-xxxx",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                isRequired
                                {...field}
                                icon={<CardIcon />}
                                label="Card Number"
                                placeholder="Enter Card Number"
                                error={error?.message}
                                onChange={(e) => {
                                  const value = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  );
                                  const formattedValue = value
                                    .replace(
                                      /(\d{4})(\d{0,4})(\d{0,4})(\d{0,4})/,
                                      "$1-$2-$3-$4"
                                    )
                                    .replace(/-+$/, "");
                                  field.onChange(formattedValue);
                                }}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol md={12}>
                          <Controller
                            name="expirationDate"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Expiration Date is required",
                              pattern: {
                                value: /^[A-Za-z ]+$/i,
                                message:
                                  "Expiration Date can only contain alphabets",
                              },
                            }}
                            render={({ field }) => (
                              <CombinedMonthYearPicker
                                label="Expiration Date"
                                backgroundColor="#f7f9fc"
                                required
                                monthyear={expirationDate || undefined}
                                placeholder="Enter Expiration Date"
                                style={{
                                  height: "40px",
                                }}
                                onDateChange={(month, year) =>
                                  handleDateChange(month, year)
                                }
                                onCancel={handleCancelDate}
                              />
                            )}
                          />
                        </FormInputCol>
                      </FormInputRow>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleAccordionChange("panel5")}
                >
                  <AccordionSummary
                    expandIcon={<DownAccordianArrow />}
                    aria-controls="panel5-content"
                    id="panel5-header"
                  >
                    Product & Services Allocation
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="accordianbox">
                      <Typography
                        sx={{
                          color: "#374151",
                          fontSize: "14px",
                          fontWeight: "600",
                          marginBottom: "20px",
                        }}
                      >
                        Select Product from below
                      </Typography>
                      <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                        {featureData.map((feature) => (
                          <FeatureCard
                            key={feature.id}
                            title={feature.title}
                            icon={feature.icon}
                            checked={selectedFeature === feature.id}
                            onChange={() => handleFeatureChange(feature.id)}
                            disabled={feature.disable}
                          />
                        ))}
                      </Box>
                      {selectedFeature === "AcuPay" && (
                        <Box sx={{ marginTop: "20px" }}>
                          <Typography
                            sx={{
                              color: "#374151",
                              fontSize: "14px",
                              fontWeight: "600",
                              marginBottom: "20px",
                            }}
                          >
                            Select Services from below
                          </Typography>
                          <Box
                            sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}
                          >
                            {serviceData.map((service) => (
                              <FeatureCard
                                key={service.id}
                                title={service.title}
                                icon={service.icon}
                                checked={selectedServices.includes(service.id)}
                                onChange={() => handleServiceChange(service.id)}
                                disabled={true}
                              />
                            ))}
                          </Box>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "700",
                              marginTop: "10px",
                            }}
                          >
                            Note :&nbsp;
                            <Typography
                              component={"span"}
                              sx={{ fontSize: "14px", fontWeight: "600" }}
                            >
                              AcuFi will serve as the default processor.
                            </Typography>
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleAccordionChange("panel6")}
                >
                  <AccordionSummary
                    expandIcon={<DownAccordianArrow />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    Password Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="accordianbox">
                      <FormInputRow>
                        <FormInputCol>
                          <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "New Password is required",
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                                message:
                                  "Password must include at least one uppercase letter, one lowercase letter, one special character, and one number",
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                {...field}
                                icon={<PasswordIcon />}
                                label="New Password"
                                type="password"
                                isRequired
                                placeholder="Enter new password"
                                password
                                error={error?.message || null}
                              />
                            )}
                          />
                        </FormInputCol>
                        <FormInputCol>
                          <Controller
                            name="confirmPassword"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "Confirm Password is required",
                              validate: (value) =>
                                value === watch("password") ||
                                "Passwords do not match",
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <InputComponent
                                {...field}
                                icon={<PasswordIcon />}
                                label="Confirm Password"
                                type="password"
                                placeholder="Re-enter new password"
                                password
                                isRequired
                                error={error?.message || null}
                              />
                            )}
                          />
                        </FormInputCol>
                      </FormInputRow>
                    </Box>
                  </AccordionDetails>
                </Accordion>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    sx={{
                      background: "#fff",
                      color: "#000000",
                      border: "1px solid black",
                      marginRight: "10px",
                    }}
                    onClick={handleClientReset}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      background: allFieldsFilled ? "#1F3A91" : "#BCBCBD",
                      color: "#ffffff !important",
                    }}
                    onClick={handleSubmit}
                    disabled={!allFieldsFilled}
                  >
                    Add Client
                  </Button>
                </Box>
              </Box>
              {!isLargeScreen && (
                <Box
                  sx={{
                    width: "1%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "1px",
                      border: "1px dashed #D5E1F9",
                      height: "100%",
                    }}
                  />
                </Box>
              )}
              {!isLargeScreen && (
                <Box sx={{ width: isMiniDesktopScreen ? "42%" : "32%" }}>
                  <Box
                    className="accordianbox"
                    sx={{ background: "#F7FAFF !important" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        color: "#374151",
                        fontSize: "16px",
                        marginBottom: "10px",
                      }}
                    >
                      Client Overview
                    </Typography>
                    <Box
                      sx={{
                        background: "#fff",
                        padding: "20px",
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        {!hasClientDetails && (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <NoDeatilsInformation />
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#303132",
                                fontWeight: "600",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              No details added yet!
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "#6B7280",
                                fontWeight: "400",
                                textAlign: "center",
                                marginTop: "5px",
                              }}
                            >
                              Get started by adding details to onboard a new
                              client.
                            </Typography>
                          </Box>
                        )}
                        {hasClientDetails && (
                          <>
                            {expanded === "panel1" && (
                              <>
                                <CustomerOverview
                                  section="Client Information"
                                  sectionDetails={watch("businessName")}
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Company Name",
                                      value: errors.businessName
                                        ? "-"
                                        : watch("businessName") || "-",
                                      done:
                                        !errors.businessName &&
                                        watch("businessName") &&
                                        watch("businessName") !== "-",
                                    },
                                    {
                                      label: "Company Email",
                                      value: errors.companyEmail
                                        ? "-"
                                        : watch("companyEmail") || "-",
                                      done:
                                        !errors.companyEmail &&
                                        watch("companyEmail") &&
                                        watch("companyEmail") !== "-",
                                    },
                                    {
                                      label: "Company Phone",
                                      value: errors.contactPhone
                                        ? "-"
                                        : watch("contactPhone") || "-",
                                      done:
                                        !errors.contactPhone &&
                                        watch("contactPhone") &&
                                        watch("contactPhone") !== "-",
                                    },
                                    {
                                      label: "Merchant ID",
                                      value: errors.merchantId
                                        ? "-"
                                        : watch("merchantId") || "-",
                                      done:
                                        !errors.merchantId &&
                                        watch("merchantId") &&
                                        watch("merchantId") !== "-",
                                    },
                                    {
                                      label: "EIN",
                                      value: errors.EIN
                                        ? "-"
                                        : watch("EIN") || "-",
                                      done:
                                        !errors.EIN &&
                                        watch("EIN") &&
                                        watch("EIN") !== "-",
                                    },
                                    {
                                      label: "State",
                                      value: errors.state
                                        ? "-"
                                        : watch("state") || "-",
                                      done:
                                        !errors.state &&
                                        watch("state") &&
                                        watch("state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: errors.city
                                        ? "-"
                                        : watch("city") || "-",
                                      done:
                                        !errors.city &&
                                        watch("city") &&
                                        watch("city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: errors.zipCode
                                        ? "-"
                                        : watch("zipCode") || "-",
                                      done:
                                        !errors.zipCode &&
                                        watch("zipCode") &&
                                        watch("zipCode") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={"-"}
                                  section="Contact Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={BankInformation}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={CardInformation}
                                  sectionDetails={"-"}
                                  section="Card Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={ProductInformation}
                                  sectionDetails={"-"}
                                  section="Product & Services Allocation"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={PasswordInformation}
                                  sectionDetails={"-"}
                                  section="Password Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel2" && (
                              <>
                                <CustomerOverview
                                  section="Client Information"
                                  sectionDetails={watch("businessName")}
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Company Name",
                                      value: errors.businessName
                                        ? "-"
                                        : watch("businessName") || "-",
                                      done:
                                        !errors.businessName &&
                                        watch("businessName") &&
                                        watch("businessName") !== "-",
                                    },
                                    {
                                      label: "Company Email",
                                      value: errors.companyEmail
                                        ? "-"
                                        : watch("companyEmail") || "-",
                                      done:
                                        !errors.companyEmail &&
                                        watch("companyEmail") &&
                                        watch("companyEmail") !== "-",
                                    },
                                    {
                                      label: "Company Phone",
                                      value: errors.contactPhone
                                        ? "-"
                                        : watch("contactPhone") || "-",
                                      done:
                                        !errors.contactPhone &&
                                        watch("contactPhone") &&
                                        watch("contactPhone") !== "-",
                                    },
                                    {
                                      label: "Merchant ID",
                                      value: errors.merchantId
                                        ? "-"
                                        : watch("merchantId") || "-",
                                      done:
                                        !errors.merchantId &&
                                        watch("merchantId") &&
                                        watch("merchantId") !== "-",
                                    },
                                    {
                                      label: "EIN",
                                      value: errors.EIN
                                        ? "-"
                                        : watch("EIN") || "-",
                                      done:
                                        !errors.EIN &&
                                        watch("EIN") &&
                                        watch("EIN") !== "-",
                                    },
                                    {
                                      label: "State",
                                      value: errors.state
                                        ? "-"
                                        : watch("state") || "-",
                                      done:
                                        !errors.state &&
                                        watch("state") &&
                                        watch("state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: errors.city
                                        ? "-"
                                        : watch("city") || "-",
                                      done:
                                        !errors.city &&
                                        watch("city") &&
                                        watch("city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: errors.zipCode
                                        ? "-"
                                        : watch("zipCode") || "-",
                                      done:
                                        !errors.zipCode &&
                                        watch("zipCode") &&
                                        watch("zipCode") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={`${
                                    watch("firstName") || "-"
                                  } ${watch("lastName")}`}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "First Name",
                                      value: errors.firstName
                                        ? "-"
                                        : watch("firstName") || "-",
                                      done:
                                        !errors.firstName &&
                                        watch("firstName") &&
                                        watch("firstName") !== "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: errors.lastName
                                        ? "-"
                                        : watch("lastName") || "-",
                                      done:
                                        !errors.lastName &&
                                        watch("lastName") &&
                                        watch("lastName") !== "-",
                                    },
                                    {
                                      label: "Title",
                                      value: errors.title
                                        ? "-"
                                        : watch("title") || "-",
                                      done:
                                        !errors.title &&
                                        watch("title") &&
                                        watch("title") !== "-",
                                    },
                                    {
                                      label: "Mobile Number",
                                      value: errors.phone
                                        ? "-"
                                        : watch("phone") || "-",
                                      done:
                                        !errors.phone &&
                                        watch("phone") &&
                                        watch("phone") !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: errors.email
                                        ? "-"
                                        : watch("email") || "-",
                                      done:
                                        !errors.email &&
                                        watch("email") &&
                                        watch("email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={BankInformation}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={CardInformation}
                                  sectionDetails={"-"}
                                  section="Card Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={ProductInformation}
                                  sectionDetails={"-"}
                                  section="Product & Services Allocation"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={PasswordInformation}
                                  sectionDetails={"-"}
                                  section="Password Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel3" && (
                              <>
                                <CustomerOverview
                                  section="Client Information"
                                  sectionDetails={watch("businessName")}
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Company Name",
                                      value: errors.businessName
                                        ? "-"
                                        : watch("businessName") || "-",
                                      done:
                                        !errors.businessName &&
                                        watch("businessName") &&
                                        watch("businessName") !== "-",
                                    },
                                    {
                                      label: "Company Email",
                                      value: errors.companyEmail
                                        ? "-"
                                        : watch("companyEmail") || "-",
                                      done:
                                        !errors.companyEmail &&
                                        watch("companyEmail") &&
                                        watch("companyEmail") !== "-",
                                    },
                                    {
                                      label: "Company Phone",
                                      value: errors.contactPhone
                                        ? "-"
                                        : watch("contactPhone") || "-",
                                      done:
                                        !errors.contactPhone &&
                                        watch("contactPhone") &&
                                        watch("contactPhone") !== "-",
                                    },
                                    {
                                      label: "Merchant ID",
                                      value: errors.merchantId
                                        ? "-"
                                        : watch("merchantId") || "-",
                                      done:
                                        !errors.merchantId &&
                                        watch("merchantId") &&
                                        watch("merchantId") !== "-",
                                    },
                                    {
                                      label: "EIN",
                                      value: errors.EIN
                                        ? "-"
                                        : watch("EIN") || "-",
                                      done:
                                        !errors.EIN &&
                                        watch("EIN") &&
                                        watch("EIN") !== "-",
                                    },
                                    {
                                      label: "State",
                                      value: errors.state
                                        ? "-"
                                        : watch("state") || "-",
                                      done:
                                        !errors.state &&
                                        watch("state") &&
                                        watch("state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: errors.city
                                        ? "-"
                                        : watch("city") || "-",
                                      done:
                                        !errors.city &&
                                        watch("city") &&
                                        watch("city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: errors.zipCode
                                        ? "-"
                                        : watch("zipCode") || "-",
                                      done:
                                        !errors.zipCode &&
                                        watch("zipCode") &&
                                        watch("zipCode") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={`${
                                    watch("firstName") || "-"
                                  } ${watch("lastName")}`}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "First Name",
                                      value: errors.firstName
                                        ? "-"
                                        : watch("firstName") || "-",
                                      done:
                                        !errors.firstName &&
                                        watch("firstName") &&
                                        watch("firstName") !== "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: errors.lastName
                                        ? "-"
                                        : watch("lastName") || "-",
                                      done:
                                        !errors.lastName &&
                                        watch("lastName") &&
                                        watch("lastName") !== "-",
                                    },
                                    {
                                      label: "Title",
                                      value: errors.title
                                        ? "-"
                                        : watch("title") || "-",
                                      done:
                                        !errors.title &&
                                        watch("title") &&
                                        watch("title") !== "-",
                                    },
                                    {
                                      label: "Mobile Number",
                                      value: errors.phone
                                        ? "-"
                                        : watch("phone") || "-",
                                      done:
                                        !errors.phone &&
                                        watch("phone") &&
                                        watch("phone") !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: errors.email
                                        ? "-"
                                        : watch("email") || "-",
                                      done:
                                        !errors.email &&
                                        watch("email") &&
                                        watch("email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={BankInformation}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[
                                    {
                                      label: "Bank Name",
                                      value: errors.bankName
                                        ? "-"
                                        : watch("bankName") || "-",
                                      done:
                                        !errors.bankName &&
                                        watch("bankName") &&
                                        watch("bankName") !== "-",
                                    },
                                    {
                                      label: "Account Number",
                                      value: errors.accountNumber
                                        ? "-"
                                        : watch("accountNumber") || "-",
                                      done:
                                        !errors.accountNumber &&
                                        watch("accountNumber") &&
                                        watch("accountNumber") !== "-",
                                    },
                                    {
                                      label: "Routing Number",
                                      value: errors.routingNumber
                                        ? "-"
                                        : watch("routingNumber") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={CardInformation}
                                  sectionDetails={"-"}
                                  section="Card Information"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={ProductInformation}
                                  sectionDetails={"-"}
                                  section="Product & Services Allocation"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={PasswordInformation}
                                  sectionDetails={"-"}
                                  section="Password Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel4" && (
                              <>
                                <CustomerOverview
                                  section="Client Information"
                                  sectionDetails={watch("businessName")}
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Company Name",
                                      value: errors.businessName
                                        ? "-"
                                        : watch("businessName") || "-",
                                      done:
                                        !errors.businessName &&
                                        watch("businessName") &&
                                        watch("businessName") !== "-",
                                    },
                                    {
                                      label: "Company Email",
                                      value: errors.companyEmail
                                        ? "-"
                                        : watch("companyEmail") || "-",
                                      done:
                                        !errors.companyEmail &&
                                        watch("companyEmail") &&
                                        watch("companyEmail") !== "-",
                                    },
                                    {
                                      label: "Company Phone",
                                      value: errors.contactPhone
                                        ? "-"
                                        : watch("contactPhone") || "-",
                                      done:
                                        !errors.contactPhone &&
                                        watch("contactPhone") &&
                                        watch("contactPhone") !== "-",
                                    },
                                    {
                                      label: "Merchant ID",
                                      value: errors.merchantId
                                        ? "-"
                                        : watch("merchantId") || "-",
                                      done:
                                        !errors.merchantId &&
                                        watch("merchantId") &&
                                        watch("merchantId") !== "-",
                                    },
                                    {
                                      label: "EIN",
                                      value: errors.EIN
                                        ? "-"
                                        : watch("EIN") || "-",
                                      done:
                                        !errors.EIN &&
                                        watch("EIN") &&
                                        watch("EIN") !== "-",
                                    },
                                    {
                                      label: "State",
                                      value: errors.state
                                        ? "-"
                                        : watch("state") || "-",
                                      done:
                                        !errors.state &&
                                        watch("state") &&
                                        watch("state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: errors.city
                                        ? "-"
                                        : watch("city") || "-",
                                      done:
                                        !errors.city &&
                                        watch("city") &&
                                        watch("city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: errors.zipCode
                                        ? "-"
                                        : watch("zipCode") || "-",
                                      done:
                                        !errors.zipCode &&
                                        watch("zipCode") &&
                                        watch("zipCode") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={`${
                                    watch("firstName") || "-"
                                  } ${watch("lastName")}`}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "First Name",
                                      value: errors.firstName
                                        ? "-"
                                        : watch("firstName") || "-",
                                      done:
                                        !errors.firstName &&
                                        watch("firstName") &&
                                        watch("firstName") !== "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: errors.lastName
                                        ? "-"
                                        : watch("lastName") || "-",
                                      done:
                                        !errors.lastName &&
                                        watch("lastName") &&
                                        watch("lastName") !== "-",
                                    },
                                    {
                                      label: "Title",
                                      value: errors.title
                                        ? "-"
                                        : watch("title") || "-",
                                      done:
                                        !errors.title &&
                                        watch("title") &&
                                        watch("title") !== "-",
                                    },
                                    {
                                      label: "Mobile Number",
                                      value: errors.phone
                                        ? "-"
                                        : watch("phone") || "-",
                                      done:
                                        !errors.phone &&
                                        watch("phone") &&
                                        watch("phone") !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: errors.email
                                        ? "-"
                                        : watch("email") || "-",
                                      done:
                                        !errors.email &&
                                        watch("email") &&
                                        watch("email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={BankInformation}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[
                                    {
                                      label: "Bank Name",
                                      value: errors.bankName
                                        ? "-"
                                        : watch("bankName") || "-",
                                      done:
                                        !errors.bankName &&
                                        watch("bankName") &&
                                        watch("bankName") !== "-",
                                    },
                                    {
                                      label: "Account Number",
                                      value: errors.accountNumber
                                        ? "-"
                                        : watch("accountNumber") || "-",
                                      done:
                                        !errors.accountNumber &&
                                        watch("accountNumber") &&
                                        watch("accountNumber") !== "-",
                                    },
                                    {
                                      label: "Routing Number",
                                      value: errors.routingNumber
                                        ? "-"
                                        : watch("routingNumber") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={CardInformation}
                                  sectionDetails={watch("cardholdername")}
                                  section="Card Information"
                                  details={[
                                    {
                                      label: "Card Holder Name",
                                      value: errors.cardholdername
                                        ? "-"
                                        : watch("cardholdername") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                    {
                                      label: "Card Number",
                                      value: errors.cardnumber
                                        ? "-"
                                        : watch("cardnumber") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                    {
                                      label: "Expiration Date",
                                      value: errors.expirationDate
                                        ? "-"
                                        : `${expirationDate || "-"}`,
                                      done: !!expirationDate,
                                    },
                                  ]}
                                  visibility={visibility}
                                  onToggleVisibility={handleToggleVisibility}
                                />
                                <CustomerOverview
                                  src={ProductInformation}
                                  sectionDetails={"-"}
                                  section="Product & Services Allocation"
                                  details={[]}
                                />
                                <CustomerOverview
                                  src={PasswordInformation}
                                  sectionDetails={"-"}
                                  section="Password Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel5" && (
                              <>
                                <CustomerOverview
                                  section="Client Information"
                                  sectionDetails={watch("businessName")}
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Company Name",
                                      value: errors.businessName
                                        ? "-"
                                        : watch("businessName") || "-",
                                      done:
                                        !errors.businessName &&
                                        watch("businessName") &&
                                        watch("businessName") !== "-",
                                    },
                                    {
                                      label: "Company Email",
                                      value: errors.companyEmail
                                        ? "-"
                                        : watch("companyEmail") || "-",
                                      done:
                                        !errors.companyEmail &&
                                        watch("companyEmail") &&
                                        watch("companyEmail") !== "-",
                                    },
                                    {
                                      label: "Company Phone",
                                      value: errors.contactPhone
                                        ? "-"
                                        : watch("contactPhone") || "-",
                                      done:
                                        !errors.contactPhone &&
                                        watch("contactPhone") &&
                                        watch("contactPhone") !== "-",
                                    },
                                    {
                                      label: "Merchant ID",
                                      value: errors.merchantId
                                        ? "-"
                                        : watch("merchantId") || "-",
                                      done:
                                        !errors.merchantId &&
                                        watch("merchantId") &&
                                        watch("merchantId") !== "-",
                                    },
                                    {
                                      label: "EIN",
                                      value: errors.EIN
                                        ? "-"
                                        : watch("EIN") || "-",
                                      done:
                                        !errors.EIN &&
                                        watch("EIN") &&
                                        watch("EIN") !== "-",
                                    },
                                    {
                                      label: "State",
                                      value: errors.state
                                        ? "-"
                                        : watch("state") || "-",
                                      done:
                                        !errors.state &&
                                        watch("state") &&
                                        watch("state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: errors.city
                                        ? "-"
                                        : watch("city") || "-",
                                      done:
                                        !errors.city &&
                                        watch("city") &&
                                        watch("city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: errors.zipCode
                                        ? "-"
                                        : watch("zipCode") || "-",
                                      done:
                                        !errors.zipCode &&
                                        watch("zipCode") &&
                                        watch("zipCode") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={`${
                                    watch("firstName") || "-"
                                  } ${watch("lastName")}`}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "First Name",
                                      value: errors.firstName
                                        ? "-"
                                        : watch("firstName") || "-",
                                      done:
                                        !errors.firstName &&
                                        watch("firstName") &&
                                        watch("firstName") !== "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: errors.lastName
                                        ? "-"
                                        : watch("lastName") || "-",
                                      done:
                                        !errors.lastName &&
                                        watch("lastName") &&
                                        watch("lastName") !== "-",
                                    },
                                    {
                                      label: "Title",
                                      value: errors.title
                                        ? "-"
                                        : watch("title") || "-",
                                      done:
                                        !errors.title &&
                                        watch("title") &&
                                        watch("title") !== "-",
                                    },
                                    {
                                      label: "Mobile Number",
                                      value: errors.phone
                                        ? "-"
                                        : watch("phone") || "-",
                                      done:
                                        !errors.phone &&
                                        watch("phone") &&
                                        watch("phone") !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: errors.email
                                        ? "-"
                                        : watch("email") || "-",
                                      done:
                                        !errors.email &&
                                        watch("email") &&
                                        watch("email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={BankInformation}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[
                                    {
                                      label: "Bank Name",
                                      value: errors.bankName
                                        ? "-"
                                        : watch("bankName") || "-",
                                      done:
                                        !errors.bankName &&
                                        watch("bankName") &&
                                        watch("bankName") !== "-",
                                    },
                                    {
                                      label: "Account Number",
                                      value: errors.accountNumber
                                        ? "-"
                                        : watch("accountNumber") || "-",
                                      done:
                                        !errors.accountNumber &&
                                        watch("accountNumber") &&
                                        watch("accountNumber") !== "-",
                                    },
                                    {
                                      label: "Routing Number",
                                      value: errors.routingNumber
                                        ? "-"
                                        : watch("routingNumber") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={CardInformation}
                                  sectionDetails={watch("cardholdername")}
                                  section="Card Information"
                                  details={[
                                    {
                                      label: "Card Holder Name",
                                      value: errors.cardholdername
                                        ? "-"
                                        : watch("cardholdername") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                    {
                                      label: "Card Number",
                                      value: errors.cardnumber
                                        ? "-"
                                        : watch("cardnumber") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                    {
                                      label: "Expiration Date",
                                      value: errors.expirationDate
                                        ? "-"
                                        : `${expirationDate || "-"}`,
                                      done: !!expirationDate,
                                    },
                                  ]}
                                  visibility={visibility}
                                  onToggleVisibility={handleToggleVisibility}
                                />
                                <CustomerOverview
                                  src={ProductInformation}
                                  sectionDetails={"-"}
                                  section="Product & Services Allocation"
                                  details={[
                                    {
                                      label: "Product",
                                      value: errors.product
                                        ? "-"
                                        : selectedFeature
                                        ? featureData.find(
                                            (feature) =>
                                              feature.id === selectedFeature
                                          )?.title || "-"
                                        : "-",
                                      done:
                                        !!selectedFeature &&
                                        !!featureData.find(
                                          (feature) =>
                                            feature.id === selectedFeature
                                        )?.title,
                                    },
                                    {
                                      label: "Services",
                                      value: errors.services
                                        ? "-"
                                        : selectedServices.length > 0
                                        ? selectedServices
                                            .map(
                                              (id) =>
                                                serviceData.find(
                                                  (service) => service.id === id
                                                )?.title || "-"
                                            )
                                            .join(", ")
                                        : "-",
                                      done:
                                        selectedServices.length > 0 &&
                                        selectedServices.every(
                                          (id) =>
                                            !!serviceData.find(
                                              (service) => service.id === id
                                            )?.title
                                        ),
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={PasswordInformation}
                                  sectionDetails={"-"}
                                  section="Password Information"
                                  details={[]}
                                />
                              </>
                            )}
                            {expanded === "panel6" && (
                              <>
                                <CustomerOverview
                                  section="Client Information"
                                  sectionDetails={watch("businessName")}
                                  src={customerInformationIcon}
                                  details={[
                                    {
                                      label: "Company Name",
                                      value: errors.businessName
                                        ? "-"
                                        : watch("businessName") || "-",
                                      done:
                                        !errors.businessName &&
                                        watch("businessName") &&
                                        watch("businessName") !== "-",
                                    },
                                    {
                                      label: "Company Email",
                                      value: errors.companyEmail
                                        ? "-"
                                        : watch("companyEmail") || "-",
                                      done:
                                        !errors.companyEmail &&
                                        watch("companyEmail") &&
                                        watch("companyEmail") !== "-",
                                    },
                                    {
                                      label: "Company Phone",
                                      value: errors.contactPhone
                                        ? "-"
                                        : watch("contactPhone") || "-",
                                      done:
                                        !errors.contactPhone &&
                                        watch("contactPhone") &&
                                        watch("contactPhone") !== "-",
                                    },
                                    {
                                      label: "Merchant ID",
                                      value: errors.merchantId
                                        ? "-"
                                        : watch("merchantId") || "-",
                                      done:
                                        !errors.merchantId &&
                                        watch("merchantId") &&
                                        watch("merchantId") !== "-",
                                    },
                                    {
                                      label: "EIN",
                                      value: errors.EIN
                                        ? "-"
                                        : watch("EIN") || "-",
                                      done:
                                        !errors.EIN &&
                                        watch("EIN") &&
                                        watch("EIN") !== "-",
                                    },
                                    {
                                      label: "State",
                                      value: errors.state
                                        ? "-"
                                        : watch("state") || "-",
                                      done:
                                        !errors.state &&
                                        watch("state") &&
                                        watch("state") !== "-",
                                    },
                                    {
                                      label: "City",
                                      value: errors.city
                                        ? "-"
                                        : watch("city") || "-",
                                      done:
                                        !errors.city &&
                                        watch("city") &&
                                        watch("city") !== "-",
                                    },
                                    {
                                      label: "Zip Code",
                                      value: errors.zipCode
                                        ? "-"
                                        : watch("zipCode") || "-",
                                      done:
                                        !errors.zipCode &&
                                        watch("zipCode") &&
                                        watch("zipCode") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={contactInformation}
                                  sectionDetails={`${
                                    watch("firstName") || "-"
                                  } ${watch("lastName")}`}
                                  section="Contact Information"
                                  details={[
                                    {
                                      label: "First Name",
                                      value: errors.firstName
                                        ? "-"
                                        : watch("firstName") || "-",
                                      done:
                                        !errors.firstName &&
                                        watch("firstName") &&
                                        watch("firstName") !== "-",
                                    },
                                    {
                                      label: "Last Name",
                                      value: errors.lastName
                                        ? "-"
                                        : watch("lastName") || "-",
                                      done:
                                        !errors.lastName &&
                                        watch("lastName") &&
                                        watch("lastName") !== "-",
                                    },
                                    {
                                      label: "Title",
                                      value: errors.title
                                        ? "-"
                                        : watch("title") || "-",
                                      done:
                                        !errors.title &&
                                        watch("title") &&
                                        watch("title") !== "-",
                                    },
                                    {
                                      label: "Mobile Number",
                                      value: errors.phone
                                        ? "-"
                                        : watch("phone") || "-",
                                      done:
                                        !errors.phone &&
                                        watch("phone") &&
                                        watch("phone") !== "-",
                                    },
                                    {
                                      label: "Email",
                                      value: errors.email
                                        ? "-"
                                        : watch("email") || "-",
                                      done:
                                        !errors.email &&
                                        watch("email") &&
                                        watch("email") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={BankInformation}
                                  sectionDetails={"-"}
                                  section="Bank Information"
                                  details={[
                                    {
                                      label: "Bank Name",
                                      value: errors.bankName
                                        ? "-"
                                        : watch("bankName") || "-",
                                      done:
                                        !errors.bankName &&
                                        watch("bankName") &&
                                        watch("bankName") !== "-",
                                    },
                                    {
                                      label: "Account Number",
                                      value: errors.accountNumber
                                        ? "-"
                                        : watch("accountNumber") || "-",
                                      done:
                                        !errors.accountNumber &&
                                        watch("accountNumber") &&
                                        watch("accountNumber") !== "-",
                                    },
                                    {
                                      label: "Routing Number",
                                      value: errors.routingNumber
                                        ? "-"
                                        : watch("routingNumber") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={CardInformation}
                                  sectionDetails={watch("cardholdername")}
                                  section="Card Information"
                                  details={[
                                    {
                                      label: "Card Holder Name",
                                      value: errors.cardholdername
                                        ? "-"
                                        : watch("cardholdername") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                    {
                                      label: "Card Number",
                                      value: errors.cardnumber
                                        ? "-"
                                        : watch("cardnumber") || "-",
                                      done:
                                        !errors.routingNumber &&
                                        watch("routingNumber") &&
                                        watch("routingNumber") !== "-",
                                    },
                                    {
                                      label: "Expiration Date",
                                      value: errors.expirationDate
                                        ? "-"
                                        : `${expirationDate || "-"}`,
                                      done: !!expirationDate,
                                    },
                                  ]}
                                  visibility={visibility}
                                  onToggleVisibility={handleToggleVisibility}
                                />
                                <CustomerOverview
                                  src={ProductInformation}
                                  sectionDetails={"-"}
                                  section="Product & Services Allocation"
                                  details={[
                                    {
                                      label: "Product",
                                      value: errors.product
                                        ? "-"
                                        : selectedFeature
                                        ? featureData.find(
                                            (feature) =>
                                              feature.id === selectedFeature
                                          )?.title || "-"
                                        : "-",
                                      done:
                                        !!selectedFeature &&
                                        !!featureData.find(
                                          (feature) =>
                                            feature.id === selectedFeature
                                        )?.title,
                                    },
                                    {
                                      label: "Services",
                                      value: errors.services
                                        ? "-"
                                        : selectedServices.length > 0
                                        ? selectedServices
                                            .map(
                                              (id) =>
                                                serviceData.find(
                                                  (service) => service.id === id
                                                )?.title || "-"
                                            )
                                            .join(", ")
                                        : "-",
                                      done:
                                        selectedServices.length > 0 &&
                                        selectedServices.every(
                                          (id) =>
                                            !!serviceData.find(
                                              (service) => service.id === id
                                            )?.title
                                        ),
                                    },
                                  ]}
                                />
                                <CustomerOverview
                                  src={PasswordInformation}
                                  sectionDetails={"-"}
                                  section="Password Information"
                                  details={[
                                    {
                                      label: "Password",
                                      value: errors.password
                                        ? "-"
                                        : watch("password")
                                        ? visibility["Password"]
                                          ? watch("password")
                                          : "*".repeat(watch("password").length)
                                        : "-",
                                      done:
                                        !errors.password &&
                                        watch("password") &&
                                        watch("password") !== "-",
                                    },
                                    {
                                      label: "Confirm Password",
                                      value: errors.confirmPassword
                                        ? "-"
                                        : watch("confirmPassword")
                                        ? visibility["Confirm Password"]
                                          ? watch("confirmPassword")
                                          : "*".repeat(
                                              watch("confirmPassword").length
                                            )
                                        : "-",
                                      done:
                                        !errors.confirmPassword &&
                                        watch("confirmPassword") &&
                                        watch("confirmPassword") !== "-",
                                    },
                                  ]}
                                  visibility={visibility}
                                  onToggleVisibility={handleToggleVisibility}
                                />
                              </>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </form>
        </Box>
        {loading && <CircularSpinner progress={progress} />}
      </Panel>
    </>
  );
};

export default AddClient;
