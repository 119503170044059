const CreditTransaction = () => {
  return (
    <svg width="61" height="64" viewBox="0 0 61 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M42.6863 39.8367H52.6204V42.5088L46.8969 43.9451C46.6144 44.0167 46.3859 44.2483 46.2981 44.5553C46.025 45.505 45.6734 46.4468 45.2527 47.3548C45.1203 47.6411 45.1344 47.9845 45.289 48.2564L48.4214 53.7633L43.8617 58.8213L38.8975 55.3465C38.6526 55.1748 38.3432 55.1605 38.0854 55.3075C37.2885 55.7641 36.4412 56.1544 35.5658 56.468C35.2905 56.5656 35.0818 56.8193 35.0174 57.1328L33.7236 63.4852H27.2759L25.982 57.1328C25.9176 56.8193 25.7102 56.5656 25.4348 56.468C24.5699 56.1584 23.7226 55.7667 22.9141 55.3049C22.6562 55.1566 22.3468 55.1709 22.1019 55.3439L17.138 58.8198L12.5794 53.7631L15.7107 48.2562C15.8654 47.9843 15.8794 47.6408 15.7469 47.3546C15.3262 46.4466 14.9747 45.5047 14.7027 44.555C14.6136 44.2479 14.3863 44.0164 14.1027 43.9448L8.37927 42.5088V39.8367H18.3133H19.954C20.3735 45.8887 24.9428 50.6708 30.4986 50.6708C36.0557 50.6708 40.626 45.8887 41.0467 39.8367H42.6863ZM7.83633 17.9426H4.79359C4.3413 17.9426 3.97473 17.5356 3.97473 17.0335V9.43876H1.31888C1.00608 9.43876 0.720522 9.2409 0.583145 8.92885C0.445767 8.6168 0.48064 8.24518 0.672851 7.97119L5.66893 0.850371C5.82416 0.629304 6.06252 0.5 6.31496 0.5C6.56752 0.5 6.80576 0.629304 6.96099 0.850371L11.9571 7.97119C12.1493 8.24518 12.1842 8.6168 12.0468 8.92885C11.9094 9.2409 11.6238 9.43876 11.311 9.43876H8.6552V17.0337C8.6552 17.5356 8.28862 17.9426 7.83633 17.9426ZM60.3272 12.0387L55.3311 19.1595C55.1758 19.3805 54.9375 19.5098 54.685 19.5098C54.4326 19.5098 54.1942 19.3805 54.039 19.1595L49.0428 12.0387C48.8506 11.7647 48.8157 11.393 48.9531 11.081C49.0905 10.769 49.376 10.5711 49.6888 10.5711H52.3447V2.97619C52.3447 2.4741 52.7114 2.06715 53.1636 2.06715H56.2064C56.6587 2.06715 57.0253 2.4741 57.0253 2.97619V10.5711H59.6811C59.9939 10.5711 60.2795 10.769 60.4169 11.081C60.5542 11.3929 60.5194 11.7645 60.3272 12.0387Z" fill="url(#paint0_linear_4660_58876)"/>
    <path d="M13.3579 37.1114L13.3551 37.1114C13.2595 37.1119 13.1635 37.0919 13.0722 37.051C12.9808 37.01 12.8946 36.9482 12.8198 36.8669L12.4516 37.2052L12.8198 36.8669C12.745 36.7854 12.6835 36.6862 12.6408 36.5739C12.5981 36.4616 12.5757 36.3398 12.5757 36.2158V15.4337V15.4336C12.5757 15.3097 12.5981 15.1879 12.6408 15.0755C12.6835 14.9632 12.745 14.864 12.8198 14.7826C12.8946 14.7012 12.9808 14.6395 13.0722 14.5985C13.1635 14.5576 13.2595 14.5376 13.3551 14.5381L13.3551 14.5381H13.3579L46.8283 14.5381C47.0187 14.5381 47.2116 14.6205 47.3612 14.7842L47.7303 14.4469L47.3613 14.7842C47.5123 14.9494 47.6039 15.1824 47.6039 15.4337V36.2158C47.6039 36.4671 47.5123 36.7001 47.3613 36.8653C47.2116 37.029 47.0187 37.1114 46.8283 37.1114H30.0931L13.3579 37.1114Z" stroke="#2461C8"/>
    <path d="M48.0974 18.4912H12.0757V22.5584H48.0974V18.4912Z" fill="#4378D0"/>
    <path d="M20.0515 32.8545H14.758C14.5958 32.8545 14.4403 32.925 14.3256 33.0505C14.2109 33.1759 14.1465 33.3461 14.1465 33.5235C14.1482 33.6997 14.2134 33.868 14.3279 33.9919C14.4424 34.1159 14.597 34.1854 14.758 34.1853H20.0515C20.1315 34.1863 20.2108 34.1699 20.285 34.137C20.3591 34.1042 20.4266 34.0556 20.4835 33.9941C20.5404 33.9325 20.5855 33.8592 20.6163 33.7785C20.6471 33.6977 20.663 33.6111 20.663 33.5235C20.6639 33.4354 20.6487 33.348 20.6182 33.2664C20.5878 33.1848 20.5428 33.1106 20.4858 33.0483C20.4289 32.986 20.3611 32.9368 20.2865 32.9035C20.2119 32.8702 20.132 32.8536 20.0515 32.8545Z" fill="#BED6FF"/>
    <path d="M28.199 32.8545H22.9055C22.825 32.8536 22.7451 32.8702 22.6705 32.9035C22.5959 32.9368 22.5281 32.986 22.4711 33.0483C22.4142 33.1106 22.3692 33.1848 22.3387 33.2664C22.3083 33.348 22.2931 33.4354 22.294 33.5235C22.294 33.6111 22.3098 33.6977 22.3407 33.7785C22.3715 33.8592 22.4166 33.9325 22.4735 33.9941C22.5304 34.0556 22.5978 34.1042 22.672 34.137C22.7462 34.1699 22.8255 34.1863 22.9055 34.1853H28.199C28.36 34.1854 28.5146 34.1159 28.6291 33.9919C28.7436 33.868 28.8088 33.6997 28.8105 33.5235C28.8105 33.3461 28.7461 33.1759 28.6314 33.0505C28.5167 32.925 28.3611 32.8545 28.199 32.8545Z" fill="#BED6FF"/>
    <path d="M36.3498 32.8545H31.0498C30.9698 32.8545 30.8906 32.8718 30.8168 32.9056C30.743 32.9393 30.676 32.9887 30.6197 33.0509C30.5634 33.1131 30.519 33.1869 30.489 33.268C30.459 33.3491 30.444 33.436 30.4449 33.5235C30.4449 33.699 30.5086 33.8674 30.6221 33.9915C30.7355 34.1156 30.8894 34.1853 31.0498 34.1853H36.3498C36.5109 34.1853 36.6655 34.1158 36.78 33.9919C36.8945 33.868 36.9597 33.6997 36.9614 33.5235C36.9614 33.3461 36.8969 33.1759 36.7823 33.0504C36.6676 32.925 36.512 32.8545 36.3498 32.8545Z" fill="#BED6FF"/>
    <path d="M44.4916 32.8545H39.1916C39.0294 32.8545 38.8739 32.925 38.7592 33.0504C38.6445 33.1759 38.5801 33.3461 38.5801 33.5235C38.5818 33.6997 38.647 33.868 38.7615 33.9919C38.876 34.1158 39.0306 34.1853 39.1916 34.1853H44.4916C44.6521 34.1853 44.806 34.1156 44.9194 33.9915C45.0329 33.8674 45.0966 33.699 45.0966 33.5235C45.0975 33.436 45.0825 33.3491 45.0524 33.268C45.0224 33.1869 44.978 33.1131 44.9217 33.0509C44.8655 32.9887 44.7985 32.9393 44.7247 32.9056C44.6508 32.8718 44.5716 32.8545 44.4916 32.8545Z" fill="#BED6FF"/>
    <path d="M14.5152 15.0039C14.4266 15.003 14.3386 15.0213 14.2565 15.0577C14.1744 15.0942 14.0996 15.1481 14.0367 15.2163C13.9737 15.2845 13.9237 15.3657 13.8896 15.4552C13.8555 15.5447 13.8379 15.6407 13.8379 15.7377V15.9967C13.8396 16.1919 13.9117 16.3785 14.0386 16.5159C14.1654 16.6533 14.3367 16.7304 14.5152 16.7304C14.6931 16.7304 14.8637 16.6531 14.9895 16.5155C15.1152 16.3779 15.1859 16.1913 15.1859 15.9967V15.7377C15.1859 15.5431 15.1152 15.3565 14.9895 15.2188C14.8637 15.0812 14.6931 15.0039 14.5152 15.0039Z" fill="#BED6FF"/>
    <path d="M15.9619 15.8672C15.9636 16.0968 16.0482 16.3163 16.1973 16.478C16.3463 16.6397 16.5477 16.7304 16.7576 16.7304H19.5588C19.7681 16.7304 19.9688 16.6394 20.1168 16.4776C20.2648 16.3157 20.3479 16.0961 20.3479 15.8672C20.3479 15.6382 20.2648 15.4187 20.1168 15.2568C19.9688 15.0949 19.7681 15.0039 19.5588 15.0039H16.7576C16.6534 15.003 16.5501 15.0246 16.4536 15.0676C16.3571 15.1105 16.2693 15.174 16.1954 15.2542C16.1214 15.3345 16.0627 15.4299 16.0226 15.5351C15.9825 15.6404 15.9619 15.7532 15.9619 15.8672Z" fill="#BED6FF"/>
    <path d="M19.4847 24.7441H15.868C15.287 24.7441 14.8159 25.2594 14.8159 25.8951V29.3077C14.8159 29.9434 15.287 30.4587 15.868 30.4587H19.4847C20.0657 30.4587 20.5368 29.9434 20.5368 29.3077V25.8951C20.5368 25.2594 20.0657 24.7441 19.4847 24.7441Z" fill="#AACAFF"/>
    <path d="M31.2494 27.8307C30.956 27.3867 30.2098 27.3859 29.9157 27.8294C27.7886 31.0367 23.997 31.4674 22.4511 31.5033C22.0859 31.5117 21.7806 31.8221 21.8044 32.1867C22.5607 43.7688 29.1384 47.213 30.3816 47.763C30.5111 47.8203 30.6565 47.8202 30.786 47.7628C32.0523 47.2015 38.8534 43.6309 39.3863 31.4928C39.3863 31.4928 33.9552 31.9259 31.2494 27.8307Z" fill="#BACCCB"/>
    <path d="M30.5838 46.6842C29.2428 45.9991 28.0352 45.0362 27.0276 43.8485C24.6028 41.0611 23.1921 37.2586 22.8247 32.5539C24.6322 32.4572 28.3354 31.845 30.5838 28.6387C32.8322 31.845 36.5354 32.4572 38.3429 32.5539C37.9609 37.2586 36.5501 41.0611 34.1401 43.8485C33.1295 45.0326 31.9226 45.9949 30.5838 46.6842Z" fill="#2F80ED"/>
    <path d="M32.5824 36.3564H28.5706C28.2622 36.3564 28.0122 36.6306 28.0122 36.9687V40.4747C28.0122 40.8128 28.2622 41.0869 28.5706 41.0869H32.5824C32.8909 41.0869 33.1409 40.8128 33.1409 40.4747V36.9687C33.1409 36.6306 32.8909 36.3564 32.5824 36.3564Z" fill="white"/>
    <path d="M32.4057 38.3703H28.7466V35.7279C28.7466 35.3333 28.8885 34.9546 29.1417 34.674C29.3948 34.3935 29.7386 34.2337 30.0985 34.2295H31.0537C31.4137 34.2337 31.7575 34.3935 32.0106 34.674C32.2638 34.9546 32.4057 35.3333 32.4057 35.7279V38.3703ZM29.1727 37.903H31.9942V35.7279C31.9942 35.4544 31.8952 35.1921 31.7188 34.9988C31.5424 34.8054 31.3032 34.6967 31.0537 34.6967H30.0985C29.8517 34.701 29.6162 34.8115 29.443 35.0044C29.2698 35.1973 29.1727 35.4572 29.1727 35.7279V37.903Z" fill="white"/>
    <path d="M31.216 38.289C31.216 38.1037 31.1499 37.9258 31.0318 37.7933C30.9137 37.6608 30.753 37.5843 30.5841 37.5801C30.4126 37.5801 30.2481 37.6548 30.1269 37.7877C30.0056 37.9207 29.9375 38.101 29.9375 38.289C29.9377 38.4151 29.9692 38.5388 30.0288 38.6467C30.0884 38.7546 30.1737 38.8426 30.2755 38.9013V39.5135C30.2755 39.6033 30.308 39.6893 30.3659 39.7528C30.4238 39.8162 30.5022 39.8519 30.5841 39.8519C30.624 39.8519 30.6634 39.8431 30.7001 39.8259C30.7367 39.8087 30.7698 39.7835 30.7973 39.7519C30.8248 39.7202 30.8461 39.6828 30.86 39.6418C30.8739 39.6008 30.88 39.5572 30.878 39.5135V38.9013C30.9798 38.8426 31.0651 38.7546 31.1247 38.6467C31.1842 38.5388 31.2158 38.4151 31.216 38.289Z" fill="#1A5163"/>
    <defs>
    <linearGradient id="paint0_linear_4660_58876" x1="30.5" y1="0.49987" x2="30.5" y2="63.4851" gradientUnits="userSpaceOnUse">
    <stop stop-color="#17A6D7"/>
    <stop offset="0.0617" stop-color="#259ADB"/>
    <stop offset="0.1856" stop-color="#3B87E2"/>
    <stop offset="0.2649" stop-color="#4380E4"/>
    <stop offset="1" stop-color="#2F5EAC"/>
    </linearGradient>
    </defs>
    </svg>
    
  )
}

export default CreditTransaction