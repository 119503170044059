import {
  Box,
  Button,
  Checkbox,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import Payment from "../../assets/IconComponents/Setting/Payment";
import InputComponent from "../../components/form/input";
import DTIThreshold from "../../assets/IconComponents/Setting/DTIThreshold";
import AddIcon from "../../assets/IconComponents/AddIcon";
import Edit from "../../assets/IconComponents/Setting/EditIcon";
import DeleteIcon from "../../assets/IconComponents/Setting/DeleteIcon";
import { useState } from "react";

const dataFields = [
  { label: "Installment Payment Allowed", value: "5%" },
  { label: "Rate of Interest (%)", value: "5%" },
  { label: "Rate of Principal (%)", value: "5%" },
];

type DtiThreshold = {
  id: number;
  label: string;
  threshold: string;
};

const SettingField = ({
  label,
  value,
  isLast,
}: {
  label: string;
  value: string;
  isLast?: boolean;
}) => (
  <Box
    sx={{
      padding: "20px 0",
      display: "flex",
      alignItems: "center",
      borderBottom: isLast ? "none" : "1px dashed #D5E1F9",
    }}
  >
    <Typography
      sx={{
        color: "#111928",
        fontSize: "14px",
        fontWeight: "600",
        width: "210px",
      }}
    >
      {label}
    </Typography>
    <Box sx={{ width: "100px" }}>
      <InputComponent value={value} />
    </Box>
  </Box>
);

const DTISetting = () => {
  const theme = useTheme();
  const [addClick, setAddClick] = useState(false);
  const [dtiThresholds, setDtiThresholds] = useState<DtiThreshold[]>([]);
  const [inputData, setInputData] = useState({
    label: "",
    threshold: "",
  });

  const handleInputChange = (key: string, value: string) => {
    setInputData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleAddThreshold = () => {
    const newId =
      dtiThresholds.length > 0
        ? dtiThresholds[dtiThresholds.length - 1].id + 1
        : 1;
    const newThreshold: DtiThreshold = {
      id: newId,
      label: inputData.label,
      threshold: inputData.threshold,
    };
    setDtiThresholds((prevThresholds) => [...prevThresholds, newThreshold]);
    setInputData({
      label: "",
      threshold: "",
    });
    setAddClick(false);
  };

  const handleCancel = () => {
    setInputData({
      label: "",
      threshold: "",
    });
    setAddClick(false);
  };

  const handleDeleteThreshold = (id: number) => {
    setDtiThresholds((prevThresholds) =>
      prevThresholds.filter((threshold) => threshold.id !== id)
    );
  };

  const [selectedThresholds, setSelectedThresholds] = useState<number[]>([]);

  const handleCheckboxChange = (id: number) => {
    setSelectedThresholds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((thresholdId) => thresholdId !== id)
        : [...prevSelected, id]
    );
  };

  const [editId, setEditId] = useState<number | null>(null);
  const [editData, setEditData] = useState({ label: "", threshold: "" });

  const handleEditClick = (id: number, label: string, threshold: string) => {
    setEditId(id);
    setEditData({ label, threshold });
  };

  const handleEditInputChange = (key: string, value: string) => {
    setEditData((prev) => ({ ...prev, [key]: value }));
  };

  const handleSaveChanges = (id: number) => {
    setDtiThresholds((prevThresholds) =>
      prevThresholds.map((threshold) =>
        threshold.id === id
          ? {
              ...threshold,
              label: editData.label,
              threshold: editData.threshold,
            }
          : threshold
      )
    );
    setEditId(null);
  };

  const handleCancelEdit = () => {
    setEditId(null);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box sx={{ padding: "20px", borderBottom: "1px solid #D5E1F9" }}>
        <Typography
          sx={{ color: "#374151", fontSize: "18px", fontWeight: "700" }}
        >
          DTI Score Settings
        </Typography>
      </Box>
      <Box
        sx={{
          margin: { xs: "20px 0 0 0", sm: "20px" },
          padding: "20px",
          background: "#F7FAFF",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            padding: "0 20px",
            background: "#FFFFFF",
            borderRadius: "8px",
            border: "1px solid #D5E1F9",
            boxShadow: "4px 4px 10px rgba(213, 225, 249, 0.25)",
          }}
        >
          <Box
            sx={{
              paddingY: "20px",
              borderBottom: "1px solid #D5E1F9",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Payment />
            <Typography
              sx={{
                color: "#374151",
                fontSize: "18px",
                fontWeight: "600",
                marginLeft: 2,
              }}
            >
              Payments
            </Typography>
          </Box>
          {dataFields.map((field, index) => (
            <SettingField
              key={index}
              label={field.label}
              value={field.value}
              isLast={index === dataFields.length - 1}
            />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          margin: { xs: "20px 0 0 0", sm: "20px" },
          padding: "20px",
          background: "#F7FAFF",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            padding: "0 20px",
            background: "#FFFFFF",
            borderRadius: "8px",
            border: "1px solid #D5E1F9",
            boxShadow: "4px 4px 10px rgba(213, 225, 249, 0.25)",
          }}
        >
          <Box
            sx={{
              paddingY: "20px",
              borderBottom: "1px solid #D5E1F9",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DTIThreshold />
            <Typography
              sx={{
                color: "#374151",
                fontSize: "18px",
                fontWeight: "600",
                marginLeft: 2,
              }}
            >
              DTI Threshold
            </Typography>
          </Box>
          <Box sx={{ paddingY: "20px", borderBottom: "1px solid #D5E1F9" }}>
            {addClick === true ? (
              <Grid container spacing={2}>
                <Grid size={{ xs: 12, sm: 6, lg: 3.5 }}>
                  <InputComponent
                    placeholder="Enter DTI Label"
                    value={inputData.label}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange("label", e.target.value)
                    }
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6, lg: 2.5 }}>
                  <InputComponent
                    placeholder="Enter Threshold %"
                    value={inputData.threshold}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputChange("threshold", e.target.value)
                    }
                  />
                </Grid>

                <Grid
                  size={{ xs: 12, lg: 6 }}
                  sx={{
                    display: { xs: "flex", sm: "" },
                    flexDirection: { xs: "column-reverse", sm: "row" },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      color: " #001F3D",
                      background: "#ffffff",
                      padding: "10px 16px",
                      borderRadius: "10px",
                      border: "1px solid #000000",
                      fontSize: "14px",
                      fontWeight: "700",
                      height: "48px",
                      marginRight:{xs:0,sm:2},
                      marginBottom: { xs: 1, sm: "" },
                    }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      color: theme.palette.primary.contrastText,
                      background: theme.palette.primary.light,
                      padding: "10px 16px",
                      borderRadius: "10px",
                      fontSize: "14px",
                      fontWeight: "700",
                      height: "48px",
                      marginBottom: { xs: 1, sm: "" },
                    }}
                    startIcon={<AddIcon />}
                    onClick={handleAddThreshold}
                  >
                    Add DTI Threshold
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: theme.palette.primary.contrastText,
                  background: theme.palette.primary.light,
                  padding: "10px 16px",
                  borderRadius: "10px",
                  fontSize: "14px",
                  fontWeight: "700",
                  height: "48px",
                }}
                startIcon={<AddIcon />}
                onClick={() => setAddClick(true)}
              >
                Add DTI Threshold
              </Button>
            )}
          </Box>

          <Box>
            {dtiThresholds.map((threshold) => {
              const isSelected = selectedThresholds.includes(threshold.id);
              return (
                <Box
                  key={threshold.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px dashed #D5E1F9",
                    width: "100%",
                    "&:last-child": { borderBottom: "none" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "90%",
                        lg: "70%",
                        xl: "50%",
                      },
                      padding: "10px 20px 10px 10px",
                      borderRadius: "8px",
                      marginY: "15px",
                      border: isSelected
                        ? "3px solid #1F3A91"
                        : "1px solid #D5E1F9",
                      boxShadow: "4px 4px 10px 0px rgba(182, 218, 255, 0.25)",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    {editId === threshold.id ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", lg: "row" },
                            gap: 1,
                            width: { xs: "100%", sm: "80%",md:"100%" },
                          }}
                        >
                          <InputComponent
                            placeholder="Edit DTI Label"
                            value={editData.label}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleEditInputChange("label", e.target.value)}
                          />
                          <InputComponent
                            placeholder="Edit Threshold"
                            value={editData.threshold}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) =>
                              handleEditInputChange("threshold", e.target.value)
                            }
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                            gap: 1,
                            flexDirection:{xs:"column",md:"row"},
                            width:"100%",
                            justifyContent:"flex-end"
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              color: theme.palette.primary.contrastText,
                              background: theme.palette.primary.light,
                              padding: "10px 16px",
                              borderRadius: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              height: "48px",
                              marginTop: { xs: "10px", sm: "" },
                              width: { xs: "100%", sm: "fit-content" },
                            }}
                            onClick={() => handleSaveChanges(threshold.id)}
                          >
                            Save Changes
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              color: " #001F3D",
                              background: "#ffffff",
                              padding: "10px 16px",
                              borderRadius: "10px",
                              border: "1px solid #000000",
                              fontSize: "14px",
                              fontWeight: "700",
                              height: "48px",
                              width: { xs: "100%", sm: "fit-content" },
                            }}
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            background: "#fff",
                            borderRadius: "30px",
                          }}
                        >
                          <Checkbox
                            sx={{ marginRight: "10px" }}
                            checked={isSelected}
                            onChange={() => handleCheckboxChange(threshold.id)}
                          />
                          <Typography
                            sx={{
                              color: "#111928",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {threshold.label} - {threshold.threshold}%
                          </Typography>
                        </Box>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 2 }}
                        >
                          <Box
                            onClick={() =>
                              handleEditClick(
                                threshold.id,
                                threshold.label,
                                threshold.threshold
                              )
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <Edit />
                          </Box>
                          <Box
                            onClick={() => handleDeleteThreshold(threshold.id)}
                            sx={{ cursor: "pointer" }}
                          >
                            <DeleteIcon />
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DTISetting;
