import React from "react";
import { Box, Typography, Checkbox } from "@mui/material";

interface FeatureCardProps {
  title: string;
  icon?: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  title,
  icon,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 2,
        border: "0.5px solid #F0F5FF",
        borderRadius: 2,
        width: "250px",
        textAlign: "center",
        boxShadow: "0px 9px 14.7px 0 #0000000A",
        backgroundColor: "#FFFFFF",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out",
        opacity: disabled ? 0.5 : 1,
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (!disabled) {
          onChange(!checked);
        }
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: 600 }}>
          {title}
        </Typography>
        <Checkbox
          onClick={(e) => {
            if (!disabled) {
              onChange(!checked);
            }
          }}
          checked={checked}
          onChange={(e) => {
            if (!disabled) {
              onChange(e.target.checked);
            }
          }}
          sx={{ padding: 0 }}
          disabled={disabled}
        />
      </Box>
      <Box sx={{ marginTop: 1 }}>{icon}</Box>
    </Box>
  );
};

export default FeatureCard;
