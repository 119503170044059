import {
  Box,
  Button,
  ButtonGroup,
  Grid2 as Grid,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Panel from "../../components/Panel";
import { useEffect, useState } from "react";
import { GetCustomers, UpdateCustomer } from "../../api/customerAPI";
import moment from "moment";
import InputComponent from "../../components/form/input";
import SearchIcon from "../../assets/IconComponents/SearchIcon";
import ViewIcon from "../../assets/media/icons/ViewIcon.png";
import EditIcon from "../../assets/media/icons/EditIcon.png";
import {
  DataTable,
  PaginationControl,
} from "../../components/form/TableComponent";
import { useAlertMessage } from "../../utils/useAlertMessage";
import TruncatedTooltipText from "../../components/form/ToolTip";
import EditModal from "../../components/form/EditModal";
import {
  FormInputCol,
  FormInputRow,
} from "../../components/form/FormRolColInput";
import { Controller, useForm } from "react-hook-form";
import UserIconGray from "../../assets/IconComponents/UserIconGray";
import AddressIcon from "../../assets/IconComponents/AddressIcon";
import StoreIcon from "../../assets/IconComponents/StoreIcon";
import SelectComponent from "../../components/form/SelectComponent";
import Homework from "../../assets/IconComponents/Homework";
import Location from "../../assets/IconComponents/Location";
import MyLocation from "../../assets/IconComponents/MyLocation";
import States from "../../assets/json/states.json";
import { FormData } from "./types";
import DriverLicense from "../../assets/IconComponents/DriverLicense";
import SsnNumber from "../../assets/IconComponents/SsnNumber";
import CountryIcon from "../../assets/IconComponents/Country";
import AddIcon from "../../assets/IconComponents/AddIcon";
import CircularSpinner from "../../components/Loader/CircularLoader";
import BusinessIcon from "../../assets/IconComponents/BussinessIcon";

export const getFullName = (firstName: any, lastName: any) => {
  return [firstName, lastName].join(" ");
};

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  color: "#ff0000",
  border: "none",
  height: "48px !important",
  "& .MuiButtonGroup-grouped": {
    border: "1px solid #D5E1F9 !important",
  },
  "&:hover": {
    boxShadow: "none !important",
  },
}));

const countries = Object.keys(States).map((state) => ({
  value: state,
  label: state,
}));

const CustomersPage = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [customerList, setCustomerList] = useState<any>([]);
  const [totalRecordsCount, setTotalRecordsCount] = useState<any>(0);
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [query, setQuery] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
    nameQuery: "",
    orderBy: "createdDate",
    order: "desc",
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [userInfo, setUserInfo] = useState<any>({});
  const [searchValue, setSearchValue] = useState("");
  const notification = useAlertMessage();

  useEffect(() => {
    getData(query, true);
  }, []);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      getData({ ...query, nameQuery: searchValue, pageNumber: 1 });
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [searchValue]);

  const handleInputChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const getData: any = async (query: any, onInitialCall: boolean) => {
    toggleLoading(true);
    if (!onInitialCall) {
      setQuery(query);
    }
    try {
      const result: any = await GetCustomers(query);
      setCustomerList(result?.data.data || []);
      setTotalRecordsCount(result.data.totalCount);
      setTimeout(() => toggleLoading(false), 500);
    } catch (err) {
      console.error("");
      notification.errorNotification(
        "Error",
        "Error in fetching customer list."
      );
      toggleLoading(false);
    }
  };

  const createSortHandler = (key: string) => {
    getData({
      ...query,
      pageNumber: 1,
      order: query.order?.toLowerCase() === "asc" ? "desc" : "asc",
      orderBy: key,
    });
  };

  const handlePageChange = (event: any, value: any) => {
    getData({
      ...query,
      pageNumber: value,
    });
  };

  const selectCustomerType = (type: string) => {
    getData({
      ...query,
      pageNumber: 1,
      customerType: type,
    });
  };

  const handleClose = () => {
    setOpen(false);
    if (userInfo.customerType === "Customer") {
      resetCustomerForm();
    } else {
      resetBusinessForm();
    }
  };

  const [cityList, setCityList] = useState<any[]>([]);

  useEffect(() => {
    if (userInfo.state) {
      onStateSelect(userInfo.state,userInfo.customerType === "Customer" ? "customer" : "business");
    }
  }, [userInfo]);

  const {
    control: customerControl,
    handleSubmit: handleCustomerSubmit,
    watch: watchCustomer,
    formState: { errors: customerErrors },
    reset: resetCustomerForm,
    setValue: setCustomerValue,
  } = useForm<FormData>({ mode: "onChange" });

  const {
    control: businessControl,
    handleSubmit: handleBusinessSubmit,
    watch: watchBusiness,
    formState: { errors: businessErrors },
    reset: resetBusinessForm,
    setValue: setBusinessValue,
  } = useForm<FormData>({ mode: "onChange" });

  const onStateSelect = (state: string, userType: "customer" | "business") => {
    if (!state) return;
  
    const updatedCityList =
      (States as any)[state]?.map((city: string) => ({
        value: city,
        label: city,
      })) || [];
  
    setCityList(updatedCityList);
    const currentCity = userType === "customer" ? userInfo.city : userInfo.city;
  
    const isCurrentCityValid = updatedCityList.some(
      (city:any) => city.value === currentCity
    );
  
    if (isCurrentCityValid) {
      userType === "customer"
        ? setCustomerValue("customer.city", currentCity)
        : setBusinessValue("business.city", currentCity);
    } else {
      userType === "customer"
        ? setCustomerValue("customer.city", "")
        : setBusinessValue("business.city", "");
    }
  };

  const handleUpdate = () => {
    if (userInfo.customerType === "Customer") {
      handleCustomerSubmit(onSubmit)();
    } else {
      handleBusinessSubmit(onSubmit)();
    }
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    setProgress(0);
    let customerObj;
    if (userInfo.customerType === "Customer") {
      customerObj = {
        ...userInfo,
        id: userInfo.id,
        customerType: "Customer",
        firstName: data.customer?.firstName,
        lastName: data.customer?.lastName,
        phoneType: data.customer?.selectedPhone,
        phoneNumber: data.customer?.phoneNumber,
        email: data.customer?.email,
        addressLine1: data.customer?.address1,
        addressLine2: data.customer?.address2,
        city: data.customer?.city,
        state: data.customer?.state,
        postalCode: data.customer?.postalCode,
        payFrequency: data.customer?.payFrequency,
        dob: data.customer?.dob,
        ssn: data.customer?.ssn,
        country: data.customer?.country,
        bankName: data.customer?.bankName,
        bankAccNo: data.customer?.bankAccNo,
        routingNo: data.customer?.routingNo,
        employerName: data.customer?.employerName,
        driversLicense: data.customer.driversLicense,
        userName: userInfo.userName,
      };
    } else {
      customerObj = {
        id: userInfo.id,
        customerType: "Business",
        companyName: data.business?.companyName,
        firstName: data.business?.firstName,
        lastName: data.business?.lastName,
        phoneType: data.business?.selectedPhone,
        phoneNumber: data.business?.phoneNumber,
        email: data.business?.email,
        addressLine1: data.business?.address1,
        addressLine2: data.business?.address2,
        city: data.business?.city,
        state: data.business?.state,
        postalCode: data.business?.postalCode,
        payFrequency: data.business?.payFrequency,
        country: data.business?.country,
        bankName: data.business?.bankName,
        bankAccNo: data.business?.bankAccNo,
        routingNo: data.business?.routingNo,
        ein: data.business?.ein,
        employerName: data.business?.employerName,
      };
    }

    try {
      const timer = setInterval(() => {
        setProgress((prev) => (prev < 90 ? prev + 10 : prev));
      }, 200);
      const { data, status } = await UpdateCustomer(userInfo.id, customerObj);
      clearInterval(timer);
      setProgress(100);
      if (status === 200) {
        handleClose();
        getData(query, true);
        notification.successNotification(
          "Success",
          "Customer Updated successfully"
        );
      } else {
        notification.errorNotification(
          "Error",
          data?.content || "Error in updating customer"
        );
      }
    } catch (err) {
      notification.errorNotification(
        "Error",
        (err as any).response.data.message
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container>
      <Grid container size={12}>
        <Grid
          size={{ xs: 6, md: 8 }}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography>
            All customer's related data can be managed from this screen
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, md: 4 }} textAlign={"right"}>
          <Link to={"/acucheck/customers/new"}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.light,
                padding: "10px 16px",
                borderRadius: "10px",
              }}
            >
              Add New Customer
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid size={12} container marginTop={"20px"}>
        <Panel sx={{ width: "100%" }}>
          <Box>
            <Grid size={12} sx={{ padding: "20px 24px 10px" }}>
              <Grid container>
                <Grid size={{ xs: 12, sm: 6 }} container>
                  <Grid size={{ xs: 12, sm: 8, md: 6 }}>
                    <InputComponent
                      onChange={handleInputChange}
                      placeholder="Search"
                      icon={<SearchIcon />}
                      backgroundColor="#fff"
                      borderColor="rgba(213, 225, 249, 0.5)"
                    />
                  </Grid>
                </Grid>
                <Grid
                  size={{ xs: 12, sm: 6 }}
                  sx={{
                    textAlign: { xs: "", sm: "right" },
                    marginTop: { xs: "15px", sm: "0px" },
                  }}
                >
                  <StyledButtonGroup>
                    {[
                      { value: undefined, label: "All" },
                      { value: "Customer", label: "Individual" },
                      { value: "Business", label: "Business" },
                    ].map((type: any) => (
                      <Button
                        key={type.label}
                        onClick={() => selectCustomerType(type.value)}
                        sx={{
                          border: "1px solid #D5E1F9",
                          backgroundColor:
                            query.customerType === type.value
                              ? "#F1F4FF"
                              : "transparent",
                          color:
                            query.customerType === type.value
                              ? "#1F3A91"
                              : "#6B7280",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight:
                              query.customerType === type.value ? 600 : 500,
                          }}
                        >
                          {type.label}
                        </Typography>
                      </Button>
                    ))}
                  </StyledButtonGroup>
                </Grid>
              </Grid>
            </Grid>

            <Grid size={12} rowSpacing={2} rowGap={5}>
              <DataTable
                columns={[
                  {
                    label: "Name",
                    id: "name",
                    render: (row) => {
                      const fullName = getFullName(row.firstName, row.lastName);
                      return (
                        <TruncatedTooltipText
                          text={fullName}
                          tooltipPlacement="top"
                        >
                          <Box
                            onClick={() =>
                              navigate(`/acucheck/customers/${row?.id}/edit`, {
                                state: {
                                  fullName,
                                },
                              })
                            }
                            sx={{
                              cursor: "pointer",
                              fontWeight: "600",
                              textDecoration: "underline",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              // maxWidth: "200px",
                            }}
                          >
                            {fullName}
                          </Box>
                        </TruncatedTooltipText>
                      );
                    },
                  },

                  // { label: "Bank", id: "bank", render: () => "Bank Name" },
                  // {
                  //   label: "Account Type",
                  //   id: "customerType",
                  //   // render: () => "Account Type",
                  // },
                  { label: "Email", id: "email" },
                  { label: "Phone", id: "phoneNumber" },
                  {
                    label: "Onboarding Date",
                    id: "createdDate",
                    render: (row) =>
                      moment(row.createdDate).format("MM/DD/YYYY"),
                  },
                  {
                    label: "Action",
                    id: "action",
                    sortable: true,
                    render: (row) => (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TruncatedTooltipText text="">
                          <Box
                            component={"img"}
                            src={ViewIcon}
                            onClick={() => {
                              handleOpen();
                              setUserInfo(row);
                            }}
                            sx={{
                              width: { xs: "50px", md: "3.2rem" },
                              height: { xs: "50px", md: "3.2rem" },
                            }}
                          />
                        </TruncatedTooltipText>
                        <TruncatedTooltipText
                          text="View Report Details"
                          forceTooltip={true}
                        >
                          <Box
                            component="img"
                            src={EditIcon}
                            onClick={() =>
                              navigate(`/acucheck/customers/${row?.id}/edit`)
                            }
                            sx={{
                              width: { xs: "50px", md: "3.2rem" },
                              height: { xs: "50px", md: "3.2rem" },
                            }}
                          />
                        </TruncatedTooltipText>
                      </Box>
                    ),
                  },
                ]}
                rows={customerList || []}
                isLoading={isLoading}
                orderBy={query.orderBy}
                order={query.order}
                createSortHandler={createSortHandler}
                NodataTitle="Customer's"
                onGetStartedClick={() => navigate("/acucheck/customers/new")}
              />
              <PaginationControl
                page={query.pageNumber}
                rowsPerPage={query.pageSize}
                totalItems={totalRecordsCount}
                handlePageChange={handlePageChange}
              />
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <EditModal
        open={open}
        onClose={handleClose}
        title={`Edit Customer Information`}
        handleUpdate={handleUpdate}
        disabled={!!customerErrors.customer || !!businessErrors.business}
      >
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #DBE9FE",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{ padding: "20px", background: "#F7FAFF", borderRadius: "6px" }}
          >
            {userInfo?.customerType === "Customer" ? (
              <FormInputRow>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.firstName"
                    control={customerControl}
                    defaultValue={userInfo?.firstName || ""}
                    render={({ field }) => (
                      <InputComponent
                        isRequired={true}
                        {...field}
                        icon={<UserIconGray />}
                        label="First Name"
                        placeholder="Enter first name"
                        value={userInfo?.firstName || ""}
                        disabled={true}
                        error={
                          customerErrors.customer?.firstName
                            ? customerErrors.customer.firstName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>

                <FormInputCol md={12}>
                  <Controller
                    name="customer.lastName"
                    control={customerControl}
                    defaultValue={userInfo?.lastName || ""}
                    render={({ field }) => (
                      <InputComponent
                        isRequired={true}
                        {...field}
                        icon={<UserIconGray />}
                        label="Last Name"
                        placeholder="Enter last name"
                        value={userInfo?.lastName || ""}
                        disabled={true}
                        error={
                          customerErrors.customer?.lastName
                            ? customerErrors.customer.lastName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="customer.dob"
                    control={customerControl}
                    defaultValue={userInfo?.dob || ""}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<UserIconGray />}
                        label="DOB"
                        value={
                          userInfo?.dob
                            ? new Date(userInfo.dob).toLocaleDateString(
                                "en-US",
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                }
                              )
                            : "-"
                        }
                        disabled={true}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.address1"
                    control={customerControl}
                    defaultValue={userInfo?.addressLine1 || ""}
                    rules={{ required: "Address Line 1 is required" }}
                    render={({ field }) => (
                      <InputComponent
                        isRequired={true}
                        {...field}
                        icon={<AddressIcon />}
                        label="Address Line 1 (Street Address)"
                        placeholder="Enter address"
                        error={
                          customerErrors.customer?.address1
                            ? customerErrors.customer.address1.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.address2"
                    control={customerControl}
                    defaultValue={userInfo?.addressLine2 || ""}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<StoreIcon />}
                        label="Address Line 2 (Post office number, Suite Number, etc)"
                        placeholder="Enter post office number, suite number, etc."
                        error={
                          customerErrors.customer?.address2
                            ? customerErrors.customer.address2.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.driversLicense"
                    control={customerControl}
                    defaultValue={userInfo?.driversLicense || ""}
                    rules={{
                      pattern: {
                        value: /^[a-zA-Z0-9]{4,13}$/,
                        message:
                          "Driver’s License must be 4-10 alphanumeric characters.",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<DriverLicense />}
                        label="Driver’s License Number"
                        placeholder="Enter Driver’s License Number"
                        error={
                          customerErrors.customer?.driversLicense
                            ? customerErrors.customer.driversLicense.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>

                <FormInputCol md={12}>
                  <Controller
                    name="customer.ssn"
                    control={customerControl}
                    defaultValue={userInfo?.ssn || ""}
                    rules={{
                      pattern: {
                        value: /^\d{4}$/,
                        message: "SSN Number must be exactly 4 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<SsnNumber />}
                        label="SSN Number(Last 4 digits)"
                        placeholder="Enter SSN Number"
                        error={
                          customerErrors.customer?.ssn
                            ? customerErrors.customer.ssn.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.country"
                    control={customerControl}
                    defaultValue="USA"
                    render={({ field }) => (
                      <InputComponent
                        // isRequired={true}
                        {...field}
                        icon={<CountryIcon />}
                        label="Country"
                        disabled={true}
                        error={
                          customerErrors.customer?.country
                            ? customerErrors.customer.country.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.state"
                    control={customerControl}
                    defaultValue={userInfo?.state || ""}
                    render={({ field }) => {
                      const stateOptions = countries;
                      const selectedOption = stateOptions?.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={stateOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                            onStateSelect(newValue.value,"customer");
                          }}
                          label="State"
                          placeholder="Select State"
                          fullWidth={true}
                          icon={<Homework />}
                          error={
                            customerErrors.customer?.state
                              ? customerErrors.customer?.state.message
                              : null
                          }
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.city"
                    control={customerControl}
                    defaultValue={userInfo?.city || ""}
                    render={({ field }) => {
                      const cityOptions = cityList;
                      const selectedOption = cityOptions?.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={cityOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                          }}
                          icon={<Location />}
                          label="City"
                          placeholder="Select City"
                          error={
                            customerErrors.customer?.city
                              ? customerErrors.customer?.city.message
                              : null
                          }
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="customer.postalCode"
                    control={customerControl}
                    defaultValue={userInfo?.postalCode || ""}
                    rules={{
                      required: "Zip Code is required",
                      pattern: {
                        value: /^[0-9]{5}$/,
                        message:
                          "Zip Code must be exactly 5 digits",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        isRequired={true}
                        {...field}
                        icon={<MyLocation />}
                        label="Zip Code"
                        placeholder="Enter zip code"
                        error={
                          customerErrors.customer?.postalCode
                            ? customerErrors.customer?.postalCode.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            ) : (
              <FormInputRow>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="business.companyName"
                    control={businessControl}
                    defaultValue={userInfo?.companyName || ""}
                    rules={{
                      required: "Buiness name is required",
                      pattern: {
                        value: /^[A-Za-z ]+$/i,
                        message: "Buiness name can only contain alphabets",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        isRequired={true}
                        disabled 
                        icon={<BusinessIcon />}
                        label="Business Name"
                        placeholder="Enter business name"
                        error={
                          businessErrors.business?.companyName
                            ? businessErrors.business.companyName.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="business.ein"
                    control={businessControl}
                    defaultValue={userInfo?.ein || ""}
                    rules={{
                      pattern: {
                        value: /^[0-9]{9}$/,
                        message: "EIN must contain exactly 9 numbers",
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <InputComponent
                        {...field}
                        maxLength={9}
                        icon={<SsnNumber />}
                        label="EIN"
                        placeholder="Enter EIN"
                        error={error?.message}
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="business.address1"
                    control={businessControl}
                    defaultValue={userInfo?.addressLine1 || ""}
                    rules={{ required: "Address Line 1 is required" }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        isRequired={true}
                        icon={<AddressIcon />}
                        label="Address Line 1 (Street Address)"
                        placeholder="Enter address"
                        error={
                          businessErrors.business?.address1
                            ? businessErrors.business.address1.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12} xl={12}>
                  <Controller
                    name="business.address2"
                    control={businessControl}
                    defaultValue={userInfo?.addressLine2 || ""}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<StoreIcon />}
                        label="Address Line 2 (Post office number, Suite Number, etc)"
                        placeholder="Enter post office number, suite number, etc."
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.country"
                    control={customerControl}
                    defaultValue="USA"
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        icon={<CountryIcon />}
                        label="Country"
                        disabled={true}
                        error={
                          customerErrors.business?.country
                            ? customerErrors.business?.country.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.state"
                    control={businessControl}
                    defaultValue={userInfo?.state || ""}
                    render={({ field }) => {
                      const stateOptions = countries;
                      const selectedOption = stateOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={stateOptions}
                          value={selectedOption}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                            onStateSelect(newValue.value,"business");
                          }}
                          label="State"
                          placeholder="Select State"
                          fullWidth={true}
                          icon={<Homework />}
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.city"
                    control={businessControl}
                    defaultValue={userInfo?.city || ""}
                    render={({ field }) => {
                      const cityOptions = cityList;
                      const selectedOption = cityOptions.find(
                        (option) => option.value === field.value
                      );
                      return (
                        <SelectComponent
                          options={cityOptions}
                          value={selectedOption || null}
                          onChange={(newValue: any) => {
                            if (newValue) {
                              field.onChange(newValue.value);
                            }
                          }}
                          icon={<Location />}
                          label="City"
                          placeholder="Select City"
                        />
                      );
                    }}
                  />
                </FormInputCol>
                <FormInputCol md={12}>
                  <Controller
                    name="business.postalCode"
                    control={businessControl}
                    defaultValue={userInfo?.postalCode || ""}
                    rules={{
                      required: "Zip Code is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "Only numbers are allowed for Zip Code",
                      },
                    }}
                    render={({ field }) => (
                      <InputComponent
                        {...field}
                        maxLength={5}
                        isRequired={true}
                        icon={<MyLocation />}
                        label="Zip Code"
                        placeholder="Enter zip code"
                        error={
                          businessErrors.business?.postalCode
                            ? businessErrors.business?.postalCode.message
                            : null
                        }
                      />
                    )}
                  />
                </FormInputCol>
              </FormInputRow>
            )}
          </Box>
        </Box>
      </EditModal>
      {loading && <CircularSpinner progress={progress} />}
    </Grid>
  );
};

export default CustomersPage;
