import React, { useState } from 'react';
import {
    Box, Button, Typography, Paper, Grid2 as Grid,
    OutlinedInput, FormControl, InputAdornment,
    IconButton, FormHelperText,
    CircularProgress,
    useTheme
} from '@mui/material';
import { styled, useMediaQuery } from '@mui/system';
import { passwordUpdate } from '../../api/AuthAPI';
import Acufi from "../../assets/media/icons/acufi.png";
import { EmailOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { useAlertMessage } from '../../utils/useAlertMessage';
import { AxiosError } from 'axios';
import loginFormValidator from '../../utils/validations/loginFormValidator';

const FormSection = styled(Box)(({ theme }) => ({
    // height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
}));

const ForcePasswordUpdate = ({ username, currentPassword, onUpdatePassword }: any) => {
    const [showPassword, togglePassword] = useState(false);
    const [showPasswordNew, toggleNewPassword] = useState(false);
    const [isSubmitted, toggleSubmit] = useState(false);
    const notification = useAlertMessage();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmNewPassword: ''
        },
        validationSchema: loginFormValidator.PasswordUpdateValidator,
        onSubmit: async (values) => {
            try {
                if (isSubmitted) {
                    console.info('Login is in progress');
                    return;
                }

                if (currentPassword === values.newPassword) {
                    notification.errorNotification('Error', 'Current Password and new password cannot be same')
                    return;
                }

                toggleSubmit(true);

                const result: any = await passwordUpdate(username, currentPassword, values.newPassword);

                notification.successNotification('Success', 'Password update successfully. Please login to continue.')

                onUpdatePassword(false);
                toggleSubmit(false);
                return;

            } catch (err: AxiosError | any) {
                toggleSubmit(false);
                console.error(err);
                notification.errorNotification('Error', 'Something went wrong!. Please contact support.');
            }
        }
    });

    return (
        <FormSection>
            <div>
                {/* <Link to={'https://banksure.ai/'} target='_blank'> */}
                    <Box component={"img"} src={Acufi} alt="logo" sx={{ width: "300px", height: "100px" }} />
                {/* </Link> */}
            </div>

            <Paper elevation={3} style={{ padding: '20px', borderRadius: '8px', width: '100%', maxWidth: isSmallScreen ? '90%' : '60%' }}>
                <Typography variant="h4" gutterBottom align='center'>
                    <b>
                        Update Your Password
                    </b>
                </Typography>
                <br />
                <br />
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} rowSpacing={4}>
                        <Grid size={12}>
                            <Typography>
                                New Password
                            </Typography>

                            <FormControl variant="standard" fullWidth>
                                <OutlinedInput
                                    name="newPassword"
                                    type={showPasswordNew ? 'text' : 'password'}
                                    placeholder="Enter your new password"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LockOpenIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => toggleNewPassword(!showPasswordNew)}>
                                                {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onChange={formik.handleChange}
                                    value={formik.values.newPassword}
                                    error={!formik.isSubmitting && !!formik.errors.newPassword}
                                />
                                {formik.errors.newPassword ?
                                    <FormHelperText error={!!formik.errors.newPassword}>
                                        {formik.errors.newPassword}
                                    </FormHelperText> : ''
                                }
                            </FormControl>
                        </Grid>
                        <Grid size={12}>
                            <Typography>
                                Confirm New Password
                            </Typography>
                            <FormControl variant="standard" fullWidth>
                                <OutlinedInput
                                    name="confirmNewPassword"
                                    placeholder="Enter your confirm new password"
                                    type={showPassword ? 'text' : 'password'}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LockOpenIcon />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => togglePassword(!showPassword)}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onChange={formik.handleChange}
                                    value={formik.values.confirmNewPassword}
                                    error={!formik.isSubmitting && !!formik.errors.confirmNewPassword}
                                />
                                {formik.errors.confirmNewPassword ?
                                    <FormHelperText error={!!formik.errors.confirmNewPassword}>
                                        {formik.errors.confirmNewPassword}
                                    </FormHelperText> : ''
                                }
                            </FormControl>
                        </Grid>

                        <Grid size={12}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                style={{ marginTop: '16px', backgroundColor: '#264D8E', height: 50 }}
                            >
                                {isSubmitted ? <CircularProgress size={20} sx={{ height: '20px', width: '20px', color: 'white' }} /> : 'Update Password'}
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </Paper>
        </FormSection>
    );
}

export default ForcePasswordUpdate;
