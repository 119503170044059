import { RouteObject, Navigate } from 'react-router-dom';
import DashboardPage from '../pages/dashboard';
import IBAReportPage from '../pages/ibaReport';
import CustomersPage from '../pages/customers';
import PaymentProcessingPage from '../pages/paymentProcessing';
import InvoiceRecordsPage from '../pages/invoiceRecords';
import UsersPage from '../pages/users';
import ActivityLogsPage from '../pages/activityLogs';
import ConnectPage from '../pages/connect';
import IBAReportAnalysis from '../pages/ibaReport/ibaAnalysis';
import IBARequest from '../pages/ibaReport/IBARequest';
import AddNewCustomerPage from '../pages/customers/AddNewCustomer';
import Page from '../components/@extended/Page';
import InvoiceReport from '../pages/invoiceRecords/InvoiceReport';
import EditCustomer from '../pages/customers/EditCustomer';
import DTICalculator from '../pages/dti';
import IBASetting from '../pages/settings/IBASetting';
import NotificationSetting from '../pages/settings/NotificationSetting';
import DTISetting from '../pages/settings/DTISetting';
import SettingsLayout from '../pages/settings';
import AddNewUser from '../pages/users/AddNewUser';
import UsersProfile from '../pages/users/UsersProfile';

const AcuPayRouter: RouteObject[] = [
  {
    path: 'dashboard',
    // element: <Page component={<DashboardPage />} title='Dashboard' />,
    element: <div>Acu Pay Dashboard Component</div>
  },
  {
    path: '',
    element: <Navigate to="/acupay/dashboard" />,
  },
];

export default AcuPayRouter;
