import { createContext, useState } from 'react';

export const DefaultAppContext = {
    app: {
        pageTitle: process.env.REACT_APP_DEFAULT_PAGE_TITLE || 'Dashboard',
        user: {
            firstName: '',
            lastName: '',
            email: '',
            accountType: "", 
        },
        isLoggedIn: false
    },
    updateCtx: (newState: any = {}) => newState
};

export const AppContext = createContext(DefaultAppContext);

// A wrapper component for app context
export default function AppContextProvider({ children }: any) {

    // State for app
    const [appCtx, updateAppCtx] = useState(DefaultAppContext.app);

    return <AppContext.Provider value={{ app: appCtx, updateCtx: updateAppCtx }}>
        {children}
    </AppContext.Provider>
};
