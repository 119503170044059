import React from "react";
import { Box, Tooltip } from "@mui/material";

interface TruncatedTooltipTextProps {
  text: string;
  maxLength?: number;
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  children?: React.ReactNode;
  forceTooltip?: boolean;
}

const TruncatedTooltipText: React.FC<TruncatedTooltipTextProps> = ({
  text,
  maxLength = 25,
  tooltipPlacement = "top",
  children,
  forceTooltip = false,
}) => {
  const isTextTruncated = text?.length > maxLength;

  return (
    <Tooltip
      title={isTextTruncated || forceTooltip ? text : ""}
      arrow
      placement={tooltipPlacement}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -8], 
            },
          },
        ],
      }}
    >
      <Box
        component="span"
        sx={{
          display: "inline-block",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: `${maxLength * 8}px`, 
          cursor: "pointer",
        }}
      >
        {children ? children : isTextTruncated ? `${text.slice(0, maxLength)}...` : text}
      </Box>
    </Tooltip>
  );
};

export default TruncatedTooltipText;
