import AxiosInstance from './http';


/**
 * 
 * @param queryObj
 * @returns
 */
export async function CreateNewIBAReqest(customerId: any, sendVia: string): Promise<any> {
    try {
        const body = {
            CustomerId: customerId,
            SendVia: sendVia == 'SMS' ? ['sms'] : sendVia == 'Email' ? ['email'] : ['sms', 'email']
        };

        const response = await AxiosInstance.post('/InstantBankAnalysis/ReportRequest', body);

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetReportRequestsList(queryObj: any = {}): Promise<any> {
    try {
        const query = { ...queryObj };
        if (query.status === 'All') {
            delete query.status;
        }
        query.order = (query?.order || '')?.toUpperCase();
        const response = await AxiosInstance.get('/InstantBankAnalysis/ReportRequests', {
            params: query
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * @description Get report by report Id
 * @param reportId
 * @returns 
 */
export async function GetReportByReportId(reportId: number): Promise<any> {
    try {
        const response = await AxiosInstance.get(`/InstantBankAnalysis/ReportRequests/${reportId}`);

        return response?.data;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * @description Get report by report Id
 * @param reportId
 * @returns 
 */
export async function GetAccountTransactionsByAccountId(accountId: number, pageNumber = 1, pageSize = 100): Promise<any> {
    try {
        const response = await AxiosInstance.get(`/InstantBankAnalysis/BankingTransactionsByAccount/${accountId}`, {
            params: {
                pageNumber,
                pageSize
            }
        });

        return response?.data;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * @description Get Bank account details
 * @param customerId
 * @returns 
 */
export async function GetBankAccountDetails(customerId: string): Promise<any> {
    try {
        const response = await AxiosInstance.get(`/InstantBankAnalysis/GetBankAccountByCustomerId/${customerId}`);

        return response?.data;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetTransactionHistory(queryObj: object): Promise<any> {
    try {
        const response = await AxiosInstance.get('/InstantBankAnalysis/BankingTransactions');

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetAccountDetails(queryObj: object): Promise<any> {
    try {
        const response = await AxiosInstance.get('/InstantBankAnalysis/AccountDetails');
        // , {
        //     "userID": "User1",
        //     "cryptVal": "cashedge1"
        // });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetAccountSummary(queryObj: object): Promise<any> {
    try {
        const response = await AxiosInstance.get('/InstantBankAnalysis/AccountsSummary', {
            params: {
                "acctId": "8765432",
                "acctType": "SDA",
                "extAcctType": "SDA",
                "userID": "User1",
                "cryptVal": "cashedge1"
            }
        });

        return response;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};

/**
 * 
 * @param queryObj 
 * @returns 
 */
export async function GetFinancialReport(queryObj: object): Promise<any> {
    try {

        const response = await AxiosInstance.get('/InstantBankAnalysis/FinancialReport', {
            params: queryObj
        });

        return response?.data;
    } catch (err) {
        console.error('err', err);
        throw err;
    }
};