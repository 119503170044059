import React, { useEffect, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import {
  CartesianGrid,
  LineChart,
  Line,
  ReferenceLine,
  XAxis,
  YAxis,
} from "recharts";

interface LineChartProps {
  labels?: string[];
  dataPoints?: number[];
  threshold?: number;
  title?: string;
  subTitle?: string;
  XLabel?: string;
  isLoading?: boolean;
}

const CustomizedDot = (props: any) => {
  const { cx, cy, value } = props;
  const dotSize = 5;
  const dotColor = value > 350 ? "#FF0000" : "#1D4F9E";
  return <circle cx={cx} cy={cy} r={dotSize} fill={dotColor} />;
};

const LineCharts: React.FC<LineChartProps> = ({
  title,
  subTitle,
  isLoading,
  XLabel = "Monthly Installment",
}) => {
  const lineData = [
    { frequency: "", amount: 0 },
    { frequency: "", amount: 200 },
    { frequency: "", amount: 400 },
    { frequency: "", amount: 600 },
    { frequency: "", amount: 780 },
  ];

  const [chartWidth, setChartWidth] = useState(300);
  const [chartHeight, setChartHeight] = useState(200);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 420) {
        setChartWidth(200);
        setChartHeight(200);
      } else if (window.innerWidth < 600) {
        setChartWidth(300);
        setChartHeight(200);
      } else if (window.innerWidth < 666) {
        setChartWidth(230);
        setChartHeight(200);
      } else if (window.innerWidth < 765) {
        setChartWidth(260);
        setChartHeight(200);
      } else if (window.innerWidth < 900) {
        setChartWidth(300);
        setChartHeight(200);
      } else if (window.innerWidth < 1024) {
        setChartWidth(180);
        setChartHeight(200);
      } else if (window.innerWidth < 1110) {
        setChartWidth(140);
        setChartHeight(200);
      } else if (window.innerWidth < 1200) {
        setChartWidth(160);
        setChartHeight(200);
      } else if (window.innerWidth < 1279) {
        setChartWidth(160);
        setChartHeight(200);
      } else if (window.innerWidth < 1336) {
        setChartWidth(180);
        setChartHeight(200);
      } else if (window.innerWidth < 1448) {
        setChartWidth(200);
        setChartHeight(200);
      } else if (window.innerWidth < 1564) {
        setChartWidth(230);
        setChartHeight(200);
      } else if (window.innerWidth < 1708) {
        setChartWidth(250);
        setChartHeight(200);
      } else if (window.innerWidth < 1760) {
        setChartWidth(300);
        setChartHeight(200);
      } else {
        setChartWidth(300);
        setChartHeight(200);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {isLoading ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Skeleton variant="text" animation="wave" width={chartWidth} height={50} />
          <Skeleton variant="rounded" animation="wave" width={chartWidth} height={chartHeight} />
        </Box>
      ) : (
        <>
          {title && (
            <Box
              sx={{
                background: "#F0F8FE",
                width: "fit-content",
                padding: "10px",
                marginBottom: 2,
                borderRadius: "8px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontSize: { md: "1vw", lg: "0.8vw" },
                  fontWeight: "700",
                  color: "#1A2D51",
                }}
              >
                {title}{" "}
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontSize: { md: "1vw", lg: "0.8vw" },
                  fontWeight: "800",
                  color: "#2553A8",
                }}
              >
                {subTitle}
              </Typography>
            </Box>
          )}
          <LineChart width={chartWidth} height={chartHeight} data={lineData}>
            <CartesianGrid strokeWidth={2} />
            <XAxis
              dataKey="frequency"
              tickSize={0}
              tickMargin={20}
              strokeWidth={2}
              axisLine={false}
            />
            <YAxis
              tickSize={0}
              tickFormatter={(value) => `$${value}`}
              tickMargin={20}
              strokeWidth={2}
              axisLine={false}
              tick={{
                fontFamily: "Inter",
                fontSize: 14,
                fontWeight: 400,
                fill: "#000000",
              }}
            />
            <ReferenceLine y={350} stroke="#FF0000" strokeDasharray="3 3" />
            <Line
              dataKey="amount"
              stroke="#1D4F9E"
              dot={<CustomizedDot />}
              strokeWidth={2}
            />
          </LineChart>
          <Box>
            <Typography
              component={"span"}
              sx={{
                fontSize: { md: "1vw", lg: "0.8vw" },
                fontWeight: "400",
                color: "#000000",
              }}
            >
              {XLabel}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default LineCharts;
