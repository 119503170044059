import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

/**
 * @description Create an axios instance with base domain. If base domain does not exist, then use the current domain.
 */
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "",
});

/**
 * @description Add intercepting actions for the HTTP requests.
 */
AxiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.withCredentials = true; // Attach cookies to every request
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @description Intercept HTTP responses.
 */
AxiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,

  (err) => {
    // Handle Unauthorized error
    if (!err.response || err.response.status === 401) {
      // If the user is not logged in, redirect to login page with the current URL to retain the user state
      const currentLocation = window.location.pathname;
      let toUrl = "/auth/login";
      if (currentLocation === toUrl) {
        throw err;
      }
      if (currentLocation && currentLocation !== "/") {
        toUrl += `?redirectUrl=${currentLocation}`;
      }
      window.location.href = toUrl;
    }
    throw err;
  }
);

export default AxiosInstance;
