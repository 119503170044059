import { Box, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

interface BarChartProps {
  data: any;
  title?: string;
  subTitle?: string;
  threshold?: number;
  axis?: "x" | "y";
  isLoading?: boolean;
}

const BarChart: React.FC<BarChartProps> = ({
  data,
  title,
  subTitle,
  threshold = 0,
  axis = "y",
  isLoading,
}) => {
  const updatedData = {
    ...data,
    datasets: data.datasets.map((dataset: any) => ({
      ...dataset,
      backgroundColor: dataset.data.map((value: number) =>
        value >= threshold ? "#FE7F7F" : "#1D4F9E"
      ),
    })),
  };

  const options = {
    responsive: true,
    indexAxis: "y" as const,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const [chartWidth, setChartWidth] = useState<any>();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      if (width < 395) {
        setChartWidth("250px");
      } else if (width < 480) {
        setChartWidth("300px");
      } else if (width < 560) {
        setChartWidth("380px");
      } else if (width < 710) {
        setChartWidth("450px");
      } else if (width < 1120) {
        setChartWidth("450px");
      } else if (width < 1150) {
        setChartWidth("450px");
      } else if (width < 1250) {
        setChartWidth("410px");
      } else if (width < 1270) {
        setChartWidth("450px");
      } else if (width < 1390) {
        setChartWidth("480px");
      } else if (width < 1520) {
        setChartWidth("530px");
      } else if (width < 1760) {
        setChartWidth("600px");
      } else {
        setChartWidth("700px");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: chartWidth,
      }}
    >
      {isLoading ? (
        <>
          <Box sx={{ display: "flex", flexDirection: "column",mb:2 }}>
            <Skeleton variant="text" animation="wave" width={250} height={50} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={500}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={500}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={450}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={400}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={350}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={300}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={250}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={200}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={150}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={100}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={50}
              height={15}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={0}
              height={15}
            />
          </Box>
        </>
      ) : (
        <>
          {title && (
            <Box
              sx={{
                background: "#F0F8FE",
                width: "fit-content",
                padding: "10px",
                marginBottom: 2,
                borderRadius: "8px",
                display: "flex",
                justifyContent: { xs: "center", sm: "" },
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  fontSize: { md: "1vw", lg: "0.8vw" },
                  fontWeight: "700",
                  color: "#1A2D51",
                }}
              >
                {title}{" "}
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  fontSize: { md: "1vw", lg: "0.8vw" },
                  fontWeight: "800",
                  color: "#2553A8",
                }}
              >
                {subTitle}
              </Typography>
            </Box>
          )}
          <Bar data={updatedData} options={options} />
        </>
      )}
    </Box>
  );
};

export default BarChart;
